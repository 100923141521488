
import { Routes } from '@angular/router';
import { ApplicationAdminGuard,SuperAdminGuard,SecurityAdminGuard } from '../core/guards/auth.guard';
import { IndexComponent } from './pages/index.component';
import { EditAppcycleComponent } from './applicationCycle/edit.component';
import { CycleComponent } from './pages/cycle.component';
import { ApplicationListComponent } from './application-list/application-list.component';
import { AddReviewerComponent } from './committee-assignment/add-reviewer.component';
import { ReviewersComponent } from './pages/reviewers.component';
import { EmailsComponent } from './emails/emails.component';
import { DownloadsComponent } from './downloads/downloads.component';
export const adminRoutes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', canActivate: [ApplicationAdminGuard,SecurityAdminGuard], component: IndexComponent },

  { path: 'cycle',  component: CycleComponent },
  { path: 'application-list', canActivate: [ApplicationAdminGuard], component: ApplicationListComponent },
  { path: 'edit/:id',  component: EditAppcycleComponent },
 // { path: 'reviewers', canActivate: [AdminGuard,SecurityAdminGuard], component: ReviewersComponent },
 { path: 'reviewers', canActivate: [ApplicationAdminGuard,SecurityAdminGuard], component: ReviewersComponent },
 { path: 'add-reviewer', canActivate: [ApplicationAdminGuard,SecurityAdminGuard], component: AddReviewerComponent },
 { path: 'emails', canActivate: [ApplicationAdminGuard], component: EmailsComponent },
 { path: 'downloads', canActivate: [ApplicationAdminGuard], component: DownloadsComponent  },
];
