import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigService } from 'src/app/core/services/config.service';
import { ReviewGridDTO } from 'src/app/review/models/ReviewGridDTO';
import { OthersReviewDTO } from 'src/app/review/models/OthersReviewDTO';

@Injectable({
  providedIn: 'root'
})
export class ModeratorService {

    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
    
    private baseurl = "/moderator/";
    private getAllApplicationsForMyTypeUrl = this.apiUrl + this.baseurl+ "type/";
    
    private getOthersReviewUrl = this.apiUrl + this.baseurl  + "others/";
   

  
  constructor(protected httpClient: HttpClient) { }
  
  public getReviewsByApplicationId(applicationId: number): Observable<OthersReviewDTO[]> {
    //alert("reviewService------"+applicationId);  
    return this.httpClient.get<OthersReviewDTO[]>(this.getOthersReviewUrl + applicationId);
  }

  public getAllApplicationsForType(applicationTypeId: number): Observable<ReviewGridDTO[]> {
    return this.httpClient.get<ReviewGridDTO[]>
      (this.getAllApplicationsForMyTypeUrl + applicationTypeId );
  }
 


}
