
    <div class="container pt-3 pb-5" >
        <div class="row">
          <div class="col-md-3 offset-md-1">
          <label for="applicationPeriodId" class="control-label"  >Application Cycle</label>
  
          <select
            class="form-control mr-1"
            id="applicationPeriodId"
            width="80"
            name="applicationPeriodId"
            (change)="selectChangeApplicationCycleHandler($event)"
          >
            <option [value]="null" >Select an Application Cycle</option>
            <option
              *ngFor="let applicationCycle of liveCycles"
              [value]="applicationCycle.applicationPeriodId"
            >
            {{applicationCycle.applicationCycleName}}
            </option>
          </select>
          </div>
          <div class="col-md-3 ">
          <label for="applicationTypeId" class="control-label"  >Application Type</label>
  
          <select
            class="form-control mr-1"
            id="applicationTypeId"
            width="80"
            name="applicationTypeId"
            (change)="selectChangeApplicationTypeHandler($event)"
          >
            <option [value]="null" >Select an Application Type</option>
            <option
              *ngFor="let applicationType of types"
              [value]="applicationType.applicationTypeId"
            >
            {{applicationType.applicationTypeName}}
            </option>
          </select>
        </div>

        <div class="col-md-3 ">
          <label for="applicationStatusId" class="control-label"  >Application Status</label>
  
          <select
            class="form-control mr-1"
            id="applicationStatusId"
            width="80"
            name="applicationStatusId"
            (change)="selectChangeApplicationStatusHandler($event)"
          >
            <option [value]="null" >Select an Application Status</option>
            <option
              *ngFor="let applicationStatus of applicationStatuses"
              [value]="applicationStatus.applicationStatusId"
            >
            {{applicationStatus.applicationStatusName}}
            </option>
          </select>
        </div>
        <div></div><div></div>
        <div class="col-md-6 offset-md-1">
          <button  class="btn btn-primary" (click)="DownloadPDFs()">
            <i  aria-hidden="false"></i>Download Application PDFs
          </button>
        </div>
   
        </div> 
      </div>