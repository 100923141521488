<div class="row">
    <div class="col-md-12">
        <div class="alert alert-info shadow-sm border border-info">
            Please provide an entry for each past cycle where you applied for a fellowship. Please include all previous submissions, even if you were not awarded a fellowship.
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-sm table-bordered shawdow-sm">
                    <thead>
                        <tr class="bg-light">
                            <th>Application Type</th>
                            <th>Cycle</th>
                            <th>Awarded Fellowship</th>
                            <th>[Actions]</th>
                        </tr>
                        <tr *ngFor="let model of models">
                            <td>{{friendlyApplicationTypeName(model.applicationTypeId)}}</td>
                            <td>{{model.applicationCycleName}} {{model.applicationCycleYear}}</td>
                            <td>{{model.wasFellowshipAwarded ? 'Yes' : 'No'}}</td>
                            <td>
                                <button class="btn btn-sm btn-primary" (click)="editSelected(model.applicationPreviousSubmissionId)" *ngIf="!disabled">Edit</button>&nbsp;
                                <button class="btn btn-sm btn-secondary" (click)="deleteSelected(model.applicationPreviousSubmissionId)" *ngIf="!disabled">Remove</button>
                            </td>
                        </tr>
                        <tr *ngIf="models.length === 0">
                            <td colspan="5">No Previous Submissions entered.</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <button class="btn btn-primary" (click)="addForm()"><i class="fa fa-plus"></i> Add Previous Submission</button>
            </div>
        </div>
        <div class="row mt-1" *ngIf="showForm">
            <div class="col-12">
                <previous-submission-form [applicationId]="applicationId" [previousSubmissionId]="selectedSubmissionId" (savedEvent)="handleRefresh($event)"></previous-submission-form>
            </div>
        </div>
    </div>
</div>