import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { LookupService } from "src/app/core/services/lookup.service";
import { ApplicationDTO } from "src/app/shared/models/ApplicationDTO";
import { FormSectionState } from "../../models/FormSectionState";
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationService } from "../../services/application.service";
import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
import { AuthService } from 'src/app/core/services/auth.service';
import { debounceTime, take } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
declare var $: any;
@Component({
selector: 'application-status',
templateUrl: 'status.component.html'
})
export class ApplicationStatusComponent implements OnInit, OnChanges {
    @Input()
    public application: ApplicationDTO = undefined!;
    
    public applicationStatus: string = "";
    public applicationType: string = "";
    public applicationUpdate: string = "";
    public applicationNumber: string ="";

    @Input()
    public contactInfoSectionState: FormSectionState | undefined = undefined;
    @Input()
    public academicBackgroundSectionState: FormSectionState | undefined = undefined;
    @Input()
    public mentorSectionState: FormSectionState | undefined = undefined;
    @Input()
    public researchProposalSectionState: FormSectionState | undefined = undefined;

    readonly invalidFgCss: string = 'fa fa-square-o';
    readonly validFgCss: string = 'fa fa-check-square-o';
    
    readForSubmission: boolean = false;

    contactInfoCss: string = this.invalidFgCss;
    academicBackgroundCss: string = this.invalidFgCss;
    mentorCss: string = this.invalidFgCss;
    researchProposalCss: string = this.invalidFgCss;
    submittedCss: string = this.invalidFgCss;
    
    isSubmitted: boolean = false;
    isWithdrawn: boolean = false;

    applicationTypes: ApplicationTypeDTO[];
    public appCycleOpen = false;
    
    @Output()
    readyForSubmissionEvent = new EventEmitter<{readyForSubmission: boolean}>(true);

    @Output()
    applicationTypeChanged = new EventEmitter<{changed: boolean}>(true);

    constructor(private lkpSvc: LookupService,
        private ConfigService: ConfigService,
        public authService: AuthService,
        private svc: ApplicationService,
        private toastr: ToastrService,
        private pipe: DatePipe) { }

    ngOnChanges(changes: SimpleChanges) {
        this.determineReadyForSubmission();
        if(this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete)
        {
            this.isSubmitted = true;
        }
        else {
            this.isSubmitted = false;
        }

        if(this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN)
        {
            this.isWithdrawn = true;
        }
        else {
            this.isWithdrawn= false;
        }
       
    }

    determineReadyForSubmission() {
        if (
            this.contactInfoSectionState !== undefined &&
            this.academicBackgroundSectionState !== undefined &&
            this.mentorSectionState !== undefined &&
            this.researchProposalSectionState !== undefined) {

                if (this.contactInfoSectionState.isValid) this.contactInfoCss = this.validFgCss; else this.contactInfoCss = this.invalidFgCss;
                if (this.academicBackgroundSectionState.isValid) this.academicBackgroundCss = this.validFgCss; else this.academicBackgroundCss = this.invalidFgCss;
                if (this.mentorSectionState.isValid) this.mentorCss = this.validFgCss; else this.mentorCss = this.invalidFgCss;
                if (this.researchProposalSectionState.isValid) this.researchProposalCss = this.validFgCss; else this.researchProposalCss = this.invalidFgCss;

                if (this.contactInfoSectionState.isValid &&
                    this.academicBackgroundSectionState.isValid &&
                    this.mentorSectionState.isValid &&
                    this.researchProposalSectionState.isValid 
                    ) {
                        this.readForSubmission = true;                        
                        this.readyForSubmissionEvent.emit({ readyForSubmission: true});
                    } else {
                        this.readForSubmission = false;                        
                        this.readyForSubmissionEvent.emit({ readyForSubmission: false});
                    }
            } else {
                this.readForSubmission = false;                
                this.readyForSubmissionEvent.emit({ readyForSubmission: false});
            }
    }

    ngOnInit() {
        if (this.application) {
            this.lkpSvc.applicationTypes$.subscribe(
                data => {
                    this.applicationType = data.find(m => m.applicationTypeId === this.application.applicationTypeId)?.displayName ?? '';
                    this.applicationTypes = data;
                });
                
            this.lkpSvc.applicationStatuses$.subscribe(
                data => {
                    this.applicationStatus = data.find(m => m.applicationStatusId === this.application.applicationStatusId)?.displayName ?? '';
                });
               
            let lastestUpdateDate = this.application.modifiedOn != null ? this.application.modifiedOn : this.application?.createdOn;
            this.applicationUpdate = this.pipe.transform(lastestUpdateDate, 'medium') ?? 'Unknown';   
            this.applicationNumber = this.application.applicationNumber; 

            if (this.application.applicationStatusId === 3) {
                this.submittedCss = this.validFgCss;
                this.isSubmitted = true;
                
                this.contactInfoCss = this.validFgCss;
                this.academicBackgroundCss = this.validFgCss;
                this.mentorCss = this.validFgCss;
                this.researchProposalCss = this.validFgCss;
            }
            else {
                this.isSubmitted = false;
                this.determineReadyForSubmission();
            }
            this.lkpSvc.currentCycle$.pipe(take(2)).subscribe(
                data => {
                  if (data) {
                    if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                      this.appCycleOpen = true;
                    }
                  }
                }
              );
        }
    }
    withdrawApplication() {
        console.log('withdraw Application!');
        if (confirm('Are you sure you want to withdraw your application??')) {
            this.svc.withdrawApplication(this.application.applicationId).subscribe(
                data => { 
                    //this.handleApplicationSubmission.emit({ submittedApplication : data }); 
                    window.location.reload();
                },
                error => {
                    console.log(JSON.stringify(error));
                    this.toastr.error(error.error, 'Error submitting application');
                  } 
            );
        }
    }

    unWithdrawApplication(){
        console.log('unwithdraw Application!');
        if (confirm('Are you sure you want to Un withdraw your application??')) {
        this.svc.unWithdrawApplication(this.application.applicationId).subscribe(
            data => { 
                //this.handleApplicationSubmission.emit({ submittedApplication : data }); 
                window.location.reload();
            },
            error => {
                console.log(JSON.stringify(error));
                this.toastr.error(error.error, 'Error submitting application');
              } );
        }
    }

    unSubmitApplication(){
        console.log('unSubmit Application!');
        if (confirm('Are you sure you want to unSubmit this application??')) {
        this.svc.unSubmitApplication(this.application.applicationId).subscribe(
            data => {               
                window.location.reload();
            },
            error => {
                console.log(JSON.stringify(error));
                this.toastr.error(error.error, 'Error submitting application');
              } );
        }
    }
    changeApplicationType() {
        console.log('Change Application Type: ' + this.application.applicationTypeId);
        if (confirm('Are you sure you want to change the application type?')) {
            this.svc.updateApplication(this.application).subscribe(
                data => {
                    this.applicationTypeChanged.emit({changed: true});
                    this.close();
                },
                error => {
                    console.log(JSON.stringify(error));
                    this.toastr.error(error.error, 'Error submitting application');
                  } 
            )
        }
        
    }

    goTo(section: string) {
        const ele = document.getElementById(section);
        if (ele) {
            ele.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        }
    }

    close(): void {
        $('#changeTypeModal').modal('hide');
    }
}