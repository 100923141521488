import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageTitleComponent } from './pagetitle.component';
import { ColumnFilterComponent } from './column-filter/column-filter.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DownloadPdfComponent } from './download-pdf/download-pdf.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CccNavcardComponent } from './ccc-navcard/ccc-navcard.component';
import { RouterModule } from '@angular/router';
import { PhonePipe } from './PhonePipe/phone.pipe';
import { FooterComponent } from './footer/footer.component';

/*
import { FooterComponent } from './footer/footer.component';
import { JumpLinkComponent } from './jump-link/jump-link.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

import { SaveIndicatorComponent } from './save-indicator/save-indicator.component';
import { DownloadReportComponent } from './download-report/download-report.component';
import { FormatTitlePipe } from '../award/pipes/format-title.pipe';
*/
@NgModule({
  declarations: [
    PageTitleComponent,
    ColumnFilterComponent,
    DownloadPdfComponent,
    CccNavcardComponent,
    PhonePipe,
    FooterComponent,
  ],
  exports: [
    PageTitleComponent,
    ColumnFilterComponent,
    DownloadPdfComponent,
    CccNavcardComponent,
    PhonePipe,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    DropDownListModule,
    FormsModule,
    RouterModule
  ]
})
export class SharedModule { }
