<div class="my-4" id="AcademicBackgroundSection" *ngIf="academicBackgroundFG">
    <h3>Academic Background</h3>

    <div [formGroup]="academicBackgroundFG">

    <div *ngIf="applicationTypeId === UNDEFINED" class="row">
        <div class="col-12 text-center">
            <i class="fa fa-circle-notch fa-spin"></i> Loading Academic Background...
        </div>
    </div>
    <div *ngIf="applicationTypeId !== UNDEFINED">
        <!-- Biosketch Placeholder (ALL) -->
        <div class="card border-secondary my-4" [ngClass]="{'border-danger' : f.hasBiosketchDocument.invalid}" *ngIf="applicationTypeId !== 2">
            <div class="card-header">Application Biosketch</div>
            <div class="card-body">
                <div class="alert shadow-sm">
                    <i class="fas fa-info-circle"></i> Your biosketch must be in NIH format that includes a personal statement.  Include only publications that are published or in-press (no “submitted” or “in preparation” please).  List impact factor and ranking in field for each publication.
                </div>

                <document-upload [applicationId]="applicationId"
                    [application]="application"
                    [documentUploadId]="applicationBiosketchId"                    
                    [documentTypeId]="docSvc.APPLICATIONBIOSKETCHDOCTYPE"
                    [documentDto]="biosketchDocument"
                    (fileAttached)="onFileAttached($event)">
                </document-upload>

                <div *ngIf="f.hasBiosketchDocument?.invalid">                                
                    <small *ngIf="f.hasBiosketchDocument.errors?.required" class="text-danger">This file is required</small>                      
                    </div>
            </div>
        </div>

        <div class="card border-secondary my-4" [ngClass]="{'border-danger' : f.hasBiosketchDocument.invalid}" *ngIf="applicationTypeId === 2">
            <div class="card-header">Application Resume or Biosketch</div>
            <div class="card-body">
                <div class="alert shadow-sm">
                    <i class="fas fa-info-circle"></i>Please upload a resume that includes a personal statement.  You may submit a biosketch. If you do, it must be in NIH format that includes a personal statement. Include only publications that are published or in-press (no “submitted” or “in preparation” please). List impact factor and ranking in field for each publication.
                </div>

                <document-upload [applicationId]="applicationId"
                    [application]="application"
                    [documentUploadId]="applicationBiosketchId"                    
                    [documentTypeId]="docSvc.APPLICATIONBIOSKETCHDOCTYPE"
                    [documentDto]="biosketchDocument"
                    (fileAttached)="onFileAttached($event)">
                </document-upload>

                <div *ngIf="f.hasBiosketchDocument?.invalid">                                
                    <small *ngIf="f.hasBiosketchDocument.errors?.required" class="text-danger">This file is required</small>                      
                    </div>
            </div>
        </div>

        <div class="card border-secondary mb-4">
            <div class="card-header">Current Academic Information</div>
            <div class="card-body">
            
                <div class="row mb-2">
                    <!-- First To College? (ALL) -->
                    <div class="col-md-6">
                        <div class="form-label">I am the first person in my family to go to college.</div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="firstToCollegeYes" [value]="true"
                                formControlName="firstFamilyMemberGoingToCollege" />
                            <label class="form-check-label" for="firstToCollegeYes"> Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="firstToCollegeNo" [value]="false"
                                formControlName="firstFamilyMemberGoingToCollege" />
                            <label class="form-check-label" for="firstToCollegeNo">No</label>
                        </div>
                        <div *ngIf="f.firstFamilyMemberGoingToCollege?.invalid && (f.firstFamilyMemberGoingToCollege?.dirty || f.firstFamilyMemberGoingToCollege?.touched)">
                            <small *ngIf="f.firstFamilyMemberGoingToCollege?.errors?.required" class="text-danger">select a value</small>
                        </div>
                    </div>
                    <!-- First To Grad School? (Not Undergrad) -->
                    <div class="col-md-6" *ngIf="applicationTypeId !== UNDERGRAD">
                        <div class="form-label">I am the first person in my family to go to graduate school.</div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="firstToGradSchoolYes" [value]="true"
                                formControlName="firstFamilyMemberGoingToGraduateSchool" />
                            <label class="form-check-label" for="firstToGradSchoolYes"> Yes</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="firstToGradSchoolNo" [value]="false"
                                formControlName="firstFamilyMemberGoingToGraduateSchool" />
                            <label class="form-check-label" for="firstToGradSchoolNo">No</label>
                        </div>
                        <div *ngIf="f.firstFamilyMemberGoingToGraduateSchool?.invalid && (f.firstFamilyMemberGoingToGraduateSchool?.dirty || f.firstFamilyMemberGoingToGraduateSchool?.touched)">
                            <small *ngIf="f.firstFamilyMemberGoingToGraduateSchool?.errors?.required" class="text-danger">select a value</small>
                        </div>
                    </div>
                </div>
                <!-- Start MEDICAL Specific  -->
                <div *ngIf="applicationTypeId === MEDICAL" class="row mb-2">
                    <div class="col-md-12">
                        <div class="row">

                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="inProgramYear">In what year of program are you?</label>
                                    <select type="textbox" class="form-control" name="inProgramYear"
                                        formControlName="inProgramYear">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let year of programYears" [value]="year">{{year}}</option>
                                    </select>
                                    <div *ngIf="f.inProgramYear?.invalid && (f.inProgramYear?.dirty || f.inProgramYear?.touched)">
                                        <small *ngIf="f.inProgramYear?.errors?.required" class="text-danger">Please select a value</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Start Undergraduate or Graduate Specific  -->
                <div *ngIf="applicationTypeId !== POSTDOC  && applicationTypeId !== MEDICAL" class="row mb-2">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="major">{{applicationTypeId === GRAD ? "Graduate Program" : "Major"}}</label>
                                    <input type="textbox" class="form-control" name="major" formControlName="major" />
                                    <div *ngIf="f.major?.invalid && (f.major?.dirty || f.major?.touched)">
                                        <small *ngIf="f.major?.errors?.required" class="text-danger">Major is required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="inProgramYear">In what year of program are you?</label>
                                    <select type="textbox" class="form-control" name="inProgramYear"
                                        formControlName="inProgramYear">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let year of programYears" [value]="year">{{year}}</option>
                                    </select>
                                    <div *ngIf="f.inProgramYear?.invalid && (f.inProgramYear?.dirty || f.inProgramYear?.touched)">
                                        <small *ngIf="f.inProgramYear?.errors?.required" class="text-danger">Please select a value</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="gpa">GPA</label>
                                    <input type="textbox" class="form-control" id="gpa" name="gpa" formControlName="gpa" />
                                    <div *ngIf="f.gpa?.invalid && (f.gpa?.dirty || f.gpa?.touched)">
                                        <small *ngIf="f.gpa?.errors?.required" class="text-danger">GPA is required</small>
                                        <small *ngIf="f.gpa?.errors?.min || f.gpa?.errors?.max" class="text-danger">GPA must be between 0 and 5</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="expectedGraduationDate">{{applicationTypeId === GRAD ? "Date of Graduate Candidacy"
                                        : "Expected Graduation"}}</label>
                                    <kendo-datepicker id="expectedGraduationDate" 
                                        formControlName="expectedGraduationDate">
                                    </kendo-datepicker>
                                    <div *ngIf="f.expectedGraduationDate?.invalid && (f.expectedGraduationDate?.dirty || f.expectedGraduationDate?.touched)">
                                        <small *ngIf="f.expectedGraduationDate?.errors?.required" class="text-danger">Date is required</small>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="applicationTypeId === GRAD" class="col-md-4">
                                <div class="form-group">
                                    <label for="inGraduateSchoolYear">In what year of grad school are you?</label>
                                    <select type="textbox" class="form-control" name="inGraduateSchoolYear"
                                        formControlName="inGraduateSchoolYear">
                                        <option value="">Please Select</option>
                                        <option *ngFor="let year of gradYears" [value]="year">{{year}}</option>
                                    </select>
                                    <div *ngIf="f.inGraduateSchoolYear?.invalid && (f.inGraduateSchoolYear?.dirty || f.inGraduateSchoolYear?.touched)">
                                        <small *ngIf="f.inGraduateSchoolYear?.errors?.required" class="text-danger">Please select a value</small>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="row" *ngIf="applicationTypeId === GRAD">
                            <div class="col-md-12">
                                <div class="alert alert-info shadow-sm border border-info">
                                    Who is on your Dissertation Committee? (Please list multiple names if you have more than one.
                                    Such as Firstname Lastname)
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="dissertationCommittee">Dissertation Committee Name(s)</label>
                                    <textarea class="form-control" name="dissertationCommittee" rows="5"
                                        formControlName="dissertationCommittee"></textarea>
                                    <div *ngIf="f.dissertationCommittee?.invalid && (f.dissertationCommittee?.dirty || f.dissertationCommittee?.touched)">
                                        <small *ngIf="f.dissertationCommittee?.errors?.required" class="text-danger">Dissertation Committee is required</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <!-- Start Graduate Specific  -->
                <div *ngIf="applicationTypeId === GRAD || applicationTypeId === POSTDOC || applicationTypeId === MEDICAL" class="row mb-2">
                    <div class="col-md-12">
                        <academic-history [applicationId]="applicationId" (recordCountEvent)="handleAcadmicHistoryCount($event)">
                        </academic-history>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <div class="row">
        <div class="col-12">
            <validation-summary 
                [formGroup]="academicBackgroundFG"
                [friendlyFormFieldNames]="friendlyFieldNames"></validation-summary>
        </div>
</div>
