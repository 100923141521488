import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailsService } from './emails.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ApplicationTypeDTO } from '../../shared/models/ApplicationTypeDTO';
//import {EmailOptions} from '../models/EmailOptions';
@Component({
  selector: 'app-emails',
  templateUrl: 'emails.component.html',
  styles: []
})
export class EmailsComponent implements OnInit {
  adminForm: UntypedFormGroup;
  
  formError: any;
  public applicationTypes!: ApplicationTypeDTO[];
  loading = false;
  applicationTypeId = 0;
  isSubmitted = false;
  //emailOptions: EmailOptions;
  //award = '';

  constructor(
    private fb: UntypedFormBuilder,
    private emailsService: EmailsService,
    private lookupService: LookupService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.lookupService.applicationTypes$.subscribe(
      data => {
        if (data) { this.applicationTypes= data; }
      }
    );
   this.adminForm =
    this.fb.group({
   });

  }


sendReviewerInvites() {
    if (confirm('This will send emails to all Assigned Reviewers. Are you sure?')) {
     // document.getElementById('emailButton').innerHTML = '<i class="fa fa-envelope fa-spin fa-fw text-light"></i>';
      this.emailsService.sendReviewerInvites(this.applicationTypeId).subscribe(
        data => {
         // document.getElementById('emailButton').innerHTML = '<i class="fa fa-envelope" aria-hidden="false"></i> Send Reviewer Invites';
        },
        error => {
        //  document.getElementById('emailButton').innerHTML = '<i class="fa fa-envelope" aria-hidden="false"></i> Send Reviewer Invites';
          this.toastr.error(error.statusText, 'Error');
        }
      );
    }
  }
 
  selectChangeApplicationTypeHandler(event: any) {
    this.applicationTypeId= event.target.value;
   // alert("selectChangeApplicationTypeHandler: this.applicationTypeId $$$"+ this.applicationTypeId);
  }
  
  }

