
  <div class="container-fluid">
    <div class="row">
    <div class="col-md-6 offset-md-3">
        <label for="applicationTypeId" class="control-label"  >Application Type</label>

        <select
          class="form-control mr-1"
          id="applicationTypeId"
          width="80"
          name="applicationTypeId"
          (change)="selectChangeApplicationTypeHandler($event)"
        >
          <option [value]="null" >Select an Application Type</option>
          <option
            *ngFor="let applicationType of applicationTypes"
            [value]="applicationType.applicationTypeId"
          >
          {{applicationType.displayName}}
          </option>
        </select>
       
      </div>
    </div>

  <div class="row-fluid no-gutters">
    <div class="col-md-12 ">
    <kendo-grid
    [data]="allView"
    [loading]="loading"
    [skip]="allState.skip!" 
        [filter]="allState.filter!"  
    [sortable]="true"
    [sort]="allState.sort!"

    [pageable]="false"
    [filterable]="true"
    [filter]="allState.filter!"
    [resizable]="true"
    [selectable]="true"   
   style="height:800px"
        class="mb-5 table-striped"
    (dataStateChange)="allDataStateChange($event)">
       
    <kendo-grid-column
    field="applicationNumber"
    title="Application Number"
    [width]="120"
    headerClass="bg-dark text-light"
    [editable]="false"
    [sortable]="false"
    [filterable]="false">
  </kendo-grid-column>

 

<kendo-grid-column
field="applicantName"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
    [sortable]="false"
    [filterable]="false">
<ng-template kendoGridHeaderTemplate>
Applicant name
</ng-template>
</kendo-grid-column>
<kendo-grid-column
field="mentorName"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
    [sortable]="false"
    [filterable]="false">
<ng-template kendoGridHeaderTemplate>
Mentor Name
</ng-template>
</kendo-grid-column>

<kendo-grid-column
field="titleOfProject"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
[sortable]="false"
[filterable]="false">
<ng-template kendoGridHeaderTemplate>
Project Title
</ng-template>
</kendo-grid-column>

       
        <kendo-grid-column field="avgInitialScore" title="Avg Intitial Score" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.avgInitialScore == 0"></div>
            <div *ngIf="dataItem.avgInitialScore != 0">{{ dataItem.avgInitialScore }}</div>
          </ng-template>
        </kendo-grid-column> 
        <kendo-grid-column field="avgFinalScore" title="Avg Final Score" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.avgFinalScore == 0"></div>
            <div *ngIf="dataItem.avgFinalScore != 0">{{ dataItem.avgFinalScore }}</div>
          </ng-template>
        </kendo-grid-column> 
        <kendo-grid-column [width]="180" headerClass="bg-secondary text-light" title="Actionss"  >
            <ng-template kendoGridCellTemplate let-dataItem>          
             <app-download-pdf
                [applicationId]="dataItem.applicationId"  extraClass="float-left mb-1 mr-1">
             </app-download-pdf>
  
             <button class="btn btn-sm btn-secondary"  title="All Review"   
             (click)="otherReviewer(dataItem.applicationId) ">
             <i class="fa fa-plus" aria-hidden="true"></i> All Review
             </button>
  
        </ng-template>
      </kendo-grid-column>
        
      </kendo-grid>
    </div>
  </div>

  <app-review-detail #reviewDetalModal (othersChanged)="onOthersChanged($event)"></app-review-detail>
 
</div>