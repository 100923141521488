import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApplicationCycleDTO } from 'src/app/shared/models/ApplicationCycleDTO';
import { ApplicationStatusDTO } from 'src/app/shared/models/ApplicationStatusDTO';
import { ApplicationTypeDTO } from 'src/app/shared/models/ApplicationTypeDTO';
import { CountryDTO } from 'src/app/shared/models/CountryDTO';
//ApplicationStatusDTO
import { ConfigService } from './config.service';
import { defaultIfEmpty, skip, switchMap } from 'rxjs/operators';
import { StateDTO } from 'src/app/shared/models/StateDTO';

@Injectable({
  providedIn: 'root'
})

export class LookupService {
  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  private cyclebaseurl = "/applicationperiod/";
  private typebaseurl = "/applicationtype/";
  private statusbaseurl = "/applicationstatus/";
  private countrybaseurl = "/country/";

  private getCurrentOpenCycleUrl = this.apiUrl + this.cyclebaseurl + "current";
  private getAllCycleUrl = this.apiUrl + this.cyclebaseurl + "all";
  private getTopCycleUrl = this.apiUrl + this.cyclebaseurl + "top";
  private getAllApplicationTypesUrl = this.apiUrl + this.typebaseurl + "all";
  private getAllApplicationStatusesUrl = this.apiUrl + this.statusbaseurl + "all";
  private getAllCountriesUrl = this.apiUrl + this.countrybaseurl + "all";
  private getStatesUrl = this.apiUrl + "/state/all";

  private _applicationStatuses = new BehaviorSubject<ApplicationStatusDTO[]>(undefined!);
  public applicationStatuses$ = this._applicationStatuses.asObservable();

  private _applicationTypes = new BehaviorSubject<ApplicationTypeDTO[]>(undefined!);
  public applicationTypes$ = this._applicationTypes.asObservable();

  private _loadingCurrentCycle = new BehaviorSubject<boolean>(true);
  public loadingCurrentCycle$ = this._loadingCurrentCycle.asObservable();
  
  private _currentCycle = new BehaviorSubject<ApplicationCycleDTO>(undefined!);
  public currentCycle$ = this._currentCycle.asObservable();

  private _allCycles = new BehaviorSubject<ApplicationCycleDTO[]>(undefined!);
  public allCycles$ = this._allCycles.asObservable();

  private _liveCycles = new BehaviorSubject<ApplicationCycleDTO[]>(undefined!);
  public liveCycles$ = this._liveCycles.asObservable();

  private _countries = new BehaviorSubject<Array<CountryDTO>>(undefined!);
  public countries$ = this._countries.asObservable();

  private _states = new BehaviorSubject<Array<StateDTO>>(undefined!);
  public states$ = this._states.asObservable();

  constructor(protected httpClient: HttpClient) {
    this.getApplicationStatuses().subscribe( data => { this._applicationStatuses.next(data); });
    this.getApplicationTypes().subscribe( data => { this._applicationTypes.next(data); });
    this.getCurrentApplicationCycle().subscribe( data => { this._loadingCurrentCycle.next(false); this._currentCycle.next(data); });
    this.getCountries().subscribe( data => { this._countries.next(data); });
    this.getStates().subscribe( data => {this._states.next(data)});
    this.getAllCycles().subscribe( data => { this._allCycles.next(data); });
    this.getLiveCycles().subscribe( data => { this._liveCycles.next(data); });
  }

  private getApplicationStatuses(): Observable<ApplicationStatusDTO[]> {
    return this.httpClient.get<ApplicationStatusDTO[]>(this.getAllApplicationStatusesUrl);
    // return this.httpClient.get<ApplicationStatusDTO[]>(`${this.getAllApplicationStatusesUrl}`, { observe: 'response'}).pipe(
    //   switchMap(res => res.status === 200 ? of(res.body!) : of([]))
    // );
  }

  private getApplicationTypes(): Observable<ApplicationTypeDTO[]> {
    return this.httpClient.get<ApplicationTypeDTO[]>(this.getAllApplicationTypesUrl);
    // return this.httpClient.get<ApplicationTypeDTO[]>(`${this.getAllApplicationTypesUrl}`, { observe: 'response'}).pipe(
    //   switchMap(res => res.status === 200 ? of(res.body!) : of([]))
    // );
  }
  private getAllCycles(): Observable<ApplicationCycleDTO[]> {
    return this.httpClient.get<ApplicationCycleDTO[]>(this.getAllCycleUrl);
    // return this.httpClient.get<ApplicationCycleDTO[]>(`${this.getAllCycleUrl}`, { observe: 'response'}).pipe(
    //   switchMap(res => res.status === 200 ? of(res.body!) : of([]))
    // );
  }
  private getLiveCycles(): Observable<ApplicationCycleDTO[]> {
    return this.httpClient.get<ApplicationCycleDTO[]>(`${this.getTopCycleUrl}`, { observe: 'response'}).pipe(
      switchMap((res: { status: number; body: any; }) => res.status === 200 ? of(res.body!) : of([]))
    );
  }
  private getCurrentApplicationCycle(): Observable<ApplicationCycleDTO> {
    return this.httpClient.get<ApplicationCycleDTO>(this.getCurrentOpenCycleUrl);
    // return this.httpClient.get<ApplicationCycleDTO>(this.getCurrentOpenCycleUrl, { observe: 'response'}).pipe(
    //   switchMap((res: { status: number; body: any; }) => {        
    //     if (res.status === 200) return of(res.body!);
    //     else return of(undefined!);
    //   })
    // );
  }
  private getCountries(): Observable<CountryDTO[]> {
    return this.httpClient.get<CountryDTO[]>(`${this.getAllCountriesUrl}`);
  }
  private getStates(): Observable<StateDTO[]> {
    return this.httpClient.get<StateDTO[]>(`${this.getStatesUrl}`);
  }
}
