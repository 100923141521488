
import { Routes } from '@angular/router';
import { ApplicationAdminGuard,SuperAdminGuard,ModeratorGuard } from '../core/guards/auth.guard';
import { AuthGuard } from '../core/guards/auth.guard';
import { IndexComponent } from './pages/index.component';


export const moderatorRoutes: Routes = [
  //{ path: '', redirectTo: 'index', pathMatch: 'full' },
  //{ path: 'moderator',  component: ModeratorComponent },
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full'
  },
  {
    path: 'index',
    canActivate: [AuthGuard],
    component: IndexComponent
  }
];
