<pagetitle
  *ngIf="application"
  [pageTitle]="'Review: ' + application.application!.applicationNumber"
></pagetitle>

<div class="container pt-2 pb-2 mb-5" *ngIf="reviewForm" [formGroup]="reviewForm">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="mb-2 p-2 bg-secondary text-dark">
        <dl>
            <dt>Applicant Name:</dt>
            <dd>{{ application.contactInformation!.applicantLastName }},  {{ application.contactInformation!.applicantFirstName }}</dd> 
          
          <dt>Application Number:</dt>
          <dd>{{ application.application!.applicationNumber }}</dd>
         
          
        </dl>
      </div>
     
    </div>
  </div>

  
  <div class="form-group table-active p-3 mb-5">

    <h4>Applicant Score</h4>
    <p class="mt-2" *ngIf="applicationType === Postdoc">
        Application strengths and research potential ( weighted score 33.34% for Postdoc)
        </p>
      

      <p class="mt-2" *ngIf="applicationType !==Postdoc">
        Application strengths and research potential ( weighted score 40% for Graduate, Medical and Undergraduate)
      </p>
    <div class="input-group mb-1">
      <input
        type="range"
        min="1"
        max="9"
        step="1"
        formControlName="applicantScore"
        style="width:350px"
        title="1 = best, 9 = worst"
      />
      <span class="input-group-text bg-light">
        &nbsp; {{ reviewForm.get('applicantScore')!.value }} &nbsp;
      </span>
     
    </div>
  <br/><br/>
    <h4>Mentor Score</h4>

    <p class="mt-2" *ngIf="applicationType ===Postdoc">
      Mentor's qualifications and proven track record of training students ( weighted score 33.33% for Postdoc)
    </p>
    <p class="mt-2" *ngIf="applicationType !== Postdoc">
        Mentor's qualifications and proven track record of training students ( weighted score 40% for Graduate, Medical and Undergraduate)
      </p>
    <div class="input-group mb-1">
      <input
        type="range"
        min="1"
        max="9"
        step="1"
        formControlName="mentorScore"
        style="width:350px"
        title="1 = best, 9 = worst"
        (change)="calculate()"
      />
     
      <span class="input-group-text bg-light">
        &nbsp; {{ reviewForm.get('mentorScore')!.value }} &nbsp;
      </span>
    </div>
    <br/><br/>
    <h4>Proposal Score</h4>
    <p class="mt-2" *ngIf="applicationType === Postdoc">
        Innovativeness and impact of project relevant to cancer research ( weighted score 33.33% for Postdoc)
      </p>

      <p class="mt-2" *ngIf="applicationType !== Postdoc">
        Innovativeness and impact of project relevant to cancer research ( weighted score 20% for Graduate, Medical and Undergraduate)
      </p>
    <div class="input-group mb-1">
      <input
        type="range"
        min="1"
        max="9"
        step="1"
        formControlName="proposalScore"
        style="width:350px"
        title="1 = best, 9 = worst"
        (change)="calculate()"
      />
      <span class="input-group-text bg-light">
        &nbsp; {{ reviewForm.get('proposalScore')!.value }} &nbsp;
      </span>
     
    </div>
    <br/><br/>
    <h4>Weighted Score (Calculated from Above)</h4>
    <p class="mt-2">
        This weighted score is based on the critera above. This weighted score is a suggestion/guide/recomendation to help you determine your intial score
      </p>
    <div class="input-group mb-1" val style="color:red">
        <input type="number" formControlName="weightedScore">
    </div>
    <br/><br/>
    <h4>Initial Score</h4>
    <div class="input-group mb-1">
      <input
        type="range"
        min="1"
        max="9"
        step="1"
        formControlName="initialScore"
        style="width:350px"
        title="1 = best, 9 = worst"
      />
     
      <span class="input-group-text bg-light">
        &nbsp; {{ reviewForm.get('initialScore')!.value }} &nbsp;
      </span>
    </div>
    <br/><br/>
   
    <label>Strength</label>
    <textarea
      kendoTextArea
      maxlength="3000"
      [autoSize]="true"
      class="form-control"
      formControlName="strength">
    </textarea>
    <label>Weakness</label>
    <textarea
      kendoTextArea
      maxlength="3000"
      [autoSize]="true"
      class="form-control"
      formControlName="weakness">
    </textarea>
    <label>Additional Comments</label>
    <textarea
      kendoTextArea
      maxlength="1000"
      [autoSize]="true"
      class="form-control"
      formControlName="additionalComments">
    </textarea>
  </div>

  
  <div class="row">
    <div class="col-12">
        <validation-review 
            [formGroup]="reviewForm"
            [friendlyFormFieldNames]="friendlyFieldNames"></validation-review>
    </div>
</div> 
<app-submit
    *ngIf="canSubmit()"
    (clicked)="submit()">
  </app-submit>

  <app-unsubmit
    [submitted]="review.submit"
    [canUnsubmit]="canUnsubmit()"
    (clicked)="unsubmit()">
  </app-unsubmit>
  <div class="bg-dark text-white mt-5 collapse" id="debugCard">
    <div class="card-body">
      <pre class="text-light">
        dirty: {{reviewForm.dirty}}
        <hr/>
        {{review | json}}
        <hr/>
        {{reviewForm.errors | json}}
      </pre>
    </div>
  </div>
</div>