import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, retry, switchMap, map } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationDTO } from '../../shared/models/ApplicationDTO';

import { ApplicationContactInformationDTO } from 'src/app/shared/models/ContactInformationDTO';
import { ApplicationContactInformationFullDTO } from 'src/app/shared/models/ContactInformationFullDTO';
import { CountryDTO } from 'src/app/shared/models/CountryDTO';
import { StateDTO } from 'src/app/shared/models/StateDTO';
import { CountyDTO } from 'src/app/shared/models/CountyDTO';
import { VisaTypeDTO } from 'src/app/shared/models/VisaTypeDTO';
import { ApplicationFullDTO } from 'src/app/shared/models/ApplicationFullDTO';
import { EmartPersonDTO } from 'src/app/shared/models/EMart';
import { ApplicationMentorRecommenderDTO } from 'src/app/shared/models/ApplicationMentorRecommenderDTO';
import { CincinnatiDepartmentDTO } from '../models/CincinnatiDepartmentDTO';
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
    private baseurl = "/application/";
    private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";
    private getCurrentByCycleUrl = this.apiUrl + this.baseurl + "current/bycycle/";
    private updateUrl = this.apiUrl + this.baseurl + "update";
    private addUrl = this.apiUrl + this.baseurl + "add";    
    private submitUrl = this.apiUrl + this.baseurl + "submit/"; 
    private withdrawUrl = this.apiUrl + this.baseurl + "withdraw/"; 
    private unWithdrawUrl = this.apiUrl + this.baseurl + "unwithdraw/"; 
    private unSubmitUrl = this.apiUrl + this.baseurl + "unsubmit/"; 
    private getUrl  =  this.apiUrl + this.baseurl + "get/";  
    private baseContacturl = "/applicationcontactinformation/";
    private addContactUrl = this.apiUrl + this.baseContacturl + "add"; 
    private updateContactUrl = this.apiUrl + this.baseContacturl + "update"; 
    private getContacturl = this.apiUrl + this.baseContacturl+"contact/";
    
    private baseCountryurl = "/country/";
    private getAllCountryurl= this.apiUrl + this.baseCountryurl + "all";
    
    private baseStateurl = "/state/";
    private getAllStateurl= this.apiUrl + this.baseStateurl + "all";
    
    private baseCountyurl = "/county/";
    private getAllCountyurl= this.apiUrl + this.baseCountyurl + "all";

    private baseMentorurl = "/applicationmentorrecommender/";
    private nameSearchUrl = this.apiUrl + this.baseMentorurl + 'EMartNameSearch/';
    private addMentorUrl = this.apiUrl + this.baseMentorurl + "addMentorRecommender"; 
    private updateMentorUrl = this.apiUrl + this.baseMentorurl+ "updateMentorRecommender"; 
    private getMentorurl = this.apiUrl + this.baseMentorurl+"mentor/";
    private deleteMentorUrl = this.apiUrl + this.baseMentorurl+"deletementor/";
    private deleteCoMentorUrl = this.apiUrl + this.baseMentorurl+"deletecomentor/";
    
    private baseCincinnatiDepartmenturl = "/cincinnatidepartment/";
    private getAllCincinnatiDepartmenturl= this.apiUrl + this.baseCincinnatiDepartmenturl + "all";
    
    private baseVisaTypeurl = "/visatype/";
    private getAllVisaTypeurl= this.apiUrl + this.baseVisaTypeurl + "all";
  constructor(
            protected httpClient: HttpClient,
            protected authService: AuthService) {

  }

  public getApplication(applicationId: number): Observable<ApplicationDTO> {
    return this.httpClient.get<ApplicationDTO>(this.getSpecificUrl + applicationId);
  }
  public getFullApplication(applicationId: number): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.getUrl + applicationId, null);
  }
  public getCurrentApplicationByCycleId(applicationCycleId: number): Observable<ApplicationDTO> {
    return this.httpClient.get<ApplicationDTO>(this.getCurrentByCycleUrl + applicationCycleId);
    // return this.httpClient.get<ApplicationDTO>(this.getCurrentByCycleUrl + applicationCycleId, { observe: 'response'}).pipe(
    //   switchMap(res => res.status === 200 ? of(res.body!) : of(undefined!))
    // );
  }
  
  public getCurrentApplicationFullByCycleId(applicationCycleId: number): Observable<ApplicationFullDTO> {
    return this.httpClient.get<ApplicationFullDTO>(this.getCurrentByCycleUrl + applicationCycleId + "/full");
  }
  public getCurrentApplications(applicationCycleId: number): Observable<ApplicationFullDTO[]> {
    return this.httpClient.get<ApplicationFullDTO[]>(this.getCurrentByCycleUrl + applicationCycleId + "/all");
  }
  public addApplication(application: ApplicationDTO): Observable<ApplicationDTO> {
    return this.httpClient.post<ApplicationDTO>(this.addUrl, application);
  }
  public updateApplication(application: ApplicationDTO): Observable<ApplicationDTO> {
    return this.httpClient.post<ApplicationDTO>(this.updateUrl, application);
  }
  public updateContactInformation(contactInformation: ApplicationContactInformationDTO): Observable<ApplicationContactInformationDTO> {
    return this.httpClient.post<ApplicationContactInformationDTO>(this.updateContactUrl, contactInformation);
  }
  
  public addContactInformation(contactInformation: ApplicationContactInformationDTO): Observable<ApplicationContactInformationDTO> {
    return this.httpClient.post<ApplicationContactInformationDTO>(this.addContactUrl, contactInformation);
  }

  public getContactInformation(applicationId: number): Observable<ApplicationContactInformationFullDTO> {
    return this.httpClient.get<ApplicationContactInformationFullDTO>(this.getContacturl + applicationId);
  }
  
  public getCountrys():Observable<CountryDTO[]> {
    return this.httpClient.get<CountryDTO[]>(this.getAllCountryurl);
  }

  public getStates():Observable<StateDTO[]> {
    return this.httpClient.get<StateDTO[]>(this.getAllStateurl);
  }

  public getCountys():Observable<CountyDTO[]> {
    return this.httpClient.get<CountyDTO[]>(this.getAllCountyurl);
  }

  public getVisaTypes():Observable<VisaTypeDTO[]> {
    return this.httpClient.get<VisaTypeDTO[]>(this.getAllVisaTypeurl);
  }

  public mentorSearch(partialName: string): Observable<EmartPersonDTO[]> {
    return this.httpClient.get<EmartPersonDTO[]>(this.nameSearchUrl + partialName);
  }
  public addMentor(cupId: number, appId: number): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.get<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}AddMentor/${cupId}/${appId}`);
  }
  public addNonOSUMentor(newNonOSUMentor: object): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}AddNonOSUMentor`, newNonOSUMentor);
  }
  public addCoMentor(cupId: number, appId: number): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.get<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}AddCoMentor/${cupId}/${appId}`);
  }
  public addNonOSUCoMentor(newNonOSUMentor: object): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}AddNonOSUCoMentor`, newNonOSUMentor);
  }

  public getMentor(applicationId: number): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.get<ApplicationMentorRecommenderDTO>(this.getMentorurl + applicationId);
  }

  public updateMentor(applicationMentorRecommender: ApplicationMentorRecommenderDTO): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(this.updateMentorUrl, applicationMentorRecommender);
  }
  public updateRecommender(applicationMentorRecommender: ApplicationMentorRecommenderDTO): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}UpdateRecommender`, applicationMentorRecommender);
  }
  /*
 public addMentor(applicationMentorRecommender: ApplicationMentorRecommenderDTO): Observable<ApplicationMentorRecommenderDTO> {
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(this.addMentorUrl, applicationMentorRecommender);
  }
  */
  public addMentorToApplication(applicationMentorRecommender: ApplicationMentorRecommenderDTO): Observable<ApplicationMentorRecommenderDTO> {    
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(this.addMentorUrl, applicationMentorRecommender);
  }
  public addRecommenderToApplication(applicationMentorRecommender: ApplicationMentorRecommenderDTO): Observable<ApplicationMentorRecommenderDTO> {    
    return this.httpClient.post<ApplicationMentorRecommenderDTO>(`${this.apiUrl}${this.baseMentorurl}AddRecommender`, applicationMentorRecommender);
  }
  
  // public getCincinnatiDepartments():Observable<CincinnatiDepartmentDTO[]> {
  //   return this.httpClient.get<CincinnatiDepartmentDTO[]>(this.getAllCincinnatiDepartmenturl);
  // }

  public submitApplication(applicationId: number): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.submitUrl + applicationId, null);
  }
/*
  public getAwaredApplication(applicationId: number): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.getUrl + applicationId,null);
  }

  */
  public withdrawApplication(applicationId: number): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.withdrawUrl + applicationId, null);
  }

 public unWithdrawApplication(applicationId: number): Observable<ApplicationFullDTO> {
  return this.httpClient.post<ApplicationFullDTO>(this.unWithdrawUrl + applicationId, null);
 }


 public unSubmitApplication(applicationId: number): Observable<ApplicationFullDTO> {
  return this.httpClient.post<ApplicationFullDTO>(this.unSubmitUrl + applicationId, null);
 }

 public deleteMentor(applicationId: number): Observable<ApplicationMentorRecommenderDTO> {
  return this.httpClient.post<ApplicationMentorRecommenderDTO>(this.deleteMentorUrl + applicationId, null);
 }
 
 public deleteCoMentor(applicationId: number): Observable<ApplicationMentorRecommenderDTO> {
  return this.httpClient.post<ApplicationMentorRecommenderDTO>(this.deleteCoMentorUrl + applicationId, null);
 }
 
}
