import { Component, OnInit , Input} from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { DownloadsService } from './downloads.service';
import { ToastrService } from 'ngx-toastr';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ApplicationCycleDTO } from "src/app/shared/models/ApplicationCycleDTO";
import { saveAs } from '@progress/kendo-file-saver';
import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
import { ApplicationStatusDTO } from 'src/app/shared/models/ApplicationStatusDTO';
@Component({
  selector: 'app-downloads',
  templateUrl: 'downloads.component.html',
  styles: []
})
export class DownloadsComponent implements OnInit {
 @Input()
fileName!: string;
  //public currentCycle: ApplicationCycleDTO | null = null;
  public liveCycles!: ApplicationCycleDTO[] ;
  types!: ApplicationTypeDTO[];
  applicationStatuses!: ApplicationStatusDTO[] ;
  public disabled = false;
  //public innerHTML = '<i class="fa fa-file-pdf-o"></i>';
  applicationCycleId = 0;
  applicationTypeId =0
  applicationStatusId =0;
  

  constructor(
    private fb: UntypedFormBuilder,
    private downloadsService: DownloadsService,
    private lookupService: LookupService,
    private toastr: ToastrService) { }

  ngOnInit() {
    
    this.lookupService.liveCycles$.subscribe(
        data => {
          if (data) { this.liveCycles = data; }
        }
      );
      this.lookupService.applicationTypes$.subscribe(
        data => {
          if (data) { this.types= data; }
        }
      );

      this.lookupService.applicationStatuses$.subscribe(
        data => {
          if (data) { this.applicationStatuses = data; }
        }
      );
  }

  DownloadPDFs() {
      if(this.applicationCycleId != 0){
            if (confirm('This will download application PDFs. Are you sure?')) {
                this.disabled = true;       
     
                this.downloadsService.downloadByCycle(this.applicationCycleId, this.applicationTypeId, this.applicationStatusId).subscribe(
                 (res: any) => {          
                    const contentDisposition = res.headers.get('content-disposition') || '';       
                    const matches = /fileName=([^;]+)/ig.exec(contentDisposition);      
                    const fileName = (matches![1] || 'untitled').trim().replace(/"/g, '');
                    console.log('DownloadPdfComponent -> downloadFile -> fileName', fileName);        
                    const file = new Blob([res.body], { type: 'application/zip' });
                
                    saveAs(file, fileName);         
                    this.resetButton();
                },
                (error: any) => {           
                    this.toastr.error('There was an error downloading the applications', 'Error');
                    console.error(JSON.stringify(error));
                    this.resetButton();
                }
                );
            }
        }
        else {
            confirm('You must select Cycle')
        }
  }
 

  private resetButton() {
    this.disabled = false;
    //this.innerHTML = '<i class="fa fa-file-pdf-o"></i>';
  }
  selectChangeApplicationCycleHandler(event: any) {
    this.applicationCycleId= event.target.value;
  }

  selectChangeApplicationTypeHandler(event: any) {
    this.applicationTypeId= event.target.value;
  }

  selectChangeApplicationStatusHandler(event: any) {
    this.applicationStatusId= event.target.value;
  }
  
  }
