/*import { Component, OnInit } from "@angular/core";
import { ApplicationPeriodDto } from "../models/ApplicationPeriodDto";
import { ApplicationPeriodService } from '../services/applicationperiod.service'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'admin-index',
    templateUrl: 'index.component.html'
})
export class AdminIndexComponent implements OnInit{
    public periods: ApplicationPeriodDto[] | null;
    private counter: number = 0;
    constructor(private appPrdSvc: ApplicationPeriodService,
        private toastr: ToastrService) {
        this.periods = null;
     }

    ngOnInit() {
       // this.getAll();   
    }

    getAll(): void {
        this.appPrdSvc.getAll().subscribe(
            data => {
                this.periods = data;
                this.toastr.success('Application Periods Loaded!');
            },
            error => {
                console.log(JSON.stringify(error))
                this.toastr.error('Error Loading Data from Database...');
            }
        )
    }
    pad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    createNewAppPeriod(): void {
        let appPrd: ApplicationPeriodDto = {
            applicationPeriodId: 0,
            name: 'New' + this.pad(this.counter, 4),
            displayName: `New Application Cycle ${this.counter}`,
            cycleStartDate: new Date(),
            cycleEndDate: new Date(),
            submissionStartDate: new Date(),
            submissionEndDate: new Date(),
            reviewStartDate: new Date(),
            reviewEndDate: new Date(),
            readingPhaseStartDate: new Date(),
            readingPhaseEndDate: new Date()
        };

        this.appPrdSvc.add(appPrd).subscribe(
            data => {
                this.toastr.success('Application Period Added!');
                this.counter++;
                this.getAll();
            },
            error => {
                console.log(JSON.stringify(error))
                this.toastr.error('Error Adding record...');
            }
        )
    }
}
*/

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-index',
  templateUrl: 'index.component.html',
  styles: []
})
export class IndexComponent implements OnInit {

  currentCycleLabel = '';
  
  constructor(public authService: AuthService,
    ) { }
  ngOnInit() {
    
  }

}
