import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/core/services/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmailsService {
  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  private baseurl = "/adminapplication/";
  private getInviteUrl = this.apiUrl + this.baseurl + "invite/";
  constructor(protected httpClient: HttpClient, protected authService: AuthService) {}

  public sendReviewerInvites(applicationTypeId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.getInviteUrl  +applicationTypeId);
  }

  
 
}
