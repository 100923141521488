import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AcademicHistoryDTO } from "../../models/AcademicHistoryDTO";
import { AcademicHistoryService } from "../../services/academichistory.service";

@Component({
    selector: 'history-form',
    templateUrl: 'history-form.component.html'
})
export class HistoryFormComponent implements OnInit, OnChanges {
    historyFG: UntypedFormGroup = undefined!;
    @Input()
    applicationId: number = 0;
    @Input()
    public academicHistoryId: number = 0;
    //@Input()
    //model: AcademicHistoryDTO = undefined!;    
    @Output()
    savedEvent = new EventEmitter<string>();
    isFormValid: boolean = true;

    public needsOtherDegree: boolean = false;
    constructor(private fb: UntypedFormBuilder,
        private svc: AcademicHistoryService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.initForm();
    } 

    ngOnChanges(changes: SimpleChanges): void {           
        if (this.academicHistoryId > 0) {
            this.loadForEdit(this.academicHistoryId);
        } else {
            this.initForm();
        }
    }

    changeDegree(event: any) {        
        let degreeVal = this.historyFG.get('degree')?.value;
        this.needsOtherDegree = degreeVal === 'Other';
        // not sure why we need this twice, but it doesnt behave correctly without both calls
        this.historyFG.controls['otherDegree'].setValue('');

        if (this.needsOtherDegree === true) {
            this.historyFG.controls['otherDegree'].setValidators([Validators.required, Validators.maxLength(150)]);
        } else {
            this.historyFG.controls['otherDegree'].setValidators([]);
        }
        
        // not sure why we need this twice, but it doesnt behave correctly without both calls
        this.historyFG.controls['otherDegree'].setValue('');
        this.historyFG.updateValueAndValidity();
    }

    loadForEdit(id: number) {
        this.svc.get(id).subscribe(
            data => {
                this.historyFG.patchValue({
                    "applicationAcademicHistoryId": data.applicationAcademicHistoryId,
                    "applicationId": data.applicationId,
                    "academicInstitution": data.academicInstitution,
                    "yearAwarded": data.yearAwarded,
                    "degree": data.degree,
                    "otherDegree": data.otherDegree,
                    "fieldOfStudy": data.fieldOfStudy,
                });
                this.historyFG.markAsUntouched();
                this.historyFG.markAsPristine();
            }
        );
    }

    save() {
        this.isFormValid = this.historyFG.valid;

        if (this.historyFG.valid && this.historyFG.touched) {
            let dto: AcademicHistoryDTO = {
                applicationAcademicHistoryId: this.academicHistoryId,
                applicationId: this.applicationId,                                
                academicInstitution: this.historyFG.get('academicInstitution')?.value,
                yearAwarded:  this.historyFG.get('yearAwarded')?.value,
                degree:  this.historyFG.get('degree')?.value,
                otherDegree:  this.historyFG.get('otherDegree')?.value,
                fieldOfStudy:  this.historyFG.get('fieldOfStudy')?.value
            };

            if (this.academicHistoryId === 0) {
                this.svc.add(dto).subscribe(
                    data => { 
                        this.savedEvent.emit("saved");
                        this.initForm();
                        this.toastr.success("Saved Academic History Record."); }
                );
            } else {
                this.svc.update(dto).subscribe(
                    data => { 
                        this.savedEvent.emit("saved");
                        this.initForm();
                        this.toastr.success("Saved Academic History Record."); 
                    }
                );
            }
        }
    }

    get f() {
        return this.historyFG.controls;
    }

    initForm() {
        let curYear = new Date().getFullYear();
        this.historyFG = this.fb.group({
            academicInstitution: ['', [Validators.required, Validators.maxLength(100)]],
            yearAwarded: ['', [Validators.required, Validators.max(curYear), Validators.pattern('^[0-9]{4}$')]],
            degree: ['', Validators.required],
            otherDegree: [''],
            fieldOfStudy: ['', [Validators.required, Validators.maxLength(150)]],
        });
        this.historyFG.markAsUntouched();
        this.historyFG.markAsPristine();
    }

    cancelForm() {
        this.savedEvent.emit("cancelled");
        this.academicHistoryId = 0;        
    }

}