import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";


import { ToastrService } from "ngx-toastr";
import { ApplicationContactInformationDTO } from 'src/app/shared/models/ContactInformationDTO';
import { CountryDTO } from 'src/app/shared/models/CountryDTO';
import { StateDTO } from 'src/app/shared/models/StateDTO';
import { CountyDTO } from 'src/app/shared/models/CountyDTO';
import { VisaTypeDTO } from 'src/app/shared/models/VisaTypeDTO';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ApplicationService } from '../../services/application.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { debounceTime, take } from 'rxjs/operators';
import { FormSectionState } from "../../models/FormSectionState";
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-contact-information',
  templateUrl: 'contact-information.component.html'
})
export class ContactInformationComponent implements OnInit {    
  
  @Input()
  public applicationId: number = 0;

  @Input()
  public application!: ApplicationDTO;
  
  private recordExists: boolean = false;

  public contactInformationForm!: UntypedFormGroup;
  public disabled = false;

  @Output()
  formGroupValidationMonitorEvent = new EventEmitter<FormSectionState>(true);

  @Input()
  public contactInformation?: ApplicationContactInformationDTO;
  public savingInProgress: boolean = false;
  public applicationBiosketchId: number = 0;
  public countrys: CountryDTO[] = [];
  public states: StateDTO[] = [];
  public countys: CountyDTO[] = [];
  public visaTypes: VisaTypeDTO[] = [];
  private subscriptions: Subscription[] = [];
  public appCycleOpen = false;
  // public hometownCountryId : any;
  //public hometownCountyId : any;
  public hometownStateId: any;
  //public mailingAddressCountryId : any;
  //public mailingAddressStateId: any;
  //public citizenshipStatus : any;
  public countryOfCitizenshipId: any;
  public visaTypeId: any;
  public usa: any;
  public ohio: any;
  public friendlyFieldNames: Record<string, string> = {};
  public hasRace: boolean = false;

  constructor(private fb: UntypedFormBuilder,
              private toastr: ToastrService,
              //public formStateService: FormStateService,
              public authService: AuthService,
              public applicationService: ApplicationService,
              private lookupService: LookupService) { }

  ngOnInit() {
    this.lookupService.currentCycle$.pipe(take(2)).subscribe(
      data => {
        if (data) {
          if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
            this.appCycleOpen = true;
          }
        }
      }
    );

    this.applicationService.getCountrys().subscribe(
      data => { this.countrys = data; },
      error => { this.toastr.error(error.statusText, 'Error'); }
    );

    this.applicationService.getStates().subscribe(
      data => { this.states = data; },
      error => { this.toastr.error(error.statusText, 'Error'); }
    );
    this.applicationService.getCountys().subscribe(
      data => { this.countys = data; },
      error => { this.toastr.error(error.statusText, 'Error'); }
    );

    this.applicationService.getVisaTypes().subscribe(
      data => { this.visaTypes = data; },
      error => { this.toastr.error(error.statusText, 'Error'); }
    );
    this.usa = ConfigService.settings.const.UNITED_States_OF_AMERICA;
    this.ohio = ConfigService.settings.const.Ohio;
    if(this.authService.isApplicationsAdmin)
    {
      //alert("isApplicationsAdmin " );
      this.disabled = false;
    }
   else if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
      || this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.contactInformationForm = this.fb.group({
      applicationId: [this.applicationId],
      osuNameDotNumber: [{ value: '', disabled: this.disabled }, Validators.required],
      applicantFirstName: [{ value: '', disabled: this.disabled }, Validators.required],
      applicantLastName: [{ value: '', disabled: this.disabled }, Validators.required],
      applicantEmailAddress: [{ value: '', disabled: this.disabled }, [Validators.email, Validators.required]],
      applicantNoOSUEmail: [{ value: '', disabled: this.disabled }, Validators.required],
      linkedIn: [{ value: '', disabled: this.disabled }],
      twitter: [{ value: '', disabled: this.disabled }],
      applicantPhoneNumber: [{ value: '', disabled: this.disabled }, Validators.required],
      contactPersonFirstName: [{ value: '', disabled: this.disabled }, Validators.required],
      contactPersonLastName: [{ value: '', disabled: this.disabled }, Validators.required],
      contactPersonPhone: [{ value: '', disabled: this.disabled }, Validators.required],
      contactPersonEmail: [{ value: '', disabled: this.disabled }, Validators.required],
      mailingAddressCountryId: [{ value: '', disabled: this.disabled }, Validators.required],
      mailingAddress1: [{ value: '', disabled: this.disabled }, Validators.required],
      mailingAddress2: [{ value: '', disabled: this.disabled }],
      mailingAddressCity: [{ value: '', disabled: this.disabled }, Validators.required],
      mailingAddressZipPostalCode: [{ value: '', disabled: this.disabled }, Validators.required],
      mailingAddressStateId: [{ value: '', disabled: this.disabled }],
      mailingAddressStateProvinceTerritory: [{ value: '', disabled: this.disabled }],
      gender: [{ value: '', disabled: this.disabled }, Validators.required],
      disabilityStatus: [{ value: '', disabled: this.disabled }, Validators.required],
      ethnicity: [{ value: '', disabled: this.disabled }, Validators.required],
      raceAmericanIndianOrAlaskaNative: [{ value: '', disabled: this.disabled }],
      raceAsian: [{ value: '', disabled: this.disabled }],
      raceBlackOrAfricanAmerican: [{ value: '', disabled: this.disabled }],
      raceNativeHawaiianOrPacificIslander: [{ value: '', disabled: this.disabled }],
      raceWhite: [{ value: '', disabled: this.disabled }],
      raceUndisclosed: [{ value: '', disabled: this.disabled }],

      citizenshipStatus: [{ value: '', disabled: this.disabled }, Validators.required],
      countryOfCitizenshipId: [{ value: '', disabled: this.disabled }],
      visaTypeId: [{ value: '', disabled: this.disabled }],
      visaExpiration: [{ value: '', disabled: this.disabled }],

      hometownCountryId: [{ value: '', disabled: this.disabled }, Validators.required],
      hometownStateId: [{ value: '', disabled: this.disabled }],
      hometownCity: [{ value: '', disabled: this.disabled }, Validators.required],
      hometownCountyId: [{ value: '', disabled: this.disabled }],
      hometownStateProvinceTerritory: [{ value: '', disabled: this.disabled }],
      hometownAddressZipPostalCode: [{ value: '', disabled: this.disabled }],
      hasRace: [''],
    });
    this.friendlyFieldNames['osuNameDotNumber'] = 'OsuName.# ';
    this.friendlyFieldNames['applicantFirstName'] = 'First Name ';
    this.friendlyFieldNames['applicantLastName'] = 'Last Name ';
    this.friendlyFieldNames['applicantEmailAddress'] = 'EmailAddress ';
    this.friendlyFieldNames['applicantPhoneNumber'] = 'Phone Number ';
    this.friendlyFieldNames['applicantNoOSUEmail'] = 'No OSU Email ';
    this.friendlyFieldNames['contactPersonFirstName'] = 'Contact Person First Name ';
    this.friendlyFieldNames['contactPersonLastName'] = 'Contact Person Last Name ';
    this.friendlyFieldNames['contactPersonEmail'] = 'Contact Person Email ';
    this.friendlyFieldNames['contactPersonPhone'] = 'Contact Person Phone Number ';
    this.friendlyFieldNames['mailingAddressCountryId'] = 'Mailing Address Country ';
    this.friendlyFieldNames['mailingAddress1'] = 'Mailing Address 1 ';
    this.friendlyFieldNames['mailingAddressCity'] = 'Mailing Address City ';
    this.friendlyFieldNames['mailingAddressZipPostalCode'] = 'Mailing Address Zip or Postal Code ';
    this.friendlyFieldNames['mailingAddressStateId'] = 'Mailing Address State ';
    this.friendlyFieldNames['mailingAddressStateProvinceTerritory'] = 'Mailing Address Province ';

    this.friendlyFieldNames['gender'] = 'Gender ';
    this.friendlyFieldNames['disabilityStatus'] = 'Disability Status ';
    this.friendlyFieldNames['ethnicity'] = 'Ethnicity ';
    this.friendlyFieldNames['citizenshipStatus'] = 'Citizenship Status ';
    this.friendlyFieldNames['countryOfCitizenshipId'] = 'Country Of Citizenship';
    this.friendlyFieldNames['visaTypeId'] = 'Visa Type';

    this.friendlyFieldNames['visaExpiration'] = 'Visa Expiration';
    this.friendlyFieldNames['hometownCountryId'] = 'Hometown Country';
    this.friendlyFieldNames['hometownStateId'] = 'Hometown State ';
    this.friendlyFieldNames['hometownCity'] = 'Hometown City ';
    this.friendlyFieldNames['hometownCountyId'] = 'Hometown County';
    this.friendlyFieldNames['hometownStateProvinceTerritory'] = 'Hometown Province';
    this.friendlyFieldNames['hometownAddressZipPostalCode'] = 'Hometown AddressZip or Postal Code';
    this.friendlyFieldNames['hasRace'] = 'Race ';
    if (this.contactInformationForm !== undefined) {
    
      this.applicationService.getContactInformation(this.applicationId).subscribe(
        data => {
          if(data == null){
        
            this.recordExists = false;
           this.watchForChanges();
          }
          if (data !== undefined) {
           
            console.log(data);
            this.toSetFormGroup(data);
          } else {
            
            this.recordExists = false;
          }
        }
      );

    }
  }

  watchForChanges() {
   
    this.subscriptions.push(this.contactInformationForm.valueChanges
      .pipe(debounceTime(2000)).subscribe(() => {
        if (!this.disabled && this.contactInformationForm.touched && this.contactInformationForm.dirty) {
        
          this.save();
        }
      })
    );

    this.contactInformationForm.statusChanges.subscribe(() => {
      this.formGroupValidationMonitorEvent.emit({ sectionName: 'ContactInformation', isValid: this.contactInformationForm.valid });
    });

    this.formGroupValidationMonitorEvent.emit({ sectionName: 'ContactInformation', isValid: this.contactInformationForm.valid });
    // console.log("🚀 ~ file: contact-information.component.ts ~ line 200 ~ ContactInformationComponent ~ watchForChanges ~ { sectionName: 'ContactInformation', isValid: this.contactInformationForm.valid}", { sectionName: 'ContactInformation', isValid: this.contactInformationForm.valid })
  }
       
  setCountryValue(e: any) {
    const hometownCountryId = e.target.value

    if (hometownCountryId == this.usa) {

      this.contactInformationForm.controls['hometownStateId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['hometownAddressZipPostalCode'].setValidators([Validators.required]);

    }
    else {
      this.contactInformationForm.controls['hometownStateId'].setValidators(null);
      this.contactInformationForm.controls['hometownAddressZipPostalCode'].setValidators(null);
    }

    this.contactInformationForm.patchValue({
      'hometownStateId': '',
      'hometownAddressZipPostalCode': '',
    });
    this.contactInformationForm.updateValueAndValidity();
  }
    
  setMailingCountryValue(e: any) {
    const mailingAddressCountryId = e.target.value

    if (mailingAddressCountryId == this.usa) {
      this.contactInformationForm.controls['mailingAddressStateId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].setValidators(null);
    }
    else {
      this.contactInformationForm.controls['mailingAddressStateId'].setValidators(null);
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].setValidators([Validators.required]);
    }

    this.contactInformationForm.patchValue({
      'mailingAddressStateId': '',
      'mailingAddressStateProvinceTerritory': '',
    });
    this.contactInformationForm.updateValueAndValidity();
  }


  setStateValue(e: any) {
    this.hometownStateId = e.target.value
    if (this.hometownStateId == this.ohio) {
    
      this.contactInformationForm.controls['hometownCountyId'].setValidators([Validators.required]);
    }
    else {
      
      this.contactInformationForm.controls['hometownCountyId'].setValidators(null);
    }

    this.contactInformationForm.patchValue({
      'hometownCountyId': '',
    });
    this.contactInformationForm.updateValueAndValidity();
  }
  /*
  setCountyValue(e:any) { 
    this.hometownCountyId = e.target.value
    
  }

  setMailingStateValue(e:any) { 
    alert ("Before mailingAddressStateId");
    this.mailingAddressStateId = e.target.value ;
    alert ("mailingAddressStateId ===  "+this.mailingAddressStateId);
    
  }
  */

  get f() {
    return this.contactInformationForm.controls;
  }
  
  save() {
    this.savingInProgress = true;
   
    if (this.recordExists === false) {
      this.applicationService.addContactInformation(this.initializeForm()).subscribe(
        data => {
          this.toSetFormGroup(data);
          this.toastr.success('Updated Application!');
          this.savingInProgress = false;
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error updating application');
        }
      );
    } else {
      //alert("Linkin is **** "+this.initializeForm().linkedIn);
      this.applicationService.updateContactInformation(this.initializeForm()).subscribe(
        data => {
          this.toSetFormGroup(data);
          this.toastr.success('Updated Application!');
          this.savingInProgress = false;
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error updating application');
        }
      );
    }
  }

  initializeForm(): ApplicationContactInformationDTO {
   
    let dto: ApplicationContactInformationDTO = {
      applicationId: this.applicationId,
      osuNameDotNumber: null,
      applicantFirstName: null,
      applicantLastName: null,
      applicantEmailAddress: null,
      applicantNoOSUEmail: null,
      linkedIn:  null,
      twitter: null,
      applicantPhoneNumber: null,
      mailingAddressCountryId: null,
      mailingAddress1: null,
      mailingAddress2: null,
      mailingAddressCity: null,
      mailingAddressZipPostalCode: null,
      mailingAddressStateId: null,
      mailingAddressStateProvinceTerritory: null,
      gender: null,
      disabilityStatus: null,
      ethnicity: null,
      raceAmericanIndianOrAlaskaNative: undefined,
      raceAsian: undefined,
      raceBlackOrAfricanAmerican: undefined,
      raceNativeHawaiianOrPacificIslander: undefined,
      raceWhite: undefined,
      raceUndisclosed: undefined,

      citizenshipStatus: null,
      countryOfCitizenshipId: null,
      visaTypeId: null,
      visaExpiration: undefined,

      hometownCountryId: null,
      hometownStateId: null,
      hometownCity: null,
      hometownCountyId: null,
      hometownStateProvinceTerritory: null,
      hometownAddressZipPostalCode: null,
      contactPersonFirstName: null,
      contactPersonLastName: null, 
      contactPersonPhone: null, 
      contactPersonEmail: null,
    };


    dto.applicantFirstName = this.contactInformationForm.get('applicantFirstName')?.value;
    dto.applicantLastName = this.contactInformationForm.get('applicantLastName')?.value;
    dto.applicantEmailAddress = this.contactInformationForm.get('applicantEmailAddress')?.value;
    dto.applicantNoOSUEmail = this.contactInformationForm.get('applicantNoOSUEmail')?.value;
    dto.osuNameDotNumber = this.contactInformationForm.get('osuNameDotNumber')?.value;
    dto.linkedIn = this.contactInformationForm.get('linkedIn')?.value;
    dto.twitter = this.contactInformationForm.get('twitter')?.value;
    dto.applicantPhoneNumber = this.contactInformationForm.get('applicantPhoneNumber')?.value;

    dto.contactPersonFirstName = this.contactInformationForm.get('contactPersonFirstName')?.value;
    dto.contactPersonLastName = this.contactInformationForm.get('contactPersonLastName')?.value;
    dto.contactPersonPhone = this.contactInformationForm.get('contactPersonPhone')?.value;
    dto.contactPersonEmail = this.contactInformationForm.get('contactPersonEmail')?.value;
    dto.mailingAddressCountryId = this.contactInformationForm.get('mailingAddressCountryId')?.value;
    dto.mailingAddress1 = this.contactInformationForm.get('mailingAddress1')?.value;
    dto.mailingAddress2 = this.contactInformationForm.get('mailingAddress2')?.value;
    dto.mailingAddressCity = this.contactInformationForm.get('mailingAddressCity')?.value;
    dto.mailingAddressZipPostalCode = this.contactInformationForm.get('mailingAddressZipPostalCode')?.value;
    dto.mailingAddressStateId = this.contactInformationForm.get('mailingAddressStateId')?.value;
    dto.mailingAddressStateProvinceTerritory = this.contactInformationForm.get('mailingAddressStateProvinceTerritory')?.value;
    dto.gender = this.contactInformationForm.get('gender')?.value;
    dto.disabilityStatus = this.contactInformationForm.get('disabilityStatus')?.value;
    dto.ethnicity = this.contactInformationForm.get('ethnicity')?.value;
    dto.raceAmericanIndianOrAlaskaNative = this.contactInformationForm.get('raceAmericanIndianOrAlaskaNative')?.value;
    dto.raceAsian = this.contactInformationForm.get('raceAsian')?.value;
    dto.raceBlackOrAfricanAmerican = this.contactInformationForm.get('raceBlackOrAfricanAmerican')?.value;
    dto.raceNativeHawaiianOrPacificIslander = this.contactInformationForm.get('raceNativeHawaiianOrPacificIslander')?.value;
    dto.raceWhite = this.contactInformationForm.get('raceWhite')?.value;
    dto.raceUndisclosed = this.contactInformationForm.get('raceUndisclosed')?.value;

    dto.citizenshipStatus = this.contactInformationForm.get('citizenshipStatus')?.value;
    dto.countryOfCitizenshipId = this.contactInformationForm.get('countryOfCitizenshipId')?.value;
    dto.visaTypeId = this.contactInformationForm.get('visaTypeId')?.value;
    dto.visaExpiration = this.contactInformationForm.get('visaExpiration')?.value;

    dto.hometownCountryId = this.contactInformationForm.get('hometownCountryId')?.value;
    dto.hometownStateId = this.contactInformationForm.get('hometownStateId')?.value;
    dto.hometownCity = this.contactInformationForm.get('hometownCity')?.value;

    dto.hometownCountyId = this.contactInformationForm.get('hometownCountyId')?.value;
    dto.hometownStateProvinceTerritory = this.contactInformationForm.get('hometownStateProvinceTerritory')?.value;
    dto.hometownAddressZipPostalCode = this.contactInformationForm.get('hometownAddressZipPostalCode')?.value;
    return dto;
  }

  toSetFormGroup(dto: ApplicationContactInformationDTO) {
    
    this.contactInformationForm.patchValue({
      "osuNameDotNumber": dto?.osuNameDotNumber,
      "applicantFirstName": dto.applicantFirstName,
      "applicantLastName": dto.applicantLastName,
      "applicantEmailAddress": dto.applicantEmailAddress,
      "applicantNoOSUEmail": dto.applicantNoOSUEmail,
      "linkedIn": dto.linkedIn,
      "twitter": dto.twitter,
      "applicantPhoneNumber": dto.applicantPhoneNumber,
      "contactPersonFirstName": dto.contactPersonFirstName,
      "contactPersonLastName": dto.contactPersonLastName, 
      "contactPersonPhone": dto.contactPersonPhone, 
      "contactPersonEmail": dto.contactPersonEmail,
      "mailingAddressCountryId": dto.mailingAddressCountryId,
      "mailingAddress1": dto.mailingAddress1,
      "mailingAddress2": dto.mailingAddress2,
      "mailingAddressCity": dto.mailingAddressCity,
      "mailingAddressZipPostalCode": dto.mailingAddressZipPostalCode,
      "mailingAddressStateId": dto.mailingAddressStateId,
      "mailingAddressStateProvinceTerritory": dto.mailingAddressStateProvinceTerritory,
      "gender": dto.gender,
      "disabilityStatus": dto.disabilityStatus,
      "ethnicity": dto.ethnicity,
      "raceAmericanIndianOrAlaskaNative": dto.raceAmericanIndianOrAlaskaNative,
      "raceAsian": dto.raceAsian,
      "raceBlackOrAfricanAmerican": dto.raceBlackOrAfricanAmerican,
      "raceNativeHawaiianOrPacificIslander": dto.raceNativeHawaiianOrPacificIslander,
      "raceWhite": dto.raceWhite,
      "raceUndisclosed": dto.raceUndisclosed,

      "citizenshipStatus": dto.citizenshipStatus,
      "countryOfCitizenshipId": dto.countryOfCitizenshipId,
      "visaTypeId": dto.visaTypeId,
      "visaExpiration": dto.visaExpiration,

      "hometownCountryId": dto.hometownCountryId,
      "hometownStateId": dto.hometownStateId,
      "hometownCity": dto.hometownCity,
      "hometownCountyId": dto.hometownCountyId,
      "hometownStateProvinceTerritory": dto.hometownStateProvinceTerritory,
      "hometownAddressZipPostalCode": dto.hometownAddressZipPostalCode,
    });

    if (dto.hometownCountryId == this.usa && dto.hometownStateId != null) {
      this.checkStateValue(dto.hometownStateId);
    }

    if (dto.mailingAddressCountryId != null) {
      this.checkMillingStateValue(dto.mailingAddressCountryId);
    }

    if (dto.citizenshipStatus !== null) {
      this.toggleCitizenshipStatus(dto.citizenshipStatus);
    }

    this.checkRaceValue(dto);
    this.recordExists = true;
    this.contactInformationForm.markAsPristine();

    this.watchForChanges();
  }


  checkRaceValue(dto: ApplicationContactInformationDTO) {

    if ((dto.raceAmericanIndianOrAlaskaNative == null || dto.raceAmericanIndianOrAlaskaNative == false)
      && (dto.raceAsian == null || dto.raceAsian == false)
      && (dto.raceBlackOrAfricanAmerican == null || dto.raceBlackOrAfricanAmerican == false)
      && (dto.raceNativeHawaiianOrPacificIslander == null || dto.raceNativeHawaiianOrPacificIslander == false)
      && (dto.raceUndisclosed == null || dto.raceUndisclosed == false)
      && (dto.raceWhite == null || dto.raceWhite == false)) {

      this.contactInformationForm.controls['hasRace'].setValidators([Validators.required]);
    }
    else {
      this.contactInformationForm.controls['hasRace'].setValidators(null);
    }
    this.contactInformationForm.patchValue({
      'hasRace': '',
    });
    this.contactInformationForm.updateValueAndValidity();
  }


  checkStateValue(hometownStateId: any) {

    if (hometownStateId == this.ohio) {

      this.contactInformationForm.controls['hometownCountyId'].setValidators([Validators.required]);
    }
    else {
      this.contactInformationForm.controls['hometownCountyId'].setValidators(null);
    }

    this.contactInformationForm.updateValueAndValidity();
  }


  checkMillingStateValue(mailingAddressCountryId: any) {
    if (mailingAddressCountryId == this.usa) {
      this.contactInformationForm.controls['mailingAddressStateId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['mailingAddressStateId'].updateValueAndValidity();
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].setValidators(null);
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].updateValueAndValidity();
    }
    else {
      this.contactInformationForm.controls['mailingAddressStateId'].setValidators(null);
      this.contactInformationForm.controls['mailingAddressStateId'].updateValueAndValidity();
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].setValidators([Validators.required]);
      this.contactInformationForm.controls['mailingAddressStateProvinceTerritory'].updateValueAndValidity();
    }
    //this.contactInformationForm.updateValueAndValidity();
  }


  toggleCitizenshipStatus(citizenshipStatus: any) {
    // alert("citizenshipStatus " +citizenshipStatus);
    if (citizenshipStatus === 'Alien Temporary') {
      this.contactInformationForm.controls['countryOfCitizenshipId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['countryOfCitizenshipId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaTypeId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['visaTypeId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaExpiration'].setValidators([Validators.required]);
      this.contactInformationForm.controls['visaExpiration'].updateValueAndValidity();
    }
    else if (citizenshipStatus === 'Alien Permanent') {
      this.contactInformationForm.controls['countryOfCitizenshipId'].setValidators([Validators.required]);
      this.contactInformationForm.controls['countryOfCitizenshipId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaTypeId'].setValidators(null);
      this.contactInformationForm.controls['visaTypeId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaExpiration'].setValidators(null);
      this.contactInformationForm.controls['visaExpiration'].updateValueAndValidity();
      this.contactInformationForm.patchValue({
        'visaTypeId': '',
        'visaExpiration': '',
      })
      this.contactInformationForm.updateValueAndValidity();
    }
    else {
      this.contactInformationForm.controls['countryOfCitizenshipId'].setValidators(null);
      this.contactInformationForm.controls['countryOfCitizenshipId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaTypeId'].setValidators(null);
      this.contactInformationForm.controls['visaTypeId'].updateValueAndValidity();
      this.contactInformationForm.controls['visaExpiration'].setValidators(null);
      this.contactInformationForm.controls['visaExpiration'].updateValueAndValidity();
      this.contactInformationForm.patchValue({
        'countryOfCitizenshipId': '',
        'visaTypeId': '',
        'visaExpiration': '',
      })
      this.contactInformationForm.updateValueAndValidity();
    }

    /*this.contactInformationForm.patchValue({
       // 'countryOfCitizenshipId': '', 
        //'visaType':'',
      //  'visaExpiration':'',
        
        
    });*/
    // this.contactInformationForm.updateValueAndValidity();
  }

}