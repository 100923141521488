import { Component, OnInit, ViewChild  } from '@angular/core';
import { GridDataResult ,DataStateChangeEvent} from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy,process,State } from '@progress/kendo-data-query';
import { ReviewService } from '../services/review.service';
import { Router } from '@angular/router';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ReviewGridDTO } from '../models/ReviewGridDTO';
import { ToastrService } from 'ngx-toastr';
import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { HttpParams } from '@angular/common/http';
import { ApplicationAssignedReviewerGridDTO } from 'src/app/admin/models/ApplicationAssignedReviewerGridDTO';
import { OthersReviewComponent } from 'src/app/review/components/others-review.component';


@Component({
  selector: 'app-my-assigned-list',
  templateUrl: './my-assigned-list.component.html',
  styles: []
})
export class MyAssignedListComponent implements OnInit {

  public myView!: GridDataResult;
  public mySort: SortDescriptor[] = [{
    field: 'overallImpactScore',
    dir: 'asc'
  }];

  public state: State = {
    skip: 0,
    sort: [{
      field: 'overallImpactScore',
      dir: 'asc'
    }]
  };
  public myApplications!: ReviewGridDTO[];
  @ViewChild('othersReviewModal') othersReviewModal!: OthersReviewComponent;
  public loading = true;

  //noCurrentCycle = true;
  noPostdocReview =true;
  noGradReview =true;
  noUndergradReview =true;
  noMedicalReview =true;
  //public readingPhrase = false;
 
  constructor(
    private router: Router,
    private lookupService: LookupService,
    private reviewService: ReviewService,
   // private applicationService: ApplicationService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    this.lookupService.currentCycle$.subscribe(
      data => {
        if (data) {
         /* if ((data.reviewStartDate! <= new Date()) && (data.reviewEndDate! >= new Date())) {
            this.noCurrentCycle = false;
          }*/
          if ((data.postdocReviewStartDate! <= new Date()) && (data.postdocReviewEndDate! >= new Date())) {
            this.noPostdocReview = false;
          }
         
          if ((data.graduateReviewStartDate! <= new Date()) && (data.graduateReviewEndDate! >= new Date())) {
            this.noGradReview = false;
          }
          if ((data.undergraduateReviewStartDate! <= new Date()) && (data.undergraduateReviewEndDate! >= new Date())) {
            this.noUndergradReview = false;
          }
          if ((data.medicalReviewStartDate! <= new Date()) && (data.medicalReviewEndDate! >= new Date())) {
            this.noMedicalReview = false;
          }
          if (new Date() > data.readingPhaseStartDate! ) {
            //this.readingPhrase = true;
          }
        }
      }
    );

    this.reviewService.getAssignedApplications().subscribe((data) => {
     
      this.myApplications = data;

      this.myView = {
        data: orderBy(this.myApplications, this.mySort),
        total: data.length
      };

      this.loading = false;
    });
  }

  public addReview(applicationId: number) {
      
    this.reviewService.addReview(applicationId).subscribe(
      data => {
        this.review(data.reviewId);
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }
  otherReviewer(applicationId: number) {
  
    this.othersReviewModal.init(applicationId);
    //this.othersReviewModal.show();
  }
  onOthersChanged(event: boolean) {
   
    if (event) {
        this.reviewService.getAssignedApplications().subscribe((data) => {
            this.myApplications = data;
      
            this.myView = {
              data: orderBy(this.myApplications, this.mySort),
              total: data.length
            };
      
            this.loading = false;
          });
    } else { }
  }
  public review(reviewId: number) {
    this.router.navigate(['/review/review/', reviewId]);
  }
  

  
  public sortMine(sort: SortDescriptor[]): void {
    this.mySort = sort;

    this.myView = {
      data: orderBy(this.myApplications, this.mySort),
      total: this.myApplications.length
    };
  }
}
