import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AcademicBackgroundDTO } from "src/app/shared/models/AcademicBackgroundDTO";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { AcademicBackgroundService } from "../../services/academicbackground.service";
import { ToastrService } from "ngx-toastr";
import { ApplciationDocumentService } from "../../services/applicationdocument.service";
import { ApplicationDocumentDTO } from "src/app/shared/models/ApplicationDocumentDTO";
import { FormSectionState } from "../../models/FormSectionState";
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'academic-background',
    templateUrl: 'academic-background.component.html'
})
export class AcademicBackgroundComponent implements OnInit {    
    @Input()
    public applicationTypeId: number = 0;
    @Input()
    public applicationId: number = 0;
    @Input()
    public biosketchDocument: ApplicationDocumentDTO | undefined = undefined;
    @Input()
    public application!: ApplicationDTO;
    private recordExists: boolean = false;
    public academicBackgroundFG: UntypedFormGroup = undefined!;
    public savingInProgress: boolean = false;
    public applicationBiosketchId: number = 0;
    //public appCycleOpen = false;
    @Output()
    formGroupValidationMonitorEvent = new EventEmitter<FormSectionState>(true);
    public disabled = false;
    friendlyFieldNames: Record<string, string> = { };

    private subscriptions: Subscription[] = [];
    public programYears: number[] = [];
    public gradYears: number[] = [];
    readonly UNDEFINED: number = 0;
    readonly POSTDOC: number = 1;
    readonly UNDERGRAD: number = 2;
    readonly GRAD: number = 3;
    readonly MEDICAL: number = 4;
    constructor(private fb: UntypedFormBuilder,
        private toastr: ToastrService,
        private svc: AcademicBackgroundService,
        private configService: ConfigService,
        private lookupService: LookupService,
        public authService: AuthService,
        public docSvc: ApplciationDocumentService) { }

    ngOnInit() {     
        this.programYears = Array(7).fill(7).map((x,i) => i);
        this.gradYears = Array(5).fill(5).map((x,i) => i);
/*
        this.lookupService.currentCycle$.pipe(take(2)).subscribe(
            data => {
              if (data) {
                if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                  this.appCycleOpen = true;
                }
              }
            }
          );
*/
if(this.authService.isApplicationsAdmin)
{
  this.disabled = false;
}
else if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
            || this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ){ //|| !this.appCycleOpen) {
            this.disabled = true;
           // alert("this.disabled in academic &&&"+this.disabled);
        } else {
            this.disabled = false;
        }
        this.friendlyFieldNames = { };
        switch(this.applicationTypeId)
        {
            case this.GRAD:
                this.academicBackgroundFG = this.fb.group({
                    firstFamilyMemberGoingToCollege: [{value: '',  disabled: this.disabled}, Validators.required],
                    firstFamilyMemberGoingToGraduateSchool: [{value: '',  disabled: this.disabled}, Validators.required],
                    expectedGraduationDate: [{value: '',  disabled: this.disabled}, Validators.required],
                    gpa: [{value: '',  disabled: this.disabled}, [Validators.required, Validators.min(0.00), Validators.max(5.00)]],
                    major: [{value: '',  disabled: this.disabled}, [Validators.required, Validators.maxLength(50)]],
                    dissertationCommittee: [{value: '',  disabled: this.disabled}, [Validators.required, Validators.maxLength(500)]],
                    inProgramYear: [{value: '',  disabled: this.disabled}, Validators.required],
                    inGraduateSchoolYear: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasBiosketchDocument: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasHistoryRecord: ['', Validators.required]
                });
                
                this.friendlyFieldNames['firstFamilyMemberGoingToCollege'] = 'First to College';
                this.friendlyFieldNames['firstFamilyMemberGoingToGraduateSchool'] = 'First to Grad School';
                this.friendlyFieldNames['expectedGraduationDate'] = 'Date of Graduate Candidacy';
                this.friendlyFieldNames['gpa'] = 'GPA';
                this.friendlyFieldNames['major'] = 'Graduate Program';
                this.friendlyFieldNames['dissertationCommittee'] = 'Dissertation Committee';
                this.friendlyFieldNames['inProgramYear'] = 'Program Year';
                this.friendlyFieldNames['inGraduateSchoolYear'] = 'Graduate Year';
                this.friendlyFieldNames['hasBiosketchDocument'] = 'Biosketch Document';
                this.friendlyFieldNames['hasHistoryRecord'] = 'Academic History (at least 1 entry)';
                break;
            case this.UNDERGRAD:
                this.academicBackgroundFG = this.fb.group({
                    firstFamilyMemberGoingToCollege: [{value: '',  disabled: this.disabled}, Validators.required],                    
                    expectedGraduationDate: [{value: '',  disabled: this.disabled}, Validators.required],
                    gpa: [{value: '',  disabled: this.disabled}, [Validators.required, Validators.min(0.00), Validators.max(5.00)]],
                    major: [{value: '',  disabled: this.disabled}, [Validators.required, Validators.maxLength(50)]],                    
                    inProgramYear: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasBiosketchDocument: [{value: '',  disabled: this.disabled}, Validators.required]
                });
                
                this.friendlyFieldNames['firstFamilyMemberGoingToCollege'] = 'First to College';
                this.friendlyFieldNames['expectedGraduationDate'] = 'Expected Graduation';
                this.friendlyFieldNames['gpa'] = 'GPA';
                this.friendlyFieldNames['major'] = 'Major';                
                this.friendlyFieldNames['inProgramYear'] = 'Program Year';                
                this.friendlyFieldNames['hasBiosketchDocument'] = 'Biosketch Document';
                break;
            case this.POSTDOC:
                this.academicBackgroundFG = this.fb.group({
                    firstFamilyMemberGoingToCollege: [{value: '',  disabled: this.disabled}, Validators.required],
                    firstFamilyMemberGoingToGraduateSchool: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasBiosketchDocument: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasHistoryRecord: [{value: '',  disabled: this.disabled}, Validators.required]
                });
                
                this.friendlyFieldNames['firstFamilyMemberGoingToCollege'] = 'First to College';
                this.friendlyFieldNames['firstFamilyMemberGoingToGraduateSchool'] = 'First to Grad School';              
                this.friendlyFieldNames['hasBiosketchDocument'] = 'Biosketch Document';
                this.friendlyFieldNames['hasHistoryRecord'] = 'Academic History (at least 1 entry)';
                break;
                case this.MEDICAL:
                this.academicBackgroundFG = this.fb.group({
                    firstFamilyMemberGoingToCollege: [{value: '',  disabled: this.disabled}, Validators.required],
                    firstFamilyMemberGoingToGraduateSchool: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasBiosketchDocument: [{value: '',  disabled: this.disabled}, Validators.required],
                    hasHistoryRecord: [{value: '',  disabled: this.disabled}, Validators.required],
                    inProgramYear: [{value: '',  disabled: this.disabled}, Validators.required],
                });
                
                this.friendlyFieldNames['firstFamilyMemberGoingToCollege'] = 'First to College';
                this.friendlyFieldNames['firstFamilyMemberGoingToGraduateSchool'] = 'First to Grad School';              
                this.friendlyFieldNames['hasBiosketchDocument'] = 'Biosketch Document';
                this.friendlyFieldNames['hasHistoryRecord'] = 'Academic History (at least 1 entry)';
                this.friendlyFieldNames['inProgramYear'] = 'Program Year';  
                break;
            case this.UNDEFINED:
                this.academicBackgroundFG = undefined!;
                break;
        }

        // we need to refactor this to take in the data from an input!
        if (this.academicBackgroundFG !== undefined) {
            this.svc.get(this.applicationId).subscribe(
                data => { 
                    if (data !== undefined!) {
                        //console.log(data);
                        this.toFormGroup(data);
                    } else {
                        this.recordExists = false;
                    }
                }
            );
            this.setupAcadBackgroundSave();
        }
    }

    setupAcadBackgroundSave() {
        this.subscriptions.push(this.academicBackgroundFG.valueChanges
            .pipe(debounceTime(2000))
            .subscribe(() => {
                if (!this.disabled && this.academicBackgroundFG.touched && this.academicBackgroundFG.dirty) this.save();
            })
        );

        this.academicBackgroundFG.statusChanges.subscribe(() => {            
            this.formGroupValidationMonitorEvent.emit({ sectionName: 'AcademicBackground', isValid: this.academicBackgroundFG.valid});
        });  
        
        this.formGroupValidationMonitorEvent.emit({ sectionName: 'AcademicBackground', isValid: this.academicBackgroundFG.valid});
        // console.log("🚀 ~ file: academic-background.component.ts ~ line 150 ~ AcademicBackgroundComponent ~ setupAcadBackgroundSave ~ { sectionName: 'AcademicBackground', isValid: this.academicBackgroundFG.valid}", { sectionName: 'AcademicBackground', isValid: this.academicBackgroundFG.valid})
    }

    save() {
        this.savingInProgress = true;        
        if (this.recordExists === false) {
            this.svc.add(this.toDto()).subscribe(
                data => { 
                    this.toFormGroup(data);
                    this.toastr.success('Updated Application!');                    
                    this.savingInProgress = false;
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.statusText, 'Error updating application');
                }
            );
        } else {
                this.svc.update(this.toDto()).subscribe(
                data => {
                    this.toFormGroup(data);
                    this.toastr.success('Updated Application!');
                    this.savingInProgress = false;
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.statusText, 'Error updating application');
                }
            );
        }
    }

    get f() {
        return this.academicBackgroundFG.controls;
    }

    toDto() : AcademicBackgroundDTO {
        let dto: AcademicBackgroundDTO = {
            applicationId: this.applicationId,
            firstFamilyMemberGoingToCollege: null,
            firstFamilyMemberGoingToGraduateSchool: null,
            expectedGraduationDate: null,
            professionalType: null, // legacy?  
            gpa: null,
            major: null,
            academicYear: null,            
            inGraduateSchoolYear: null,
            inProgramYear: null,
            dissertationCommittee: null
        };

        switch(this.applicationTypeId) {
            case this.UNDERGRAD:
                dto.firstFamilyMemberGoingToCollege = this.academicBackgroundFG.get('firstFamilyMemberGoingToCollege')?.value;
                dto.expectedGraduationDate = this.academicBackgroundFG.get('expectedGraduationDate')?.value;
                dto.gpa = this.academicBackgroundFG.get('gpa')?.value;
                dto.major = this.academicBackgroundFG.get('major')?.value;  
                dto.inProgramYear = this.academicBackgroundFG.get('inProgramYear')?.value;              
                break;
            case this.GRAD:
                dto.firstFamilyMemberGoingToCollege = this.academicBackgroundFG.get('firstFamilyMemberGoingToCollege')?.value;
                dto.firstFamilyMemberGoingToGraduateSchool = this.academicBackgroundFG.get('firstFamilyMemberGoingToGraduateSchool')?.value;
                dto.expectedGraduationDate = this.academicBackgroundFG.get('expectedGraduationDate')?.value;
                dto.gpa = this.academicBackgroundFG.get('gpa')?.value;
                dto.major = this.academicBackgroundFG.get('major')?.value;    
                dto.inProgramYear = this.academicBackgroundFG.get('inProgramYear')?.value; 
                dto.inGraduateSchoolYear = this.academicBackgroundFG.get('inGraduateSchoolYear')?.value;   
                dto.dissertationCommittee = this.academicBackgroundFG.get('dissertationCommittee')?.value;     
                break;
            case this.POSTDOC:
                dto.firstFamilyMemberGoingToCollege = this.academicBackgroundFG.get('firstFamilyMemberGoingToCollege')?.value;
                dto.firstFamilyMemberGoingToGraduateSchool = this.academicBackgroundFG.get('firstFamilyMemberGoingToGraduateSchool')?.value;                
                break;
            case this.MEDICAL:
                dto.firstFamilyMemberGoingToCollege = this.academicBackgroundFG.get('firstFamilyMemberGoingToCollege')?.value;
                dto.firstFamilyMemberGoingToGraduateSchool = this.academicBackgroundFG.get('firstFamilyMemberGoingToGraduateSchool')?.value;    
                dto.inProgramYear = this.academicBackgroundFG.get('inProgramYear')?.value;              
                break;
            default:
                break;
        }

        return dto;
    }

    toFormGroup(dto: AcademicBackgroundDTO) { 
        switch(this.applicationTypeId) {
            case this.UNDERGRAD:
                this.academicBackgroundFG.patchValue({
                    "gpa": dto.gpa,
                    "major": dto.major,
                    "expectedGraduationDate": dto.expectedGraduationDate,
                    "firstFamilyMemberGoingToCollege": dto.firstFamilyMemberGoingToCollege, 
                    "inProgramYear": dto.inProgramYear,                    
                });
                break;
            case this.GRAD:
                this.academicBackgroundFG.patchValue({
                    "gpa": dto.gpa,
                    "major": dto.major,
                    "expectedGraduationDate": dto.expectedGraduationDate,
                    "firstFamilyMemberGoingToCollege": dto.firstFamilyMemberGoingToCollege,
                    "firstFamilyMemberGoingToGraduateSchool": dto.firstFamilyMemberGoingToGraduateSchool,
                    "inProgramYear": dto.inProgramYear,   
                    "inGraduateSchoolYear": dto.inGraduateSchoolYear,                    
                    "dissertationCommittee": dto.dissertationCommittee,                    
                });
                break;
            case this.POSTDOC:
                this.academicBackgroundFG.patchValue({
                    "firstFamilyMemberGoingToCollege": dto.firstFamilyMemberGoingToCollege,
                    "firstFamilyMemberGoingToGraduateSchool": dto.firstFamilyMemberGoingToGraduateSchool,                    
                });
                break;
            case this.MEDICAL:
                this.academicBackgroundFG.patchValue({
                    "firstFamilyMemberGoingToCollege": dto.firstFamilyMemberGoingToCollege,
                    "firstFamilyMemberGoingToGraduateSchool": dto.firstFamilyMemberGoingToGraduateSchool,  
                    "inProgramYear": dto.inProgramYear,  
                });  
                break;
            default:
                break;
        }        
        this.recordExists = true;
        this.academicBackgroundFG.markAsPristine();
    }

    onFileAttached(event: {attached: boolean, documentTypeId: number, documentDto: ApplicationDocumentDTO | undefined}) {

        if (event.attached === true) {
            this.academicBackgroundFG.patchValue({
                "hasBiosketchDocument": event.attached
            });
        } else {
            this.academicBackgroundFG.patchValue({
                "hasBiosketchDocument": ''
            });
        }

        this.biosketchDocument = event.documentDto;
    }

    handleAcadmicHistoryCount(args: number) {
        if (args > 0) {
            this.academicBackgroundFG.patchValue({
                'hasHistoryRecord': true
            });
        } else { 
            this.academicBackgroundFG.patchValue({
                'hasHistoryRecord': ''
            });
        }
    }

}