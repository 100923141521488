<button type="button" class="btn btn-secondary mr-3" data-toggle="modal" data-target="#coMentorOSUModal" title="Add OSU CoMentor"
  *ngIf="appMentor == undefined || isCoMentorOSU == null "> 
<i class="fa fa-plus" aria-hidden="true"></i> Add OSU CoMentor
</button>

<button type="button" class="btn btn-secondary " data-toggle="modal" data-target="#coMentorNoOsuModal" title="Add Non OSU CoMentor"
  *ngIf="appMentor == undefined || isCoMentorOSU == null ">
<i class="fa fa-plus" aria-hidden="true"></i> Add Non OSU CoMentor
</button>

<div id="coMentorOSUModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Add CoMentor to Application</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-center">Add CoMentor using the form below. Search the OSU directory by the
            CoMentor's name or lastname.# using the form below:</p>

          <div name="searchByNameDiv" class="form p-3" [ngClass]="{'border bg-light': searchByName}">
            <div class="form-row">
              <label for="inputLastName" class="control-label col-md-3">Last Name</label>
              <div class="col-md-9">
                <input type="text" class="form-control" name="inputLastName"
                    [(ngModel)]="inputLastName"
                    (focus)="activateSearchByName()">
              </div>
            </div>
            <div class="form-row">
                <label for="inputFirstname" class="control-label col-md-3">First Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" name="inputFirstName"
                      [(ngModel)]="inputFirstName"
                      (focus)="activateSearchByName()">
                </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 text-center">
              <strong>- or -</strong>
            </div>
          </div>
          <div name="searchByDotNumberDiv" class="form p-3" [ngClass]="{'border bg-light': searchByDotNumber}">
            <div class="form-row">
              <label for="inputOSUUserName" class="control-label col-md-3">OSU Username</label>
              <div class="col-md-9">
                  <input type="text" class="form-control"
                  [(ngModel)]="inputUserName" name="inputUserName" placeholder="lastname.#" (focus)="activateSearchByDotNumber()">
              </div>
            </div>
          </div>
          <div class="form-row">
              <div class="col-sm-10 offset-md-3">
                  <button id="findPeopleSearch" type="submit" (click)="search()" class="btn btn-primary mt-2">{{searchText}}</button>
              </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <div id="searchResultsDiv" class="text-left" *ngIf="searchResults.length > 0">

                <strong *ngIf="searchResults.length > 149">
                  search results exceeded 150 matches not all displayed.  If you don't see your match, please narrow your search.
                </strong>

                <strong>Search Results:</strong>
                <p class="text-info">
                  Please select the person from the results below.
                </p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let element of searchResults">
                    <button class="btn btn-success btn-sm"
                        [disabled]="disabled"
                        (click)="piSelect(element.email, element.last_name, element.first_name)"
                        [id]="element.username">
                      {{buttonText}}
                    </button>
                    {{element.first_name}} {{element.last_name}}
                    (.{{element.username.split('.')[1]}}) - {{element.appointments.length >0 ? element.appointments[0].organization : '' || ''}}
                    <br/>
                  </li>
                </ul>
              </div>
              <div id="noResultsDiv" *ngIf="searchResults.length == 0 && searchParams.length > 1 && showResults">
                  <strong>No results match your search for {{searchParams}}.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="coMentorNoOsuModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
         <h5 class="modal-title text-dark">Add Non OSU CoMentor to Application</h5><h5 style="color:red;">( FirstName, Last Name, Email are required)</h5>
          <button type="button" class="close2" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
  
          <div name="coMentorFirstName" class="form p-3" >
            <div class="form-row">
              <label for="coMentorFirstName" class="control-label col-md-4">CoMentor First Name<h6 style="color:red;">*</h6></label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="coMentorFirstName" name="coMentorFirstName" required>
              </div>
            </div>
          </div>
  
          <div name="coMentorLastName" class="form p-3" >
            <div class="form-row">
              <label for="coMentorLastName" class="control-label col-md-4">CoMentor Last Name<h6 style="color:red;">*</h6></label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="coMentorLastName" name="coMentorLastName"  required >
              </div>
            </div>
          </div>
  
  
          <div name="coMentorEmailAddress" class="form p-3">
            <div class="form-row" >
              <label for="coMentorEmailAddress" class="control-label col-md-4">CoMentor Email Address<h6 style="color:red;">*</h6></label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="coMentorEmailAddress" name="coMentorEmailAddress" required >
              </div>
            </div>
          </div>
  
          <div class="form-row">
            <div class="col-sm-10 offset-md-2">
              <button class="btn btn-primary"  [disabled]="disabled" (click)="piNoOsuSelect(coMentorEmailAddress,coMentorFirstName,coMentorLastName)" id="addNonOsuButton"> {{'Add'}}</button>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>



 