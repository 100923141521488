import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { encodeBase64, saveAs } from '@progress/kendo-file-saver';

import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly iprApiUrl = ConfigService.settings.urls.apiUrl;

  constructor(protected httpClient: HttpClient) {}

  // public savePdf(applicationId: number) {
  //   this.getPdf(applicationId).subscribe(
  //     (res) => {

  //       const contentDisposition = res.headers.get('content-disposition') || '';
  //       const matches = /filename=([^;]+)/ig.exec(contentDisposition);
  //       const fileName = (matches[1] || 'untitled').trim();
  //       console.log('SharedService -> constructor -> fileName', fileName);

  //       const file = new Blob([res.body], { type: 'application/pdf' });
  //       saveAs(file, fileName);
  //     }
  //   );
  // }
  public getPdf(applicationId: number): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(
      `${this.iprApiUrl}/Application/GetPdf?applicationId=${applicationId}`,
      { observe: 'response', responseType: 'blob' }
    );
  }

 
}