<pagetitle pageTitle="{{PageTitle}}"></pagetitle>
<div class="container container-content p-3 shadow">
    <div class="row py-3 border-bottom mx-2">
        <div class="col-12"><img class="img-fluid rounded mx-auto d-block" src="assets/img/PelotoniaRiders.jpg" role="presentation" alt=""></div>        
    </div>
    <h2 class="text-danger" style="text-align: center;" *ngIf="landingText" [innerHTML]="landingText"></h2>
    <div class="row py-3 border-bottom mx-2">
        <div class="col-12">
            <p><a href="https://pelotonia.org" target="_blank"><u>Pelotonia</u></a>, the annual grassroots bicycle tour established in 2009 to raise money for cancer research at the OSUCCC – James, has generated more than $213 million through rider pledges and donations. Thanks to the generous underwriters of the event, every dollar raised by riders since Pelotonia began goes to help our researchers translate scientific discoveries to innovative patient care and prevention strategies that contribute to Pelotonia's single goal of ending cancer. This includes the Pelotonia Scholars Program that trains promising and accomplished undergraduate, graduate, medical and postdoctoral students from any discipline at Ohio State who have the potential to become independent cancer researchers.</p>
            <p>To join the more than 560 fellows, start the online application process below.</p>
            <p>Individuals from underrepresented racial and ethnic groups as well as individuals with disabilities are always encouraged to apply.</p>
            <p>See the <a href="https://pelotonia.org/our-impact" target="_blank"><u>IMPACT</u></a> Pelotonia is making toward a cancer free world.</p>
        </div>
    </div>
    
    <hr class="my-4">
    <div class="lead" [innerHTML]="welcomeText"></div>
    <div class="row pt-3 mx-2" *ngIf="!authSvc.isLoggedIn && offline === false" >
        
        <div class="col-12">
            <p class="lead font-weight-normal">Please <button class="btn btn-primary" id="login" (click)="login()">Sign in</button> to create or access your application.</p>
            
        </div>
        <div class="col-12">
            <p class="alert alert-danger" role="alert">Note : If you register as a New External user, please <a href="mailto:CCCDEV@osumc.edu?subject=Pelotonia Scholars Approval Message"><u>Contact Us for Approval</u></a> after register</p>
            
        </div>
    </div>
    <div class="row mx-2">
        <div class="col-12 border bg-light shadow p-3 m-1">
            <h2>Information Required for the Application</h2>
            <ul>
                <li>Current Address and Hometown</li>
                <li>Your Demographic Information</li>
                <li>Your NIH-Format Biosketch and Academic History</li>
                <li>Name, Email Address, and NIH-Format Biosketch of your Mentor (and co-mentor if applicable)</li>
                <li>Name and Email Address of Someone Who Can Provide a Letter of Support</li>
                <li>Dates of Previous Pelotonia Fellowship Applications</li>
                <li>Your Research Proposal</li>
            </ul>
            <div class="border bg-white shadow-sm p-3 m-1">
                <h3 class="h4">Research Proposal</h3>
				<div class="alert alert-info">
					The research proposal document must be written in single spaced 11 point Arial font, with margins of 0.5 inch.
				</div>
                <p>*If this is a re-submission, please submit a ½ page description of what has changed since the last submission</p>
                <ul>
                    <li>Title of Project</li>
                    <li>Project Summary (up to 1 page)</li>
                    <li>Abstract (350 words maximum): Provide an executive summary of the project, including overall goal, methodology and significance, for a lay audience.</li>
                    <li>Impact/Innovation: Tell us how your goals will have an impact on cancer research.</li>
                    <li>Project Description (up to 3 pages not including bibiolography).</li>
                    <li>
                        Suggested Headings:
                        <ul>
                            <li>Specific Aims</li>
                            <li>Background and Significance</li>
                            <li>Preliminary Investigation</li>
                            <li>Experiment Design Methods</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>