
<div class="my-4" id="MentorSection" *ngIf="mentorForm">
    <h3>Mentor(s) and Recommender <i *ngIf="savingInProgress" class="fa fa-circle-notch fa-spin"></i></h3>

    <div class="row mt-2 mb-1">
      <div class="col-md-8 offset-md-2  bg-secondary">
        <h5 class="text-light">Download Traning Table Template for Mentor and Co Mentor</h5>
       
          <a class="nav-link text-dark" href="/assets/templates/TrainingTable.docx">
            <i class="fa fa-file-text-o" aria-hidden="true"></i>  Blank Traning Table
          </a>
      
      </div>
    </div>
    <div [formGroup]="mentorForm">
        <div class="card border-secondary my-4">
          <div class="card-header">Mentor</div>
          <div class="card-body">
           
            <div *ngIf="appMentor === undefined || appMentor?.mentorId == null" >
              <app-find-mentor
                [applicationId]="applicationId"
                [appMentor]="appMentor"
                [isCoMentor]="false"
                (savedEvent)="handleRefresh($event)">
              </app-find-mentor>
            </div>

            <div class="row mb-2" *ngIf="appMentor">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="mentorOsuNameDotNumber">OSU Lastname.#</label><br/>
                  {{appMentor.mentorOsuNameDotNumber}}
                </div>
              </div>              
              <div class="col-md-3">
                <div class="form-group">
                  <label for="mentorFirstName">First Name</label><br/>
                  {{appMentor.mentorFirstName}}
                </div>
              </div>                   
              <div class="col-md-3">
                <div class="form-group">
                  <label for="mentorLastName">Last Name</label><br/>
                  {{appMentor.mentorLastName}}
                </div>
              </div>
              <div class="col-md-3">      
                <div class="form-group">
                  <label for="mentorEmailAddress">Email Address</label><br/>
                  {{appMentor.mentorEmailAddress}}
                </div>
              </div>
              <div class="col-md-1">      
                <div class="form-group" *ngIf="appMentor !== undefined && appMentor?.mentorId != null && !disabled ">
                  <label>Remove</label><br/>
                  <button type="button" class="btn btn-sm btn-primary" title="Remove" (click)="removeMentor(appMentor.applicationId)">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 py-2 mb-3">
                <div *ngIf="f.mentorId?.invalid">                                
                  <small class="text-danger">Please provide a valid mentor</small>                      
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-md-4">
                  <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasMentorBiosketchDocument.invalid}">
                    <b>Mentor Biosketch </b><br />
                    <document-upload [applicationId]="applicationId"
                      [application]="application"
                      [documentUploadId]="mentorBiosketchId"                    
                      [documentTypeId]="docSvc.MENTORBIOSKETCHDOCTYPE"
                      [documentDto]="mentorBiosketchDocument"
                      (fileAttached)="onFileAttached($event)">
                    </document-upload>
                    <div *ngIf="f.hasMentorBiosketchDocument?.invalid">
                      <small *ngIf="f.hasMentorBiosketchDocument.errors?.required" class="text-danger">This file is required</small>                      
                    </div>
                  </div>
              </div>
              
              <div class="col-md-4">
                  <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasMentorSupportDocument.invalid}">
                      <b>Mentor Letter of Support</b><br />
                      <document-upload [applicationId]="applicationId"
                        [application]="application"
                        [documentUploadId]="mentorSupportId"                    
                        [documentTypeId]="docSvc.MENTORLETTEROFSUPPORTDOCTYPE"
                        [documentDto]="mentorSupportDocument"
                        (fileAttached)="onFileAttached($event)">
                      </document-upload>
                      <div *ngIf="f.hasMentorSupportDocument?.invalid">
                        <small *ngIf="f.hasMentorSupportDocument.errors?.required" class="text-danger">This file is required</small>                      
                      </div>
                  </div>
              </div>
            
              <div class="col-md-4">
                <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasMentorTrainingDocument.invalid}">
                    <b>Mentor Training Table</b><br />
                    <document-upload [applicationId]="applicationId"
                      [application]="application"
                      [documentUploadId]="mentorTrainingId"                    
                      [documentTypeId]="docSvc.MENTORTRAININGLETTERDOCTYPE"
                      [documentDto]="mentorTrainingDocument"
                      (fileAttached)="onFileAttached($event)">
                    </document-upload>
                    <div *ngIf="f.hasMentorTrainingDocument?.invalid">
                      <small *ngIf="f.hasMentorTrainingDocument.errors?.required" class="text-danger">This file is required</small>                      
                    </div>
                </div>
            </div>
          </div>

        </div>
    </div>
           
           
    <div class="card border-secondary my-4">
      <div class="card-header">Co-Mentor</div>
      <div class="card-body">
          
        <div class="row">
          <div class="col-md-12">                
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="hasCoMentor" [value]="true" formControlName="hasCoMentor" (click)="toggleCoMentor(true)"/>
                  <label class="form-check-label" for="hasCoMentor">  I have a Co-Mentor</label>
              </div>
              <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" id="hasCoMentor" [value]="false" formControlName="hasCoMentor" (click)="toggleCoMentor(false)"/>
                  <label class="form-check-label" for="hasCoMentor">I do not have Co-Mentor</label>
              </div>
              <!--small style="color:red" *ngIf="mentorForm.controls['hasCoMentor'].value">
                  Co Mentor information will be removed if you switch to "I do not have Co-Mentor"
              </small-->
          </div>
        </div>

        <div *ngIf="hasCoMentor === true && (appMentor === undefined || appMentor?.coMentorId == null)">
          <app-find-mentor
              [applicationId]="applicationId"
              [appMentor]="appMentor"
              [isCoMentor]="true"
              (savedEvent)="handleRefresh($event)">
          </app-find-mentor>
        </div>

        <div class="row" *ngIf="hasCoMentor === true">
         <!-- <input hidden class="form-check-input" type="radio" id="coMentorOrganization" [value]="'The Ohio State University'" formControlName="coMentorOrganization"/> -->
          
          <!--<div class="col-md-3" *ngIf="mentorForm.controls['coMentorOrganization'].value == 'The Ohio State University'">-->
          <div class="col-md-2" >
            <div class="form-group">
              <label for="coMentorOsuNameDotNumber">OSU Lastname.#</label><br/>
              {{appMentor.coMentorOsuNameDotNumber}}
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="coMentorFirstName">First Name</label><br/>
              {{appMentor.coMentorFirstName}}
            </div>
          </div>              
          <div class="col-md-3">
            <div class="form-group">
              <label for="coMentorLastName">Last Name</label><br/>
              {{appMentor.coMentorLastName}}
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="coMentorEmailAddress">Email Address</label><br/>
              {{appMentor.coMentorEmailAddress}}
            </div>
          </div>
          <div class="col-md-1">      
            <div class="form-group" *ngIf="appMentor !== undefined && appMentor?.coMentorId != null && !disabled">
              <label>Remove</label><br/>
              <button type="button" class="btn btn-sm btn-primary" title="Remove" (click)="removeCoMentor(appMentor.applicationId)">
                <i class="fa fa-times"></i>
              </button>
            </div>
          </div> 
        </div>
        <div class="row">
          <div class="col-md-12 py-2 mb-3">
            <div *ngIf="hasCoMentor === true && f.coMentorId?.invalid">                                
              <small class="text-danger">Please provide a valid co-mentor</small>
            </div>
          </div>
        </div>
              
        <div class="row" *ngIf="hasCoMentor === true">
          <div class="col-md-4">
            <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasCoMentorBiosketchDocument.invalid}">
              <b>Co Mentor Biosketch</b><br />
              <document-upload [applicationId]="applicationId"
                [application]="application"
                [documentUploadId]="coMentorBiosketchId"
                [documentTypeId]="docSvc.COMENTORBIOSKETCHDOCTYPE"
                [documentDto]="coMentorBiosketchDocument"
                (fileAttached)="onFileAttached($event)">
              </document-upload>
              <div *ngIf="f.hasCoMentorBiosketchDocument?.invalid">
                <small *ngIf="f.hasCoMentorBiosketchDocument.errors?.required" class="text-danger">This file is required</small>                      
              </div>
            </div>
          </div>   
          <!--            
          <div class="col-md-4">
            <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasCoMentorSupportDocument?.invalid}">
              <b>Co Mentor Support</b><br />
              <document-upload [applicationId]="applicationId" 
                [documentUploadId]="coMentorSupportId"
                [documentTypeId]="docSvc.COMENTORLETTEROFSUPPORTDOCTYPE" 
                [documentDto]="coMentorSupportDocument"
                (fileAttached)="onFileAttached($event)">
              </document-upload>
              <div *ngIf="f.hasCoMentorSupportDocument?.invalid">
                <small *ngIf="f.hasCoMentorSupportDocument.errors?.required" class="text-danger">This file is required</small>                      
              </div>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.hasCoMentorTrainingDocument.invalid}">
              <b>Co Mentor Training Table</b><br />
              <document-upload [applicationId]="applicationId" 
                [application]="application"
                [documentUploadId]="coMentorTrainingId"
                [documentTypeId]="docSvc.COMENTORTRAININGLETTERDOCTYPE" 
                [documentDto]="coMentorTrainingDocument"
                (fileAttached)="onFileAttached($event)">
              </document-upload>
              <div *ngIf="f.hasCoMentorTrainingDocument?.invalid">
                <small *ngIf="f.hasCoMentorTrainingDocument.errors?.required" class="text-danger">This file is required</small>                      
              </div>
            </div>
          </div>
        </div>
          
      </div>
    </div>


    <div class="card border-secondary my-4">
      <div class="card-header">Recommender</div>
      <div class="card-body">

        <div class="row mb-2">
          <div class="col-md-3">
            <div class="form-group">
              <label for="recommenderFirstName">First Name</label>
              <input type="text" class="form-control" name="recommenderFirstName" formControlName="recommenderFirstName" (blur)="save()"/>
              <div *ngIf="f.recommenderFirstName?.invalid && (f.recommenderFirstName?.dirty || f.recommenderFirstName?.touched)">                                
                <small *ngIf="f.recommenderFirstName.errors?.required" class="text-danger">First Name is required</small>                      
              </div>
            </div>
          </div>          
          <div class="col-md-3 ">
            <div class="form-group">
              <label for="recommenderLastName">Last Name</label>
              <input type="text" class="form-control" name="recommenderLastName" formControlName="recommenderLastName"  (blur)="save()"/>
              <div *ngIf="f.recommenderLastName?.invalid && (f.recommenderLastName?.dirty || f.recommenderLastName?.touched)">                                
                <small *ngIf="f.recommenderLastName.errors?.required" class="text-danger">Last Name is required</small>                      
              </div>
            </div>
          </div>
          <div class="col-md-3 ">
            <div class="form-group">
              <label for="recommenderEmailAddress">Email Address</label>
              <input type="email" class="form-control" name="recommenderEmailAddress" formControlName="recommenderEmailAddress"  (blur)="save()"/>
              <div *ngIf="f.recommenderEmailAddress?.invalid && (f.recommenderEmailAddress?.dirty || f.recommenderEmailAddress?.touched)"> 
                <small *ngIf="f.recommenderEmailAddress.errors?.email" class="text-danger">Please provide a valid email address</small>
              </div>
            </div>
          </div>
          <div class="col-md-3 ">
            <div class="form-group">
              <label for="recommenderRelationship">Relationship</label>
              <input type="text" class="form-control" name="recommenderRelationship" formControlName="recommenderRelationship"  (blur)="save()"/>
              <div *ngIf="f.recommenderRelationship?.invalid && (f.recommenderRelationship?.dirty || f.recommenderRelationship?.touched)">                                
                <small *ngIf="f.recommenderRelationship.errors?.required" class="text-danger">Relationship is required</small>                      
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="alert alert-secondary border border-secondary shadow-sm"  [ngClass]="{'border-danger' : f.hasRecommederSupport.invalid}">
              <b>Recommender Support</b><br />
              <document-upload [applicationId]="applicationId"
                [application]="application"
                [documentUploadId]="recommederSupportId"                    
                [documentTypeId]="docSvc.RECOMMEDERLETTEROFSUPPORTDOCTYPE"
                [documentDto]="recommederSupportDocument"
                (fileAttached)="onFileAttached($event)">
              </document-upload>
              <div *ngIf="f.hasRecommederSupport?.invalid">                                
                <small *ngIf="f.hasRecommederSupport.errors?.required" class="text-danger">This file is required</small>                      
              </div>
            </div>
          </div>
        </div> 

      </div>
    </div>
    <div class="row">
      <div class="col-12">
          <validation-summary 
              [formGroup]="mentorForm"
              [friendlyFormFieldNames]="friendlyFieldNames"></validation-summary>
      </div>
    </div>
</div>