import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { CommitteeAssignmentDTO } from 'src/app/shared/models/CommitteeAssignmentDTO';
import { AddReviewerDTO } from '../models/AddReviewerDTO';
import { UserSearchResultDTO } from '../models/UserSearchResultDTO';


@Injectable({
  providedIn: 'root'
})
export class CommitteeAssignmentService {

  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  private baseurl = "/committeeassignment/";
  private getAllUrl = this.apiUrl + this.baseurl + "all";
  private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";
  private updateUrl = this.apiUrl + this.baseurl + "update";
  private addNewUrl = this.apiUrl + this.baseurl + "new"; 
  private addUrl = this.apiUrl + this.baseurl + "addExisting"; 
  private getSpecificUrlByName = this.apiUrl + this.baseurl + "byname/";  
  private deleteUrl = this.apiUrl + this.baseurl + "delete/"
  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService
  ) {
    
  }
  public getReviewers(): Observable<CommitteeAssignmentDTO[]> {
    return this.httpClient.get<CommitteeAssignmentDTO[]>(this.getAllUrl);
  }

  public findReviewer(subString: string): Observable<UserSearchResultDTO[]> {
    return this.httpClient.get<UserSearchResultDTO[]>(this.getSpecificUrlByName+subString);
  }

  public addReviewer(addReviewer: AddReviewerDTO): Observable<any> {
    //alert("email in PI select"+addReviewer.email);
    return this.httpClient.post<any>(this.addNewUrl, addReviewer)
    .pipe(
      //retry(1),
       catchError(this.handleError)
    );
  }
/*
  public addExternalReviewer(addReviewer: AddReviewerDTO): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}/user/AddExternalReviewer`, addReviewer);
  }
*/
  public addRoleAssignment(reviewer: AddReviewerDTO): Observable<CommitteeAssignmentDTO> {
    return this.httpClient.post<CommitteeAssignmentDTO>(this.addUrl,  reviewer)
    .pipe(
      //retry(1),
       catchError(this.handleError)
    );
  }

  public updateRoleAssignment(reviewer: CommitteeAssignmentDTO): Observable<CommitteeAssignmentDTO> {
    //return this.httpClient.post<CommitteeAssignmentDTO>(this.updateUrl,  reviewer)
    return this.httpClient.post<CommitteeAssignmentDTO>(this.addUrl,  reviewer)
    .pipe(
      //retry(0),
       catchError(this.handleError)
    );
  }

  public deleteRoleAssignment(id: number): Observable<number> {
    return this.httpClient.post<number>(this.deleteUrl+id,null)
    .pipe(
     // retry(1),
       catchError(this.handleError)
    );
  }

  

 



  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error.error);
  }

}
