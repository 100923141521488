import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { WelcomeComponent } from './welcome.component';
//import { CycleComponent } from './cycle.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { DateConverterInterceptor } from './core/interceptors/date-converter.interceptor';
import { ConfigService } from './core/services/config.service';
import { AdminModule } from './admin/admin.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApplyModule } from './apply/apply.module';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { GridModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ModeratorModule } from 'src/app/moderator/moderator.module';

import { ReviewModule } from 'src/app/review/review.module';
import { EditorModule } from '@progress/kendo-angular-editor';

export function initializeApp(configService: ConfigService) {
  return (): Promise<any> => {
    return configService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule ,
    SharedModule,
    DropDownsModule,
    BrowserAnimationsModule,
    AdminModule,
    ApplyModule,
    ReviewModule,
    ModeratorModule,
    DateInputsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    GridModule,
    LayoutModule,
    EditorModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService], multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: DateConverterInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
