import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 *
 * Sample usage:
 *
 * <div class="card" ccc-navcard
 *  [title]="'Researchers'"
 *  [buttonText]="'Researchers'"
 *  [icon]="'fa-handshake-o'"
 *  [link]="['/researchers']">
 *  See which researchers are in which CCC Program. View their details. Track non-member researchers
 * </div>
 *
 * @export
 * @class CccNavcardComponent
 * @implements {OnChanges}
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: '[ccc-navcard]',
  template: `

    <div class="card-header bg-primary text-light text-center py-4">
      <i [ngClass]="icon" class="fa fa-5x" aria-hidden="true"></i>
    </div>
    <div class="card-body bg-secondary">
      <h4 class="card-title">{{title}}</h4>
      <p class="card-text">
        <ng-content></ng-content>
      </p>
    </div>
    <div class="card-footer bg-secondary text-center">
      <a [routerLink]="link" class="btn btn-primary btn-block">{{buttonText}}</a>
    </div>

  `,
  styles: []
})
export class CccNavcardComponent implements OnChanges {

  @Input()
  title = 'Title';

  @Input()
  buttonText = 'button';

  @Input()
  icon = 'fa-question';

  @Input()
  link: string | any[];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const iconVal = changes['icon'].currentValue;
    if ( !this.icon.startsWith('fa') ) {
      console.warn('icon must start with fa-. please consult https://fontawesome.com/v4.7.0/icons/ for icon classes');
    }
  }
}
