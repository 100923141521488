import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { HttpParams } from '@angular/common/http';
import { ReviewGridDTO } from 'src/app/review/models/ReviewGridDTO';
import { ReviewDTO } from 'src/app/review/models/ReviewDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationListService } from 'src/app/admin/application-list/application-list.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReviewService } from 'src/app/review/services/review.service';
import { FinalReviewGridDTO } from 'src/app/review/models/FinalReviewGridDTO';
declare var $: any;
@Component({
    selector: 'app-all-final-scores',
    templateUrl: 'all-final-scores.component.html',
    styles: []
})
export class AllFinalScoresComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    @Output() finalscoresChanged = new EventEmitter<boolean>(true);
    public gridView!: GridDataResult;
    models: FinalReviewGridDTO[] = [];
    public currentModScore!: string;
    private editedRowIndex!: number;
    private editedScore!: FinalReviewGridDTO;
    disabled: boolean = false;    
    public state: State = {
        skip: 0,
        sort: [{
          field: 'reviewerDisplayName',
         // dir: 'desc'
        }]
      };
    //public reviewGridDTO!: ReviewGridDTO;

    public loadDataItem : FinalReviewGridDTO  |  undefined;
    constructor(private svc: ApplicationListService,
       public authService: AuthService,
       public reviewService: ReviewService,
        public configService : ConfigService,
        private toastr: ToastrService) {}
    public showForm: boolean = false;

   


    ngOnInit() {}

    init(applicationId: number): void {
       
       this.applicationId = applicationId;
       this.getAllFinalScores(this.applicationId); 
    }
    

    private getAllFinalScores(applicationId:number){
      //alert("getAllFinalScores ****");
        this.reviewService.getAllFinalScores(applicationId).subscribe(
            data => { 
                this.models = data; 
                //alert("getAllFinalScores models****"+this.models);
                this.gridView = process(this.models, this.state);
                this.show();
            }
        );
    }

    public allDataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.gridView = process(this.models, this.state);
      }
    public editHandler({sender, rowIndex, dataItem}:{sender:any, rowIndex:any, dataItem:any}) {
      //alert("editHandler ****"+dataItem.applicationId);
        this.closeEditor(sender);
    
        this.editedRowIndex = rowIndex;
        this.editedScore = Object.assign({}, dataItem);
        
        // make sure select shows the right existing value
        if (dataItem.finalReview !== undefined) {
          if (dataItem.finalReview.score > 0) {
            this.currentModScore = dataItem.finalReview.score;
          } else {
            this.currentModScore = 'null';
          }
        }
        sender.editRow(rowIndex);
      }

      public cancelHandler({sender, rowIndex}:{sender:any, rowIndex:any, dataItem:any}) {
       // alert("cancelHandler ****");
       
        $('#allFinalScoresModal').modal('hide');
        //this.applicationId = undefined!;
        
       // this.models = [];
        this.finalscoresChanged.emit(true);

        sender.closeRow(rowIndex);
    
        //this.editedRowIndex = undefined || 0;
        
        //this.closeEditor(sender, rowIndex);
        this.getAllFinalScores(this.applicationId);
        
      }

      public saveHandler({sender, rowIndex, dataItem, isNew}:{sender:any, rowIndex:any, dataItem:any, isNew:any}) {
        const scoreToSave = Object.assign({}, dataItem.finalReview);
       
        scoreToSave.reviewCommitteeAssignmentId = dataItem.reviewCommitteeAssignmentId;
 
        scoreToSave.applicationId = this.applicationId;
        if (+this.currentModScore >= 1 && +this.currentModScore <= 9) {
          scoreToSave.score = +this.currentModScore;
         
        } else if (this.currentModScore === 'null') {
          scoreToSave.score = null;          
        }  
        if (dataItem.finalReviewId > 0) {
          scoreToSave.finalReviewId = dataItem.finalReviewId;        
          this.reviewService.updateFinalScore(scoreToSave).subscribe(
            data => {
              //document.getElementById('spin' + rowIndex)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i>';
              this.toastr.success('Final Score Saved', '', {closeButton: false, timeOut: 3000});
              this.getAllFinalScores(this.applicationId); 
            },
            error => {
              console.log(JSON.stringify(error));
              this.toastr.error(error.statusText, 'Error Saving Score');
            }
          );
        } else {
          this.reviewService.addFinalScore(scoreToSave).subscribe(
            data => {
              //document.getElementById('spin' + rowIndex)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i>';
              this.toastr.success('Final Score Saved', '', {closeButton: false, timeOut: 3000});
              this.getAllFinalScores(this.applicationId); 
            },
            error => {
              console.log(JSON.stringify(error));
              this.toastr.error(error.statusText, 'Error Saving Score');
            }
          );
        }
    
        sender.closeRow(rowIndex);
    
        //this.editedScore = undefined ;
        this.editedRowIndex = undefined || 0;
      }
    private loadItems(): void {
        
        this.gridView = process(this.models, this.state);
      }
    
     show(): void {
        
        $('#allFinalScoresModal').modal('show');
      }
    
      close(): void {
        //alert("close   ****");
        $('#allFinalScoresModal').modal('hide');
        this.applicationId = undefined!;
        //this.reviewers = [];
        //this.locReviewers = [];
       // this.models = [];
        this.finalscoresChanged.emit(true);
        
      }
      private closeEditor(grid:any, rowIndex = this.editedRowIndex) {
        //alert("closeEditor ****");
        grid.closeRow(rowIndex);
        this.resetItem(this.editedScore);
        this.editedRowIndex = undefined || 0; 
             
         
      }
      private resetItem(dataItem: FinalReviewGridDTO) {
        if (!dataItem) { return; }
        //alert("resetItem  ****"+dataItem.applicationId);
        this.getAllFinalScores(dataItem.applicationId);
       
       /* // find orignal data item
        const originalDataItem = this.models.find(item => item.applicationId === dataItem.applicationId);
        alert("resetItem  originalDataItem ****"+originalDataItem);
        if(originalDataItem == null){
          this.loadDataItem = this.models.find(item => item.applicationId === dataItem.applicationId);
          alert("  loadDataItem****"+this.loadDataItem);
          Object.assign(this.loadDataItem!, dataItem);
        }
        else {
        // revert changes
        Object.assign(originalDataItem, dataItem);
        }
        */
        return;
      }
   
}