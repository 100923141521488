<div>
    <div class="row">
        <div class="col-md-12 col-lg-12 p-1">
            <div class="alert alert-info m-2 border border-info">Please select a fellowship below to start the application process.</div>
        </div>
    </div>
    <div class="row mb-4">
       
        <div class="col-md-3"  *ngIf="undergraduateType === true">
            <div class="card h-100 shadow-sm">
                <div class="card-header bg-primary text-light text-center py-4">
                    <h2 class="h3">Undergraduate<br />Award</h2>
                </div>
                <div class="card-body bg-light">
                    <p>Awards are available to ANY Ohio State University undergraduate students in any field of discipline (from astronomy to zoology) for 1 year. We highly encourage ALL students who are interested in cancer research to apply, including those in fields we have not previously funded.</p>
                    <p>Applicants must propose a cancer related project and the proposal MUST be written by the applicant. Only minor editing may be performed by the mentor or any other advisors Scoring Criteria.</p>
                </div>  
                <div class="card-footer border-top-0 bg-light">
                    <p class="text-center"><button class="btn btn-primary" (click)="btnTypeSelectionClick('Undergraduate')">Apply for Undergraduate</button></p>
                </div>
            </div>
        </div>
   
        <div class="col-md-3" *ngIf="graduateType === true">
            <div class="card h-100 shadow-sm">
                <div class="card-header bg-primary text-light text-center py-4">
                    <h2 class="h3">Graduate<br />Award</h2>
                </div>
                <div class="card-body bg-light">
                    <p>Applicants must have passed candidacy to apply. The award is available to any graduate student at The Ohio State University.</p>
                    <p>Applicants must conduct cancer research at The Ohio State University.</p>
                </div>  
                <div class="card-footer border-top-0 bg-light">
                    <p class="text-center"><button class="btn btn-primary" (click)="btnTypeSelectionClick('Graduate')">Apply for Graduate</button></p>
                </div>
            </div>
        </div>
    
        <div class="col-md-3" *ngIf="postDocType === true">
            <div class="card h-100 shadow-sm">
                <div class="card-header bg-primary text-light text-center py-4">
                    <h2 class="h3">Post-Doctorate<br />Award</h2>
                </div>
                <div class="card-body bg-light">
                    <p>Applicants must have passed candidacy to apply. The award is available for any postdoctoral trainee, regardless of nationality, to do cancer research at The Ohio State University.</p>
                    <p></p>
                </div>
                <div class="card-footer border-top-0 bg-light">
                    <p class="text-center"><button class="btn btn-primary" (click)="btnTypeSelectionClick('Postdoc')">Apply for Postdoc</button></p>
                </div>
            </div>
        </div>
    

        <div class="col-md-3" *ngIf="medicalType === true">
            <div class="card h-100 shadow-sm">
                <div class="card-header bg-primary text-light text-center py-4">
                    <h2 class="h3">Medical<br />Award</h2>
                </div>
                <div class="card-body bg-light">
                    <p>Applicants must be enrolled at the Ohio State University College of Medicine who are interested in dwvoting one year to cancer research.</p>
                    <p></p>
                </div>
                <div class="card-footer border-top-0 bg-light">
                    <p class="text-center"><button class="btn btn-primary" (click)="btnTypeSelectionClick('Medical')">Apply for Medical</button></p>
                </div>
            </div>
        </div>
    
    </div>
</div>
<div class="row" *ngIf="SelectedApplicationType == 'Undergraduate'">
    <div class="col-md-12 col-lg-12">
        <div class="p-2 border border-secondary bg-light rounded shadow-sm">
            <h2 class="h3">Undergraduate Award Guidelines</h2>            
        
            <ul class="list-group">
                <li class="list-group-item">
                    All applications will be critically reviewed based on the following criteria:
                    <ul>
                        <li>Applicant strengths and research potential(40%)</li>
                        <li>Mentor/advisor’s qualifications and training record(40%)</li>
                        <li>Innovativeness and impact of the project relevant to cancer research(20%)</li>
                    </ul>
                </li>
                <li class="list-group-item">
                    <strong>If awarded, the fellow and their mentor will be expected to participate in Pelotonia. The student may register as a student rider for the discounted fund raising minimum. If the fellow or mentor is unable to ride in Pelotonia, they are still required to register as a rider with the same fundraising expectations. Exceptions may be made by program on a case by case basis.</strong>
                </li>
                <li class="list-group-item">
                    <strong>All Fellows and their mentors must attend the Pelotonia Fellowship Symposium on Friday, November 13th.</strong>
                </li>  
                <li class="list-group-item">
                    <div class="form-check">
                        <td class="form-check-label mr-1">
                            <label class="form-check-label"> <input type="checkbox" class="form-check-input" id="ugradGuidelinesAccepted" [(ngModel)]="ugradGuidelinesAccepted">
                             I have read and understood and agree to follow the guidelines if selected for a Pelotonia Fellowship</label>
                      </div>
                    <div class="mt-2 pl-1">
                        <button [disabled]="ugradGuidelinesAccepted == false" class="btn btn-primary" (click)="btnGuidelinesAcceptedClick('Undergraduate')">Start Undergraduate Application</button>
                    </div>
                </li>                  
            </ul>
        </div>
    </div>    
</div>
<div class="row" *ngIf="SelectedApplicationType == 'Graduate'">
    <div class="col-md-12 col-lg-12">
        <div class="p-2 border border-secondary bg-light rounded shadow-sm">
            <h2>Graduate Award Guidelines</h2>
            <ul class="list-group">
                <li class="list-group-item">
                    All applications will be critically reviewed based on the following criteria:
                    <ul>
                        <li>Applicant strengths and research potential (40%)</li>
                        <li>Mentor/advisor’s qualifications and training record (40%)</li>
                        <li>Innovativeness and impact of the project relevant to cancer research (20%)</li>
                    </ul>
                </li>
                <li class="list-group-item">
                    Applicants must have passed candidacy to apply. The award is available to any graduate student at The Ohio State University.
                </li>
                <li class="list-group-item">
                    Applicants may only apply twice with the same project.
                </li>
                <li class="list-group-item">
                    Applicants must propose a cancer related project.
                </li>
                <li class="list-group-item">
                    A $6,000 annual matching contribution to The Ohio State University Comprehensive Cancer Center’s Pelotonia Fellowship Program will be required for each award. Options for fulfilling this requirement are numerous. We suggest the mentor/advisor and the home department of the mentor/advisor split the contribution. However, the contribution may come from any combination of funds (from the college, department, graduate program, student’s advisor, mentor’s lab or development funds, etc.). Please contact us if you need additional suggestions.</li>
                <li class="list-group-item">
                    The awardee will hold a one-year appointment with the option for a second year at the sole discretion of The Ohio State University. The awardee will receive a stipend on the last day of each month during the term of the award.</li>
                <li class="list-group-item">
                    <strong>If awarded, the scholar and their mentor are required to participate in Pelotonia. The scholar may register as a student rider for the discounted fund raising minimum. If the scholar or mentor is unable to ride in Pelotonia, they are still required to register as a rider with the same fundraising expectations. If the scholar and mentor do not participate in Pelotonia they may lose their award.</strong>
                </li>
                <li class="list-group-item">
                    <div class="form-check">
                        <td class="form-check-label mr-1">
                            <label class="form-check-label"> <input type="checkbox" class="form-check-input" id="gradGuidelinesAccepted" [(ngModel)]="gradGuidelinesAccepted">
                             I have read and understood and agree to follow the guidelines if selected for a Pelotonia Award</label>
                      </div>
                    <div class="mt-2 pl-1">
                        <button [disabled]="gradGuidelinesAccepted == false" class="btn btn-primary" (click)="btnGuidelinesAcceptedClick('Graduate')">Start Graduate Application</button>
                    </div>
                </li>   
            </ul>
        </div>
    </div>
</div>
<div class="row" *ngIf="SelectedApplicationType == 'Postdoc'">
    <div class="col-md-12 col-lg-12">
        <div class="p-2 border border-secondary bg-light rounded shadow-sm">
            <h2>Post-Doctorate Award Guidelines</h2>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    All applications will be critically reviewed based on the following criteria:
                    <ul>
                        <li>Applicant strengths and research potential (33.4%)</li>
                        <li>Mentor/advisor’s qualifications and proven track record of training postdocs (33.3%)</li>
                        <li>Innovativeness and impact of the project relevant to cancer research (33.3%)</li>
                    </ul>
                </li>
                <li class="list-group-item">
                    Applicants must have passed candidacy to apply. The award is available for any postdoctoral trainee, regardless of nationality, to do cancer research at The Ohio State University. </li>
                <li class="list-group-item">
                    Chosen applicants will be appointed as “Postdoctoral Scholars”.
                </li>
                <li class="list-group-item">
                    Applicants must propose a cancer related project and the project must be written by the applicant.
                </li>
                <li class="list-group-item">
                    Applicants may only apply twice with the same project.
                </li>
                <li class="list-group-item">Clinical fellows are strongly encouraged to apply.</li>
                <li class="list-group-item">
                    The Postdoctoral Scholar will hold a one-year appointment with the option for a second year at the sole discretion of The Ohio State University. The Postdoctoral Scholar will receive a monthly stipend on the last day of each month during the appointment term. </li>
                <li class="list-group-item">
                    A $10,000 annual “matching” contribution to The Ohio State University Comprehensive Cancer Center's Pelotonia Fellowship Program will be required for each award. Options for fulfilling this requirement are numerous. We suggest the mentor and the mentor’s home department split the contribution. However, the contribution may come from any combination of funds (from the college, department, development funds, mentor’s lab, etc.). Please contact us if you need additional suggestions. </li>
                <li class="list-group-item">
                    <strong>If awarded, the fellow and their mentor are required to participate in Pelotonia. The fellow may register as a student rider for the discounted fund raising minimum. If the fellow or mentor is unable to ride in Pelotonia, they are still required to register as a rider with the same fundraising expectations. If the Postdoctoral Scholar and mentor do not participate in Pelotonia they may lose their award.</strong>
                </li>
                <li class="list-group-item">
                    <div class="form-check">
                        <td class="form-check-label mr-1">
                            <label class="form-check-label"> <input type="checkbox" class="form-check-input" id="postDocGuidelinesAccepted" [(ngModel)]="postDocGuidelinesAccepted">
                             I have read and understood and agree to follow the guidelines if selected for a Pelotonia Award</label>
                      </div>
                    <div class="mt-2 pl-1">
                        <button [disabled]="postDocGuidelinesAccepted == false" class="btn btn-primary" (click)="btnGuidelinesAcceptedClick('Postdoc')">Start Post-Doctorate Application</button>
                    </div>
                </li> 
            </ul>
        </div>
    </div>
</div>

<div class="row" *ngIf="SelectedApplicationType == 'Medical'">
    <div class="col-md-12 col-lg-12">
        <div class="p-2 border border-secondary bg-light rounded shadow-sm">
            <h2>Medical Award Guidelines</h2>
            <ul class="list-group list-group-flush">
                <li class="list-group-item">
                    All applications will be critically reviewed based on the following criteria:
                    <ul>
                        <li>Applicant strengths and research potential (40%)</li>
                        <li>Mentor/advisor’s qualifications and training record (40%)</li>
                        <li>Innovativeness and impact of the project relevant to cancer research (20%)</li>
                    </ul>
                </li>
                <li class="list-group-item">
                    Medical student fellowships are available for 1 year of dedicated full time research.
                </li>
                <li class="list-group-item">
                    Applications for medical fellowships will be accepted once per year
                </li>
                <li class="list-group-item">
                    Applicant must propose a cancer related project
                </li>
               
               
                <li class="list-group-item">
                    <strong>If awarded, the awardee and their mentor will be expected to participate in Pelotonia. The student may register as a student rider for the discounted fund raising minimum. If the awardee or mentor is unable to ride in Pelotonia, they are still required to register as a rider with the same fundraising expectations. Exceptions may be made by program on a case by case basis.</strong>
                </li>
                <li class="list-group-item">
                    <div class="form-check">
                        <td class="form-check-label mr-1">
                            <label class="form-check-label"> <input type="checkbox" class="form-check-input" id="medicalGuidelinesAccepted" [(ngModel)]="medicalGuidelinesAccepted">
                             I have read and understood and agree to follow the guidelines if selected for a Pelotonia Award</label>
                      </div>
                    <div class="mt-2 pl-1">
                        <button [disabled]="medicalGuidelinesAccepted == false" class="btn btn-primary" (click)="btnGuidelinesAcceptedClick('Medical')">Start Medical Application</button>
                    </div>
                </li> 
            </ul>
        </div>
    </div>
</div>


