import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor, State, process } from '@progress/kendo-data-query';


import { ToastrService } from 'ngx-toastr';
// TODO: don't like using a service from a different module

import { AuthService } from 'src/app/core/services/auth.service';
import { LookupService } from 'src/app/core/services/lookup.service';


declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: 'index.component.html',
  styles: []
})
export class IndexComponent implements OnInit {

  

  constructor(
    private datePipe: DatePipe,
    private lookupService: LookupService,
    //private reportService: ReportService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {

   

  }


 

}
