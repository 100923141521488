import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationMentorRecommenderDTO } from 'src/app/shared/models/ApplicationMentorRecommenderDTO';

@Injectable({
  providedIn: 'root'
})
export class FormStateService {

  private _mentors = new BehaviorSubject<ApplicationMentorRecommenderDTO>(undefined!);
  readonly mentors$ = this._mentors.asObservable();

  private _attachmentsValid = new BehaviorSubject<boolean>(undefined!);
  readonly attachmentsValid$ = this._attachmentsValid.asObservable();

  private _readOnly = new BehaviorSubject<boolean>(undefined!);
  readonly readOnly$ = this._readOnly.asObservable();
  
  constructor() { }


  public setApplicationStatusId(applicationStatusId: number) {
    if (applicationStatusId !== ConfigService.settings.const.APPLICATION_STATUS_INPROGRESS) {
      this._readOnly.next(true);
    } else {
      this._readOnly.next(false);
    }
  }

  public addMentor(mentor: ApplicationMentorRecommenderDTO) {
    // alert("at addMentor*****");
    this._mentors.next(mentor);
  }

}
