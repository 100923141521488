import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { PreviousSubmissionDTO } from "../../models/PreviousSubmissionDTO";
import { PreviousSubmissionsService } from "../../services/previoussubmissions.service";
import { LookupService } from 'src/app/core/services/lookup.service';

import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
@Component({
    selector: 'previous-submission-form',
    templateUrl: 'previous-form.component.html'
})
export class PreviousSubmissionFormComponent implements OnInit, OnChanges {
    prevSubFG: UntypedFormGroup = undefined!;
    @Input()
    applicationId: number = 0;
    @Input()
    public previousSubmissionId: number = 0;  
    @Output()
    savedEvent = new EventEmitter<string>();
    isFormValid: boolean = true;
    public applicationTypes: ApplicationTypeDTO[] = [];
    constructor(private fb: UntypedFormBuilder,
        private svc: PreviousSubmissionsService,
        private lookupService: LookupService,
        private toastr: ToastrService) { }


    ngOnInit() {
        this.lookupService.applicationTypes$.subscribe(
            data => {
              if (data) { this.applicationTypes= data; }
            }
          );
        this.initForm();
     }
    
    ngOnChanges(changes: SimpleChanges): void {        
        if (this.previousSubmissionId > 0) {
            this.loadForEdit(this.previousSubmissionId);
        } else {
            this.initForm();
        }       
    }
    
    initForm() {
        let curYear = new Date().getFullYear();
        this.prevSubFG = this.fb.group({
            applicationTypeId: ['', Validators.required],
            applicationCycleName: ['', [Validators.required, Validators.maxLength(50)]],
            applicationCycleYear: ['', [Validators.required, Validators.min(2000), Validators.max(curYear), Validators.pattern('^[0-9]{4}$')]],
            wasFellowshipAwarded: ['', Validators.required],
        });
        this.prevSubFG.markAsUntouched();
        this.prevSubFG.markAsPristine();
    }

    get f() {
        return this.prevSubFG.controls;
    }
    
    loadForEdit(id: number) {
        this.svc.get(id).subscribe(
            data => {
                this.prevSubFG.patchValue({
                    "applicationTypeId": data.applicationTypeId,
                    "applicationCycleName": data.applicationCycleName,
                    "applicationCycleYear": data.applicationCycleYear,
                    "wasFellowshipAwarded": data.wasFellowshipAwarded
                });
                this.prevSubFG.markAsUntouched();
                this.prevSubFG.markAsPristine();
            }
        );
    }

    save() {
        this.isFormValid = this.prevSubFG.valid;
        if (this.prevSubFG.valid && this.prevSubFG.touched) {
            let dto: PreviousSubmissionDTO = {
                applicationPreviousSubmissionId: this.previousSubmissionId,
                applicationId: this.applicationId,                                
                applicationTypeId: this.prevSubFG.get('applicationTypeId')?.value,
                applicationCycleName:  this.prevSubFG.get('applicationCycleName')?.value,
                applicationCycleYear:  this.prevSubFG.get('applicationCycleYear')?.value,
                wasFellowshipAwarded:  this.prevSubFG.get('wasFellowshipAwarded')?.value,
            };

            if (this.previousSubmissionId === 0) {
                this.svc.add(dto).subscribe(
                    data => { 
                        this.savedEvent.emit("saved");
                        this.initForm();
                        this.toastr.success("Saved Previous Submission Record."); }
                );
            } else {
                this.svc.update(dto).subscribe(
                    data => { 
                        this.savedEvent.emit("saved");
                        this.initForm();
                        this.toastr.success("Saved Previous Submission Record."); 
                    }
                );
            }
        }
    }

    cancelForm() {
        this.savedEvent.emit("cancelled");
        this.previousSubmissionId = 0;
    }
}