<div id="allFinalScoresModal" class="modal fade" tabindex="-1"  role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 50%;"  role="document">
      <div class="modal-content" >
    <div class="modal-header">
      <h5 class="modal-title text-dark">All final scores for this application</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form novalidate #myForm="ngForm" > 
      <kendo-grid
      [data]="gridView"
      [pageSize]="state.take!"
      [skip]="state.skip!"
     
     
      [pageable]="false"
     
      [reorderable]="true"
      [resizable]="true"
     
      style="height:auto"
      class="mb-5"
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (dataStateChange)="allDataStateChange($event)">  
  
      <kendo-grid-column
      field="reviewerDisplayName"
      title="Reviewer Name"
     >
      [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.reviewerDisplayName}}
      </ng-template>
    </kendo-grid-column>
  
   
    <kendo-grid-column
    field="initalScore"
    title="InitialScore"
    [width]="180">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.initalScore}}
    </ng-template>
  </kendo-grid-column>
<!--
  <kendo-grid-column
    field="score"
    title="Final Review Score"
    [width]="180">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.score}}
    </ng-template>
  </kendo-grid-column>
-->

<kendo-grid-column 
field="score"
title="Final Review Score"       
[width]="170"
headerClass="bg-primary text-light"
[editable]="true"
[sortable]="false"
[filterable]="false"
[hidden]="!authService.isReviewer"
>
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  
  <ng-container *ngIf="dataItem.finalReviewId == 0 || dataItem.score == null">
    <button kendoGridEditCommand class="btn btn-sm btn-primary" title="Add Final Score">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
  </ng-container>
  <ng-container >  
    
    <button kendoGridEditCommand class="btn btn-sm btn-secondary" title="Edit Final Score" 
    *ngIf="dataItem.score> 0">
      {{dataItem.score ||''}}
    </button>
  
  </ng-container>
  <span [id]="'spin' + rowIndex"></span>
</ng-template>
<ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
  <select class="form-control mr-3" id="score" name="score" required
    [(ngModel)]="currentModScore">
   
    <optgroup label="Score">
      <option [value]="null">Null</option>
      <option [value]="1">1</option>
      <option [value]="2">2</option>
      <option [value]="3">3</option>
      <option [value]="4">4</option>
      <option [value]="5">5</option>
      <option [value]="6">6</option>
      <option [value]="7">7</option>
      <option [value]="8">8</option>
      <option [value]="9">9</option>
    </optgroup>
   
  </select>

  <button kendoGridSaveCommand class="btn btn-sm btn-primary mt-1 clearfix" [disabled]="!currentModScore || currentModScore.length == 0"
    > Save
  </button>
  <button kendoGridCancelCommand class="btn btn-sm btn-secondary mt-1 ml-1">Cancel</button>
  <span [id]="'spin' + rowIndex"></span>
</ng-template>
</kendo-grid-column>




    </kendo-grid>
   
    </form>
   
  </div>
  </div>

  </div>