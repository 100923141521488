import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";


import { ToastrService } from "ngx-toastr";
import { ApplicationMentorRecommenderDTO } from 'src/app/shared/models/ApplicationMentorRecommenderDTO';
import { MentorTypeDTO } from 'src/app/apply/models/MentorTypeDTO';
import { CincinnatiDepartmentDTO } from 'src/app/apply/models/CincinnatiDepartmentDTO';
//import { FindPeopleResult } from '../../models/FindPeopleResult';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ApplicationService } from '../../services/application.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { debounceTime, take } from 'rxjs/operators';
import { ApplciationDocumentService } from "../../services/applicationdocument.service";
import { ApplicationDocumentDTO } from "src/app/shared/models/ApplicationDocumentDTO";
import { FormSectionState } from "../../models/FormSectionState";

import { HttpClient } from '@angular/common/http';
import { FormStateService } from '../../services/form-state.service';
import { AuthService } from 'src/app/core/services/auth.service';

declare var $: any;
@Component({
  selector: 'app-mentor',
  templateUrl: 'mentor.component.html'
})
export class ApplicationMentorRecommenderComponent implements OnInit {    
  
    @Input()
    public applicationId: number = 0;
    @Input()
    public application!: ApplicationDTO;
    private recordExists: boolean = false;

    @Output()
    formGroupValidationMonitorEvent = new EventEmitter<FormSectionState>(true);
   
    public mentorForm!: UntypedFormGroup;
    public disabled = false;
    public hasCoMentor: boolean = false;
    @Input()
    public mentorRecommenderDto: ApplicationMentorRecommenderDTO | undefined = undefined;;
    public savingInProgress: boolean = false;
    public isMentorOSU: any;
    public isCoMentorOSU: any;
    public isMentorCCH: boolean = false; 
    //public applicationBiosketchId: number = 0;
    public mentorTypes: MentorTypeDTO[] = [];
    public cincinnatiDepartments: CincinnatiDepartmentDTO[] = [];
   
    private subscriptions: Subscription[] = [];
    public appCycleOpen = false;
    //public mentorTypeId : any;
    // public mentorCincinnatiDepartmentId : any;
    // public coMentorCincinnatiDepartmentId : any;
    public usa : any;
    public ohio : any;
    public mentorBiosketchId: number = 0;
    public mentorSupportId: number = 0;
    
    public coMentorBiosketchId: number = 0;
    //public coMentorSupportId: number = 0;

    public mentorTrainingId: number = 0;
    public coMentorTrainingId: number = 0;
    public recommederSupportId: number = 0;
    //public formSection: FormSectionState;

    @Input()
    public mentorBiosketchDocument: ApplicationDocumentDTO | undefined = undefined;

    @Input()
    public mentorSupportDocument: ApplicationDocumentDTO | undefined = undefined;

    @Input()
    public coMentorBiosketchDocument: ApplicationDocumentDTO | undefined = undefined;
/*
    @Input()
    public coMentorSupportDocument: ApplicationDocumentDTO | undefined = undefined;
*/
    @Input()
    public mentorTrainingDocument: ApplicationDocumentDTO | undefined = undefined;

    @Input()
    public coMentorTrainingDocument: ApplicationDocumentDTO | undefined = undefined;
    @Input()
    public recommederSupportDocument: ApplicationDocumentDTO | undefined = undefined;
    public friendlyFieldNames: Record<string, string> = { };

    public appMentor: ApplicationMentorRecommenderDTO;
    constructor(private fb: UntypedFormBuilder,
        private toastr: ToastrService,
        private http: HttpClient,
        private formStateService: FormStateService,
      public applicationService: ApplicationService,
      public authService: AuthService,
      private lookupService: LookupService,
      public docSvc: ApplciationDocumentService) { }
  


    ngOnInit() {     
        this.lookupService.currentCycle$.pipe(take(2)).subscribe(
            data => {
              if (data) {
                if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                  this.appCycleOpen = true;
                }
              }
            }
          );
    //   this.applicationService.getCincinnatiDepartments().subscribe(
    //     data => { this.cincinnatiDepartments = data; },
    //     error => { this.toastr.error(error.statusText, 'Error'); }
    //   );
      if(this.authService.isApplicationsAdmin) {
        this.disabled = false;
      }
      else if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
            || this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ){ //|| !this.appCycleOpen) {
            this.disabled = true;
      } else {
        this.disabled = false;
      }
    
      this.mentorForm = this.fb.group({
        applicationId: [this.applicationId],
        // mentorOsuNameDotNumber: [{value: '',  disabled: this.disabled}],
        // mentorFirstName: [{value: '',  disabled: this.disabled}],
        // mentorLastName: [{value: '',  disabled: this.disabled}],
        // mentorEmailAddress: [{value: '',  disabled: this.disabled}, [Validators.email, Validators.required]],
       // mentorOrganization: [{value: '',  disabled: this.disabled}, Validators.required],
         mentorId: [{value: '',  disabled: this.disabled}, Validators.required],
    //    mentorCincinnatiDepartmentId: [{value: '',  disabled: this.disabled}],
       hasMentorBiosketchDocument:  [{value: '',  disabled: this.disabled}, Validators.required],
       hasMentorSupportDocument:  [{value: '',  disabled: this.disabled}, Validators.required],
       hasMentorTrainingDocument: [{value: '',  disabled: this.disabled}, Validators.required],
       hasCoMentor: [{value: '',  disabled: this.disabled}, Validators.required],

    //    coMentorFirstName: [{value: '',  disabled: this.disabled}],
    //    coMentorLastName: [{value: '',  disabled: this.disabled}],
    //    coMentorEmailAddress: [{value: '',  disabled: this.disabled},Validators.email],
     
      // coMentorOrganization : [{value: '',  disabled: this.disabled}],
    //    coMentorOsuNameDotNumber : [{value: '',  disabled: this.disabled}],
        coMentorId: [{value: '',  disabled: this.disabled}],
       //coMentorCincinnatiDepartmentId: [{value: '',  disabled: this.disabled}],
       hasCoMentorBiosketchDocument:  [{value: '',  disabled: this.disabled}],
       //hasCoMentorSupportDocument:  [{value: '',  disabled: this.disabled}],
        hasCoMentorTrainingDocument:  [{value: '',  disabled: this.disabled}],
        recommenderFirstName: [{value: '',  disabled: this.disabled}, Validators.required],
        recommenderLastName: [{value: '',  disabled: this.disabled}, Validators.required],
        recommenderEmailAddress: [{value: '',  disabled: this.disabled},  [Validators.email ,  Validators.required]],
        recommenderRelationship: [{value: '',  disabled: this.disabled}, Validators.required],
       hasRecommederSupport: [{value: '',  disabled: this.disabled}, Validators.required],
      });
     // this.friendlyFieldNames['mentorOsuNameDotNumber'] = 'Mentor OsuName.# '; 
    //   this.friendlyFieldNames['mentorFirstName'] = 'Mentor First Name ';   
    //   this.friendlyFieldNames['mentorLastName'] = 'Mentor Last Name ';   
    //   this.friendlyFieldNames['mentorEmailAddress'] = 'Mentor EmailAddress ';  
      this.friendlyFieldNames['mentorId'] = 'Mentor';
      this.friendlyFieldNames['coMentorId'] = 'CoMentor';
      //this.friendlyFieldNames['mentorOrganization'] = 'Mentor Organization ';   
      //this.friendlyFieldNames['mentorCincinnatiDepartmentId'] = 'Mentor Cincinnati Department ';           
      this.friendlyFieldNames['hasMentorBiosketchDocument'] = 'Mentor Biosketch Document ';   
      this.friendlyFieldNames['hasMentorSupportDocument'] = 'Mentor Support Document ';   
      this.friendlyFieldNames['hasMentorTrainingDocument'] = 'Mentor Training Document ';   
      this.friendlyFieldNames['hasCoMentor'] = 'Has a CoMentor or Have no Co mentor';  
      
     // this.friendlyFieldNames['coMentorOsuNameDotNumber'] = 'Co Mentor OsuName.# '; 
    //   this.friendlyFieldNames['coMentorFirstName'] = 'Co Mentor First Name ';   
    //   this.friendlyFieldNames['coMentorLastName'] = 'Co Mentor Last Name ';   
    //   this.friendlyFieldNames['coMentorEmailAddress'] = 'Co Mentor EmailAddress ';   
      //this.friendlyFieldNames['coMentorOrganization'] = 'Co Mentor Organization ';   
     // this.friendlyFieldNames['coMentorCincinnatiDepartmentId'] = 'Co Mentor Cincinnati Department ';           
      this.friendlyFieldNames['hasCoMentorBiosketchDocument'] = 'Co Mentor Biosketch Document ';   
      //this.friendlyFieldNames['hasCoMentorSupportDocument'] = 'Co Mentor Support Document ';   
      this.friendlyFieldNames['hasCoMentorTrainingDocument'] = 'Co Mentor Training Document '; 

      this.friendlyFieldNames['recommenderFirstName'] = 'Recommender First Name ';   
      this.friendlyFieldNames['recommenderLastName'] = 'Recommender Last Name ';   
      this.friendlyFieldNames['recommenderEmailAddress'] = 'Recommender EmailAddress'; 
      this.friendlyFieldNames['recommenderRelationship'] = 'Recommender Relationship';   
      this.friendlyFieldNames['hasRecommederSupport'] = 'Recommeder Support Document';  

      if(this.mentorForm !== undefined){
          this.applicationService.getMentor(this.applicationId).subscribe(
              data => { 
               //alert("Data is #######"+data);
                  if (data !=null ) {
                    //alert("Data is null"+data);
                      this.appMentor= data;
                      this.toSetFormGroup(data);
                      this.recordExists = true;
                  } else {
                      this.recordExists = false;
                  }
              }
          );
          this.watchForChanges();
      }

     /* this.watchForChanges();
      if (this.mentorRecommenderDto !== undefined) {
        this.toSetFormGroup(this.mentorRecommenderDto);
    }*/
    }

    watchForChanges() {
        // this.subscriptions.push(this.mentorForm.valueChanges
        //     .pipe(debounceTime(2000)).subscribe(() => {                 
        //         if (!this.disabled && this.mentorForm.touched && this.mentorForm.dirty ) {                                
        //         this.save();
        //         }
        //     })
        // );
        
        this.mentorForm.statusChanges.subscribe(() => {            
            this.formGroupValidationMonitorEvent.emit({ sectionName: 'Mentors', isValid: this.mentorForm.valid});
        });
        
        this.formGroupValidationMonitorEvent.emit({ sectionName: 'Mentors', isValid: this.mentorForm.valid});
    }
    

    // setMentorCincinnatiDepartmentValue(e:any) { 
    //   this.mentorCincinnatiDepartmentId = e.target.value
    // }
    
    // setCoMentorCincinnatiDepartmentValue(e:any) { 
    //     this.coMentorCincinnatiDepartmentId = e.target.value
    // }
    
    
    get f() {
        return this.mentorForm.controls;
    }

    save() {
        this.savingInProgress = true;  
        //alert("this.recordExists is ***"+this.recordExists);      
        if (this.recordExists === false) {
            this.applicationService.addRecommenderToApplication(this.initializeForm()).subscribe(
                data => { 
                    this.toSetFormGroup(data);
                    this.appMentor= data;
                    this.toastr.success('Application Updated');                    
                    this.savingInProgress = false;  
                    this.mentorForm.updateValueAndValidity();     
                            
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.error, 'Error updating application');
                }
            );
        } else {
            
            this.applicationService.updateRecommender(this.initializeForm()).subscribe(
                data => {
                   // this.toSetFormGroup(data);
                    this.appMentor= data;
                    this.toastr.success('Application Updated');
                    this.savingInProgress = false;
                    //window.location.reload();
                    this.mentorForm.updateValueAndValidity();   
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.error, 'Error updating application');
                  
                }
            );
        }
    }

    removeMentor(applicationId: number) {
        if (confirm('Are you sure you want to remove this mentor from this application?')) {
          //const apiid = this.piFormArray.at(index).get('applicationPIId').value;
          if (applicationId !== 0) {         
            this.applicationService.deleteMentor(applicationId).pipe(take(1))
              .subscribe(
                data => {
                    this.toSetFormGroup(data);
                    this.appMentor= data;
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.statusText, 'Error');
                }
              );
          }
        }
      }
      
      removeCoMentor(applicationId: number) {
        if (confirm('Are you sure you want to remove this Comentor from this application?')) {
          //const apiid = this.piFormArray.at(index).get('applicationPIId').value;
          if (applicationId !== 0) {         
            this.applicationService.deleteCoMentor(applicationId).pipe(take(1))
              .subscribe(
                data => {
                    this.toSetFormGroup(data);
                    this.appMentor= data;
                },
                error => {
                  console.log(JSON.stringify(error));
                  this.toastr.error(error.statusText, 'Error');
                }
              );
          }
        }
      }
    initializeForm() : ApplicationMentorRecommenderDTO {
        let dto: ApplicationMentorRecommenderDTO = {
            applicationId: this.applicationId,
            mentorOsuNameDotNumber: null,
            mentorFirstName: null,
            mentorLastName: null,
            mentorEmailAddress: null,  
            mentorOrganization: this.appMentor?.mentorOrganization,
            mentorCincinnatiDepartmentId: null,
            mentorId: null,
            //hasCoMentor: null,
            hasCoMentor: this.hasCoMentor,
            coMentorFirstName:  null,
            coMentorId: null,
            coMentorLastName:  null,
            coMentorEmailAddress: null,
          
            coMentorOrganization :  this.appMentor?.coMentorOrganization,
            coMentorOsuNameDotNumber :  null,
            coMentorCincinnatiDepartmentId:  null,

            recommenderFirstName:  null,
            recommenderLastName: null,
            recommenderEmailAddress:  null,
            recommenderRelationship:  null,
        };

       
        // dto.mentorFirstName = this.mentorForm.get('mentorFirstName')?.value;
        // dto.mentorLastName = this.mentorForm.get('mentorLastName')?.value;
        // dto.mentorEmailAddress = this.mentorForm.get('mentorEmailAddress')?.value;
        // dto.mentorOsuNameDotNumber = this.mentorForm.get('mentorOsuNameDotNumber')?.value;  
        //dto.applicantPhoneNumber = this.contactInformationForm.get('applicantPhoneNumber')?.value;  
        //dto.mentorTypeId = this.mentorForm.get('mentorTypeId')?.value;   
        dto.mentorId = this.mentorForm.get('mentorId')?.value;  
        // dto.mentorOrganization = this.appMentor?.mentorOrganization;
        // dto.mentorCincinnatiDepartmentId = this.mentorForm.get('mentorCincinnatiDepartmentId')?.value; 
        dto.hasCoMentor = this.mentorForm.get('hasCoMentor')?.value;

        // dto.coMentorFirstName = this.mentorForm.get('coMentorFirstName')?.value;
        // dto.coMentorLastName = this.mentorForm.get('coMentorLastName')?.value;
        // dto.coMentorEmailAddress = this.mentorForm.get('coMentorEmailAddress')?.value;
        // dto.coMentorOsuNameDotNumber = this.mentorForm.get('coMentorOsuNameDotNumber')?.value;  
        //dto.applicantPhoneNumber = this.contactInformationForm.get('applicantPhoneNumber')?.value;  
        //dto.mentorTypeId = this.mentorForm.get('mentorTypeId')?.value;    
        //dto.coMentorOrganization = this.mentorForm.get('coMentorOrganization')?.value;  
       // dto.coMentorCincinnatiDepartmentId = this.mentorForm.get('coMentorCincinnatiDepartmentId')?.value;
        dto.coMentorId = this.mentorForm.get('coMentorId')?.value;
            
        dto.recommenderFirstName = this.mentorForm.get('recommenderFirstName')?.value; 
        dto.recommenderLastName = this.mentorForm.get('recommenderLastName')?.value; 
        dto.recommenderEmailAddress = this.mentorForm.get('recommenderEmailAddress')?.value; 
        dto.recommenderRelationship = this.mentorForm.get('recommenderRelationship')?.value; 
       
        return dto;
        
    }

    toSetFormGroup(dto: ApplicationMentorRecommenderDTO) { 
       //alert("in toSetFormGroup mentorEmailAddress *****"+dto.mentorEmailAddress + "hasCoMentor is "+dto.hasCoMentor);
      this.mentorForm.patchValue({
        // "mentorOsuNameDotNumber": dto?.mentorOsuNameDotNumber,
        // "mentorFirstName": dto?.mentorFirstName,
        // "mentorLastName": dto?.mentorLastName,
        // "mentorEmailAddress": dto?.mentorEmailAddress,
        
        // "mentorTypeId": dto.mentorTypeId,
        // "mentorOrganization": dto?.mentorOrganization,
        // "mentorCincinnatiDepartmentId": dto?.mentorCincinnatiDepartmentId,
        "mentorId": dto?.mentorId,
        "hasCoMentor": dto?.hasCoMentor,

        // "coMentorOsuNameDotNumber": dto?.coMentorOsuNameDotNumber,
        // "coMentorFirstName": dto?.coMentorFirstName,
        // "coMentorLastName": dto?.coMentorLastName,
        // "coMentorEmailAddress": dto?.coMentorEmailAddress,
        
        // "mentorTypeId": dto.mentorTypeId,
        // "coMentorOrganization": dto?.coMentorOrganization,
        // "coMentorCincinnatiDepartmentId": dto?.coMentorCincinnatiDepartmentId,
        "coMentorId": dto?.coMentorId,
        'recommenderFirstName':dto?.recommenderFirstName, 
        'recommenderLastName':dto?.recommenderLastName ,
        'recommenderEmailAddress':dto?.recommenderEmailAddress ,
        'recommenderRelationship':dto?.recommenderRelationship ,
    });
        //this.toggleCoMentor(dto?.hasCoMentor ?? false);
        this.hasCoMentor = dto?.hasCoMentor??false;
        this.coMentorBiosketchId = this.coMentorBiosketchDocument?.applicationDocumentId ?? 0;
        //this.coMentorSupportId = this.coMentorSupportDocument?.applicationDocumentId ?? 0;
        this.coMentorTrainingId = this.coMentorTrainingDocument?.applicationDocumentId ?? 0;
        if(dto?.mentorOrganization !== null){
            //alert("mentorOrganization is Not null");
            this.isMentorOSU = dto?.mentorOrganization==="The Ohio State University"??false;
            // if(this.isMentorOSU){this.toggleMentorOsu(this.isMentorOSU)};
            // this.isMentorCCH = dto?.mentorOrganization==="Cincinnati Children Hospital Medical Center"??false;
           
            // if(this.isMentorCCH){ this.toggleMentorCincinnati(this.isMentorCCH)}
        }
        else {
            //alert("mentorOrganization is  null");
            this.isMentorOSU= null;
        }
        if(this.hasCoMentor){

            this.isCoMentorOSU = dto?.coMentorOrganization=== "The Ohio State University"?? false;
            if(dto?.coMentorOrganization!==null && dto?.coMentorOrganization!=='')
            {
                //alert("coMentorOrganization is  NOT null");
              this.toggleCoMentorNonOSU(dto?.coMentorOrganization=== "Non OSU"?? false)
            }
            else{
                this.isCoMentorOSU =null;
            //alert("coMentorOrganization is  null");
                // this.mentorForm.controls['coMentorOsuNameDotNumber'].setValidators(null);
                // this.mentorForm.controls['coMentorOsuNameDotNumber'].updateValueAndValidity();
               // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].setValidators(null);
               // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].updateValueAndValidity();
                //this.mentorForm.controls['coMentorOrganization'].setValidators([Validators.required]);
               // this.mentorForm.controls['coMentorOrganization'].updateValueAndValidity();
            }

            // this.mentorForm.controls['coMentorFirstName'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorFirstName'].updateValueAndValidity();
            // this.mentorForm.controls['coMentorLastName'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorLastName'].updateValueAndValidity();
            this.mentorForm.controls['coMentorId'].setValidators([Validators.required]);
            this.mentorForm.controls['coMentorId'].updateValueAndValidity();

            this.mentorForm.controls['hasCoMentorBiosketchDocument'].setValidators([Validators.required]);
            this.mentorForm.controls['hasCoMentorBiosketchDocument'].updateValueAndValidity();
            //this.mentorForm.controls['hasCoMentorSupportDocument'].setValidators([Validators.required]);
            //this.mentorForm.controls['hasCoMentorSupportDocument'].updateValueAndValidity();
            this.mentorForm.controls['hasCoMentorTrainingDocument'].setValidators([Validators.required]);
            this.mentorForm.controls['hasCoMentorTrainingDocument'].updateValueAndValidity();
        }
        this.recordExists = true;
        this.mentorForm.markAsPristine();
        this.mentorForm.updateValueAndValidity();
        
    }
    toggleCoMentor(hasCoMentor: boolean) {
        this.hasCoMentor= hasCoMentor;
        //alert("in toggleCoMentor , hasCoMentor is "+ this.hasCoMentor);
        if (this.hasCoMentor === true) {           
            // this.mentorForm.controls['coMentorFirstName'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorFirstName'].updateValueAndValidity();
           // this.mentorForm.controls['coMentorOrganization'].setValidators([Validators.required]);
           // this.mentorForm.controls['coMentorOrganization'].updateValueAndValidity();
            // this.mentorForm.controls['coMentorLastName'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorLastName'].updateValueAndValidity();
            this.mentorForm.controls['coMentorId'].setValidators([Validators.required]);
            this.mentorForm.controls['coMentorId'].updateValueAndValidity();
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].updateValueAndValidity();
            this.mentorForm.controls['hasCoMentorBiosketchDocument'].setValidators([Validators.required]);
            this.mentorForm.controls['hasCoMentorBiosketchDocument'].updateValueAndValidity();
           // this.mentorForm.controls['hasCoMentorSupportDocument'].setValidators([Validators.required]);
            //this.mentorForm.controls['hasCoMentorSupportDocument'].updateValueAndValidity();
            this.mentorForm.controls['hasCoMentorTrainingDocument'].setValidators([Validators.required]);
            this.mentorForm.controls['hasCoMentorTrainingDocument'].updateValueAndValidity();
           // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].setValidators([Validators.required]);
            //this.mentorForm.controls['coMentorCincinnatiDepartmentId'].updateValueAndValidity();
            
        }
        else {

            if (confirm('Co Mentor information will be removed if you switch to I do not have Co-Mentor. Are you sure?')) {
                this.applicationService.deleteCoMentor(this.applicationId).subscribe();
                //alert("Co Mentor information will be removed if you switch to I do not have Co-Mentor");
                // this.mentorForm.controls['coMentorFirstName'].setValidators(null);
                // this.mentorForm.controls['coMentorFirstName'].updateValueAndValidity();
                //this.mentorForm.controls['coMentorOrganization']?.setValidators(null);
               //this.mentorForm.controls['coMentorOrganization']?.updateValueAndValidity();
                // this.mentorForm.controls['coMentorLastName'].setValidators(null);
                // this.mentorForm.controls['coMentorLastName'].updateValueAndValidity();
                // this.mentorForm.controls['coMentorEmailAddress'].setValidators(null);
                // this.mentorForm.controls['coMentorEmailAddress'].updateValueAndValidity();
                // this.mentorForm.controls['coMentorOsuNameDotNumber'].setValidators(null);
                // this.mentorForm.controls['coMentorOsuNameDotNumber'].updateValueAndValidity();
                this.mentorForm.controls['hasCoMentorBiosketchDocument'].setValidators(null);
                this.mentorForm.controls['hasCoMentorBiosketchDocument'].updateValueAndValidity();
                //this.mentorForm.controls['hasCoMentorSupportDocument'].setValidators(null);
                //this.mentorForm.controls['hasCoMentorSupportDocument'].updateValueAndValidity();
                this.mentorForm.controls['hasCoMentorTrainingDocument'].setValidators(null);
                this.mentorForm.controls['hasCoMentorTrainingDocument'].updateValueAndValidity();
               // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].setValidators(null);
                //this.mentorForm.controls['coMentorCincinnatiDepartmentId'].updateValueAndValidity();
                this.mentorForm.controls['coMentorId'].setValidators(null);
                this.mentorForm.controls['coMentorId'].updateValueAndValidity();
                
                if(this.coMentorBiosketchDocument !== undefined){
                    this.docSvc.remove(this.coMentorBiosketchId).subscribe(data => {});
                    this.coMentorBiosketchDocument = undefined;
                    this.coMentorBiosketchId = 0;
                }

                if(this.coMentorTrainingDocument !== undefined){
                    this.docSvc.remove(this.coMentorTrainingId).subscribe(data => {});
                    this.coMentorTrainingDocument = undefined;
                    this.coMentorTrainingId = 0;
                }
                
                this.mentorForm.patchValue({
                    'hasCoMentorBiosketchDocument': '',            
                    //'hasCoMentorSupportDocument': '',
                    'hasCoMentorTrainingDocument': '',
                });
                //this.mentorForm.updateValueAndValidity();
                this.mentorForm.markAsPristine();
                this.mentorForm.updateValueAndValidity();
                //alert("Co Mentor clean");
            }
         }

    }
    // toggleMentorOsu(osu: boolean){
    //     this.isMentorOSU=osu;
    //     //alert("toggleMentorOsu " +this.isMentorOSU);
    //     this.mentorForm.controls['mentorOsuNameDotNumber'].setValidators([Validators.required]);
    //     this.mentorForm.controls['mentorOsuNameDotNumber'].updateValueAndValidity();
    //     this.mentorForm.controls['mentorCincinnatiDepartmentId'].setValidators(null);
    //     this.mentorForm.controls['mentorCincinnatiDepartmentId'].updateValueAndValidity();
    //      this.mentorForm.patchValue({          
    //         'mentorCincinnatiDepartmentId': '',          
    //     });
    //     this.mentorForm.updateValueAndValidity();
      
    // }
    // toggleMentorCincinnati(cincinnati: boolean) {
    //     this.isMentorCCH =cincinnati;
        
    //     this.mentorForm.controls['mentorCincinnatiDepartmentId'].setValidators([Validators.required]);
    //     this.mentorForm.controls['mentorCincinnatiDepartmentId'].updateValueAndValidity();
    //     this.mentorForm.controls['mentorOsuNameDotNumber'].setValidators(null);
    //     this.mentorForm.controls['mentorOsuNameDotNumber'].updateValueAndValidity();      
    //     this.mentorForm.patchValue({          
    //         'mentorOsuNameDotNumber': '',          
    //     });
    //     this.mentorForm.updateValueAndValidity();
    // }
   
    toggleCoMentorNonOSU(cincinnati: boolean) {
        //alert("coMentorOrganization " +cincinnati);
        if (cincinnati === true){
           // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].setValidators(null);
            //this.mentorForm.controls['coMentorCincinnatiDepartmentId'].updateValueAndValidity();
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].setValidators(null);
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].updateValueAndValidity();

            // this.mentorForm.patchValue({          
            //     'coMentorOsuNameDotNumber': '',          
            // });
            this.mentorForm.updateValueAndValidity();
        }
        else {
            this.isCoMentorOSU=true;
            
           // this.mentorForm.controls['coMentorCincinnatiDepartmentId'].setValidators(null);
            //this.mentorForm.controls['coMentorCincinnatiDepartmentId'].updateValueAndValidity();
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].setValidators([Validators.required]);
            // this.mentorForm.controls['coMentorOsuNameDotNumber'].updateValueAndValidity();
            // this.mentorForm.patchValue({          
            //     'coMentorCincinnatiDepartmentId': '',          
            // });
            this.mentorForm.updateValueAndValidity();
        }

      
    }

    onFileAttached(event: {attached: boolean, documentTypeId: number, documentDto: ApplicationDocumentDTO | undefined}) {
        if (event.attached === true) {
            switch(event.documentTypeId) {
                case this.docSvc.COMENTORBIOSKETCHDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorBiosketchDocument': true
                    });
                    this.coMentorBiosketchDocument = event.documentDto;
                    this.coMentorBiosketchId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
              /*  case this.docSvc.COMENTORLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorSupportDocument': true
                    });
                    this.coMentorSupportDocument = event.documentDto;
                    this.coMentorSupportId = event.documentDto?.applicationDocumentId ?? 0;
                    break;*/
                case this.docSvc.COMENTORTRAININGLETTERDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorTrainingDocument': true
                    });
                    this.coMentorTrainingDocument = event.documentDto;
                    this.coMentorTrainingId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
                case this.docSvc.MENTORBIOSKETCHDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorBiosketchDocument': true
                    });
                    this.mentorBiosketchDocument = event.documentDto;
                    this.mentorBiosketchId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
                case this.docSvc.MENTORLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorSupportDocument': true
                    });
                    this.mentorSupportDocument = event.documentDto;
                    this.mentorSupportId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
                case this.docSvc.MENTORTRAININGLETTERDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorTrainingDocument': true
                    });
                    this.mentorTrainingDocument = event.documentDto;
                    this.mentorTrainingId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
                case this.docSvc.RECOMMEDERLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasRecommederSupport': true
                    });
                    this.recommederSupportDocument = event.documentDto;
                    this.recommederSupportId = event.documentDto?.applicationDocumentId ?? 0;
                    break;
                        
            }
        } else {
            switch(event.documentTypeId) {

                case this.docSvc.COMENTORBIOSKETCHDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorBiosketchDocument': ''
                    });
                    this.coMentorBiosketchDocument = undefined;
                    this.coMentorBiosketchId = 0;
                    break;
               /*
                case this.docSvc.COMENTORLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorSupportDocument': ''
                    });
                    this.coMentorSupportDocument = undefined;
                    this.coMentorSupportId =  0;
                    break;
                    */
                case this.docSvc.COMENTORTRAININGLETTERDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasCoMentorTrainingDocument': ''
                    });
                    this.coMentorTrainingDocument = undefined;
                    this.coMentorTrainingId = 0;
                    break;
                case this.docSvc.MENTORBIOSKETCHDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorBiosketchDocument': ''
                    });
                    this.mentorBiosketchDocument = undefined;
                    this.mentorBiosketchId =  0;
                    break;
                case this.docSvc.MENTORLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorSupportDocument': ''
                    });
                    this.mentorSupportDocument = undefined;
                    this.mentorSupportId =  0;
                    break;
                case this.docSvc.MENTORTRAININGLETTERDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasMentorTrainingDocument': ''
                    });
                    this.mentorTrainingDocument = undefined;
                    this.mentorTrainingId =  0;
                    break;

                case this.docSvc.RECOMMEDERLETTEROFSUPPORTDOCTYPE:
                    this.mentorForm.patchValue({
                        'hasRecommederSupport': ''
                    });
                    this.recommederSupportDocument = undefined;
                    this.recommederSupportId =  0;
                    break;
            }
        }
    }
    handleRefresh(args: any) {
       // alert("handleRefresh $$$$$");
        //this.ngOnInit();
        window.location.reload();
       // @Output()
       // mentorEvent = new EventEmitter<string>();
       // this.mentorMonitorEvent.emit(2); 
       
    }
}
