import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /**
   * App-Specific role checks
   */

  get isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }
  
 
  get isSuperAdmin(): boolean {
    if (this.user == null) return false;
    
    return this.isLoggedIn ? this.user.profile.role.includes('SuperAdmin') : false;
  }

  get isApplicationsAdmin(): boolean {
    if (this.user == null) return false;
    //alert("My login application admin&&&&&&&&"+this.user.profile.role.includes('PFA_Admin'));
    return this.isLoggedIn ? (this.user.profile.role.includes('SuperAdmin') || this.user.profile.role.includes('PFA_Admin')) : false;

    //return false;
  }

  get isModerator(): boolean {
    if (this.user == null) return false;
   
    return this.isLoggedIn ? (this.user.profile.role.includes('PFA_Moderator') ) : false;
  }
  get isReviewer(): boolean {
    if (this.user == null) return false;
    return this.isLoggedIn ? (this.user.profile.role.includes('SuperAdmin') || this.user.profile.role.includes('PFA_Admin') || this.user.profile.role.includes('PFA_Reviewer')) : false;
  }
  get isSecurityAdmin(): boolean {
    return this.isLoggedIn ? this.user!.profile.role.includes('PelotoniaSecurityAdmin') : false;
    //alert("My login SecurityAdmin&&&&&&&&"+this.user!.profile.role.includes('SecurityAdmin'));
  }
  get isTrackingAdmin(): boolean {
    if (this.user == null) return false;   
    return this.isLoggedIn ? (this.user.profile.role.includes('SuperAdmin') || this.user.profile.role.includes('PFA_Admin') || this.user.profile.role.includes('PFA_Tracking')) : false;
  }
  get isReadOnly(): boolean {
    if (this.user == null) return false;   
    return this.isLoggedIn ? (this.user.profile.role.includes('SuperAdmin') || this.user.profile.role.includes('Pelotonia Read Only')) : false;
  }

  get userName(): string {
    if (this.user == null) return '';
    else if (this.isLoggedIn)
    {
      let usn: string = this.user.profile.name!;
      return usn;
    }
    else return '';
  }

  get userEmail(): string {
    if (this.user == null) return '';
    else if (this.isLoggedIn)
    {
      let usn: string = this.user.profile.preferred_username!;
      return usn;
    }
    else return '';
  }

  private manager: UserManager;
  private user: User | null = null;

  constructor(public router: Router) {

    this.manager = new UserManager(getClientSettings());

    this.manager.getUser().then(user => {
      this.user = user;
    });

    this.manager.events.addUserLoaded(() => {
      this.manager.getUser().then(user => {
        this.user = user;
      });
    });

    this.manager.events.addAccessTokenExpiring(() => {
      console.log('token expiring...');
    });

    this.manager.events.addAccessTokenExpired(() => {
      console.log('token expired');
      console.log(window.location.pathname);
      if (window.location.pathname !== '/welcome') {
        window.location.pathname = '/welcome';
      }
    });
  }

  // tslint:disable-next-line:no-any
  getClaims(): any {
    if (this.user == null) return '';
    else if (this.isLoggedIn)
    {
      let usn = this.user.profile!;
      return usn;
    }
    else return '';
  }

  getAuthorizationHeaderValue(): string {
    if (this.user == null) return '';
    else 
    {
      let ttkn = this.user.token_type!;
      let atkn = this.user.access_token!;
      return `${ttkn} ${atkn}`;
    }
  }

  getToken(): string {
    if (this.user == null) return '';
    else if (this.isLoggedIn)
    {
      let usn = this.user.access_token!;
      return usn;
    }
    else return '';
  }

  getProfile(): string {
    if (this.user == null) return '';
    else if (this.isLoggedIn)
    {
      let usn = this.user.profile.toString()!;
      return usn;
    }
    else return '';    
  }

  startAuthentication(redirectUrl?: string): Promise<void> {
    localStorage.removeItem('redirectUrl');
    if (redirectUrl) {
      localStorage.setItem('redirectUrl', redirectUrl);
    } else {
      localStorage.setItem('redirectUrl', window.location.pathname);
    }
    return this.manager.signinRedirect();
  }

  // completeAuthentication(): Promise<void> {
  //   console.log('AuthService::completeAuthentication');
  //   return this.manager.signinRedirectCallback().then(user => {
  //     this.user = user;

  //     const path: string = localStorage.getItem('redirectUrl');
  //     if (path) {
  //       console.log('redirectUrl found. Sending you to: ' + path);
  //       localStorage.removeItem('redirectUrl');
  //       this.router.navigateByUrl(path);
  //     } else {
  //       console.log('no redirectUrl found. Sending you to: /welcome');
  //       this.router.navigate(['/welcome']);
  //     }
  //   });
  // }

  logout() {
    this.manager.signoutRedirect().then(() => {
      this.manager.clearStaleState();
    });
  }

  // tslint:disable-next-line:no-any
  signoutRedirectCallback(): Promise<any> {
    return this.manager.signoutRedirectCallback();
  }

}

export function getClientSettings(): UserManagerSettings {
  return {
    authority: ConfigService.settings.auth.oidcAuthServer,
    client_id: ConfigService.settings.auth.oidcClientId,
    redirect_uri: ConfigService.settings.auth.oidcRedirectUrl + 'assets/oidc-login-redirect.html',
    post_logout_redirect_uri: ConfigService.settings.auth.oidcRedirectUrl,
    response_type: 'id_token token',
    scope: 'openid profile role ccc.identityapi.api ccc.emart.api ccc.pelotoniafellowship.api',
    filterProtocolClaims: true,
    loadUserInfo: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    silent_redirect_uri: ConfigService.settings.auth.oidcRedirectUrl + 'assets/oidc-silent-refresh.html'
  };
}
