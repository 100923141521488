import { Injectable } from '@angular/core';
import { AppConfig } from '../models/AppConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  static settings: AppConfig;

  constructor() { }

  load() {

    const jsonFile = './assets/config/appconfig.json';

    return new Promise<void>((resolve, reject) => {

      const xhr = new XMLHttpRequest();
      xhr.overrideMimeType('application/json');

      xhr.open('GET', jsonFile);
      // tslint:disable-next-line: only-arrow-functions
      xhr.onload = function() {
          if (xhr.status === 200) {
            ConfigService.settings = JSON.parse(xhr.responseText);
            console.log('Config Loaded');
            resolve();
          } else {
            console.log(xhr.statusText);
            reject('Could not load the config file');
          }
      };
      xhr.send();
    });
  }
}
