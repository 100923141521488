
<pagetitle pageTitle="Fellowship - Reviews"></pagetitle>
<div class="container-fluid">
  <div class="row">
   
  </div>
  <div class="col-md-12">
    <ul class="nav nav-tabs justify-content-center py-2" id="reviewTabs" role="tablist">
      <li class="nav-item">
        <button class="btn btn-sm btn-secondary" ><a class="nav-link active" href="javascript://nop" (click)="showMine()">My Assigned Applications</a> </button>
      </li>
      <li class="nav-item">
        <button class="btn btn-sm btn-secondary" ><a class="nav-link active" href="javascript://nop" (click)="showAll()">All Applications</a> </button> 
      </li>
    </ul>
    <h5 style="text-align:center;">For final review score, please select All Applications tab</h5>
  </div>
  <div class="row">
      
    <app-my-assigned-list *ngIf="mineShown"></app-my-assigned-list>

    <app-all-list *ngIf="allShown"></app-all-list> 

  </div>

  <div class="bg-dark text-white my-5 collapse" id="debugCard">
    <div class="card-body">
      
    </div>
  </div>
</div>