import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Validators } from '@angular/forms';

import { Observable } from 'rxjs';

import { AddReviewerDTO } from '../models/AddReviewerDTO';
import { ApplicationTypeDTO } from '../../shared/models/ApplicationTypeDTO';
import { CommitteeAssignmentService } from './committee-assignment.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ToastrService } from 'ngx-toastr';


declare var $: any;


interface FindPeopleResult {
  display_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  legal_last_name: string;
  name_suffix: string;
  username: string;
  email: string;
  address: {
      building: {
          name: string;
          number: string;
          url: string;
      };
      room_number: string;
      street1: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
  };
  phone: {
      area_code: string;
      exchange: string;
      subscriber: string;
      formatted: string;
  };
  majors: {
      major: string;
       college: string;
   }[];
  affiliations: string[];
  appointments: {
      job_title: string;
      working_title: string;
      organization: string;
      org_code: string;
      vp_college_name: string;
  }[];
}



@Component({
  selector: 'app-add-reviewer',
  templateUrl: 'add-reviewer.component.html'
})
export class AddReviewerComponent implements OnInit {

  @Output()
  reviewerAdded = new EventEmitter<boolean>(true);

  public applicationTypeId = 1;
  

  public applicationTypes!: ApplicationTypeDTO[];

  restItems: any;
  inputUserName = '';
  inputFirstName = '';
  inputLastName = '';
  searchParams = '';
  selectedDotNumber = '';

  searchByName = false;
  searchByDotNumber = false;
  showResults = false;

  searchResults: Array<FindPeopleResult> = [];

  disabled = false;

  constructor(private http: HttpClient,
    private lookupService: LookupService,
    private committeeAssignmentService: CommitteeAssignmentService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.lookupService.applicationTypes$.subscribe(
        data => {
          if (data) { this.applicationTypes= data; }
        }
      );
  }

  // used to display search parameters to user
  getSearchParams() {
    if (this.searchByName) {return this.inputFirstName + ' ' + this.inputLastName; } else {return null;}
    if (this.searchByDotNumber) {return this.inputUserName; }else {return null;}
  }

  search() {
    this.showResults = false;
    this.selectedDotNumber = '';
    let searchURL = '';

    if (this.searchByName) {
      searchURL = `https://directory.osu.edu/fpjson.php?lastname=${this.inputLastName}&firstname=${this.inputFirstName + '*'}`;
      this.searchParams = this.inputFirstName + ' ' + this.inputLastName;
    }

    if (this.searchByDotNumber) {
      this.inputUserName = (this.inputUserName).split('@')[0];
      searchURL = `https://directory.osu.edu/fpjson.php?name_n=${this.inputUserName}`;
      this.searchParams = this.inputUserName;
    }

    // filter out people without appointments (students)
    this.http.get<FindPeopleResult[]>(searchURL).subscribe(
      data => {
        //this.searchResults = data.filter(value => value.appointments.length > 0);
        this.searchResults = data.filter(value => value.appointments.length > 0?value.appointments.length > 0:value.username !=null);
        this.showResults = true;
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }

  activateSearchByName() {
    this.searchByName = true;
    this.searchByDotNumber = false;
  }

  activateSearchByDotNumber() {
    this.searchByName = false;
    this.searchByDotNumber = true;
  }

  piSelect(email: string, last_name: string, first_name: string) {

    const username = email.split('@')[0];
    //alert("username in PI select"+username);
    document.getElementById(username)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>';

    // tell irp api to add this reviewer to the system
    const newReviewer: AddReviewerDTO = {
      firstName: first_name,
      lastName: last_name,
      email: email,
      applicationTypeId : this.applicationTypeId
      
    };

    console.log('::AddUserComponent:onUserSelected:' + newReviewer);
    //alert("::AddUserComponent:onUserSelected:last name "+ newReviewer.lastName);
    this.committeeAssignmentService.addReviewer(newReviewer).subscribe(
      data => {
        document.getElementById(username)!.innerHTML = 'Select';
        this.reviewerAdded.emit(true);
        this.close();
      },
      error => {
        document.getElementById(username)!.innerHTML = 'Select';
        console.log(error);
        this.toastr.error(error, 'Error');
      }
    );
  }

  close(): void {
   // alert("::close^^^ ");
    $('#intReviewModal').modal('hide');
  }
}
