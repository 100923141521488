import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PreviousSubmissionDTO } from "../models/PreviousSubmissionDTO";
import { switchMap } from "rxjs/operators";

@Injectable({ 
    providedIn: 'root'
})
export class PreviousSubmissionsService {
    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
    private baseurl = "/applicationprevioussubmission/";
    private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";    
    private deleteUrl = this.apiUrl + this.baseurl + "delete";    
    private updateUrl = this.apiUrl + this.baseurl + "update";
    private addUrl = this.apiUrl + this.baseurl + "add";
    private getByApplicationUrl = this.apiUrl + this.baseurl + "byapplicationid/";
        
    constructor(protected httpClient: HttpClient,
        protected authService: AuthService) { }

    public get(id: number): Observable<PreviousSubmissionDTO> {
        return this.httpClient.get<PreviousSubmissionDTO>(this.getSpecificUrl + id);
    }

    public add(model: PreviousSubmissionDTO): Observable<PreviousSubmissionDTO> {
        return this.httpClient.post<PreviousSubmissionDTO>(this.addUrl, model);
    }

    public update(model: PreviousSubmissionDTO): Observable<PreviousSubmissionDTO> {
        return this.httpClient.post<PreviousSubmissionDTO>(this.updateUrl, model);
    }

    public delete(dto: PreviousSubmissionDTO): Observable<PreviousSubmissionDTO> {
        return this.httpClient.post<PreviousSubmissionDTO>(this.deleteUrl, dto);
    }

    public deleteAll(applicationId: number): Observable<PreviousSubmissionDTO> {
        return this.httpClient.post<any>(this.getByApplicationUrl + applicationId + "/clearall", {});
    }

    public getAllByApplicationId(applicationId: number): Observable<PreviousSubmissionDTO[]> {
        return this.httpClient.get<PreviousSubmissionDTO[]>(this.getByApplicationUrl + applicationId);
        // return this.httpClient.get<PreviousSubmissionDTO[]>(this.getByApplicationUrl + applicationId, { observe: 'response'}).pipe(
        //     switchMap(res => res.status === 200 ? of(res.body!) : of(undefined!))
        // );
    }
}