import { formatNumber } from "@angular/common";
import { Component, Input, OnInit, LOCALE_ID, Inject, OnChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup, ValidationErrors } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
    selector: 'validation-summary',
    template: `<div *ngIf="formGroup">
        <!--p>{{ formGroup.value | json }}</p -->
        <div class="row" *ngIf="errors.length == 0 && formGroup.valid">        
            <div class="col-md-12">
                <div class="border border-success alert alert-success shadow-sm">{{title}} Section Completed!</div>
            </div>
        </div>
        <div class="row" *ngIf="errors.length > 0">
            <div class="col-md-12">
                <div class="border border-warning alert alert-warning shadow-sm">
                    <p>Please correct the following issues with your application:</p>       
                    <div class="row" *ngFor="let error of errors">
                        <div class="col-12">
                            <i class="fa fa-exclamation-circle"></i> {{error}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`
})
export class ApplicationValidationSummaryComponent implements OnInit, OnChanges {
    @Input()
    public formGroup!: UntypedFormGroup;
    
    @Input()
    public title: string = '';

    @Input()
    public friendlyFormFieldNames: Record<string,string> = { };

    public errors: string[] = [];
    constructor(@Inject(LOCALE_ID)private locale: string) { }
    ngOnInit() { 
        this.formGroup.statusChanges
            .subscribe(status => {
                this.resetErrorMessages();
                this.generateErrorMessages(this.formGroup);
            });

        this.formGroup.valueChanges
            .subscribe(status => {    
                this.resetErrorMessages();
                this.generateErrorMessages(this.formGroup);
        });
    }

    ngOnChanges() {
        this.resetErrorMessages();
        this.generateErrorMessages(this.formGroup);
    }

    resetErrorMessages() {
      this.errors.length = 0;
    }

    generateErrorMessages(fg: UntypedFormGroup){
        Object.keys(fg.controls).forEach(cn => {            
            let control = fg.controls[cn];
            let errs = control.errors;
            if (errs === null || errs.count === 0) {
                return;
            }

            let fieldName = this.friendlyFormFieldNames[cn] ?? cn;

            if (errs.required) this.errors.push(`${fieldName} is required.`);
            if (errs.email) this.errors.push(`${fieldName} is invalid.`);
            if (errs.minlength) this.errors.push(`${fieldName} has a minimun length of ${errs.minlength.requiredLength}`);
            if (errs.maxlength) this.errors.push(`${fieldName} has a maximum length of ${errs.maxlength.requiredLength}`);            
            if (errs.min) this.errors.push(`${fieldName} has a minimum value of ${formatNumber(errs.min.min, this.locale,'1.2-2')}`);
            if (errs.max) this.errors.push(`${fieldName} has a maximum value of ${formatNumber(errs.max.max, this.locale,'1.2-2')}`);
        });
    }
}