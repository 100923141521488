import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { debounceTime ,take} from "rxjs/operators";
import { ApplicationDocumentDTO } from "src/app/shared/models/ApplicationDocumentDTO";
import { FormSectionState } from "../../models/FormSectionState";
import { ResearchProposalDTO } from "src/app/shared/models/ResearchProposalDTO";
import { ApplciationDocumentService } from "../../services/applicationdocument.service";
import { PreviousSubmissionsService } from "../../services/previoussubmissions.service";
import { ResearchProposalService } from "../../services/researchproposal.service";
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'research-proposal',
    templateUrl: 'research-proposal.component.html'
})
export class ResearchProposalComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    @Input()
    public application!: ApplicationDTO;
    @Input()
    public researchProposalDto: ResearchProposalDTO | undefined = undefined;
    @Input()
    public researchProposalDocument: ApplicationDocumentDTO | undefined = undefined;
    @Input()
    public rebuttalLetterDocument: ApplicationDocumentDTO | undefined = undefined;
    @Input()
    public resubmissionCritiqueDocument: ApplicationDocumentDTO | undefined = undefined;

    @Output()
    formGroupValidationMonitorEvent = new EventEmitter<FormSectionState>(true);
    public disabled = false;
    researchProposalDocumentId: number = 0;
    rebuttalLetterDocumentId: number = 0;
    resubmissionCritiqueDocumentId: number = 0;
    public appCycleOpen = false;
    public researchProposalFG: UntypedFormGroup = undefined!;
    public savingInProgress: boolean = false;
    public friendlyFieldNames: Record<string, string> = {};
    private subscriptions: Subscription[] = [];
    public previouslyApplied: boolean = false;
    public isResubmission: boolean = false;
    public isFunded: boolean = false;
    public hasPreviousSubmissionRecord: boolean = false;

    private recordExists: boolean = false;

    constructor(private svc: ResearchProposalService,
        public docSvc: ApplciationDocumentService,
        private prevSubSvc: PreviousSubmissionsService,
        private fb: UntypedFormBuilder,
        private ConfigService: ConfigService,
        public authService: AuthService,
        private lookupService: LookupService,
        private toastr: ToastrService) { }

    ngOnInit() {
        this.lookupService.currentCycle$.pipe(take(2)).subscribe(
            data => {
              if (data) {
                if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                  this.appCycleOpen = true;
                }
              }
            }
          );
          if(this.authService.isApplicationsAdmin)
          {
            this.disabled = false;
          }
         else if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
            || this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ){//|| !this.appCycleOpen) {
            this.disabled = true;
        } else {
            this.disabled = false;
        }
        this.configureFormValidation();
        this.setupAutoSave();

        // we need to refactor this to take in the data from an input!
        if (this.researchProposalDto !== undefined) {
            this.toFormGroup(this.researchProposalDto);
        }

    }

    configureFormValidation() {
        this.researchProposalFG = this.fb.group({
            previouslyAppliedForFellowship: [{ value: '', disabled: this.disabled }, Validators.required],
            isResubmission: [{ value: '', disabled: this.disabled }],
            titleOfProject: [{ value: '', disabled: this.disabled }, [Validators.required, Validators.maxLength(1000)]],
            abstract: [{ value: '', disabled: this.disabled }, Validators.required],
            layLanguageAbstract: [{ value: '', disabled: this.disabled }, Validators.required],
            hasFunding: [{ value: '', disabled: this.disabled }, [Validators.required, Validators.maxLength(50)]],
            fundingInfo: [{ value: '', disabled: this.disabled }],
            impactAndInnovation: [{ value: '', disabled: this.disabled }, Validators.required],
            researchProposalDocument: [{ value: '', disabled: this.disabled }, Validators.required],
            rebuttalLetterDocument: [{ value: '', disabled: this.disabled }],
            resubmissionCritiqueDocument: [{ value: '', disabled: this.disabled }],
            hasPreviousSubmissionRecord: [''],
        });


        this.friendlyFieldNames['previouslyAppliedForFellowship'] = 'Previously Fellowship Applications';
        this.friendlyFieldNames['isResubmission'] = 'Resubmission';
        this.friendlyFieldNames['titleOfProject'] = 'Title of Project';
        this.friendlyFieldNames['abstract'] = 'Abstract';
        this.friendlyFieldNames['layLanguageAbstract'] = 'Lay Language Abstract';
        this.friendlyFieldNames['hasFunding'] = 'Other Fundings';
        this.friendlyFieldNames['fundingInfo'] = 'Funding Info';
        this.friendlyFieldNames['impactAndInnovation'] = 'Impact and Innovation';
        this.friendlyFieldNames['researchProposalDocument'] = 'Research Proposal Document';
        this.friendlyFieldNames['rebuttalLetterDocument'] = 'Rebuttal Letter Document';
        this.friendlyFieldNames['resubmissionCritiqueDocument'] = 'Resubmission Critique Document';
        this.friendlyFieldNames['hasPreviousSubmissionRecord'] = 'Previous Submission (at least 1 entry)';
    }

    setupAutoSave() {
        this.subscriptions.push(this.researchProposalFG.valueChanges
            .pipe(debounceTime(2000))
            .subscribe(() => {
                //console.log(`touched: ${this.researchProposalFG.touched}, dirty ${this.researchProposalFG.dirty}`);
                if (!this.disabled && this.researchProposalFG.touched || this.researchProposalFG.dirty) this.save();
            })
        );

        this.researchProposalFG.statusChanges.subscribe(() => {
            this.formGroupValidationMonitorEvent.emit({ sectionName: 'ResearchProposal', isValid: this.researchProposalFG.valid });
        });

        this.formGroupValidationMonitorEvent.emit({ sectionName: 'ResearchProposal', isValid: this.researchProposalFG.valid });
    }

    save() {
        this.savingInProgress = true;
        if (this.recordExists === false) {
            this.svc.add(this.toDto()).subscribe(
                data => {
                    this.toFormGroup(data);
                    this.toastr.success('Updated Application!');
                    this.savingInProgress = false;
                },
                error => {
                    console.log(JSON.stringify(error));
                    this.toastr.error(error.statusText, 'Error updating application');
                }
            );
        } else {
            this.svc.update(this.toDto()).subscribe(
                data => {
                    this.toFormGroup(data);
                    this.toastr.success('Updated Application!');
                    this.savingInProgress = false;
                },
                error => {
                    console.log(JSON.stringify(error));
                    this.toastr.error(error.statusText, 'Error updating application');
                }
            );
        }

        // do this to make sure we are doing everything to clean this up proper        
        this.researchProposalFG.markAsUntouched();
        this.researchProposalFG.markAsPristine();
    }


    get f() {
        return this.researchProposalFG.controls;
    }


    toDto(): ResearchProposalDTO {
        let dto: ResearchProposalDTO = {
            applicationId: this.applicationId,
            previouslyAppliedForFellowship: null,
            isResubmission: null,
            titleOfProject: null,
            abstract: null,
            layLanguageAbstract: null,
            hasFunding: null,
            fundingInfo: null,
            impactAndInnovation: null,
        };

        dto.previouslyAppliedForFellowship = this.researchProposalFG.get('previouslyAppliedForFellowship')?.value;
        dto.isResubmission = this.researchProposalFG.get('isResubmission')?.value;
        dto.titleOfProject = this.researchProposalFG.get('titleOfProject')?.value;
        dto.abstract = this.researchProposalFG.get('abstract')?.value;
        dto.layLanguageAbstract = this.researchProposalFG.get('layLanguageAbstract')?.value;
        dto.hasFunding = this.researchProposalFG.get('hasFunding')?.value;
        dto.fundingInfo = this.researchProposalFG.get('fundingInfo')?.value;
        dto.impactAndInnovation = this.researchProposalFG.get('impactAndInnovation')?.value;

        // might need to include logic for conditional form fields (as we want to null out fields we dont want to capture based on selections)

        return dto;
    }


    toFormGroup(dto: ResearchProposalDTO) {
        this.researchProposalFG.patchValue({
            "previouslyAppliedForFellowship": dto.previouslyAppliedForFellowship,
            "isResubmission": dto.isResubmission,
            "titleOfProject": dto.titleOfProject,
            "abstract": dto.abstract,
            "layLanguageAbstract": dto.layLanguageAbstract,
            "hasFunding": dto.hasFunding,
            "fundingInfo": dto.fundingInfo,
            "impactAndInnovation": dto.impactAndInnovation,
        });

        // might need to include logic for conditional form fields (as we want to null out fields we dont want to capture based on selections)
        this.researchProposalDocumentId = this.researchProposalDocument?.applicationDocumentId ?? 0;
        this.rebuttalLetterDocumentId = this.rebuttalLetterDocument?.applicationDocumentId ?? 0;
        this.resubmissionCritiqueDocumentId = this.resubmissionCritiqueDocument?.applicationDocumentId ?? 0;

        this.togglePreviousApplication(dto.previouslyAppliedForFellowship ?? false)
        this.toggleResubmission(dto.isResubmission ?? false);
        this.toggleFunding((dto.hasFunding === "Funded") ?? false)
        this.recordExists = true;
        this.researchProposalFG.markAsPristine();
    }


    togglePreviousApplication(hasApplied: boolean) {
        this.previouslyApplied = hasApplied;

        if (hasApplied === true) {
            this.researchProposalFG.controls['hasPreviousSubmissionRecord'].setValidators([Validators.required]);
            this.researchProposalFG.controls['isResubmission'].setValidators([Validators.required]);

        } else {
            this.researchProposalFG.controls['hasPreviousSubmissionRecord'].setValidators(null);
            this.researchProposalFG.controls['isResubmission'].setValidators(null);
            this.prevSubSvc.deleteAll(this.applicationId).subscribe(data => { });
            this.cleanupDocuments();
        }
        this.researchProposalFG.patchValue({
            'previouslyAppliedForFellowship': hasApplied,
            //'hasPreviousSubmissionRecord': '',
            'isResubmission': ''
        });

        this.researchProposalFG.updateValueAndValidity();
    }


    toggleResubmission(isResubmission: boolean) {
        // console.log('in toggleResubmission');
        this.isResubmission = isResubmission;

        this.researchProposalFG.patchValue({
            'isResubmission': isResubmission
        });

        if (this.isResubmission === true) {
            this.researchProposalFG.controls['rebuttalLetterDocument'].setValidators([Validators.required]);
            this.researchProposalFG.controls['resubmissionCritiqueDocument'].setValidators([Validators.required]);
        }
        else {
            this.cleanupDocuments();
        }

        this.researchProposalFG.updateValueAndValidity();
    }


    cleanupDocuments() {
        // console.log('in cleanup');
        // console.log(`this.rebuttalLetterDocumentId: ${this.rebuttalLetterDocumentId}`);
        // console.log(`this.resubmissionCritiqueDocumentId: ${this.resubmissionCritiqueDocumentId}`);
        this.researchProposalFG.controls['rebuttalLetterDocument'].setValidators(null);
        this.researchProposalFG.controls['resubmissionCritiqueDocument'].setValidators(null);
        // see if we have stuff to remove
        if (this.rebuttalLetterDocument !== undefined) {
            // remove
            this.docSvc.remove(this.rebuttalLetterDocumentId).subscribe(data => { });
            this.rebuttalLetterDocument = undefined;
            this.rebuttalLetterDocumentId = 0;
        }
        if (this.resubmissionCritiqueDocument !== undefined) {
            // remove
            this.docSvc.remove(this.resubmissionCritiqueDocumentId).subscribe(data => { });
            this.resubmissionCritiqueDocument = undefined;
            this.resubmissionCritiqueDocumentId = 0;
        }

        this.researchProposalFG.patchValue({
            'rebuttalLetterDocument': '',
            'resubmissionCritiqueDocument': ''
        });

    }


    toggleFunding(isFunded: boolean) {
        this.isFunded = isFunded;

        if (isFunded === true) {
            this.researchProposalFG.controls['fundingInfo'].setValidators([Validators.required]);
        } else {
            this.researchProposalFG.controls['fundingInfo'].setValidators(null);
            this.researchProposalFG.patchValue({
                'fundingInfo': ''
            });
        }
        this.researchProposalFG.updateValueAndValidity();
    }


    onFileAttached(event: { attached: boolean, documentTypeId: number, documentDto: ApplicationDocumentDTO | undefined }) {

        if (event.attached === true) {
            // console.log('attaching file: ');
            // console.log(event.documentDto);
            switch (event.documentTypeId) {
                case this.docSvc.RESEARCHPROPOSALDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'researchProposalDocument': true
                    });
                    this.researchProposalDocument = event.documentDto;
                    this.researchProposalDocumentId = event.documentDto?.applicationDocumentId ?? 0;
                    // console.log('attaching file: ');
                    break;
                case this.docSvc.REBUTTALLETTERDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'rebuttalLetterDocument': true
                    });
                    this.rebuttalLetterDocument = event.documentDto;
                    this.rebuttalLetterDocumentId = event.documentDto?.applicationDocumentId ?? 0;
                    // console.log(this.rebuttalLetterDocumentId);
                    break;
                case this.docSvc.RESUBMISSIONCRITIQUEDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'resubmissionCritiqueDocument': true
                    });
                    this.resubmissionCritiqueDocument = event.documentDto;
                    this.resubmissionCritiqueDocumentId = event.documentDto?.applicationDocumentId ?? 0;
                    // console.log(this.resubmissionCritiqueDocumentId);
                    break;
            }
        } else {
            switch (event.documentTypeId) {
                case this.docSvc.RESEARCHPROPOSALDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'researchProposalDocument': ''
                    });
                    this.researchProposalDocument = undefined;
                    this.researchProposalDocumentId = 0;
                    break;
                case this.docSvc.REBUTTALLETTERDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'rebuttalLetterDocument': ''
                    });
                    this.rebuttalLetterDocument = undefined;
                    this.rebuttalLetterDocumentId = 0;
                    break;
                case this.docSvc.RESUBMISSIONCRITIQUEDOCTYPE:
                    this.researchProposalFG.patchValue({
                        'resubmissionCritiqueDocument': ''
                    });
                    this.resubmissionCritiqueDocument = undefined;
                    this.resubmissionCritiqueDocumentId = 0;
                    break;
            }
        }
    }


    handlePreviousSubmissionCount(args: number) {
        if (args > 0) {
            this.researchProposalFG.patchValue({
                'hasPreviousSubmissionRecord': true
            });
            this.researchProposalFG.updateValueAndValidity();
        } else {
            this.researchProposalFG.patchValue({
                'hasPreviousSubmissionRecord': ''
            });
        }
    }
}