import { Component, OnInit , ViewChild} from '@angular/core';

import { WindowCloseResult, WindowService } from '@progress/kendo-angular-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, process, State } from '@progress/kendo-data-query';

import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/core/services/auth.service';
import { LookupService } from 'src/app/core/services/lookup.service';

import { ReviewGridDTO } from '../models/ReviewGridDTO';
import { ReviewService } from '../services/review.service';

import { OthersReviewComponent } from 'src/app/review/components/others-review.component';



@Component({
  selector: 'app-all-list',
  templateUrl: './all-list.component.html',
  styles: []
})
export class AllListComponent implements OnInit {

  public allView!: GridDataResult;
  public allState: State = {
    sort: [{
      field: 'avgInitialScore',
      dir: 'asc'
    }]
  };

  public allApplications!: ReviewGridDTO[];

  public loading = true;

  //public noCurrentCycle = true;
  public readingPhrase = false;
  public currentModScore!: string;
  public reviewMeeting = false;

  @ViewChild('othersReviewModal') othersReviewModal!: OthersReviewComponent;

  public windowOpened = false;

  private editedRowIndex!: number;
  private editedScore!: ReviewGridDTO;

  constructor(
    public authService: AuthService,
    private reviewService: ReviewService,
    private lookupService: LookupService,
    private windowService: WindowService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.lookupService.currentCycle$.subscribe(
      data => {
        if (data) {
          if ((data.reviewStartDate! <= new Date()) && (data.reviewEndDate! >= new Date())) {
            //this.noCurrentCycle = false;
          }
          if (new Date() > data.readingPhaseStartDate! ) {
            this.readingPhrase = true;
          }
          if( (data.reviewMeetingStartDate! <= new Date()) && (data.reviewMeetingEndDate!>= new Date())){
            this.reviewMeeting = true;
          }
        }
      }
    );

    this.getAllApps();
  }

  public allDataStateChange(state: DataStateChangeEvent): void {
    this.allState = state;
    this.allView = process(this.allApplications, this.allState);
  }



  public editHandler({sender, rowIndex, dataItem}:{sender:any, rowIndex:any, dataItem:any}) {
   
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedScore = Object.assign({}, dataItem);
    
    // make sure select shows the right existing value
    if (dataItem.finalReview !== undefined) {
      if (dataItem.finalReview.score > 0) {
        this.currentModScore = dataItem.finalReview.score;
      } else {
        this.currentModScore = 'null';
      }
    }
    sender.editRow(rowIndex);
  }

  public cancelHandler({sender, rowIndex}:{sender:any, rowIndex:any, dataItem:any}) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({sender, rowIndex, dataItem, isNew}:{sender:any, rowIndex:any, dataItem:any, isNew:any}) {   
    const scoreToSave = Object.assign({}, dataItem.finalReview);
    //scoreToSave.committItem.finalReviewideeAssignmentId = dataItem.committeeAssignmentId;
    scoreToSave.applicationId = dataItem.applicationId;

    if (+this.currentModScore >= 1 && +this.currentModScore <= 9) {
      scoreToSave.score = +this.currentModScore;     
    } else if (this.currentModScore === 'null') {      
      scoreToSave.score = null;      
    }
      
    if (dataItem.finalReviewId > 0) {   
      scoreToSave.finalReviewId = dataItem.finalReviewId;     
      this.reviewService.updateFinalScore(scoreToSave).subscribe(
        data => {
          document.getElementById('spin' + rowIndex)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i>';
          this.toastr.success('Final Score Saved', '', {closeButton: false, timeOut: 3000});
          this.getAllApps();
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error Saving Score');
        }
      );
    } else {
      
      this.reviewService.addFinalScore(scoreToSave).subscribe(
        data => {
          document.getElementById('spin' + rowIndex)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i>';
          this.toastr.success('Final Score Saved', '', {closeButton: false, timeOut: 3000});
          this.getAllApps();
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error Saving Score');
        }
      );
    }

    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined || 0;  
  }

/*
  public showAllScoresWindow(applicationId: number) {
    // create new Kendo window
    const windowRef = this.windowService.open({
      title: 'All Final Scores',
      content: AllFinalScoresComponent,
      width: 500,
      top: 150
    });
    this.windowOpened = true; // controls modal background

    // pass values into control
    const scoreInfo = windowRef.content.instance;
    scoreInfo.applicationId = applicationId;

    // stop getting scores when window closed
    windowRef.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
          this.windowOpened = false;
      }
    });
  }
  */
/*
  public showSubstituteScoreWindow(applicationId: number, committeeAssignmentId: number) {
    // create new Kendo window
    const windowRef = this.windowService.open({
      title: 'Substitute Final Score',
      content: SubstituteFinalScoreComponent,
      width: 500,
      top: 150
    });
    this.windowOpened = true; // controls modal background

    // pass values into control
    const scoreInfo = windowRef.content.instance;
    scoreInfo.applicationId = applicationId;
   

    // hide shade when window closed
    windowRef.result.subscribe((result) => {
      if (result instanceof WindowCloseResult) {
          this.windowOpened = false;
      }
    });
  }
*/
otherReviewer(applicationId: number) {
  
  this.othersReviewModal.init(applicationId);
  //this.othersReviewModal.show();
}
onOthersChanged(event: boolean) {
   
  if (event) {
    this.getAllApps();
  } else { }
}
  private closeEditor(grid:any, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.resetItem(this.editedScore);
    this.editedRowIndex = undefined || 0;
    //this.editedScore = null;
  }

  private resetItem(dataItem: ReviewGridDTO) {
    if (!dataItem) { return; }
    // find orignal data item
    const originalDataItem = this.allApplications.find(item => item.applicationId === dataItem.applicationId);

    // revert changes
    Object.assign(originalDataItem!, dataItem);
  }

  private getAllApps() {
    this.reviewService.getAllApplicationsInMyType().subscribe(
      (data2) => {

        this.allApplications = data2;

        this.allView = process(this.allApplications, this.allState);
       
        // tslint:disable-next-line: quotemark
        const spinners = document.querySelectorAll("[id^='spin']");
        spinners.forEach(function(spinner) {
          spinner.innerHTML = '';
        });
       

        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error Getting Applications');
      }
    );
  }
}
