import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';

import { IndexComponent } from './pages/index.component';


export const applyRoutes: Routes = [
  { path: '', redirectTo: 'index/0', pathMatch: 'full' },
  { path: 'index/:section', canActivate: [AuthGuard], component: IndexComponent },
  { path: 'index/:id/:section', canActivate: [AuthGuard], component: IndexComponent }
];
