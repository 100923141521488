import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'app-download-pdf',
  template: `

    <button class="btn btn-sm btn-secondary" 
      title="Download as PDF"
      (click)="downloadFile(applicationId)"
      [disabled]="disabled"
      [innerHTML]="innerHTML">
    </button>

  `,
  styles: []
})
export class DownloadPdfComponent implements OnInit {

  @Input()
  applicationId!: number;

  @Input()
  fileName!: string;

  @Input()
  extraClass!: string;

  public disabled = false;
  public innerHTML = '<i class="fa fa-file-pdf-o"></i>';

  constructor(
    private sharedService: SharedService,
    private toastr: ToastrService
    ) { }

  ngOnInit() {
  }

  public downloadFile(applicationId: number) {
    this.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw"></i>';
    this.disabled = true;

    this.sharedService.getPdf(applicationId).subscribe(
        (res: any) => {
          const contentDisposition = res.headers.get('content-disposition') || '';
        
         console.log('res---------', res);

         //console.log('contentDisposition  **---------', contentDisposition);
         const matches = /filename=([^;]+)/ig.exec(contentDisposition);
         
          const fileName = (matches![1] || 'untitled').trim().replace(/"/g, '');
          console.log('DownloadPdfComponent -> downloadFile -> fileName', fileName);
          
          const file = new Blob([res.body], { type: 'application/pdf' });
          
          saveAs(file, fileName);
          
          this.resetButton();
        },
       (error: any) => {
          this.toastr.error('Other people are downloading the same application, please wait and try again later!', 'Error');
          console.error(JSON.stringify(error));
          this.resetButton();
        }
      );
  }

  private resetButton() {
    this.disabled = false;
    this.innerHTML = '<i class="fa fa-file-pdf-o"></i>';
  }
}
