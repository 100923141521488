
<pagetitle pageTitle="All Applications"></pagetitle>
  <div class="container-fluid">

      

  <div class="row-fluid no-gutters">
    <div class="col-md-12 ">
      <kendo-grid
        [data]="gridView"
        [loading]="loading"
        [pageSize]="applicationListService.state.take!" 
        [skip]="applicationListService.state.skip!" 
        [filter]="applicationListService.state.filter!"     
        [sortable]="true"  
        [sort]="applicationListService.state.sort!"  
        (sortChange)="sortChange($event)"    
        [pageable]="false"
        [filterable]="true"
        [reorderable]="true"
        [resizable]="true"
        [selectable]="true"     
        (dataStateChange)="dataStateChange($event)"
        (detailExpand)="expandHandler($event)"       
        style="height:800px"
        class="mb-5 table-striped">
       
        <kendo-grid-column field="application.applicationNumber" title="Application Number" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            
            <a [routerLink]="['/apply/index/', dataItem.application.applicationId, 0]" class="text-primary" style="line-height: 2em;">
              {{dataItem.application.applicationNumber}}
            </a>
          </ng-template>
        </kendo-grid-column>
       
        <kendo-grid-column field="applicantName" title="Applicant Name" [width]="120">
         
        </kendo-grid-column> 
<!--
        <kendo-grid-column field="applicationPeriod" title="Application Period" [width]="120">
          <ng-template kendoGridFilterCellTemplate let-filter>
            <app-column-filter
              [filter]="filter"
              [data]="allCycles"
              filterField="applicationPeriodId"
              textField="applicationCycleName"
              valueField="applicationPeriodId">
            </app-column-filter>
          </ng-template>
          
        </kendo-grid-column>
      -->
        <kendo-grid-column field="applicationType" title="Application Type" [width]="120">
          <ng-template kendoGridFilterCellTemplate let-filter>
            <app-column-filter
              [filter]="filter"
              [data]="types"
              filterField="applicationTypeId"
              textField="displayName"
              valueField="applicationTypeId">
            </app-column-filter>
          </ng-template>
        </kendo-grid-column>
       
        <kendo-grid-column field="applicationStatus" title="Application Status" [width]="120">
          <ng-template kendoGridFilterCellTemplate let-filter>
            <app-column-filter
              [filter]="filter"
              [data]="applicationStatuses"
              filterField="applicationStatusId"
              textField="displayName"
              valueField="applicationStatusId">
            </app-column-filter>
          </ng-template>
          
        </kendo-grid-column>
        <kendo-grid-column field="applicationAssignedReviewers" title="Assigned Reviewers"  [width]="150" [filterable]="false">
          <ng-template kendoGridCellTemplate  let-dataItem let-rowIndex="rowIndex">
              <ng-container *ngFor="let pi of dataItem.applicationAssignedReviewers; let isLast=last">
                  <span class="mr-1" >
                     {{ pi.committeeAssignment.reviewerDisplayName }}{{isLast ? '' : '; '}}
                  </span>
              </ng-container>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="avgIntitialScore" title="Avg Intitial Score" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.avgIntitialScore == 0"></div>
            <div *ngIf="dataItem.avgIntitialScore != 0">{{ dataItem.avgIntitialScore }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="avgFinalScore" title="Avg Final Score" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.avgFinalScore == 0"></div>
            <div *ngIf="dataItem.avgFinalScore != 0">{{ dataItem.avgFinalScore }}</div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="160" title="Actions" [headerClass]="'bg-dark text-light'" >
          <ng-template kendoGridCellTemplate let-dataItem>            
            <app-download-pdf
              [applicationId]="dataItem.application.applicationId"
              extraClass="float-left mb-1 mr-1">
            </app-download-pdf>
            <button class="btn btn-sm btn-secondary" 
              title="Assign Reviewer"             
              [hidden]=" dataItem.applicationStatusId != 3 "
              (click)="assignReviewers(dataItem.application.applicationId,dataItem.applicationTypeId, dataItem.applicantName) ">
              <i class="fa fa-plus" aria-hidden="true"></i> Assign
            </button>
            
            <button class="btn btn-sm btn-secondary" 
              title="All Final Scores"             
              [hidden]=" dataItem.applicationStatusId != 3 "
              (click)="allFinalScores(dataItem.application.applicationId) ">
              <i class="fa fa-plus" aria-hidden="true"></i> All Final Scores
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="awarded" title="Awarded" [width]="100" filter="boolean">

          <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-switch *ngIf="dataItem.applicationStatusId == 3"
              [onLabel]="'Yes'"
              [offLabel]="'No'"
              [checked]="dataItem.awarded"
              (valueChange)="awardedSwitchChangeHandler($event, dataItem)">
            </kendo-switch>
          </ng-template>

        </kendo-grid-column>
        
      </kendo-grid>
    </div>
  </div>

  <app-assign-reviewers #assignModal (reviewersChanged)="onReviewersChanged($event)"></app-assign-reviewers>
  <app-all-final-scores #allFinalScoresModal (finalscoresChanged)="onFinalScoresChanged($event)"></app-all-final-scores>
  </div>
 