
import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, ViewChild } from "@angular/core";
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ApplicationListService } from 'src/app/admin/application-list/application-list.service';
import { ApplicationStatusDTO } from 'src/app/shared/models/ApplicationStatusDTO';
import { AuthService } from 'src/app/core/services/auth.service';
import { ApplicationFullDTO } from 'src/app/shared/models/ApplicationFullDTO';

import { LookupService } from 'src/app/core/services/lookup.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from "@angular/common";

import { combineLatest, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { ApplicationCycleDTO } from "src/app/shared/models/ApplicationCycleDTO";

import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
//import { ApplicationService } from "src/app/apply/services/application.service";
import { AssignReviewersComponent } from 'src/app/admin/assign-reviewers/assign-reviewers.component';
import { AllFinalScoresComponent } from 'src/app/admin/application-list/all-final-scores.component';
@Component({
  selector: 'app-application-list',
  templateUrl: 'application-list.component.html'
  ,
  styles: [`
      tr .header{
        background-color: #666
      }
      ::ng-deep .k-tooltip {
        background-color:#333 !important;
        max-width:500px;
      }
      ::ng-deep .k-callout {
        color:#333 !important;
      }
      ::ng-deep .k-switch-on .k-switch-container {
        background-color: #3c763d;
      }
      ::ng-deep .k-switch-on:hover .k-switch-container,
      ::ng-deep .k-switch-on.k-state-hover .k-switch-container {
        color: white;
        background-color: #3c763d;
      }
      ::ng-deep .k-switch-off .k-switch-container {
        color: white;
      }
  `]
})

export class ApplicationListComponent implements OnInit {

  public gridView!: GridDataResult;
  apps!: ApplicationFullDTO[];
  applicationStatuses!: ApplicationStatusDTO[];
  reviewEndDate = false;
  applicationId!: number;
  public loading = true;
  public pageSizes = true;
  public pageSize = 1;
  public currentApplication: ApplicationFullDTO | null = null;
  public currentCycle: ApplicationCycleDTO | null = null;

  @ViewChild('assignModal') assignModal!: AssignReviewersComponent;
  @ViewChild('allFinalScoresModal') allFinalScoresModal!: AllFinalScoresComponent;

  types!: ApplicationTypeDTO[];
  allCycles!: ApplicationCycleDTO[];



  // public state: State = {
  //   skip: 0,
  //   sort: [{
  //     field: 'application.applicationPeriod',
  //     dir: 'desc'
  //   }]
  // };

  constructor(public authService: AuthService,
    private lookupService: LookupService,
    public applicationListService: ApplicationListService,
    //private applicationService: ApplicationService,
    private router: Router,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.getAllApplications();
    this.reviewEndDate = false;
    this.lookupService.applicationStatuses$.subscribe(
      data => {
        if (data) { this.applicationStatuses = data; }
      }
    );

    this.lookupService.allCycles$.subscribe(
      data => {
        if (data) { this.allCycles = data; }
      }
    );
    this.lookupService.applicationTypes$.subscribe(
      data => {
        if (data) { this.types = data; }
      }
    );
  }


  private getAllApplications() {
    
    this.applicationListService.getCurrentApplications().subscribe(
      data => {
        this.apps = data;
        //var name= data[0].applicantName;
        //alert("name for first one is  "+name);
        this.gridView = process(this.apps, this.applicationListService.state);
        this.loading = false;
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }


  assignReviewers(applicationId: number, applicationTypeId: number, applicantName: string) {
    //alert("assignReviewers()  applicantName is  "+applicantName + "applicationId is  "+applicationId);
    this.assignModal.init(applicationId, applicationTypeId, applicantName);
  }


  onReviewersChanged(event: boolean) {
    if (event) {
      this.getAllApplications();
    } else { }
  }


  onFinalScoresChanged(event: boolean) {
   
    if (event) {
     
      this.getAllApplications();
    } else { }
  }


  allFinalScores(applicationId: number) {
    this.allFinalScoresModal.init(applicationId);
  }


  awardedSwitchChangeHandler(event: boolean, item: ApplicationFullDTO) {

    /*this.applicationListService.getAwaredApplication(item.application!.applicationId,event).subscribe(
      data => {        
        this.getAllApplications();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );*/
    // alert("event! "+ event);
    if (event) {
      this.applicationListService.awaredApplication(item.application!.applicationId, event).subscribe(
        data => {

          this.getAllApplications();
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        }
      );
    } else {
      this.applicationListService.deniedApplication(item.application!.applicationId, event).subscribe(
        data => {

          this.getAllApplications();
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        }
      );
    }
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.applicationListService.state = state;
    this.gridView = process(this.apps, this.applicationListService.state);
  }


  rowSelect(selection: any) {
    const selectedData = selection.selectedRows[0].dataItem;
    console.log(selectedData);
    this.router.navigate(['/apply/index/', selectedData.appicationId, 0]);
  }


  expandHandler(event: any) {
    console.log(JSON.stringify(event));
  }


  private loadItems(): void {
    this.gridView = process(this.apps, this.applicationListService.state);
  }

  
  public sortChange(sort: SortDescriptor[]): void {
    this.applicationListService.state.sort = sort;
    this.loadItems();
  }

}
