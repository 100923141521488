import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
@Component({
  selector: 'app-footer',
  template: `
    <footer class="py-3 mt-5 bg-light border-top" style="height:250px">
	  <div class="container bg-light">
		<div class="row">
			<div class="col-md-7">
				<p class="h5">Pelotonia Scholars</p>
				<p>If you have any questions or problems, please <a href={{adminEmailGroup}}><u>Contact Us</u></a></p>
			</div>
			<div class="col-md-5 text-right">
				<small>If you need additional assistance or accommodations to access this or any data related to the cancer program, please contact our Cancer Program Digital Accessibility Team at <a href="mailto:Cancer-DigitalAccessibility@osumc.edu">Cancer-DigitalAccessibility@osumc.edu</a></small>
			</div>
		</div>
	  </div>
    </footer>
  `,
  styles: []
})
export class FooterComponent implements OnInit {
  public adminEmailGroup: string = '';
  constructor() { }

  ngOnInit() {
    this.adminEmailGroup = ConfigService.settings.const.AdminEmailGroup;
  }

}
