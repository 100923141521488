<form class="form-inline">
    <button type="button" class="btn btn-secondary mr-4" title="Add a committee assignment for an existing user" *ngIf="authService.isApplicationsAdmin"
      data-toggle="collapse" data-target="#reviewForm" aria-expanded="false" aria-controls="reviewForm">
      <i class="fa fa-plus" aria-hidden="true"></i> Add Existing Reviewer
    </button>
  
    <div class="collapse" id="reviewForm">
      <label class="sr-only" for="reviewerId">User Name</label>
      <kendo-combobox
        [data]="userResults"
        [value]="selectedUser"
        [textField]="'displayName'"
        [valueField]="'userId'"
        [loading]="loading"
        [filterable]="true"
        [placeholder]="'Search Users (last name)'"
        (filterChange)="callDebouncer($event)"
        (selectionChange)="selectUser($event)"
        class="form-control mr-3"
        >
        <ng-template kendoDropDownListNoDataTemplate>
          <h4>
            <span class="k-icon k-i-warning"></span><br /><br /> No Results
            <button class="btn btn-secondary" data-toggle="modal" data-target="#intReviewModal">Add Reviewer new </button>
          </h4>
        </ng-template>
      </kendo-combobox>
  
      <label class="sr-only" for="applicationTypeId">Application Type</label>
      <select class="form-control mr-3" id="applicationTypeName" name="applicationTypeName" required
        [(ngModel)]="applicationTypeId">
        <option [value]="null" disabled selected>Select Application Type</option>
        <option *ngFor="let applicationType of applicationTypes"
          [value]="applicationType.applicationTypeId">
          {{applicationType.displayName}}
        </option>
      </select>
  
      
  
      <button type="submit" class="btn btn-primary mr-5" (click)="saveReviewer()" [disabled]="saveDisabled()">Save</button>
  
    </div>
  </form>