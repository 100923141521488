import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviewers',
  template: `

  
    <pagetitle pageTitle="Committee Assignments"></pagetitle>
    <div class="container-fluid bg-light">
      <div class="row-fluid no-gutters">
        <div class="col-md-12 pb-3">
          <app-list-reviewers></app-list-reviewers>
        </div>
      </div>
    </div>

  `,
  styles: []
})
export class ReviewersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}