
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { parseISO } from 'date-fns';
import { Observable } from 'rxjs';

@Injectable()
export class DateConverterInterceptor implements HttpInterceptor {

    iso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Convert JS Dates to ISO Strings when posting to the server
        if (request.method === 'PUT' || request.method === 'POST') {
            if (!(request.body instanceof FormData)) { // Don't process FormData (file upload) posts.
                // clone so we dont effect any models breaking validation/bindings.
                const newBody = Object.assign({}, request.body);

                this.convertAllDatesToISOStrings(newBody);

                request = request.clone({
                    body: newBody
                });
            }
        }

        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            // Convert ISO Strings to JS Dates when retreiving data from the server
            if (event instanceof HttpResponse) {
                const body = event.body;
                this.convertAllISOStringsToDates(body);
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                }
            }
        }));
    }

    convertAllISOStringsToDates(body : any) {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }

        for (const key of Object.keys(body)) {
            const value = body[key];
            if (this.isIso8601(value)) {
                const convertedDate = this.convertISOStringToStandardDate(value);
                body[key] = convertedDate;
            } else if (typeof value === 'object') {
                this.convertAllISOStringsToDates(value);
            }
        }
    }

    convertISOStringToStandardDate(isoDate: string) {
        // using the date-fns parse function to translate the ISO date string to a correct local time
        // Safari has issues doing new Date(value) directly (converts to UTC), so parsing it first converts to a format all browsers
        // can understand in local time zone
        const returnDate = new Date(parseISO(isoDate));
        return returnDate;
    }

    isIso8601(value: string) {
        if (value === null || value === undefined) {
            return false;
        }

        return this.iso8601.test(value);
    }

    convertAllDatesToISOStrings(body: { [x: string]: string; } | null | undefined) :any {
        if (body === null || body === undefined) {
            return body;
        }

        if (typeof body !== 'object') {
            return body;
        }
        
        for (const key of Object.keys(body)) {
            const value : any = body[key];
            if (value instanceof Date) {
                body[key] = new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString();
            } else if (typeof value === 'object') {
                this.convertAllDatesToISOStrings(value);
            }
        }
    }

}
