import { DatePipe } from "@angular/common";
import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { combineLatest, of } from "rxjs";
import { mergeMap, tap } from "rxjs/operators";
import { ApplicationService } from "src/app/apply/services/application.service";
import { LookupService } from "src/app/core/services/lookup.service";
import { ApplicationCycleDTO } from "src/app/shared/models/ApplicationCycleDTO";
import { ApplicationStatusDTO } from "src/app/shared/models/ApplicationStatusDTO";
import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
import { ApplicationDocumentDTO } from "src/app/shared/models/ApplicationDocumentDTO";
import { ApplicationFullDTO } from "src/app/shared/models/ApplicationFullDTO";
import { FormSectionState } from "../models/FormSectionState";
import { ApplciationDocumentService } from "../services/applicationdocument.service";
import { ActivatedRoute, Router } from '@angular/router';
import { StepperComponent } from "@progress/kendo-angular-layout";
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
    selector: 'apply-index',
    templateUrl: 'index.component.html'
})
export class IndexComponent implements OnInit, AfterContentInit {

    @ViewChild(StepperComponent, { static: true }) public stepper: StepperComponent;

    public loadedApplication: boolean = false;
    public loadingCycle: boolean = true;
    public hasOpenSubmissionCycle: boolean = false;
    public hasApplication: boolean = false;
    public applicationStatus: string = '';
    public applicationType: string = '';
    public lastModifiedOn: string | null = '';
    public applicationId!: number;
    
    public applicationDueDateMessage: string = '';
    
    public currentApplication: ApplicationFullDTO | null = null;
    public currentCycle: ApplicationCycleDTO | null = null;

    private statuses: ApplicationStatusDTO[] = [];
    private types: ApplicationTypeDTO[] = [];

    public isApplicationSubmitted: boolean = false;

    // all documents for this application
    public biosketchDocument: ApplicationDocumentDTO | undefined = undefined;
    public mentorBiosketchDocument: ApplicationDocumentDTO | undefined = undefined;
    public mentorSupportDocument: ApplicationDocumentDTO | undefined = undefined;
  
    public coMentorBiosketchDocument: ApplicationDocumentDTO | undefined = undefined;
    //public coMentorSupportDocument: ApplicationDocumentDTO | undefined = undefined;

    public mentorTrainingDocument: ApplicationDocumentDTO | undefined = undefined;
    public coMentorTrainingDocument: ApplicationDocumentDTO | undefined = undefined;
    public recommederSupportDocument: ApplicationDocumentDTO | undefined = undefined;
    public researchProposalDocument: ApplicationDocumentDTO | undefined = undefined;
    public rebuttalLetterDocument: ApplicationDocumentDTO | undefined = undefined;
    public resubmissionCritiqueDocument: ApplicationDocumentDTO | undefined = undefined;
    
    public contactInfoSectionState: FormSectionState | undefined = undefined;
    public academicBackgroundSectionState: FormSectionState | undefined = undefined;
    public mentorSectionState: FormSectionState | undefined = undefined;
    public researchProposalSectionState: FormSectionState | undefined = undefined;

    public readyForSubmission: boolean = false;


    
    
    
    public currentStep: number;
    public validatedSteps = [];
    
    //because this page can be addressed from 2 dirrent urls
    private trimmedUrl = '';
    private isStepValid = (index: number): boolean => {
       
        switch(index) { 
           
            case 0: { return this.contactInfoSectionState?.isValid ? true : false; } 
            case 1: { return this.academicBackgroundSectionState?.isValid ? true : false; } 
            case 2: { return this.mentorSectionState?.isValid ? true : false; } 
            case 3: { return this.researchProposalSectionState?.isValid ? true : false; }
            case 4: { return this.readyForSubmission }
            default: return false;
        }         
    };

    
    public steps = [
        {
            label: "Your Information",
            isValid: this.isStepValid,
            validate: true
        },
        {
            label: "Academics",
            isValid: this.isStepValid,
            validate: true
        },
        {
            label: "Mentor(s)",
            isValid: this.isStepValid,
            validate: true
        },
        {
            label: "Research Proposal",
            isValid: this.isStepValid,
            validate: true
        },
        {
            label: "Submit",
            isValid: this.isStepValid,
            validate: true
        }
    ];

    constructor(private applicationSvc: ApplicationService,
        private docSvc: ApplciationDocumentService,
        private lookupSvc: LookupService,
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private toastr: ToastrService,
        private datePipe: DatePipe) { }

    ngOnInit() {    
        this.applicationDueDateMessage = ConfigService.settings.const.ApplicationDueDateMessage;
        this.applicationId = this.route.snapshot.params.id;
        let getCurrentCycle = this.lookupSvc.currentCycle$.pipe(
            mergeMap(cycle => {
                if (cycle !== undefined) {
                    this.currentCycle = cycle;
                    if(this.applicationId !== undefined) {
                        return this.applicationSvc.getFullApplication(this.applicationId);
                    }
                    else {
                        return this.applicationSvc.getCurrentApplicationFullByCycleId(cycle?.applicationPeriodId);
                    }
                } else return of(null);
            }),
            tap(x => { if (x !== undefined) this.currentApplication = x; })
        );
        
        
        let getStatuses = this.lookupSvc.applicationStatuses$.pipe(tap(x => this.statuses = x));
        let getTypes = this.lookupSvc.applicationTypes$.pipe(tap(x => this.types = x));

        let fetchingCycle = this.lookupSvc.loadingCurrentCycle$.pipe(tap(x => this.loadingCycle = x));        

        combineLatest(
            [getCurrentCycle, fetchingCycle, getStatuses, getTypes]
        ).subscribe(x => {
            
            if (x[1] === false) {
                if (this.statuses?.length > 0 && this.types?.length > 0) {
                    if (this.currentCycle !== undefined) {
                        if (this.loadApplicationCycle()) this.loadCurrentApplication();
                        //alert("this.currentCycle at combineLatest()&&&&&"+this.currentCycle);

                        //alert("this.loadApplicationCycle() at combineLatest()&&&&&"+this.loadApplicationCycle());

                        //alert("this.loadCurrentApplication() at combineLatest()&&&&&"+this.loadCurrentApplication());
                        //alert("this.currentApplication?.application at combineLatest()&&&&&"+this.currentApplication?.application );
                        //alert("this.hasOpenSubmissionCycle  at combineLatest()&&&&&"+this.hasOpenSubmissionCycle );

                    }
                }
            }
        });
    }

    ngAfterContentInit(): void {
        //put the form on the right tab
        this.currentStep = +this.route.snapshot.params.section;
        this.trimmedUrl = this.router.url.substring(0,this.router.url.lastIndexOf("/"));
    }


    loadApplicationCycle(): boolean {
        this.hasOpenSubmissionCycle = false;
        if(this.authService.isApplicationsAdmin)
        {
            this.hasOpenSubmissionCycle = true;
         
        }
        if (this.currentCycle != null  && this.currentCycle !== undefined) {
            let now = new Date();
            if (this.currentCycle.applicationStartDate  != null  && this.currentCycle.applicationStartDate !== undefined &&
                this.currentCycle.applicationEndDate  != null  && this.currentCycle.applicationEndDate !== undefined) {
                    if (this.currentCycle.applicationStartDate <= now && this.currentCycle.applicationEndDate > now) {
                        this.hasOpenSubmissionCycle = true;
                    }
                }
        }       
        return this.hasOpenSubmissionCycle;
    }


    loadCurrentApplication(): boolean {
        this.hasApplication = false;
        if (this.currentApplication !== null 
            && this.currentApplication !== undefined
            && this.currentApplication.application !== null 
            && this.currentApplication.application !== undefined) {

            this.applicationStatus = this.statuses.find(m => m.applicationStatusId === this.currentApplication?.application?.applicationStatusId)?.displayName ?? "Unknown";
            this.applicationId = this.currentApplication.application.applicationId;
            
            this.applicationType = this.types.find(m => m.applicationTypeId === this.currentApplication?.application?.applicationTypeId)?.displayName ?? "Unknown";
            let lastestUpdateDate = this.currentApplication.application.modifiedOn != null ? this.currentApplication.application.modifiedOn : this.currentApplication.application.createdOn;
            this.lastModifiedOn = this.datePipe.transform(lastestUpdateDate, 'medium') ?? 'Unknown';            
            this.hasApplication = true;

            // map any documents
            this.biosketchDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.APPLICATIONBIOSKETCHDOCTYPE);
            this.mentorBiosketchDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.MENTORBIOSKETCHDOCTYPE);
            this.mentorSupportDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.MENTORLETTEROFSUPPORTDOCTYPE);
            this.coMentorBiosketchDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.COMENTORBIOSKETCHDOCTYPE);
           // this.coMentorSupportDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.COMENTORLETTEROFSUPPORTDOCTYPE);
            this.mentorTrainingDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.MENTORTRAININGLETTERDOCTYPE);
            this.coMentorTrainingDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.COMENTORTRAININGLETTERDOCTYPE);
            this.recommederSupportDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.RECOMMEDERLETTEROFSUPPORTDOCTYPE);
            this.researchProposalDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.RESEARCHPROPOSALDOCTYPE);
            this.rebuttalLetterDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.REBUTTALLETTERDOCTYPE);
            this.resubmissionCritiqueDocument = this.currentApplication.applicationDocuments?.find(m => m.documentTypeId === this.docSvc.RESUBMISSIONCRITIQUEDOCTYPE);

            // submitted application
            this.isApplicationSubmitted = this.currentApplication.application.applicationStatusId === 3;

            /*if(this.currentApplication.application.applicationStatusId === 3){

                this.isApplicationSubmitted = true;
            }
            else {
                this.isApplicationSubmitted = false;
            }*/
        }
        
        return this.hasApplication;
    }


    handleSectionIsValidMonitor(args: FormSectionState) {  
            
        switch(args.sectionName) {
            case 'AcademicBackground':
                this.academicBackgroundSectionState = args;
                break;
            case 'ContactInformation':
                this.contactInfoSectionState = args;
                break;
            case 'Mentors':
                this.mentorSectionState = args;
                break;
            case 'ResearchProposal':
                this.researchProposalSectionState = args;
                break;
        }
        
        this.stepper?.validateSteps();
    }


    handleReadyForSubmissionEvent(event: { readyForSubmission: boolean}) {     
        this.readyForSubmission = event.readyForSubmission;
    }


    handleApplicationSubmission(event: { submittedApplication: ApplicationFullDTO}) {
        this.currentApplication = event.submittedApplication;
        this.loadCurrentApplication();
    }

    applicationTypeChanged(event: {changed: boolean}) {
        window.location.reload();
    }

    applicationUpdated(event: boolean) {
        // when something in a child component updates the application, reload it
        this.loadCurrentApplication();
    }


    currentStepChange($event: any) {
        
        this.router.navigate([this.trimmedUrl, $event]);
    }

    public next(): void {
        this.router.navigate([this.trimmedUrl, this.currentStep+1]);
        this.currentStep += 1;
    }

    
    public prev(): void {
        this.router.navigate([this.trimmedUrl, this.currentStep-1]);
        this.currentStep -= 1;
    }


    goToSubmitPage() {
        this.router.navigate([this.trimmedUrl, 4]);
        this.currentStep = 4;
    }

} 