import { Component, OnInit, Input , LOCALE_ID, Inject } from '@angular/core';

import { FormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { FormStateService } from '../../services/form-state.service';
import { debounceTime } from "rxjs/operators";
import { formatNumber } from "@angular/common";

@Component({
  selector: 'contact-validation',
  template: `
 
    <div class="row"  *ngIf="errors.length > 0">
      <div class="col-md-12">
        <div class="border border-warning alert alert-warning shadow-sm">
          <h5>Missing fields:</h5>
          <div class="row" *ngFor="let error of errors">
          <div class="col-12">
              <i class="fa fa-exclamation-circle"></i> {{error}}
          </div>
      </div>
        </div>
      </div>
    </div>

  `
})
export class ContactInformationValidationComponent implements OnInit {

  @Input()
  public formGroup!: UntypedFormGroup ;
  public errors: string[] = [];
  constructor(@Inject(LOCALE_ID)private locale: string) { }
  /*constructor(
    public formStateService: FormStateService
  ) { }
*/
  ngOnInit() {
    this.formGroup.valueChanges
    .subscribe(status => {            
        this.resetErrorMessages();
        this.generateErrorMessages(this.formGroup);
});


  }
  resetErrorMessages() {
    this.errors.length = 0;
  }

  generateErrorMessages(fg: UntypedFormGroup){
      Object.keys(fg.controls).forEach(cn => {            
          let control = fg.controls[cn];
          let errs = control.errors;
          if (errs === null || errs.count === 0) {
              return;
          }
          if (errs.required) this.errors.push(`${cn} is required.`);    
          if (errs.email) this.errors.push(`${cn} is invalid.`);     
          if (errs.minlength) this.errors.push(`${cn} has a minimun length of ${errs.minlength.requiredLength}`);
          if (errs.maxlength) this.errors.push(`${cn} has a maximum length of ${errs.maxlength.requiredLength}`);            
          if (errs.min) this.errors.push(`${cn} has a minimum value of ${formatNumber(errs.min.min, this.locale,'1.2-2')}`);
          if (errs.max) this.errors.push(`${cn} has a maximum value of ${formatNumber(errs.max.max, this.locale,'1.2-2')}`);
      });
    }

}
