import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";

import { CommonModule, DatePipe } from '@angular/common';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { moderatorRoutes } from "./routes";


import { ListAppsComponent } from './pages/list-apps.component';


import { ReviewDetailComponent } from './pages/review-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ComboBoxModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { BrowserModule } from '@angular/platform-browser';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { IndexComponent } from './pages/index.component';

@NgModule({
    declarations: [
       
        ListAppsComponent,      
        
        ReviewDetailComponent,    
        IndexComponent, 
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DateInputsModule,
        InputsModule,
        ComboBoxModule,
        DropDownListModule,
        GridModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        RouterModule.forChild(moderatorRoutes)
    ],
    providers: [
        DatePipe
    ]
})
export class ModeratorModule { }