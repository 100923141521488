import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { PreviousSubmissionDTO } from "../../models/PreviousSubmissionDTO";
import { PreviousSubmissionsService } from "../../services/previoussubmissions.service";
import { ApplicationService } from 'src/app/apply/services/application.service';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { LookupService } from 'src/app/core/services/lookup.service';
import { debounceTime, take } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'previous-submissions',
    templateUrl: 'previous-submissions.component.html'
})
export class PreviousSubmissionListComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    models: PreviousSubmissionDTO[] = [];
    public selectedSubmissionId: number = 0;
    selectedModel: PreviousSubmissionDTO = undefined!;
    public showForm: boolean = false;
    public appCycleOpen = false;
    
    @Output()
    recordCountEvent = new EventEmitter<number>();
    disabled: boolean = false;    
 
    public application!: ApplicationDTO;
    constructor(private svc: PreviousSubmissionsService,
        public applicationService : ApplicationService,
        public configService : ConfigService,
        public authService: AuthService,
        private lookupService : LookupService,
        private toastr: ToastrService) {}
    

    ngOnInit() {
        this.handleRefresh({});
        this.lookupService.currentCycle$.pipe(take(2)).subscribe(
            data => {
              if (data) {
                if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                  this.appCycleOpen = true;
                }
              }
            }
          );

        this.applicationService.getApplication(this.applicationId).subscribe(
            data => {
                if(this.authService.isApplicationsAdmin)
                {
                  this.disabled = false;
                }
               else if (data.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
                || data.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN) {              
                this.disabled = true;
            } else {
                this.disabled = false;
            }               
            }
          );   
        
    }
    
    editSelected(id: number) {
        this.selectedSubmissionId = id;
        this.showForm = true;
    }
    
    deleteSelected(id: number) {     
        let dto: PreviousSubmissionDTO = {
            applicationPreviousSubmissionId: id,
            applicationId: this.applicationId,
            applicationCycleName: '',
            applicationCycleYear: 0,
            wasFellowshipAwarded: false,
            applicationTypeId: 0,
        };

        this.svc.delete(dto).subscribe(
            data => { 
                this.handleRefresh({}); 
                this.toastr.success("Removed previous submission record.");
            }
        );
    }
    
    addForm() {
        this.showForm = true;
    }

    handleRefresh(args: any) {
        this.svc.getAllByApplicationId(this.applicationId).subscribe(
            data => { 
                this.models = data; 
                this.recordCountEvent.emit(data.length);
            }
        );
        this.showForm = false;
        this.selectedSubmissionId = 0;
    }

    friendlyApplicationTypeName(id: number) {
        let name: string = 'Unknown';

        switch(id) {
            case 2: 
                name = 'Undergraduate';
                break;
            case 3: 
                name = 'Graduate';
                break;
            case 1:
                name = 'Post-Doc';
                break;
            case 4:
                name = 'Medical';
                break;
        }
        return name;
    }
}