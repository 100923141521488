import { Component, OnInit } from '@angular/core';
import { AdministrationService } from './administration.service';
import { Router } from '@angular/router';
import { ApplicationCycleDTO } from '../../shared/models/ApplicationCycleDTO';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';


@Component({
  selector: 'app-list-appcycle',
  template: `
    <h4 class="my-4">
      Application Cycles
      <a class="btn btn-primary float-right text-light" (click)="addAppCycle()" *ngIf="authService.isSuperAdmin">
        <i class="fa fa-plus" aria-hidden="true"></i> Add
      </a>
    </h4>

    <table class="table table-hover">
      <thead class="thead-dark">
        <tr>
          <th>Cycle Name</th>
  
          <th>Application</th>
         
          <!--<th>Review</th> -->
          <th>Postdoc</th>
          <th>Graduate</th>
          <th>Undergraduate</th>
          <th>Medical</th>
          <th>Meeting</th>
          <th>Reading Phase</th>
         
          <th style="width:150px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cycle of appCycles">
          <td>{{ cycle.applicationCycleName }}</td>
          
          <td>{{ cycle.applicationStartDate | date:'M/d/yyyy' }} - {{ cycle.applicationEndDate | date:'M/d/yyyy' }}</td>
          
         <!-- <td>{{ cycle.reviewStartDate | date:'M/d/yyyy' }} - {{ cycle.reviewEndDate | date:'M/d/yyyy' }}</td>  -->
         <td>{{ cycle.postdocReviewStartDate | date:'M/d/yyyy' }} - {{ cycle.postdocReviewEndDate | date:'M/d/yyyy' }}</td>
         <td>{{ cycle.graduateReviewStartDate | date:'M/d/yyyy' }} - {{ cycle.graduateReviewEndDate | date:'M/d/yyyy' }}</td>
         <td>{{ cycle.undergraduateReviewStartDate | date:'M/d/yyyy' }} - {{ cycle.undergraduateReviewEndDate | date:'M/d/yyyy' }}</td>
         <td>{{ cycle.medicalReviewStartDate | date:'M/d/yyyy' }} - {{ cycle.medicalReviewEndDate | date:'M/d/yyyy' }}</td>
         <td>{{ cycle.reviewMeetingStartDate | date:'M/d/yyyy' }} - {{ cycle.reviewMeetingEndDate | date:'M/d/yyyy' }}</td>
          <td>{{ cycle.readingPhaseStartDate | date:'M/d/yyyy' }} - {{ cycle.readingPhaseEndDate | date:'M/d/yyyy' }}</td>
          <td class="table-secondary">
           
            <a class="btn btn-secondary float-right text-light mr-3" (click)="editAppCycle(cycle.applicationPeriodId)">
              Edit
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row">
      <div class="bg-dark my-5 collapse" id="debugCard">
        <div class="card-body">
          <pre class="text-white">{{ appCycles | json }}</pre>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class ListAppcycleComponent implements OnInit {

  appCycles: ApplicationCycleDTO[];

  constructor(private router: Router,
    private administrationService: AdministrationService,
    public authService: AuthService,
    private toastr: ToastrService) { this.appCycles = []}

  public ngOnInit(): void {
    this.administrationService.getApplicationTopCycles().subscribe(
      data => {
        this.appCycles = data;
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }

  addAppCycle() {
    this.router.navigate(['/admin/edit/', 0]);
  }

  editAppCycle(id: number) {
    //alert("Hello! "+ id);
    this.router.navigate(['/admin/edit/', id]);
  }

  
}
