import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { AuthService } from 'src/app/core/authentication/auth.service';
import { Observable, of } from 'rxjs';
import { ApplicationCycleDTO } from '../../shared/models/ApplicationCycleDTO';
import { ConfigService } from 'src/app/core/services/config.service';



@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  private baseurl = "/applicationperiod/";
  private getAllUrl = this.apiUrl + this.baseurl + "all";
  private getTopUrl = this.apiUrl + this.baseurl + "top";
  private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";
  private updateUrl = this.apiUrl + this.baseurl + "update";
  private addUrl = this.apiUrl + this.baseurl + "add";    


  constructor(
            protected httpClient: HttpClient//,
            //protected authService: AuthService
            ) { }



            public getApplicationCycles(): Observable<ApplicationCycleDTO[]> {
              return this.httpClient.get<ApplicationCycleDTO[]>(this.getAllUrl);
          }
          public getApplicationTopCycles(): Observable<ApplicationCycleDTO[]> {
            return this.httpClient.get<ApplicationCycleDTO[]>(this.getTopUrl);
        }
      
          public getApplicationCycle(id: number): Observable<ApplicationCycleDTO> {
           
              return this.httpClient.get<ApplicationCycleDTO>(this.getSpecificUrl + id)
          }
      
          public updateAppCycle(dto: ApplicationCycleDTO): Observable<ApplicationCycleDTO> {
              return this.httpClient.post<ApplicationCycleDTO>(this.updateUrl, dto);
          }
          
          public saveAppCycle(dto: ApplicationCycleDTO): Observable<ApplicationCycleDTO> {
           
            return this.httpClient.post<ApplicationCycleDTO>(this.addUrl, dto);
          }

 
}
