
<div class=" my-4" id="ResearchProposalSection" *ngIf="researchProposalFG">
    <h3>Research Proposal <i *ngIf="savingInProgress" class="fa fa-circle-notch fa-spin ml-1"></i></h3>
    
    <div [formGroup]="researchProposalFG">
        <div class="card border-secondary my-4">
            <div class="card-header">Previous Proposal</div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <div class="form-label">Previous Fellowship Applications</div>                    
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="previouslyAppliedForFellowshipNo" [value]="false" formControlName="previouslyAppliedForFellowship" (change)="togglePreviousApplication(false)" />
                            <label class="form-check-label" for="previouslyAppliedForFellowshipNo">I <strong>have not</strong> previously applied for a Pelotonia Fellowship</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="previouslyAppliedForFellowshipYes" [value]="true" formControlName="previouslyAppliedForFellowship" (change)="togglePreviousApplication(true)" />
                            <label class="form-check-label" for="previouslyAppliedForFellowshipYes">I <strong>have</strong> previously applied for a Pelotonia Fellowship</label>
                        </div>
                        <div *ngIf="f.previouslyAppliedForFellowship?.invalid && (f.previouslyAppliedForFellowship?.dirty || f.previouslyAppliedForFellowship?.touched)">
                            <small *ngIf="f.previouslyAppliedForFellowship?.errors?.required" class="text-danger"> is required</small>
                        </div>
                    </div>
                </div>
                <div class="row mb-2" *ngIf="previouslyApplied === true">
                    <div class="col-md-12">
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <previous-submissions [applicationId]="applicationId" (recordCountEvent)="handlePreviousSubmissionCount($event)"></previous-submissions>              
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-12">
                                <div class="form-label">Resubmission</div>     
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="resubmissionYes" [value]="false" formControlName="isResubmission" (change)="toggleResubmission(false)" /> 
                                    <label class="form-check-label" for="resubmissionYes"><strong>No</strong>, this is <strong>not</strong> a resubmission</label>
                                </div>               
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="resubmissionNo" [value]="true" formControlName="isResubmission" (change)="toggleResubmission(true)" />
                                    <label class="form-check-label" for="resubmissionNo"><strong>Yes</strong>, this is a resubmission</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" *ngIf="isResubmission">
                            <div class="col-md-12">
                                <div class="alert alert-warning border border-warning shadow-sm">
                                    <b>Submission Changes</b>
                                    <p>If this is resubmission of a previous application, you must provide the following:</p>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="alert alert-info shadow-sm border border-info">
                                    <strong>Rebuttal Letter</strong><br />
                                    1/2 page response that states how you addressed the comments from reviewers. 
                                </div>
                            </div>
                            <div class="col-md-12">
                                <document-upload [applicationId]="applicationId"
                                    [application]="application"
                                    [documentUploadId]="rebuttalLetterDocumentId"                    
                                    [documentTypeId]="docSvc.REBUTTALLETTERDOCTYPE"
                                    [documentDto]="rebuttalLetterDocument"
                                    (fileAttached)="onFileAttached($event)"></document-upload>
                            </div>
                            <div class="col-md-12">
                                <div class="alert alert-info shadow-sm border border-info">
                                    <strong>Resubmission Critique</strong><br />
                                    The comments from reviewers that were sent to you.
                                </div>
                            </div>
                            <div class="col-md-12">
                                <document-upload [applicationId]="applicationId"
                                    [application]="application"
                                    [documentUploadId]="resubmissionCritiqueDocumentId"                    
                                    [documentTypeId]="docSvc.RESUBMISSIONCRITIQUEDOCTYPE"
                                    [documentDto]="resubmissionCritiqueDocument"
                                    (fileAttached)="onFileAttached($event)"></document-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-secondary my-4">
            <div class="card-header">Fundings</div>
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <div class="form-label">Other Fundings</div>                    
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="hasFundingNo" value="No Funding" formControlName="hasFunding" (change)="toggleFunding(false)" />
                            <label class="form-check-label" for="hasFundingNo">I <strong>have no</strong> fundings</label>
                        </div>             
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="hasFundingPrevious" value="Previous Fellowship" formControlName="hasFunding" (change)="toggleFunding(false)" />
                            <label class="form-check-label" for="hasFundingPrevious">I <strong>have</strong> previous fellowship</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="hasFundingYes" value="Funded" formControlName="hasFunding" (change)="toggleFunding(true)" />
                            <label class="form-check-label" for="hasFundingYes">I <strong>have</strong> other fundings</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="isFunded === true" class="row mb-2">
                    <div class="col-md-12">
                        <div class="alert alert-info shadow-sm border border-info">
                        <strong>Funding Information</strong><br />
                        Please explain other funding
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <textarea
                                class="form-control" 
                                name="fundingInfo"
                                rows="5"
                                formControlName="fundingInfo"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card border-secondary my-4">
            <div class="card-header">Research Proposal</div>
            <div class="card-body">
            <div class="row mb-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="titleOfProject">Title of Project</label>
                        <input type="textbox" 
                                class="form-control"               
                                name="titleOfProject"                                       
                                formControlName="titleOfProject" maxlength="280" 
                                [ngClass]="{'border-danger' : f.titleOfProject.invalid}"/>
                        <div *ngIf="f.titleOfProject?.invalid && (f.titleOfProject?.dirty || f.titleOfProject?.touched)">
                            <small *ngIf="f.titleOfProject?.errors?.required" class="text-danger">Title is required</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="abstract">Abstract</label>
                        <textarea
                            class="form-control" 
                            name="abstract"
                            rows="5"
                            formControlName="abstract"
                            [ngClass]="{'border-danger' : f.abstract.invalid}">
                        </textarea>
                        <div *ngIf="f.abstract?.invalid && (f.abstract?.dirty || f.abstract?.touched)">
                            <small *ngIf="f.abstract?.errors?.required" class="text-danger">Abstract is required</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="layLanguageAbstract">Lay Language Abstract</label>
                        <textarea
                            class="form-control" 
                            name="layLanguageAbstract"
                            rows="5"
                            formControlName="layLanguageAbstract"
                            [ngClass]="{'border-danger' : f.layLanguageAbstract.invalid}">
                        </textarea>
                        <div *ngIf="f.layLanguageAbstract?.invalid && (f.layLanguageAbstract?.dirty || f.layLanguageAbstract?.touched)">
                            <small *ngIf="f.layLanguageAbstract?.errors?.required" class="text-danger">Lay abstract is required</small>
                        </div>
                        <span class="text-muted small">
                            A lay language abstract is a brief summary of a research project that is used to explain complex ideas, technical and scientific terms to people who do not have prior knowledge about the subject. (Basically a simplified version of your abstract so that someone who doesn’t work in a lab can understand it.)   
                        </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="impactAndInnovation">Impact and Innovation</label>
                        <textarea
                            class="form-control" 
                            name="impactAndInnovation"
                            rows="5"
                            formControlName="impactAndInnovation"
                            [ngClass]="{'border-danger' : f.impactAndInnovation.invalid}">
                        </textarea>
                        <div *ngIf="f.impactAndInnovation?.invalid && (f.impactAndInnovation?.dirty || f.impactAndInnovation?.touched)">
                            <small *ngIf="f.impactAndInnovation?.errors?.required" class="text-danger">Impact and Innovation is required</small>
                        </div>
                    </div>
                </div>
            </div>   
            <div class="row mb-2">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-secondary border border-secondary shadow-sm" [ngClass]="{'border-danger' : f.researchProposalDocument.invalid}">
                                <b>Research Proposal Document</b><br />
                                The research proposal document must be written in single spaced 11 point Arial font, with margins of 0.5 inch. Please use the following as a guide for the format of your research proposal document:<br />
                                <ul>
                                    <li><strong>Project Title</strong></li>
                                    <li><strong>Project Summary (up to one page)</strong></li>
                                    <li><strong>Project Description (up to 3 pages not including bibiolography)</strong></li>
                                    <li>
                                        <strong>Suggested Headings:</strong>
                                        <ul>
                                            <li>Specific Aims</li>
                                            <li>Background and Significance</li>
                                            <li>Preliminary Investigation</li>
                                            <li>Experiment Design Methods</li>
                                        </ul>
                                    </li>
                                    <li><strong>Bibliography (does not count in page limitation)</strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <document-upload [applicationId]="applicationId"
                        [application]="application"
                        [documentUploadId]="researchProposalDocumentId"                    
                        [documentTypeId]="docSvc.RESEARCHPROPOSALDOCTYPE"
                        [documentDto]="researchProposalDocument"
                        (fileAttached)="onFileAttached($event)">
                    </document-upload>
                    <div *ngIf="f.researchProposalDocument?.invalid">
                        <small *ngIf="f.researchProposalDocument?.errors?.required" class="text-danger">Proposal document is required</small>
                    </div>
                </div>
            </div>
        </div>
    
       
        <div class="row">
            <div class="col-12">
                <validation-summary 
                    [formGroup]="researchProposalFG"
                    [friendlyFormFieldNames]="friendlyFieldNames"></validation-summary>
            </div>
    </div>
</div>