<div [formGroup]="historyFG" class="mt-2">   
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="alert alert-info border border-info shadow-sm">
                All form fields are required; please enter values into all form fields to save an academic history record.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="academicInstitution">Institution</label>
                <input type="textbox" 
                    class="form-control" 
                    name="academicInstitution"
                    formControlName="academicInstitution" />
                <div *ngIf="f.academicInstitution?.invalid && (f.academicInstitution?.dirty || f.academicInstitution?.touched || isFormValid == false)">
                    <small class="text-danger" *ngIf="f.academicInstitution?.errors?.required">Institution is required <br /></small>
                    <small class="text-danger" *ngIf="f.academicInstitution?.errors?.maxlength">Institution must be less than 100 characters.</small>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="degree">Degree</label>
                <select name="degree"                
                    class="form-control" 
                    name="degree"
                    formControlName="degree"
                    (change)="changeDegree($event)">
                        <option value="">Please Select</option>
                        <option value="Associates">Associates</option>
                        <option value="Bachelors">Bachelors</option>
                        <option value="Masters">Masters</option>
                        <option value="DVM">DVM</option>
                        <option value="JD">JD</option>
                        <option value="MD">MD</option>
                        <option value="PhD">PhD</option>
                        <option value="PharmD">PharmD</option>
                        <option value="Other">Other</option>
                </select>                
                <div *ngIf="f.degree?.invalid && (f.degree?.dirty || f.degree?.touched || isFormValid == false)">
                    <small class="text-danger" *ngIf="f.degree?.errors?.required">Degree is required.</small>
                </div>
            </div>
            <div class="form-group" *ngIf="needsOtherDegree"> 
                <label for="otherDegree">Other Degree</label>
                <input type="textbox" 
                class="form-control" 
                name="otherDegree"
                formControlName="otherDegree" />
                <div *ngIf="f.otherDegree?.invalid && (f.otherDegree?.dirty || f.otherDegree?.touched || isFormValid == false)">
                    <small class="text-danger" *ngIf="f.otherDegree?.errors?.required">Other Degree is required <br /></small>
                    <small class="text-danger" *ngIf="f.otherDegree?.errors?.maxlength">Other Degree must be less than 150 characters.</small>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="fieldOfStudy">Field of Study</label>
                <input type="textbox" 
                    class="form-control" 
                    name="fieldOfStudy"
                    formControlName="fieldOfStudy" />                
                    <div *ngIf="f.fieldOfStudy?.invalid && (f.fieldOfStudy?.dirty || f.fieldOfStudy?.touched || isFormValid == false)">
                        <small class="text-danger" *ngIf="f.fieldOfStudy?.errors?.required">Field of Study is required <br /></small>
                        <small class="text-danger" *ngIf="f.fieldOfStudy?.errors?.maxlength">Field of Study must be less than 150 characters.</small>
                    </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="v">Year Awarded</label>
                <input type="textbox" 
                    class="form-control" 
                    name="yearAwarded"
                    formControlName="yearAwarded" />
                    <div *ngIf="f.yearAwarded?.invalid && (f.yearAwarded?.dirty || f.yearAwarded?.touched || isFormValid == false)">
                        <small class="text-danger" *ngIf="f.yearAwarded?.errors?.required">Year Awarded is required <br /></small>
                        <small class="text-danger" *ngIf="f.yearAwarded?.errors?.pattern">Year Awarded  must be a 4 digit number<br /></small>
                        <small class="text-danger" *ngIf="f.yearAwarded?.errors?.max">Year Awarded cannot be in the future.</small>                        
                    </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-primary" (click)="save()">Save Degree</button>&nbsp;<button class="btn btn-secondary" (click)="cancelForm()">Cancel</button>
        </div>
        <div class="col-md-12 text-right" *ngIf="isFormValid === false && historyFG.valid === false">
            <small class="text-danger">You have data missing from this Degree form. Please enter all required fields.</small>
        </div>
    </div>
</div>
