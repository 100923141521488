
<div class="alert alert-secondary" role="alert">
<div class="row">
    <div class="col-md-3">
        <b>Application Type:</b> {{applicationType}} 
        <button type="button" class="btn btn-sm btn-secondary" 
        [hidden]="isSubmitted === true || isWithdrawn === true"
            title="Change Application Type"
            data-toggle="modal" data-target="#changeTypeModal">
            <i class="fa-solid fa-arrows-rotate"></i>
        </button>
    </div>
    <div class="col-md-3">
        <b>Application Status:</b> {{applicationStatus}}
    </div>
    <div class="col-md-3">
        <b>Application Number:</b> {{applicationNumber}}
    </div>
    
    <div class="col-md-2">        
        <button class="btn btn-primary" 
            [hidden]="isSubmitted === true || isWithdrawn === true" 
            (click)="withdrawApplication()">
            <i class="fa fa-share-square"></i>Withdraw
        </button>
    </div>       
     
    <div class="col-md-2">               
        <button class="btn btn-primary"
            [hidden]="isSubmitted === true || isWithdrawn === false"
            (click)="unWithdrawApplication()">
            <i class="fa fa-share-square"></i>UnWithdraw
        </button>
    </div>   

    <div class="col-md-3" *ngIf="authService.isApplicationsAdmin">               
      <button class="btn btn-primary"
          [hidden]="isSubmitted === false "
          (click)="unSubmitApplication()">
          <i class="fa fa-share-square"></i>Admin Withdraw
      </button>
  </div>   
    
</div>
</div>

 
<div class="row">
    <div class="col-md-8 offset-md-2" *ngIf="isSubmitted === true">
        <div class="alert alert-success border border-success shadow-sm text-center" >
            You have successfully submitted your {{applicationType}} Scholarship Application! 
        </div>
    </div>
    <div class="col-md-8 offset-md-2" *ngIf="isSubmitted === false && isWithdrawn === false">
        <div class="alert alert-success border border-success shadow-sm text-center" *ngIf="readForSubmission === true">
            Your application is ready for submission!
        </div>
        <div class="alert alert-warning border border-warning shadow-sm text-center" *ngIf="readForSubmission === false">
            Your application is not complete. Please visit the incomplete sections and enter missing application fields.
        </div>
    </div>
</div>



<div id="changeTypeModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Change Application Type</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="applicationTypes">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="applicationTypeId" class="control-label">Select an Application Type</label>
              <select class="form-control" id="applicationTypeId" name="applicationTypeId"
                [(ngModel)]="application.applicationTypeId">
                <option *ngFor="let appType of applicationTypes"
                  [value]="appType.applicationTypeId">
                  {{appType.displayName}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" (click)="changeApplicationType()">Save changes</button>
          </div>
      </div>
    </div>
  </div>