<pagetitle pageTitle="Admin Landing Screen"></pagetitle>
<div class="container pt-3 pb-6" style="background:transparent">
   
    <!--<p><button class="btn btn-primary" (click)="createNewAppPeriod()">Add New Application Cycle</button></p>
    <h5 *ngIf="this.periods">{{this.periods.length}} Application Periods</h5>  -->
    <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="card-deck mt-5">
        <div class="card" *ngIf="authService.isApplicationsAdmin">
            <div class="card-header bg-primary text-light text-center py-4">
              <i class="fa fa-calendar fa-5x" aria-hidden="true"></i>
            </div>
            <div class="card-body bg-secondary">
              <h4 class="card-title text-white">Application Cycles</h4>
            
            </div>
            <div class="card-footer bg-secondary text-center" >
              <a [routerLink]="['/admin/cycle']" class="btn btn-primary btn-block">Application Cycle</a>
            </div>
         </div>

          <div class="card" *ngIf="authService.isApplicationsAdmin">
              <div class="card-header bg-primary text-light text-center py-4">
                <i class="fa fa-th-list fa-5x" aria-hidden="true"></i>
              </div>
              <div class="card-body bg-secondary">
                <h4 class="card-title text-white">Application List</h4>
              
              </div>
              <div class="card-footer bg-secondary text-center" *ngIf="authService.isLoggedIn">
                <a [routerLink]="['/admin/application-list']" class="btn btn-primary btn-block">Manage Applications </a>
              </div>
          </div>
          <div class="card" *ngIf="authService.isApplicationsAdmin || authService.isSecurityAdmin">
              <div class="card-header bg-primary text-light text-center py-4">
                <i class="fa fa-user-md fa-5x" aria-hidden="true"></i>
              </div>
              <div class="card-body bg-secondary">
                <h4 class="card-title text-white">Add Reviewers for current Cycle</h4>
              
              </div>
              <div class="card-footer bg-secondary text-center" *ngIf="authService.isLoggedIn">
                <a [routerLink]="['/admin/reviewers']" class="btn btn-primary btn-block">Manage Reviewers</a>
              </div>
          
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="card-deck mt-5">
          <div class="card" *ngIf="authService.isApplicationsAdmin">
              <div class="card-header bg-primary text-light text-center py-4">
                <i class="fa fa-user-md fa-5x" aria-hidden="true"></i>
              </div>
              <div class="card-body bg-secondary">
                <h4 class="card-title text-white">Email Notification</h4>
              
              </div>
            
              <div class="card-footer bg-secondary text-center" *ngIf="authService.isLoggedIn">
                <a [routerLink]="['/admin/emails']" class="btn btn-primary btn-block">Send Invite to Reviewers</a>
              </div>
           </div>

           <div class="card" *ngIf="authService.isApplicationsAdmin">
              <div class="card-header bg-primary text-light text-center py-4">
                <i class="fa fa-user-md fa-5x" aria-hidden="true"></i>
              </div>
              <div class="card-body bg-secondary">
                <h4 class="card-title text-white">Download PDF By Cycle</h4>
              
              </div>
            
              <div class="card-footer bg-secondary text-center" *ngIf="authService.isLoggedIn">
                <a [routerLink]="['/admin/downloads']" class="btn btn-primary btn-block">Download PDF By Cycle</a>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </div>
</div>