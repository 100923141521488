import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { RouterModule } from '@angular/router';
import { applyRoutes } from './routes';
import { IndexComponent } from './pages/index.component';
import { ContactInformationComponent } from './components/contactinformation/contact-information.component';
import { ApplicationTypeSelectionComponent } from './components/typeselection/type-selection.component';
import { ApplicationStatusComponent } from './components/status/status.component';
import { ApplicationValidationSummaryComponent } from './components/validation/validation.component';
import { AcademicBackgroundComponent } from './components/academicbackground/academic-background.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { HistoryListComponent } from './components/academicbackground/history-list.component';
import { HistoryFormComponent } from './components/academicbackground/history-form.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ContactInformationValidationComponent } from './components/contactinformation/contact-validation.component';
import { ResearchProposalComponent } from './components/researchproposal/research-proposal.component';
import { PreviousSubmissionFormComponent } from './components/researchproposal/previous-form.component';
import { PreviousSubmissionListComponent } from './components/researchproposal/previous-submissions.component';

import { ApplicationMentorRecommenderComponent } from './components/mentor/mentor.component';
import { ApplicationSubmissionComponent } from './components/submission/application-submission-component';
import { ReturnToStatusComponent } from './components/status/return-to-status.component';
import { AddMentorComponent } from './components/mentor/add-mentor.component';
import { AddCoMentorComponent } from './components/mentor/add-comentor.component';
import { FindMentorComponent } from './components/mentor/find-mentor.component';
import { LabelModule } from '@progress/kendo-angular-label';
@NgModule({
    declarations: [
        IndexComponent,
        ApplicationTypeSelectionComponent,
        ApplicationStatusComponent,
        ApplicationStatusComponent,
        ContactInformationComponent,
        AcademicBackgroundComponent,
        HistoryListComponent,
        HistoryFormComponent,
        FileUploadComponent,
        ContactInformationValidationComponent,
        ApplicationValidationSummaryComponent,
        ResearchProposalComponent,
        PreviousSubmissionFormComponent,
        PreviousSubmissionListComponent,
        ApplicationSubmissionComponent,
        ApplicationMentorRecommenderComponent,
        ReturnToStatusComponent,
        AddMentorComponent,
        AddCoMentorComponent,
        FindMentorComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        InputsModule,
		LabelModule,
        ComboBoxModule,
        DropDownsModule,
        DatePickerModule,        
        UploadModule,
        LayoutModule,
        RouterModule.forChild(applyRoutes)
    ],
    providers: [
        DatePipe
    ]
})
export class ApplyModule { }