import { HttpClient } from '@angular/common/http';
import { Component, Input, EventEmitter,OnInit,Output } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

import { ApplicationMentorRecommenderDTO } from '../../models/ApplicationMentorRecommenderDTO';
import { FindPeopleResult } from '../../models/FindPeopleResult';
import { ApplicationService } from '../../services/application.service';
import { FormStateService } from '../../services/form-state.service';
import { Subscription } from 'rxjs';

// tslint:disable-next-line:no-any
declare var $: any;

@Component({
  selector: 'app-add-mentor',
  templateUrl: 'add-mentor.component.html'
})
export class AddMentorComponent implements OnInit {
  //mentorNoOsuFG : FormGroup = undefined!;
  @Input()
  applicationId: number;

  @Input()
  isMentorOSU: boolean;
  @Input()
  appMentor: ApplicationMentorRecommenderDTO;

  @Output()
  savedEvent = new EventEmitter<string>();
  //isFormValid: boolean = true;
  //title = 'app';
  restItems: any;
  inputUserName = '';
  inputFirstName = '';
  inputLastName = '';
  searchParams = '';
  selectedDotNumber = '';

  searchByName = false;
  searchByDotNumber = false;
  searchText = 'Search By Name';
  showResults = false;
  searchObservable: Subscription;
  searchResults: Array<FindPeopleResult> = [];
  buttonText = 'Select';
  // for non-OSU
  mentorFirstName: string;
  mentorLastName: string;
  mentorEmailAddress: string;

  disabled = false;

  constructor(private http: HttpClient,
    private fb: UntypedFormBuilder,
    private formStateService: FormStateService,
    private applicationService: ApplicationService,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    //alert("appMentor is &&&&&&&"+ this.appMentor);
    this.formStateService.readOnly$.subscribe(
      data => {
        if (data === true) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    );

  
  }

  

  search() {
    this.showResults = false;
    this.selectedDotNumber = '';
    let searchURL = '';

    // if a previous search hasn't completed yet, abandon it
    if (this.searchObservable) {
      this.searchObservable.unsubscribe();
    }

    if (this.searchByName) {
       
      searchURL = `https://directory.osu.edu/fpjson.php?lastname=${this.inputLastName}*&firstname=${this.inputFirstName}*`;
      this.searchParams = this.inputFirstName + ' ' + this.inputLastName;
    } else if (this.searchByDotNumber) {
       
      this.inputUserName = (this.inputUserName).split('@')[0];
      searchURL = `https://directory.osu.edu/fpjson.php?name_n=${this.inputUserName}`;
      this.searchParams = this.inputUserName;
    } else {

    }

    // filter out people without appointments (students)
    this.searchObservable = this.http.get<FindPeopleResult[]>(searchURL).subscribe(data => {
      //this.searchResults = data.filter(value => value.appointments.length > 0);
      this.searchResults = data.filter(value => value.appointments.length > 0?value.appointments.length > 0:value.username !=null);
      this.showResults = true;
    });
  }

  activateSearchByName() {
    this.searchByName = true;
    this.searchByDotNumber = false;
    this.searchText = 'Search By Name';
  }

  activateSearchByDotNumber() {
    this.searchByName = false;
    this.searchByDotNumber = true;
    this.searchText = 'Search By Name.#';
  }

  piSelect(email: string, last_name: string, first_name: string) {
    const username = email.split('@')[0];
    document.getElementById(username)!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>';
    
    // tell irp api to add this pi to this application
    const newMentor: ApplicationMentorRecommenderDTO= {
        applicationId: this.applicationId,         
        mentorFirstName: first_name,
        mentorLastName: last_name,
        mentorEmailAddress: email,
        mentorOsuNameDotNumber: username,
        mentorOrganization :  'The Ohio State University',  
        mentorCincinnatiDepartmentId: null,
        mentorId: this.appMentor!= undefined? this.appMentor.mentorId:null,
        hasCoMentor: this.appMentor!= undefined? this.appMentor.hasCoMentor:null,
        coMentorId: this.appMentor!= undefined? this.appMentor.coMentorId:null,
        coMentorFirstName: this.appMentor!= undefined?  this.appMentor.coMentorFirstName:null,
        coMentorLastName:  this.appMentor!= undefined? this.appMentor.coMentorLastName:null,
        coMentorEmailAddress: this.appMentor!= undefined? this.appMentor.coMentorEmailAddress:null,
        coMentorOrganization : this.appMentor!= undefined? this.appMentor.coMentorOrganization:null,
        coMentorOsuNameDotNumber :  this.appMentor!= undefined? this.appMentor.coMentorOsuNameDotNumber:null,
        coMentorCincinnatiDepartmentId: null,
        recommenderFirstName:  this.appMentor!= undefined? this.appMentor.recommenderFirstName:null,
        recommenderLastName:  this.appMentor!= undefined? this.appMentor.recommenderLastName:null,
        recommenderEmailAddress: this.appMentor!= undefined? this.appMentor.recommenderEmailAddress:null,
        recommenderRelationship:  this.appMentor!= undefined?this.appMentor.recommenderRelationship:null,
      };
      if(this.appMentor == undefined){
      
          this.applicationService.addMentorToApplication(newMentor).subscribe(
            data => {
              document.getElementById(username)!.innerHTML = 'Select';              
              this.formStateService.addMentor(data);
              this.savedEvent.emit("saved");
              this.close(); 
            },
            error => {
              document.getElementById(username)!.innerHTML = 'Select';
              console.log(JSON.stringify(error));
              this.toastr.error(error.error, 'Error');
            }
          );
      }
      else {
        this.applicationService.updateMentor(newMentor).subscribe(
          data => {
            document.getElementById(username)!.innerHTML = 'Select';              
            this.formStateService.addMentor(data);
            this.savedEvent.emit("saved");
            this.close(); 
          },
          error => {
            document.getElementById(username)!.innerHTML = 'Select';
            console.log(JSON.stringify(error));
            //alert("error is &&&& "+error.error);
            this.toastr.error(error.error, 'Error updating mentor');
          }
      );
      }
  }

  /*piNoOsuSelect(mentorEmailAddress: string, mentorFirstName: string, mentorLastName: string) {   
    document.getElementById('addNonOsuButton')!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>'; 
      if(mentorEmailAddress!==undefined && mentorFirstName!==undefined  && mentorLastName!==undefined){
        
    const newMentor: ApplicationMentorRecommenderDTO= {
        applicationId: this.applicationId,         
        //mentorFirstName: this.mentorNoOsuFG.get('mentorFirstName')?.value,
        //mentorLastName: this.mentorNoOsuFG.get('mentorLastName')?.value,
        //mentorEmailAddress: this.mentorNoOsuFG.get('mentorEmailAddress')?.value,
        mentorFirstName: mentorFirstName,
        mentorLastName: mentorLastName,
        mentorEmailAddress: mentorEmailAddress,
        mentorOsuNameDotNumber: null,
        mentorOrganization :  'Non OSU',  
        mentorCincinnatiDepartmentId: null,
        mentorId: this.appMentor!= undefined? this.appMentor.mentorId:null,
        hasCoMentor: this.appMentor!= undefined? this.appMentor.hasCoMentor:null,
        coMentorId: this.appMentor!= undefined? this.appMentor.coMentorId:null,
        coMentorFirstName: this.appMentor!= undefined?  this.appMentor.coMentorFirstName:null,
        coMentorLastName:  this.appMentor!= undefined? this.appMentor.coMentorLastName:null,
        coMentorEmailAddress: this.appMentor!= undefined? this.appMentor.coMentorEmailAddress:null,
        coMentorOrganization : this.appMentor!= undefined? this.appMentor.coMentorOrganization:null,
        coMentorOsuNameDotNumber :  this.appMentor!= undefined? this.appMentor.coMentorOsuNameDotNumber:null,
        coMentorCincinnatiDepartmentId: null,
        recommenderFirstName:  this.appMentor!= undefined? this.appMentor.recommenderFirstName:null,
        recommenderLastName:  this.appMentor!= undefined? this.appMentor.recommenderLastName:null,
        recommenderEmailAddress: this.appMentor!= undefined? this.appMentor.recommenderEmailAddress:null,
        recommenderRelationship:  this.appMentor!= undefined?this.appMentor.recommenderRelationship:null,
      };
    
      if(this.appMentor == undefined){
        this.applicationService.addMentorToApplication(newMentor).subscribe(
          data => {
            this.formStateService.addMentor(data);
            this.savedEvent.emit("saved");
            this.close2();
          },
          error => {
            document.getElementById('addNonOsuButton')!.innerHTML = 'Select';
            console.log(JSON.stringify(error));
            this.toastr.error(error.error, 'Error');
          }
        );
      }
      else {
        this.applicationService.updateMentor(newMentor).subscribe(
          data => {
                      
            this.formStateService.addMentor(data);
            this.savedEvent.emit("saved");
            this.close2(); 
            
          },
          error => {
            
            console.log(JSON.stringify(error));
            //alert("error is ^^^^^^ "+error.error);
            this.toastr.error(error.error, 'Error updating mentor');
          }
         );
      }
   }
   else{
    //alert("before newMentor **** "+ mentorEmailAddress+" fname==== "+mentorFirstName+"  lname  "+mentorLastName);
    this.savedEvent.emit("cancelled");
    this.close2(); 
   }
  }*/


  piNoOsuSelect(mentorEmailAddress: string, mentorFirstName: string, mentorLastName: string) {
    // const username = email.split('@')[0];
    document.getElementById('addNonOsuButton')!.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>';
 //alert("before newMentor **** "+ coMentorEmailAddress+" fname==== "+coMentorFirstName+"  lname  "+coMentorLastName);
    // tell irp api to add this pi to this application
    if(mentorEmailAddress!==undefined && mentorFirstName!==undefined  && mentorLastName!==undefined){
    const newMentor: ApplicationMentorRecommenderDTO= {
      applicationId: this.applicationId,         
      //mentorFirstName: this.mentorNoOsuFG.get('mentorFirstName')?.value,
      //mentorLastName: this.mentorNoOsuFG.get('mentorLastName')?.value,
      //mentorEmailAddress: this.mentorNoOsuFG.get('mentorEmailAddress')?.value,
      mentorFirstName: mentorFirstName,
      mentorLastName: mentorLastName,
      mentorEmailAddress: mentorEmailAddress,
      mentorOsuNameDotNumber: null,
      mentorOrganization :  'Non OSU',  
      mentorCincinnatiDepartmentId: null,
      mentorId: this.appMentor!= undefined? this.appMentor.mentorId:null,
      hasCoMentor: this.appMentor!= undefined? this.appMentor.hasCoMentor:null,
      coMentorId: this.appMentor!= undefined? this.appMentor.coMentorId:null,
      coMentorFirstName: this.appMentor!= undefined?  this.appMentor.coMentorFirstName:null,
      coMentorLastName:  this.appMentor!= undefined? this.appMentor.coMentorLastName:null,
      coMentorEmailAddress: this.appMentor!= undefined? this.appMentor.coMentorEmailAddress:null,
      coMentorOrganization : this.appMentor!= undefined? this.appMentor.coMentorOrganization:null,
      coMentorOsuNameDotNumber :  this.appMentor!= undefined? this.appMentor.coMentorOsuNameDotNumber:null,
      coMentorCincinnatiDepartmentId: null,
      recommenderFirstName:  this.appMentor!= undefined? this.appMentor.recommenderFirstName:null,
      recommenderLastName:  this.appMentor!= undefined? this.appMentor.recommenderLastName:null,
      recommenderEmailAddress: this.appMentor!= undefined? this.appMentor.recommenderEmailAddress:null,
      recommenderRelationship:  this.appMentor!= undefined?this.appMentor.recommenderRelationship:null,
      };
      if(this.appMentor == undefined){
        this.applicationService.addMentorToApplication(newMentor).subscribe(
          data => {
            this.formStateService.addMentor(data);
            this.savedEvent.emit("saved");
            this.close2();
          },
          error => {
            document.getElementById('addNonOsuButton')!.innerHTML = 'Select';
            console.log(JSON.stringify(error));
            this.toastr.error(error.error, 'Error');
          }
        );
      }
      else {
        this.applicationService.updateMentor(newMentor).subscribe(
          data => {
                      
            this.formStateService.addMentor(data);
            this.savedEvent.emit("saved");
            this.close2(); 
          },
          error => {
            
            console.log(JSON.stringify(error));
            this.toastr.error(error.error, 'Error updating mentor');
          }
         );
      }
    }
  }


  /*
  get f() {
    return this.mentorNoOsuFG.controls;
}
*/
  close(): void {
    $('#mentorModal').modal('hide');
   
  }

 close2(): void {
  
    $('#mentorNoOsuModal').modal('hide');
  }


}
