<form novalidate #myForm="ngForm">

    <kendo-grid
      [data]="gridView"
      [pageSize]="state.take!"
      [skip]="state.skip!"
      [filter]="state.filter!"
      [sort]="state.sort!"
      [sortable]="true"
      [pageable]="false"
      [filterable]="true"
      [reorderable]="true"
      [resizable]="true"
      (edit)="editHandler($event)" (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)" (remove)="removeHandler($event)"
      (dataStateChange)="dataStateChange($event)"
      style="height:800px"
      class="mb-5">
  
      <ng-template kendoGridToolbarTemplate >
        
        <button class="btn btn-secondary" data-toggle="modal" data-target="#intReviewModal" *ngIf=" authService.isSecurityAdmin">Add New Reviewer</button>
        <app-add-committee-assignment (reviewerAdded)="onReviewerAdded($event)"></app-add-committee-assignment>
      </ng-template>
  
      <kendo-grid-column
        field="reviewerDisplayName"
        title="Name"
        [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.reviewerDisplayName}}
        </ng-template>
      </kendo-grid-column>
  
     
  
        <kendo-grid-column
        field="applicationTypeName"
        title="Application Type"
        [width]="200">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-column-filter
            [filter]="filter"
            [data]="applicationTypes"
            filterField="applicationTypeId"
            textField="displayName"
            valueField="applicationTypeId">
          </app-column-filter>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <select class="form-control mr-3" id="applicationTypeId" name="applicationTypeId" required
            [(ngModel)]="dataItem.applicationTypeId">
            <option *ngFor="let applicationType of applicationTypes"
              [value]="applicationType.applicationTypeId">
              {{applicationType.displayName}}
            </option>
          </select>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-command-column
        title="Actions"
        [width]="120">
        <ng-template kendoGridCellTemplate let-dataItem>
          <button kendoGridEditCommand class="btn btn-sm btn-primary">
            <i class="fa fa-pencil" aria-hidden="true"></i> Edit
          </button>
          <button kendoGridRemoveCommand class="btn btn-sm btn-primary">
            <i class="fa fa-times" aria-hidden="true"></i> Delete
          </button>
          <button kendoGridSaveCommand class="btn btn-sm btn-secondary"
            [disabled]="myForm.invalid! || myForm.pristine!"> Update
          </button>
          <button kendoGridCancelCommand class="btn btn-sm btn-secondary">Cancel</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
  
    </form>
  

    <app-add-reviewer (reviewerAdded)="onReviewerAdded($event)"></app-add-reviewer>
    