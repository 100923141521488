<div class="container">
  <div class="row">
    <div class="col-md-9 pt-3">
      <h1 class="d-none d-sm-block">Unauthorized</h1>
      <h4 class="text-center d-block d-sm-none">Unauthorized</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="p-5">
        <p class="lead">You do not have permission to access that resource</p>
      </div>
    </div>
  </div>
</div>