import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-cycle',
  template: `

   
   
    <div class="container pt-3 pb-5">
      <div class="row">
        <div class="col-md-12">

          <app-list-appcycle></app-list-appcycle> 

        </div>
      </div>
    </div>

  `,
  styles: []

})
export class CycleComponent implements OnInit {

 // constructor(public authService: AuthService) { }

  ngOnInit() {
  }

}
