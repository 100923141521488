import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApplicationService } from '../../services/application.service';
import { FormStateService } from '../../services/form-state.service';
import { EmartPersonDTO } from 'src/app/shared/models/EMart';
import { ApplicationMentorRecommenderDTO } from '../../models/ApplicationMentorRecommenderDTO';

declare var $: any;

@Component({
  selector: 'app-find-mentor',
  templateUrl: './find-mentor.component.html',
  styles: [
  ]
})
export class FindMentorComponent  implements OnInit, OnDestroy {

  @Input()
  applicationId: number;
  @Input()
  isCoMentor: boolean;
  @Input()
  appMentor: ApplicationMentorRecommenderDTO;

  @Output()
  savedEvent = new EventEmitter<boolean>();

  errorMessage: string | undefined;
  loading = false;
  disabled = false;

  searchResults: EmartPersonDTO[] = [];
  selectedMentor: EmartPersonDTO;

  // for non-OSU
  mentorFirstName: string;
  mentorLastName: string;
  mentorEmailAddress: string;

  private mentorSearchObservable: Subscription;
  private debouncer: Subject<EmartPersonDTO> = new Subject();

  constructor(
    private formStateService: FormStateService,
    private applicationService: ApplicationService){}

  ngOnInit() {
    this.formStateService.readOnly$.subscribe(
      data => {
        if (data === true) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    );

    this.debouncer.pipe(debounceTime(500))
      .subscribe(event => {
        this.searchPeople(event);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.mentorSearchObservable) {
      this.mentorSearchObservable.unsubscribe();
    }
  }

  searchPeople(value: any) {
    this.searchResults.length = 0;
    this.errorMessage = undefined;

    // if a previous search hasn't completed yet, abandon it
    if (this.mentorSearchObservable) {
      this.mentorSearchObservable.unsubscribe();
    }

    if (value && value.length > 1) {
      this.loading = true;

      this.mentorSearchObservable = this.applicationService
        .mentorSearch(value)
        .subscribe(
          data => {
            console.log('results: ' + data.length);
            this.searchResults = data;
            this.loading = false;
          },
          error => {
            this.errorMessage = `<b>There was an error searching:</b><br/>${error.message}`;
            this.loading = false;
          }
        );
      } else {
        // this.selectedResearcher = undefined;
      }
  }

  chooseMentor(value?: EmartPersonDTO): void {
    if (value) {
      this.selectedMentor = value;
    }
  }

  saveOSU() {
    if (this.isCoMentor) {
      this.applicationService.addCoMentor(this.selectedMentor.cupId, this.applicationId).subscribe(
        data => {
          this.closeOSU();
          this.savedEvent.emit(true);
        },
        error => {
          console.log(`There was an error adding the mentor:<br/>${error.message}`);
        }
      );
    } else {
      this.applicationService.addMentor(this.selectedMentor.cupId, this.applicationId).subscribe(
        data => {
          this.closeOSU();
          this.savedEvent.emit(true);
        },
        error => {
          console.log(`There was an error adding the mentor:<br/>${error.message}`);
        }
      );
    }
  }

  callDebouncer(event: any) {
    this.debouncer.next(event);
  }

  closeOSU(): void {
    $('#mentorModal').modal('hide');
    $('#comentorModal').modal('hide');
  }


  saveNonOSU() {
    const newNonOSUMentor = {
      applicationId: this.applicationId,
      firstName: this.mentorFirstName,
      lastName: this.mentorLastName,
      email: this.mentorEmailAddress
    }

    if (this.isCoMentor) {
      this.applicationService.addNonOSUCoMentor(newNonOSUMentor).subscribe(
        data => {
          this.closeNonOSU();
          this.savedEvent.emit(true);
        },
        error => {
          console.log(`There was an error adding the mentor:<br/>${error.message}`);
        }
      );
    } else {
      this.applicationService.addNonOSUMentor(newNonOSUMentor).subscribe(
        data => {
          this.closeNonOSU();
          this.savedEvent.emit(true);
        },
        error => {
          console.log(`There was an error adding the mentor:<br/>${error.message}`);
        }
      );
    }
  }

  closeNonOSU(): void {
    $('#mentorNonOsuModal').modal('hide');
    $('#comentorNonOsuModal').modal('hide');
  }
}
