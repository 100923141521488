import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-submit',
  template:
  `
  <div class="row">
    <div class="col-md-12">
      <div class="alert alert-success mt-3">
        <div class="row">
          <div class="col-8">
            <p class="mb-0">In order for your completed review to be included in the scoring, you must click 'Submit Review'</p>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-success btn-lg"  (click)="submit()">
              <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> Submit Review
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class SubmitComponent implements OnInit {

  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  submit() {
    this.clicked.next(true);
  }
}
