import { Component, OnInit, Input } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BaseFilterCellComponent, FilterService } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-column-filter',
  template: `

    <kendo-dropdownlist
        [data]="data"
        (valueChange)="onChange($event)"
        [value]="selectedValue"
        [defaultItem]="defaultItem"
        [valuePrimitive]="true"
        [textField]="textField"
        [valueField]="valueField">
    </kendo-dropdownlist>

  `,
  styles: []
})
export class ColumnFilterComponent extends BaseFilterCellComponent {

  @Input() public override filter!: CompositeFilterDescriptor ;
  @Input() public filterField!: string;
  @Input() public data!: any[];
  @Input() public textField!: string;
  @Input() public valueField!: string;

  public get selectedValue(): any {
     
    const filter = this.filterByField(this.valueField);
  
    return filter ? filter.value : null;
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get defaultItem(): any {
    return {
      [this.textField]: 'Show All',
      [this.valueField]: 'Show All'
    };
  }

  public onChange(value: any): void {
    
    this.applyFilter(
      value === 'Show All' ? // value of the default item
        this.removeFilter(this.filterField) : // remove the filter
        this.updateFilter({ // add a filter for the field with the value
          field: this.filterField,
          operator: 'eq',
          value: value
        })
    ); // update the root filter
  }

}
