
<!--
      <div class="container pt-3 pb-5" style="background:transparent">
        <form [formGroup]="adminForm">

          <label for="applicationTypeId" class="control-label"  >Application Type</label>

          <select
            class="form-control mr-1"
            id="applicationTypeId"
            width="80"
            name="applicationTypeId"
            (change)="selectChangeApplicationTypeHandler($event)"
          >
            <option [value]="null" >Select an Application Type</option>
            <option
              *ngFor="let applicationType of applicationTypes"
              [value]="applicationType.applicationTypeId"
            >
            {{applicationType.displayName}}
            </option>
          </select>
          <button id="emailButton" class="btn btn-primary" (click)="sendReviewerInvites()">
            <i class="fa fa-envelope" aria-hidden="false"></i> Send Reviewer Invites
          </button>
         
        </form>
      </div>
    -->

    <div class="container pt-3 pb-5" >
      <div class="row">
        <div class="col-md-6 offset-md-3">
        <label for="applicationTypeId" class="control-label"  >Application Type</label>

        <select
          class="form-control mr-1"
          id="applicationTypeId"
          width="80"
          name="applicationTypeId"
          (change)="selectChangeApplicationTypeHandler($event)"
        >
          <option [value]="null" >Select an Application Type</option>
          <option
            *ngFor="let applicationType of applicationTypes"
            [value]="applicationType.applicationTypeId"
          >
          {{applicationType.displayName}}
          </option>
        </select>
        <button id="emailButton" class="btn btn-primary" (click)="sendReviewerInvites()">
          <i class="fa fa-envelope" aria-hidden="false"></i> Send Reviewer Invites
        </button>
      </div>
      </div> 
    </div>
       
          