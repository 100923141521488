import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';

//import { ReportService } from 'src/app/report/services/report.service';
import { SharedModule } from '../shared/shared.module';

//import { ReviewQuestionComponent } from './components/review-question.component';
import { ReviewValidationComponent } from './components/validation/validation.component';
import { IndexComponent } from './pages/index.component';
import { ReviewComponent } from './pages/review.component';
import { reviewRoutes } from './routes';

import { MyAssignedListComponent } from './components/my-assigned-list.component';
import { OthersReviewComponent } from './components/others-review.component';
import { AllListComponent  } from './components/all-list.component';

import { SubmitComponent } from './components/submit.component';
import { UnsubmitComponent } from './components/unsubmit.component';

@NgModule({
  declarations: [
    IndexComponent,
     ReviewComponent,
     //ReviewQuestionComponent,
     ReviewValidationComponent,
     MyAssignedListComponent,
     OthersReviewComponent,
     AllListComponent,
     
     //SubstituteFinalScoreComponent,
     SubmitComponent,
     UnsubmitComponent
    ],
  // Register the component that will be created dynamically
 // entryComponents: [ AllFinalScoresComponent, SubstituteFinalScoreComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GridModule,
    InputsModule,
    DialogsModule,
    RouterModule.forChild(reviewRoutes)
  ],
  providers: [
    DatePipe,
    //ReportService
]
})
export class ReviewModule { }
