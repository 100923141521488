import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'pagetitle',
    template: `
	<div class="osu-title-container container-fluid no-gutters py-2">
		<div class="row">
			<div class="col-md-9">
				<h1 class="text-light">{{ pageTitle }}</h1>
			</div>
			<div class="col-md-3">
				<div class="the-james-logo float-right d-none d-sm-block"></div>
			</div>
		</div>
  	</div>
  `,
  styles: [`
	.osu-title-container {
		background-image: url(https://ccc.osumc.edu/CDN/img/bg-buckeyepattern.jpg);
		background-color: #343a40;
		margin-bottom:0;
	}
	.osu-title-container h1 {
		line-height: 64px;
	}
	.the-james-logo {
		height: 72px;
		width: 209px;
		background-image: url(https://ccc.osumc.edu/CDN/img/james.png);
		background-repeat: no-repeat;
		background-size: contain;
	}
	`]
})
export class PageTitleComponent implements OnInit {
    @Input()
    pageTitle = '';    
    constructor() {}
    ngOnInit() { }
}