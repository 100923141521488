
<div class="my-4" id="ContactInformationSection" *ngIf="contactInformationForm">

  <h3>Your Information <i *ngIf="savingInProgress" class="fa fa-circle-notch fa-spin fa-2x"></i></h3>

  <div [formGroup]="contactInformationForm">
          
        
      <div class="card border-secondary my-4">
        <div class="card-header">Contact Information</div>
        <div class="card-body">

          <div class="row mb-2">          
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="osuNameDotNumber">OSU Lastname.#</label>
                <input type="text" class="form-control" name="osuNameDotNumber" id="osuNameDotNumber" formControlName="osuNameDotNumber" />
                <div *ngIf="f.osuNameDotNumber?.invalid && (f.osuNameDotNumber?.dirty || f.osuNameDotNumber?.touched)">
                  <small *ngIf="f.osuNameDotNumber?.errors?.required" class="text-danger">OSU Name.# is required</small>
                </div>
              </div>
            </div>
            <div class="col-md-3  ">
              <div class="form-group">
                <label for="applicantFirstName">First Name</label>
                <input type="text" class="form-control" name="applicantFirstName" id="applicantFirstName" formControlName="applicantFirstName" />
                <div *ngIf="f.applicantFirstName?.invalid && (f.applicantFirstName?.dirty || f.applicantFirstName?.touched)">
                  <small *ngIf="f.applicantFirstName?.errors?.required" class="text-danger">First Name is required</small>
                </div>
              </div>
            </div>          
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="applicantLastName">Last Name</label>
                <input type="text" class="form-control" name="applicantLastName" id="applicantLastName" formControlName="applicantLastName" />
                <div *ngIf="f.applicantLastName?.invalid && (f.applicantLastName?.dirty || f.applicantLastName?.touched)">
                  <small *ngIf="f.applicantLastName?.errors?.required" class="text-danger">Last Name is required</small>
                </div>
              </div>
            </div>
          </div>

          <div class="row" mb-2>          
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="applicantEmailAddress">Email Address </label>
                <input type="email" class="form-control" name="applicantEmailAddress" id="applicantEmailAddress" formControlName="applicantEmailAddress" />
                <div *ngIf="f.applicantEmailAddress?.invalid && (f.applicantEmailAddress?.dirty || f.applicantEmailAddress?.touched)">
                  <small *ngIf="f.applicantEmailAddress?.errors?.required" class="text-danger">Email address is required</small>
                  <small *ngIf="f.applicantEmailAddress?.errors?.email" class="text-danger">A valid email address is required</small>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="applicantPhoneNumber">Phone Number</label>
                <input type="text" class="form-control" name="applicantPhoneNumber" id="applicantPhoneNumber" formControlName="applicantPhoneNumber" />
                <div *ngIf="f.applicantPhoneNumber?.invalid && (f.applicantPhoneNumber?.dirty || f.applicantPhoneNumber?.touched)">
                  <small *ngIf="f.applicantPhoneNumber?.errors?.required" class="text-danger">Phone is required</small>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label for="applicantNoOSUEmail">Non OSU Email</label>
                <input type="text" class="form-control" name="applicantNoOSUEmail" id="applicantNoOSUEmail" formControlName="applicantNoOSUEmail" />
                <div *ngIf="f.applicantNoOSUEmail?.invalid && (f.applicantNoOSUEmail?.dirty || f.applicantNoOSUEmail?.touched)">
                  <small *ngIf="f.applicantNoOSUEmail?.errors?.required" class="text-danger">Applicant No OSU Email is required</small>
                </div>
              </div>
            </div>


            <div class="col-md-4">
              <div class="form-group">
                <label for="linkedIn">LinkedIn ( Optional )</label>
                <input type="text" class="form-control" name="linkedIn" id="linkedIn" formControlName="linkedIn" />
               
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="twitter">Twitter ( Optional )</label>
                <input type="text" class="form-control" name="twitter" id="twitter" formControlName="twitter" />
               
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="card border-secondary my-4">
        <div class="card-header">Contact Person Information</div>
        <div class="card-body">

          <div class="row mb-2">          
           
            <div class="col-md-3  ">
              <div class="form-group">
                <label for="contactPersonFirstName">Contact Person First Name</label>
                <input type="text" class="form-control" name="contactPersonFirstName" id="contactPersonFirstName" formControlName="contactPersonFirstName" />
                <div *ngIf="f.contactPersonFirstName?.invalid && (f.contactPersonFirstName?.dirty || f.contactPersonFirstName?.touched)">
                  <small *ngIf="f.contactPersonFirstName?.errors?.required" class="text-danger">Contact Person First Name is required</small>
                </div>
              </div>
            </div>          
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="contactPersonLastName">Contact Person Last Name</label>
                <input type="text" class="form-control" name="contactPersonLastName" id="contactPersonLastName" formControlName="contactPersonLastName" />
                <div *ngIf="f.contactPersonLastName?.invalid && (f.contactPersonLastName?.dirty || f.contactPersonLastName?.touched)">
                  <small *ngIf="f.contactPersonLastName?.errors?.required" class="text-danger">Contact Person Last Name is required</small>
                </div>
              </div>
            </div>
          

                  
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="contactPersonEmail">Contact Person Email  </label>
                <input type="email" class="form-control" name="contactPersonEmail" id="contactPersonEmail" formControlName="contactPersonEmail" />
                <div *ngIf="f.contactPersonEmail?.invalid && (f.contactPersonEmail?.dirty || f.contactPersonEmail?.touched)">
                  <small *ngIf="f.contactPersonEmail?.errors?.required" class="text-danger">Contact Person Email address is required</small>
                  <small *ngIf="f.contactPersonEmail?.errors?.email" class="text-danger">A valid email address is required</small>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="contactPersonPhone">Contact Person Phone </label>
                <input type="text" class="form-control" name="contactPersonPhone" id="contactPersonPhone" formControlName="contactPersonPhone" />
                <div *ngIf="f.contactPersonPhone?.invalid && (f.contactPersonPhone?.dirty || f.contactPersonPhone?.touched)">
                  <small *ngIf="f.contactPersonPhone?.errors?.required" class="text-danger">Contact Person Phone is required</small>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="card border-secondary mb-4">
        <div class="card-header">Home Mailing Address (Not Lab Address)</div>
        <div class="card-body">
          
          <div class="row">
            <div class="col-md-5 ">
              <div class="form-group">
                <label for="mailingAddressCountryId">Mailing Country</label>
                <select class="form-control mr-2" name="mailingAddressCountryId" id="mailingAddressCountryId" formControlName="mailingAddressCountryId" (change)="setMailingCountryValue($event)">
                  <option *ngFor="let country of countrys" [value]="country.countryId">
                    {{country.countryName}}
                  </option>
                </select>
                <div *ngIf="f.mailingAddressCountryId?.invalid && (f.mailingAddressCountryId?.dirty || f.mailingAddressCountryId?.touched)">
                  <small *ngIf="f.mailingAddressCountryId?.errors?.required" class="text-danger">Select a country</small>
                </div>
              </div>
            </div>          
            <div class="col-md-5 " *ngIf="contactInformationForm.controls['mailingAddressCountryId'].value == usa">
              <div class="form-group">
                <label for="mailingAddressStateId">Mailing State</label>
                <select class="form-control mr-2" name="mailingAddressStateId" id="mailingAddressStateId" formControlName="mailingAddressStateId">
                  <option *ngFor="let state of states" [value]="state.stateId">
                    {{state.stateDisplayName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-5 " *ngIf="contactInformationForm.controls['mailingAddressCountryId'].value != usa">
              <div class="form-group">
                <label for="mailingAddressStateProvinceTerritory">Province or Territory</label>
                <input type="text" class="form-control mr-2" name="mailingAddressStateProvinceTerritory" 
					id="mailingAddressStateProvinceTerritory"
                  	formControlName="mailingAddressStateProvinceTerritory">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 ">
              <div class="form-group">
                <label for="mailingAddress1">Mailing Address 1</label>
                <input type="text" class="form-control" name="mailingAddress1" id="mailingAddress1" formControlName="mailingAddress1" />
                <div *ngIf="f.mailingAddress1?.invalid && (f.mailingAddress1?.dirty || f.mailingAddress1?.touched)">
                  <small *ngIf="f.mailingAddress1?.errors?.required" class="text-danger">Mailing Address is required</small>
                </div>
              </div>
            </div>
            <div class="col-md-5 ">
              <div class="form-group">
                <label for="mailingAddress2">Mailing Address 2</label>
                <input type="text" class="form-control" name="mailingAddress2" id="mailingAddress2" formControlName="mailingAddress2" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="mailingAddressCity">Mailing City</label>
                <input type="text" class="form-control" name="mailingAddressCity" id="mailingAddressCity" formControlName="mailingAddressCity" />
                <div *ngIf="f.mailingAddressCity?.invalid && (f.mailingAddressCity?.dirty || f.mailingAddressCity?.touched)">
                  <small *ngIf="f.mailingAddressCity?.errors?.required" class="text-danger">City is required</small>
                </div>
              </div>
            </div>          
            <div class="col-md-3 ">
              <div class="form-group">
                <label for="mailingAddressZipPostalCode">Zip/Postal Code</label>
                <input type="text" class="form-control" name="mailingAddressZipPostalCode" id="mailingAddressZipPostalCode"
                  formControlName="mailingAddressZipPostalCode" />
                  <div *ngIf="f.mailingAddressZipPostalCode?.invalid && (f.mailingAddressZipPostalCode?.dirty || f.mailingAddressZipPostalCode?.touched)">
                    <small *ngIf="f.mailingAddressZipPostalCode?.errors?.required" class="text-danger">Zip Code is required</small>
                  </div>
              </div>          
            </div>
          </div>

        </div>
      </div>
      
          

      <div class="card border-secondary mb-4">
        <div class="card-header">Demographic Information (You must choose an option for each demographic question.)</div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-5  ">
              <div class="form-group" role="radiogroup" aria-labelledby="genderLabel">
                <p class="form-check-label mr-1" id="genderLabel">Gender:</p>
                <input type="radio" [value]="'Male'" aria-label="Male" formControlName="gender"> Male
                <input type="radio" [value]="'Female'" aria-label="Female" formControlName="gender"> Female
				<input type="radio" [value]="'Not Declared'" aria-label="Not Declared" formControlName="gender"> Not Declared
              </div>
              <div *ngIf="f.gender?.invalid && (f.gender?.dirty || f.gender?.touched)">
                <small *ngIf="f.gender?.errors?.required" class="text-danger">Gender is required</small>
              </div>
            </div>
            <div class="col-md-7 ">
              <div class="form-group" role="radiogroup" aria-labelledby="disabilityStatusLabel">
                <p class="form-check-label mr-1" id="disabilityStatusLabel">Disability Status: </p>
                <input type="radio" [value]="'Yes'" aria-label="Yes" formControlName="disabilityStatus"> Yes
                <input type="radio" [value]="'No'" aria-label="No" formControlName="disabilityStatus"> No
                <input type="radio" [value]="'No Answer'" aria-label="No Answer" formControlName="disabilityStatus"> No Answer          
              </div>
              <div *ngIf="f.disabilityStatus?.invalid && (f.disabilityStatus?.dirty || f.disabilityStatus?.touched)">
                <small *ngIf="f.disabilityStatus?.errors?.required" class="text-danger">Disability Status is required</small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-10 ">
              <div class="form-group" role="radiogroup" aria-labelledby="ethnicityLabel">
                <p id="ethnicityLabel">Ethnicity: </p>
                <div class="alert alert-info">Hispanic or Latino is defined as a person of Cuban, Mexican, Puerto Rican,
                  South or Central American, or other Spanish culture or origin, regardless of race.</div>
                <input type="radio" [value]="'Hispanic or Latino'" aria-label="Hispanic or Latino" formControlName="ethnicity"> Hispanic or Latino
                <input type="radio" [value]="'Not Hispanic or Latino'" aria-label="Not Hispanic or Latino" formControlName="ethnicity"> Not Hispanic or Latino
                <input type="radio" [value]="'I do not wish to answer'" aria-label="I do not wish to answer" formControlName="ethnicity"> I do not wish to answer
              </div>   
              <div *ngIf="f.ethnicity?.invalid && (f.ethnicity?.dirty || f.ethnicity?.touched)">
                <small *ngIf="f.ethnicity?.errors?.required" class="text-danger">Ethnicity is required</small>
              </div>       
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 ">
              <div class="form-group">
                <h4 style="text-align:justify">Race: <small>Please choose all which apply</small>
                </h4>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceAmericanIndianOrAlaskaNative"
                    formControlName="raceAmericanIndianOrAlaskaNative">
                  <label class="form-check-label pl-2" for="raceAmericanIndianOrAlaskaNative">American Indian or Alaska Native
                    having origins in any of the original peoples of North and South America (including Central America) who
                    maintains cultural identification through tribal affiliation or community attachment.</label>
                </div>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceAsian" formControlName="raceAsian">
                  <label class="form-check-label pl-2" for="raceAsian">Asian having origins in any of the original peoples of
                    the Far East, Southeast Asia, or the Indian Subcontinent, including, for example, Cambodia, China, India,
                    Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam.</label>
                </div>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceBlackOrAfricanAmerican"
                    formControlName="raceBlackOrAfricanAmerican">
                  <label class="form-check-label pl-2" for="raceBlackOrAfricanAmerican">Black or African American having
                    origins in any of the black racial groups of Africa.</label>
                </div>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceNativeHawaiianOrPacificIslander"
                    formControlName="raceNativeHawaiianOrPacificIslander">
                  <label class="form-check-label pl-2" for="raceNativeHawaiianOrPacificIslander">Native Hawaiian or Pacific
                    Islander having origins in any of the original peoples of Hawaii, Guam, Samoa, or other Pacific
                    Islands.</label>
                </div>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceWhite" formControlName="raceWhite">
                  <label class="form-check-label pl-2" for="raceWhite">White having origins in any of the original peoples of
                    Europe, the Middle East, or North Africa.</label>
                </div>
                <div class="form-check form-check-inline col-12">
                  <input type="checkbox" class="form-check-input" id="raceUndisclosed" formControlName="raceUndisclosed">
                  <label class="form-check-label pl-2" for="raceUndisclosed">I do not wish to disclose.</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              
			  <div class="form-group" role="radiogroup" aria-labelledby="citizenshipStatusLabel">
					<div class="form-label" id="citizenshipStatusLabel">Citizenship Status</div>
				
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" aria-label="Native" [value]="'Native'"
						formControlName="citizenshipStatus" (click)="toggleCitizenshipStatus('Native')" />
						Native
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" aria-label="Alien Permanent" [value]="'Alien Permanent'"
						formControlName="citizenshipStatus" (click)="toggleCitizenshipStatus('Alien Permanent')" />
						Alien Permanent
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" aria-label="Alien Temporary" [value]="'Alien Temporary'"
						formControlName="citizenshipStatus" (click)="toggleCitizenshipStatus('Alien Temporary')" />
						Alien Temporary
					</div>
					<div *ngIf="f.citizenshipStatus?.invalid && (f.citizenshipStatus?.dirty || f.citizenshipStatus?.touched)">
						<small *ngIf="f.citizenshipStatus?.errors?.required" class="text-danger">Citizenship Status is required</small>
					</div>
			  </div>
			</div>
          </div>
      
          <div class="row"
            *ngIf="contactInformationForm.controls['citizenshipStatus'].value != 'Native' && contactInformationForm.controls['citizenshipStatus'].value !=null">
            <div class="col-md-5">
              <div class="form-group">
                <label for="countryOfCitizenshipId">Country Of Citizenship</label>
                <select class="form-control mr-2" name="countryOfCitizenshipId" id="countryOfCitizenshipId"
					formControlName="countryOfCitizenshipId" (change)="setCountryValue($event)">
                  <option *ngFor="let country of countrys" [value]="country.countryId">
                    {{country.countryName}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="contactInformationForm.controls['citizenshipStatus'].value == 'Alien Temporary'">
            <div class="col-md-2 ">
              <div class="form-group">
                <label for="visaTypeId">Visa Type</label>
                <select class="form-control mr-2" name="visaTypeId" id="visaTypeId" formControlName="visaTypeId">
                  <option *ngFor="let visaType of visaTypes" [value]="visaType.visaTypeId">
                    {{visaType.visaTypeName}}
                  </option>
                </select>
              </div>
            </div>          
            <div class="col-md-3">
              <div class="form-group">
                <kendo-label text="Visa Expiration">
					<kendo-datepicker id="visaExpiration" formControlName="visaExpiration"></kendo-datepicker>
				</kendo-label>
              </div>
            </div>
          </div>

        </div>
      </div>
      
      
      <div class="card border-secondary mb-3">
        <div class="card-header">Original Home City or Town</div>
        <div class="card-body">

          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="hometownCountryId">Hometown Country</label>
                <select class="form-control mr-2" id="hometownCountryId" formControlName="hometownCountryId" (change)="setCountryValue($event)">
                  <option *ngFor="let country of countrys" [value]="country.countryId">
                    {{country.countryName}}
                  </option>
                </select>
                <div *ngIf="f.hometownCountryId?.invalid && (f.hometownCountryId?.dirty || f.hometownCountryId?.touched)">
                  <small *ngIf="f.hometownCountryId?.errors?.required" class="text-danger">Hometown is required</small>
                </div>
              </div>
            </div>
          </div>
      
          <div class="row" *ngIf="contactInformationForm.controls['hometownCountryId'].value == usa">          
            <div class="col-md-4">
              <div class="form-group">
                <label for="hometownStateId">Hometown State</label>
                <select class="form-control mr-2" id="hometownStateId" formControlName="hometownStateId" (change)="setStateValue($event)">
                  <option *ngFor="let state of states" [value]="state.stateId">
                    {{state.stateDisplayName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="hometownAddressZipPostalCode">Zip</label>
                <input type="text" class="form-control" name="hometownAddressZipPostalCode" id="hometownAddressZipPostalCode"
                  formControlName="hometownAddressZipPostalCode" />
              </div>
            </div>
            <div class="col-md-3" *ngIf="contactInformationForm.controls['hometownStateId'].value == ohio">
              <div class="form-group">
                <label for="hometownCountyId">Hometown County</label>
                <select class="form-control mr-2" id="hometownCountyId" formControlName="hometownCountyId">
                  <option *ngFor="let county of countys" [value]="county.countyId">
                    {{county.countyName}}
                  </option>
                </select>
              </div>
            </div>
          </div>
      
          <div class="row">          
            <div class="col-md-12">
              <div class="form-group">
                <label for="hometownCity">Hometown City</label>
                <input type="text" class="form-control" name="hometownCity" id="hometownCity" formControlName="hometownCity" />
              </div>
              <div *ngIf="f.hometownCity?.invalid && (f.hometownCity?.dirty || f.hometownCity?.touched)">
                <small *ngIf="f.hometownCity?.errors?.required" class="text-danger">Hometown City is required</small>
              </div>
            </div>
          </div>

        </div>
      </div>
    
      <div class="row">
        <div class="col-12">
            <validation-summary 
                [formGroup]="contactInformationForm"
                [friendlyFormFieldNames]="friendlyFieldNames"></validation-summary>
        </div>
  </div>
                  
<!--
<div class="row mb-1">
 
  <div class="col-md-12">
      <div class="form-label">Citizenship Status</div>
      <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="citizenshipStatus" [value]="'Native'" formControlName="citizenshipStatus" />
          <label class="form-check-label" for="citizenshipStatus"> Native</label>
      </div>
      <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="citizenshipStatus" [value]="'Alien Permanent'" formControlName="citizenshipStatus" />
          <label class="form-check-label" for="citizenshipStatus">Alien Permanent</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" id="citizenshipStatus" [value]="'Alien Temporary'" formControlName="citizenshipStatus" />
        <label class="form-check-label" for="citizenshipStatus">Alien Temporary</label>
    </div>
  </div>
</div>


                <div class="row mb-1" *ngIf="contactInformationForm.controls['citizenshipStatus'].value != 'Native'">  
                     
                      
                  <div class="col-md-10">
                    <div class="form-group">
                      <label  for="countryOfCitizenshipId">Country Of Citizenship</label>
                      <select class="form-control mr-2"  formControlName="countryOfCitizenshipId" (change)="setCountryValue($event)">
                        <option *ngFor="let country of countrys"  [value]="country.countryId" >
                          {{country.countryName}}
                        </option>
                      </select>             
                    </div>
                  </div>
                </div>
                <div class="row " *ngIf="contactInformationForm.controls['citizenshipStatus'].value == 'Alien Temporary'">
                  <div class="col-md-6 ">
                    <div class="form-group">
                      <label for="visaType">Visa Type</label>
                      <input type="text"  class="form-control" name="visaType" formControlName="visaType"/>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="visaExpiration">Visa Expiration</label>
                      <input type="date"  class="form-control" name="visaExpiration" formControlName="visaExpiration"/>
                    </div>
                  </div>
                          
                
               
              </div>
            -->  
