
<!--<div id="assignModal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Assign Reviewers</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="reviewers && assignedReviewers">
          <div class="container">
            <div class="row" *ngFor="let row of reviewers">
              <ng-container *ngFor="let rev of row?.items">
                <div class="form-check col-md-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    [value]="rev.committeeAssignmentId"
                   checked ="rev.checkit" 
                    (click)="changeAssignment($event, rev)"
                  />
                  <label class="form-check-label">
                    {{ rev.reviewerDisplayName }}
                   
                    
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          
          <button type="button" class="btn btn-primary" (click)="close()">Done</button>
        </div>
      </div>
    </div>
  </div>
-->
<div id="assignModal" class="modal fade" tabindex="-1"  role="dialog" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title text-dark">Assign Reviewers To :   {{applicantName}} </h5>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form novalidate #myForm="ngForm" > 
    <kendo-grid
    [data]="gridView"
    [pageSize]="state.take!"
    [skip]="state.skip!"
    [filter]="state.filter!"
    [sort]="state.sort!"
    [sortable]="true"
    [pageable]="false"
    [filterable]="true"
    [reorderable]="true"
    [resizable]="true"
    (edit)="editHandler($event)" (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)" (remove)="removeHandler($event)"
    (dataStateChange)="dataStateChange($event)"
    style="height:800px"
    class="mb-5">

   

    <kendo-grid-column
    field="reviewerDisplayName"
    title="Name"
    [width]="60">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.reviewerDisplayName}}
    </ng-template>
  </kendo-grid-column>

  

  <kendo-grid-column field="hasAssiged" title="Assiged?" [width]="30" filter="boolean">

    <ng-template kendoGridCellTemplate let-dataItem>
      <kendo-switch 
        [onLabel]="'Yes'"
        [offLabel]="'No'"
        [checked]="dataItem.hasAssiged"
        (valueChange)="hasAssigedSwitchChangeHandler($event, dataItem)">
      </kendo-switch>
    </ng-template>

  </kendo-grid-column>
 

<kendo-grid-column 
      title="Set Reviewer Order"
      field="reviewerOrder"
      [width]="40"
      
      [editable]="true"
      [sortable]="false"
      [filterable]="false"
      >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        
        <ng-container >
          <button kendoGridEditCommand class="btn btn-sm btn-primary" title="reviewer Order" [hidden]="!dataItem.hasAssiged">
            <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </ng-container>
        {{dataItem.reviewerOrder}}
       
        <span [id]="'spin' + rowIndex"></span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" >
        {{dataItem.reviewerOrder}}
        <select class="form-control mr-3" id="reviewerOrder" name="reviewerOrder" required
          [(ngModel)]="dataItem.reviewerOrder">
          <option [value]=null>-No Order-</option>
          <optgroup label="Order">
            <option [value]="1">1</option>
            <option [value]="2">2</option>
            <option [value]="3">3</option>           
          </optgroup>         
        </select>

        <button kendoGridSaveCommand class="btn btn-sm btn-primary mt-1 clearfix"
          [disabled]="myForm.invalid! || myForm.pristine! "> Save
        </button>
        <button kendoGridCancelCommand class="btn btn-sm btn-secondary mt-1 ml-1">Cancel</button>
        <span [id]="'spin' + rowIndex"></span>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column
  field="count"
  title="Count"
  [width]="20"
  [editable]="false"
      [sortable]="false"
      [filterable]="false"
  >
  <ng-template  let-dataItem>
  {{dataItem.count}}
  </ng-template>
</kendo-grid-column>

  </kendo-grid>
 
  </form>
  <div class="modal-footer">
          
    <button type="button" class="btn btn-primary" (click)="close()">Done</button>
  </div>
</div>
</div>
</div>

