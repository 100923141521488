
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) { }

    // tslint:disable-next-line:no-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        if (this.authService.isLoggedIn &&
            !req.url.startsWith('https://directory.osu.edu')) {
            
            requestToForward = req.clone({ setHeaders: { Authorization: this.authService.getAuthorizationHeaderValue() } });
        }

        return next.handle(requestToForward).pipe(tap(
            () => { },
            error => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401 && this.authService.isLoggedIn) {
                        this.router.navigate(['/unauthorized']);
                    } else if (error.status === 401) {
                        console.log('AuthInterceptor:401 detected. sending you to log in, and then back to ' + window.location.pathname);
                        localStorage.removeItem('redirectUrl');
                        localStorage.setItem('redirectUrl', window.location.href); // save the url the user was trying to access
                        this.authService.startAuthentication();
                    }
                }
            }
        ));
    }

}
