import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter  } from "@angular/core";
import { ApplicationFullDTO } from "src/app/shared/models/ApplicationFullDTO";
import { ApplicationService } from "../../services/application.service";
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { LookupService } from 'src/app/core/services/lookup.service';
import { debounceTime, take } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
@Component({
    selector: 'application-submission',
    template: `<div class="card shadow-sm my-5" id="ApplicationSubmissionSection" *ngIf="isSubmitted === false && isWithdrawn === false">
                   
                    <div class="card-body" >
                        <div class="row">
                            <div class="col-md-12" >
                                <div class="alert alert-warning border border-warning shadow-sm" *ngIf="readyForSubmission === false">
                                    <p>Your application is not ready for submission. Please complete the missing fields before submitting your application!</p>
                                </div>
                                <div class="alert alert-success border border-success shadow-sm" *ngIf="readyForSubmission === true">
                                    <p>Your application is ready for submission! Please read over the following text and then click the <b>Submit Application</b> button to complete the Scholarship Application Process!</p>
                                    <button class="btn btn-primary" (click)="submitApplication()"><i class="fa fa-share-square"></i> Submit Application</button>
                                </div>
                            </div>

                            

                        </div>


                    </div>

                </div>
                   
                <div class="card shadow-sm my-5" id="ApplicationSubmissionSection" *ngIf="isWithdrawn === true">
                   
                    <div class="card-body" >
                        <div class="row">
                            <div class="col-md-12" >
                                <div class="alert alert-warning border border-warning shadow-sm" >
                                    <p>Your application is Withdrawn . Please UnWithdrawn your application!</p>
                                </div>
                               
                            </div>

                            

                        </div>


                    </div>

                </div>  
                   
            `

    
})
export class ApplicationSubmissionComponent implements OnInit, OnChanges {

    @Input()
    readyForSubmission: boolean | undefined = undefined;
   // @Input()
    //isApplicationSubmitted: boolean | undefined = undefined;
    @Input()
    applicationId: number = 0;
    @Input()
    public application!: ApplicationDTO;

    @Output()
    handleApplicationSubmission = new EventEmitter<{submittedApplication: ApplicationFullDTO}>(true);
    isSubmitted: boolean = false;
    isWithdrawn: boolean = false;
    public appCycleOpen = false;
    constructor(private svc: ApplicationService,private lookupService: LookupService,private toastr: ToastrService, private ConfigService: ConfigService) { }

    ngOnInit() { 
        this.lookupService.currentCycle$.pipe(take(2)).subscribe(
            data => {
              if (data) {
                if ((data.applicationStartDate! <= new Date()) && (data.applicationEndDate! >= new Date())) {
                  this.appCycleOpen = true;
                }
              }
            }
          );
        if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete|| !this.appCycleOpen) {
           
            this.isSubmitted = true;
        } else {
           
            this.isSubmitted = false;
        }
        if(this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN)
        {
            this.isWithdrawn = true;
        }
        else {
            this.isWithdrawn= false;
        }

    }

    ngOnChanges(changes: SimpleChanges) { }

    submitApplication() {
        console.log('submitting application!');
        this.svc.submitApplication(this.applicationId).subscribe(
            data => { 
                this.handleApplicationSubmission.emit({ submittedApplication : data }); 
                window.location.reload();
            },
            error => {
                console.log(JSON.stringify(error));
                this.toastr.error(error.error, 'Error submitting application');
              } 
        );
    }

    backToSummary() {
        const ele = document.getElementById('ApplicaitonStatusSection');
        if (ele) {
            ele.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        }
    }

}