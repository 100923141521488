<pagetitle pageTitle=""></pagetitle>

<div class="container" >
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <div class="form-group">
        <label class="mt-2">Cycle Name:</label>
        <input type="text" class="form-control" id="title"  [(ngModel)]="appCycle.applicationCycleName" />
      </div>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Cycle Start Date:</label>
      <kendo-datepicker id="cycleStartDate" [(ngModel)]="appCycle.cycleStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="cycleEndDate" [(ngModel)]="appCycle.cycleEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Date:</label>
      <kendo-timepicker id="cycleEndDate" [(ngModel)]="appCycle.cycleEndDate">
      </kendo-timepicker>
    </div>
  </div>

  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Application Start Date:</label>
      <kendo-datepicker id="applicationStartDate" [(ngModel)]="appCycle.applicationStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="applicationEndDate" [(ngModel)]="appCycle.applicationEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>&nbsp;</label>
      <kendo-timepicker id="applicationEndDate" [(ngModel)]="appCycle.applicationEndDate">
      </kendo-timepicker>
    </div>
  </div> 
  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>PostDoc Review Start Date:</label>
      <kendo-datepicker id="postdocReviewStartDate" [(ngModel)]="appCycle.postdocReviewStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="postdocReviewEndDate" [(ngModel)]="appCycle.postdocReviewEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Time:</label>
      <kendo-timepicker id="postdocReviewEndDate" [(ngModel)]="appCycle.postdocReviewEndDate">
      </kendo-timepicker>
    </div>
  </div>

  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Graduate Review Start Date:</label>
      <kendo-datepicker id="graduateReviewStartDate" [(ngModel)]="appCycle.graduateReviewStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="graduateReviewEndDate" [(ngModel)]="appCycle.graduateReviewEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Time:</label>
      <kendo-timepicker id="graduateReviewEndDate" [(ngModel)]="appCycle.graduateReviewEndDate">
      </kendo-timepicker>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Undergraduate Review Start Date:</label>
      <kendo-datepicker id="undergraduateReviewStartDate" [(ngModel)]="appCycle.undergraduateReviewStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="undergraduateReviewEndDate" [(ngModel)]="appCycle.undergraduateReviewEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Time:</label>
      <kendo-timepicker id="undergraduateReviewEndDate" [(ngModel)]="appCycle.undergraduateReviewEndDate">
      </kendo-timepicker>
    </div>
  </div>
  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Medical Review Start Date:</label>
      <kendo-datepicker id="medicalReviewStartDate" [(ngModel)]="appCycle.medicalReviewStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="medicalReviewEndDate" [(ngModel)]="appCycle.medicalReviewEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Time:</label>
      <kendo-timepicker id="medicalReviewEndDate" [(ngModel)]="appCycle.medicalReviewEndDate">
      </kendo-timepicker>
    </div>
  </div>

  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Review Meeting Start Date:</label>
      <kendo-datepicker id="reviewMeetingStartDate" [(ngModel)]="appCycle.reviewMeetingStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>Start Time:</label>
      <kendo-timepicker id="reviewMeetingStartDate" [(ngModel)]="appCycle.reviewMeetingStartDate">
      </kendo-timepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="reviewMeetingEndDate" [(ngModel)]="appCycle.reviewMeetingEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Time:</label>
      <kendo-timepicker id="reviewMeetingEndDate" [(ngModel)]="appCycle.reviewMeetingEndDate">
      </kendo-timepicker>
    </div>
  </div>
<!--
  <div class="row my-4">
    <div class="col-md-3 offset-md-2">
      <label>Review Start Date:</label>
      <kendo-datepicker id="reviewStartDate" [(ngModel)]="appCycle.reviewStartDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-3">
      <label>End Date:</label>
      <kendo-datepicker id="reviewEndDate" [(ngModel)]="appCycle.reviewEndDate">
      </kendo-datepicker>
    </div>
    <div class="col-md-2 pl-0">
      <label>End Date:</label>
      <kendo-timepicker id="reviewEndDate" [(ngModel)]="appCycle.reviewEndDate">
      </kendo-timepicker>
    </div>
  </div>
-->
    <div class="row my-4">
      <div class="col-md-3 offset-md-2">
        <label>Reading Phase Start Date:</label>
        <kendo-datepicker id="readingPhaseStartDate" [(ngModel)]="appCycle.readingPhaseStartDate">
        </kendo-datepicker>
      </div>
     
      <div class="col-md-3">
        <label>End Date:</label>
        <kendo-datepicker id="readingPhaseEndDate" [(ngModel)]="appCycle.readingPhaseEndDate">
        </kendo-datepicker>
      </div>
      <div class="col-md-2 pl-0">
          <label>&nbsp;</label>
          <kendo-timepicker id="readingPhaseEndDate" [(ngModel)]="appCycle.readingPhaseEndDate">
          </kendo-timepicker>
        </div>
    </div>
  <div class="row mt-5 pb-5">
    <div class="col-md-8 offset-md-2">
      <button type="button" class="btn btn-primary float-right" (click)="save()">Save</button>
      <button type="button" class="btn btn-secondary mr-3 float-right" (click)="close()">Cancel</button>
    </div>
  </div>
</div>