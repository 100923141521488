import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { AuthService } from './core/services/auth.service';
import { ConfigService } from './core/services/config.service';



@Component({
    templateUrl: 'welcome.component.html',
    selector: 'welcome'
})
export class WelcomeComponent implements OnInit{
  welcomeText = ConfigService.settings.const.WELCOME_MESSAGE;
  landingText = ConfigService.settings.const.LANDING_MESSAGE;
  loginAvailable= ConfigService.settings.const.LOGIN_AVAILABLE;
  
  public offline : boolean = false;
 
    constructor(private toastr: ToastrService, public authSvc: AuthService,
        private router: Router) { }
    public PageTitle: string = "Welcome";
    
    ngOnInit() { 
       // this.toastr.info('Hello, World!')
        this.authSvc.signoutRedirectCallback().then(() => {
            const url: string = this.router.url.substring(
              0,
              this.router.url.indexOf('?')
            );
            this.router.navigateByUrl(url);
          });
          const d = new Date(this.loginAvailable);
          let now = new Date();
          if(d > now)
          { this.offline= true; }
          else{  this.offline=false;}
    }

    login(): void {
        const header = document.getElementById('login')!;
        header.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>';    
        this.authSvc.startAuthentication();
      }
}