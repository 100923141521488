import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FileInfo, FileRestrictions, SelectEvent, SuccessEvent, UploadEvent } from "@progress/kendo-angular-upload";
import { ToastrService } from "ngx-toastr";
import { ApplicationDocumentDTO } from "src/app/shared/models/ApplicationDocumentDTO";
import { ApplciationDocumentService, saveBlob } from "../../services/applicationdocument.service";
import { ApplicationService } from 'src/app/apply/services/application.service';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    template: `<div class="row mb-2">
        <div class="col-md-12" *ngIf="!documentDto">
            <kendo-upload
                [saveUrl]="documentSvc.DocumentUploadUrl"
                (upload)="uploadEventHandler($event)"                
                [multiple]="false"
                [disabled]="disabled"
                [restrictions]="uploadRestrictions"
                (select)="selectEventHandler($event)"
                (success)="successEventHandler($event)"
                [showFileList]="false"
                [ngModel]="fileInfo"
                [ngModelOptions]="{standalone: true}">
                <kendo-upload-messages [select]="message"></kendo-upload-messages>
            </kendo-upload>            
        </div>
        <div class="col-md-12" *ngIf="documentDto">
            <p><i class="far fa-check-circle text-success"></i>&nbsp;<b>Uploaded Document:</b>&nbsp;{{fileName}}</p>
            <p>
                <button class="btn btn-primary btn" [innerHtml]="downloadButtonContent" (click)="beginFileDownload($event.target, documentDto.applicationDocumentId, fileName)"></button>&nbsp;
                <button class="btn btn-secondary btn" [innerHtml]="removeButtonContent" (click)="beginFileDeletion($event.target, documentDto.applicationDocumentId, fileName)" *ngIf="!disabled"></button>
            </p>
        </div>        
    </div>`,    
    selector: 'document-upload'
})
export class FileUploadComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    @Input()
    application!: ApplicationDTO;
    @Input()
    public documentUploadId: number = 0;
    @Input()
    public documentTypeId: number = 0;
    @Input()
    public documentDto: ApplicationDocumentDTO | undefined = undefined;
    @Input()
    public message: string = '';

    disabled: boolean = false;    
    fileName: string = '';
     
    // magic strings.
    private readonly removeDefaultContent = '<i class="fa fa-times-circle"></i>&nbsp;Remove';
    private readonly downloadDefaultContent = '<i class="fa fa-file-download"></i>&nbsp;Download';
    private readonly spinnerContent = '<i class="fa fa-circle-notch fa-spin"></i>';

    downloadButtonContent = this.downloadDefaultContent;
    removeButtonContent = this.removeDefaultContent;

    @Output()
    fileAttached = new EventEmitter<{attached: boolean, documentTypeId: number, documentDto: ApplicationDocumentDTO | undefined}>(true);
    
    public uploadRestrictions: FileRestrictions = {
        allowedExtensions: ['.pdf']
    };

    public fileInfo: FileInfo[] = [];

    uploadEventHandler(args: UploadEvent) {
        args.data = {
            applicationId: this.applicationId,
            documentTypeId: this.documentTypeId
        };
    }

    successEventHandler(args: SuccessEvent) {
        if (args.operation === 'upload') {            
            this.documentDto = args.response.body;
            if (this.documentDto !== undefined) {
                this.getDocumentName(this.documentDto);
                this.fileAttached.emit({attached: true, documentTypeId: this.documentTypeId, documentDto: this.documentDto});
                this.toastr.success(`Document "${this.fileName}" attached to application.`);
            }
          }
    }

    selectEventHandler(args: SelectEvent) {
        if (args.files && args.files[0] && args.files[0].validationErrors) {
            this.toastr.error('Please upload a valid file (.pdf).');
        }
        if (this.documentDto) {
            this.toastr.error('Please delete the existing file before uploading a new document.');
            args.preventDefault();
        }
    }

    private getDocumentName(dto: ApplicationDocumentDTO) {
        let indx: number = dto.documentPhysicalLocation.lastIndexOf('\\');
        this.fileName = dto.documentPhysicalLocation.substring(indx + 1);
    }

    constructor(private toastr: ToastrService,
        public applicationService : ApplicationService,
        public configService : ConfigService,
        public authService: AuthService,
        public documentSvc: ApplciationDocumentService) { }
    
    ngOnInit() { 
        this.message = 'Select File (.pdf only)';
        if (this.documentDto !== undefined) {
            this.getDocumentName(this.documentDto);
            this.fileAttached.emit({attached: true, documentTypeId: this.documentTypeId, documentDto: this.documentDto});
        }
        else {
            this.fileAttached.emit({attached: false, documentTypeId: this.documentTypeId, documentDto: undefined});
        }

        // this.applicationService.getApplication(this.applicationId).subscribe(
        //     data => {
                if(this.authService.isApplicationsAdmin) {
                    this.disabled = false;
                } else if (this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
                    || this.application.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ) {              
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }               
        //     }
        // );                
    }

    beginFileDownload(element: any, id: number, fileName: string) {            
        this.downloadButtonContent = this.spinnerContent;
        element.disabled = true;

        this.documentSvc.download(id).subscribe(
            data => { 
                saveBlob(data, fileName);
                this.downloadButtonContent = this.downloadDefaultContent;
                element.disabled = false;
            }
        );
    }

    beginFileDeletion(element: any, id: number, fileName: string) {
        this.removeButtonContent = this.spinnerContent;
        element.disabled = true;

        this.documentSvc.remove(id).subscribe(
            data => {
                this.documentDto = undefined;
                this.fileName = '';
                this.removeButtonContent = this.removeDefaultContent;
                element.disabled = false;
                this.fileAttached.emit({attached: false, documentTypeId: this.documentTypeId, documentDto: undefined});
                this.toastr.success(`Document "${fileName}" removed from application.`);
            },
            error => {
              console.log(JSON.stringify(error));
              this.toastr.error(error.statusText, 'Error removing document');
            }
        );
    }
}