import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { AcademicBackgroundDTO } from "src/app/shared/models/AcademicBackgroundDTO";

@Injectable({ 
    providedIn: 'root'
})
export class AcademicBackgroundService {
    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
    private baseurl = "/applicationacademicbackground/";
    private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";    
    private updateUrl = this.apiUrl + this.baseurl + "update";
    private addUrl = this.apiUrl + this.baseurl + "add";    
        
    constructor(protected httpClient: HttpClient,
        protected authService: AuthService) { }

    public get(id: number): Observable<AcademicBackgroundDTO> {
        return this.httpClient.get<AcademicBackgroundDTO>(this.getSpecificUrl + id);
        // return this.httpClient.get<AcademicBackgroundDTO>(this.getSpecificUrl + id, { observe: 'response'}).pipe(
        //     switchMap(res => res.status === 200 ? of(res.body!) : of(undefined!))
        // );
    }

    public add(model: AcademicBackgroundDTO): Observable<AcademicBackgroundDTO> {
        return this.httpClient.post<AcademicBackgroundDTO>(this.addUrl, model);
    }

    public update(model: AcademicBackgroundDTO): Observable<AcademicBackgroundDTO> {
        return this.httpClient.post<AcademicBackgroundDTO>(this.updateUrl, model);
    }
}