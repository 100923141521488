<div id="intReviewModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-dark">Add Internal Reviewer</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" *ngIf="applicationTypes">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="applicationTypeId" class="control-label">Select an Application Type</label>
              <select class="form-control" id="applicationTypeId" name="applicationTypeId"
                [(ngModel)]="applicationTypeId">
                <option *ngFor="let applicationType of applicationTypes"
                  [value]="applicationType.applicationTypeId">
                  {{applicationType.displayName}}
                </option>
              </select>
            </div>
  
           
          </div>
  
          <p class="text-center">Add reviewers using the form below. Search the OSU directory by the
          investigator's name or lastname.# using the form below:</p>
  
          <div class="form" name="searchByNameDiv" style="padding:10px">
            <div class="form-row">
              <label for="inputLastName" class="control-label col-md-3">Last Name</label>
              <div class="col-md-9">
                <input type="text" class="form-control" name="inputLastName"
                    [(ngModel)]="inputLastName"
                    (focus)="activateSearchByName()">
              </div>
            </div>
            <div class="form-row">
                <label for="inputFirstname" class="control-label col-md-3">First Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" name="inputFirstName"
                      [(ngModel)]="inputFirstName"
                      (focus)="activateSearchByName()">
                </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 text-center">
              <strong>- or -</strong>
            </div>
          </div>
          <div name="searchByDotNumberDiv"  style="padding:10px">
            <div class="form-row">
              <label for="inputOSUUserName" class="control-label col-md-3">OSU Username</label>
              <div class="col-md-9">
                  <input type="text" class="form-control"
                  [(ngModel)]="inputUserName" name="inputUserName" placeholder="lastname.#" (focus)="activateSearchByDotNumber()">
              </div>
            </div>
          </div>
          <div class="form-row">
              <div class="col-sm-10 offset-md-3">
                  <button id="findPeopleSearch" type="submit" (click)="search()" class="btn btn-primary">Search</button>
              </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <div id="searchResultsDiv" class="text-left" *ngIf="searchResults.length > 0">
  
                <strong *ngIf="searchResults.length > 149">
                  search results exceeded 150 matches not all displayed.  If you don't see your match, please narrow your search.
                </strong>
  
                <strong>Search Results:</strong>
                <p class="text-info">
                  Please select the person from the results below.
                </p>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item" *ngFor="let element of searchResults">
                    <button class="btn btn-success btn-sm"
                        (click)="piSelect(element.email, element.last_name, element.first_name)"
                        [id]="element.username">
                      Select
                    </button>
                    {{element.first_name}} {{element.last_name}}
                    (.{{element.username.split('.')[1]}}) - {{element.appointments.length >0 ? element.appointments[0].organization : '' || ''}}
                    <br/>
                  </li>
                </ul>
              </div>
              <div id="noResultsDiv" *ngIf="searchResults.length == 0 && searchParams.length > 1 && showResults">
                  <strong>No results match your search for {{searchParams}}.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>