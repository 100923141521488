import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AcademicHistoryDTO } from "../../models/AcademicHistoryDTO";
import { AcademicHistoryService } from "../../services/academichistory.service";
import { ApplicationService } from 'src/app/apply/services/application.service';
import { ApplicationDTO } from '../../../shared/models/ApplicationDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
    selector: 'academic-history',
    templateUrl: 'history-list.component.html'
})
export class HistoryListComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    models: AcademicHistoryDTO[] = [];
    public selectedHistoryId: number = 0;
    selectedModel: AcademicHistoryDTO = undefined!;
    disabled: boolean = false;    
   
    public application!: ApplicationDTO;
    constructor(private svc: AcademicHistoryService,
        public applicationService : ApplicationService,
        public configService : ConfigService,
        public authService: AuthService,
        private toastr: ToastrService) {}
    public showForm: boolean = false;

    @Output()
    recordCountEvent = new EventEmitter<number>();


    ngOnInit() {
        this.svc.getAllByApplicationId(this.applicationId).subscribe(
            data => { 
                this.models = data; 
                this.recordCountEvent.emit(data.length);
            }
        );
        this.applicationService.getApplication(this.applicationId).subscribe(
            data => {
                if(this.authService.isApplicationsAdmin)
                {
                this.disabled = false;
                }
            else if (data.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_Complete
                || data.applicationStatusId === ConfigService.settings.const.APPLICATION_STATUS_WITHDRAWN ) {              
                this.disabled = true;
            } else {
                this.disabled = false;
            }               
            }
          );   
    }

    editSelected(id: number) {
        this.selectedHistoryId = id;
        this.showForm = true;
    }

    deleteSelected(id: number) {     
        let dto: AcademicHistoryDTO = {
            applicationAcademicHistoryId: id,
            applicationId: this.applicationId,
            academicInstitution: '',
            degree: '',
            otherDegree: '',
            fieldOfStudy: '',
            yearAwarded: 0,
        };

        this.svc.delete(dto).subscribe(
            data => { 
                this.handleRefresh({}); 
                this.toastr.success("Removed academic history record.");
            }
        );
    }

    addForm() {
        this.showForm = true;
    }

    handleRefresh(args: any) {
        this.ngOnInit();
        this.showForm = false;
        this.selectedHistoryId = 0;
    }
}