import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { IndexComponent } from './pages/index.component';
import { ReviewComponent } from './pages/review.component';

export const reviewRoutes: Routes = [
  {
    path: '',
    redirectTo: 'index',
    pathMatch: 'full'
  },
  {
    path: 'index',
    canActivate: [AuthGuard],
    component: IndexComponent
  },
  {
    path: 'review/:id',
    canActivate: [AuthGuard],
    component: ReviewComponent
  }
];
