import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ApplicationCycleDTO  } from '../../shared/models/ApplicationCycleDTO';

import { AdministrationService } from './administration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IntlService } from '@progress/kendo-angular-intl';



@Component({
  selector: 'app-edit-appcycle',
  templateUrl: 'edit.component.html',
  styles: [`
    label {margin-bottom:0px;}
  `]
})
export class EditAppcycleComponent implements OnInit {

  public appCycleId!: number;

  public appCycle!: ApplicationCycleDTO ;
 
  header = 'Application Cycle';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appCycleService: AdministrationService,
    private toastr: ToastrService,
 
    private intl: IntlService) { 
      //this.appCycle = ApplicationCycleDTO ;
      //this.appCycleId = 0;
    }

  ngOnInit() {
    this.appCycleId = this.route.snapshot.params.id;
    
    if (this.appCycleId > 0) {
      this.header = 'Edit Application Cycle';
      //alert("Edit! "+ this.appCycleId);
      this.appCycleService.getApplicationCycle(this.appCycleId).subscribe(
        data => {
          this.appCycle = data;
          this.appCycle.cycleStartDate = this.appCycle.cycleStartDate;
          this.appCycle.cycleEndDate = this.appCycle.cycleEndDate;
          this.appCycle.applicationStartDate = this.appCycle.applicationStartDate;
          this.appCycle.applicationEndDate = this.appCycle.applicationEndDate;
          
          this.appCycle.reviewStartDate = this.appCycle.reviewStartDate;
          this.appCycle.reviewEndDate = this.appCycle.reviewEndDate;

          this.appCycle.postdocReviewStartDate = this.appCycle.postdocReviewStartDate;
          this.appCycle.postdocReviewEndDate = this.appCycle.postdocReviewEndDate;
          this.appCycle.graduateReviewStartDate = this.appCycle.graduateReviewStartDate;
          this.appCycle.graduateReviewEndDate = this.appCycle.graduateReviewEndDate;
          this.appCycle.undergraduateReviewStartDate = this.appCycle.undergraduateReviewStartDate;
          this.appCycle.undergraduateReviewEndDate = this.appCycle.undergraduateReviewEndDate;
          this.appCycle.medicalReviewStartDate = this.appCycle.medicalReviewStartDate;
          this.appCycle.medicalReviewEndDate = this.appCycle.medicalReviewEndDate;
          this.appCycle.reviewMeetingStartDate = this.appCycle.reviewMeetingStartDate;
          this.appCycle.reviewMeetingEndDate = this.appCycle.reviewMeetingEndDate;
          this.appCycle.readingPhaseStartDate = this.appCycle.readingPhaseStartDate;
          this.appCycle.readingPhaseEndDate = this.appCycle.readingPhaseEndDate;
          
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        }
      );
    } else {
      this.header = 'Add Application Cycle';
      this.appCycle = {
        applicationPeriodId: 0,
        applicationCycleName: '',
        
      };
    }
  }

  save() {
    
    this.appCycleService.saveAppCycle(this.appCycle).subscribe(
      data => {
        this.toastr.success('Application cycle updated', 'Changes Saved', {closeButton: false, timeOut: 3000});
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }

  close(): void {
    //this.appCycleId = undefined;
   // this.appCycle = undefined;
    this.router.navigate(['/admin/index']);
  }
}
