<div class="row">
    <div class="col-md-12">
        <div class="alert alert-info shadow-sm border border-info">
            Please provide an entry for each past academic degree.
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table table-sm table-bordered shawdow-sm">
                    <thead>
                        <tr class="bg-light">
                            <th>Institution</th>
                            <th>Degree</th>
                            <th>Field Of Study</th>
                            <th>Awarded</th>
                            <th>[Actions]</th>
                        </tr>
                        <tr *ngFor="let model of models">
                            <td>{{model.academicInstitution}}</td>
                            <td>{{model.degree}}</td>
                            <td>{{model.fieldOfStudy}}</td>
                            <td>{{model.yearAwarded}}</td>
                            <td>
                                <button class="btn btn-sm btn-primary" (click)="editSelected(model.applicationAcademicHistoryId)" *ngIf="!disabled">Edit</button>&nbsp;
                                <button class="btn btn-sm btn-secondary" (click)="deleteSelected(model.applicationAcademicHistoryId)" *ngIf="!disabled">Remove</button>
                            </td>
                        </tr>
                        <tr *ngIf="models?.length === 0">
                            <td colspan="5">No Degrees entered.</td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="!disabled">
            <div class="col-md-12">
                <button class="btn btn-primary" (click)="addForm()"><i class="fa fa-plus"></i> Add Past Degree</button>
            </div>
        </div>
        <div class="row mt-1" *ngIf="showForm">
            <div class="col-12">
                <history-form [applicationId]="applicationId" [academicHistoryId]="selectedHistoryId" (savedEvent)="handleRefresh($event)"></history-form>
            </div>
        </div>
    </div>
</div>