import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationDTO } from 'src/app/shared/models/ApplicationDTO';
import { ApplicationFullDTO } from "src/app/shared/models/ApplicationFullDTO";
import { ReviewDTO } from '../models/ReviewDTO';
import { ReviewGridDTO } from '../models/ReviewGridDTO';
import { OthersReviewDTO } from '../models/OthersReviewDTO';
import { CommitteeAssignmentDTO } from 'src/app/shared/models/CommitteeAssignmentDTO';
import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { HttpParams } from '@angular/common/http';
import { FinalReviewDTO } from '../models/FinalReviewDTO';
import { FinalReviewGridDTO } from '../models/FinalReviewGridDTO';
import { ApplicationAssignedReviewerGridDTO } from 'src/app/admin/models/ApplicationAssignedReviewerGridDTO';
@Injectable({
  providedIn: 'root'
})
export class ReviewService {

    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
   // private baseAssignedurl = "/applicationassignedreviewer/";
    //private getReviewersForApplicationUrl = this.apiUrl + this.baseAssignedurl+ "assigned";

    private baseurl = "/review/";
    private addReviewerUrl = this.apiUrl + this.baseurl  + "byappid/";
    private getReviewerUrl = this.apiUrl + this.baseurl  + "byid/";
    private updateReviewerUrl = this.apiUrl + this.baseurl  + "update";
    private submitReviewerUrl = this.apiUrl + this.baseurl  + "submit/";
    private unsubmitReviewerUrl = this.apiUrl + this.baseurl  + "unsubmit/";
    private getOthersReviewUrl = this.apiUrl + this.baseurl  + "others/";
    private baseApplicationUrl =  "/application/";
    private getappUrl = this.apiUrl + this.baseurl  + "get/";

    private basefinalurl = "/finalreview/";
    private updateFinalReviewUrl = this.apiUrl + this.basefinalurl + "updatefinalScore";
    private addFinalReviewUrl = this.apiUrl + this.basefinalurl + "addfinalScore";
    private getApplicationAllFinal =  this.apiUrl + this.basefinalurl +"allfinal";
    private getAllApplicationsForMyTypeUrl = this.apiUrl + this.baseurl+ "mytype";
    private getReviewersForApplicationUrl = this.apiUrl + this.baseurl+ "assigned";

    private getAllFinalScoresUrl = this.apiUrl + this.baseurl+"allfinal/";
  constructor(protected httpClient: HttpClient) { }
  public getAssignedApplications(): Observable<ReviewGridDTO[]> {
    return this.httpClient.get<ReviewGridDTO[]>
      (this.getReviewersForApplicationUrl );
  }

  public addReview(applicationId: number): Observable<ReviewDTO> {
    
    return this.httpClient.get<ReviewDTO>(this.addReviewerUrl + applicationId);
  }

  public getReview(reviewId: number): Observable<ReviewDTO> {
    return this.httpClient.get<ReviewDTO>(this.getReviewerUrl+reviewId);
  }

  public updateReview(review: ReviewDTO): Observable<ReviewDTO> {
    return this.httpClient.post<ReviewDTO>(this.updateReviewerUrl, review);
  }
  public submit(reviewId: number): Observable<any> {
    return this.httpClient.get<any>(this.submitReviewerUrl+reviewId);
  }

  public unsubmit(reviewId: number): Observable<any> {
    return this.httpClient.get<any>(this.unsubmitReviewerUrl+reviewId);
  }
  public getApplication(applicationId: number): Observable<ApplicationFullDTO> {
    //alert("reviewService------"+applicationId);  
    return this.httpClient.get<ApplicationFullDTO>(this.getappUrl + applicationId);
  }

  public getReviewsByApplicationId(applicationId: number): Observable<OthersReviewDTO[]> {
    //alert("reviewService------"+applicationId);  
    return this.httpClient.get<OthersReviewDTO[]>(this.getOthersReviewUrl + applicationId);
  }

  public getAllApplicationsInMyType(): Observable<ReviewGridDTO[]> {
    return this.httpClient.get<ReviewGridDTO[]>
      (this.getAllApplicationsForMyTypeUrl );
  }
  public addFinalScore(finalReview: FinalReviewDTO): Observable<FinalReviewDTO> {
    return this.httpClient.post<FinalReviewDTO>(this.addFinalReviewUrl, finalReview);
  }

  public updateFinalScore(finalReview: FinalReviewDTO): Observable<FinalReviewDTO> {
    return this.httpClient.post<FinalReviewDTO>(this.updateFinalReviewUrl, finalReview);
  }

  public adminAddFinalScore(finalReview: FinalReviewGridDTO): Observable<FinalReviewGridDTO> {
    return this.httpClient.post<FinalReviewGridDTO>(this.addFinalReviewUrl, finalReview);
  }

  public adminUpdateFinalScore(finalReview: FinalReviewGridDTO): Observable<FinalReviewGridDTO> {
    return this.httpClient.post<FinalReviewGridDTO>(this.updateFinalReviewUrl, finalReview);
  }

  public getApplicationAllFinalScores(applicationId: number): Observable<FinalReviewDTO[]> {
    return this.httpClient.get<FinalReviewDTO[]>
          (this.getApplicationAllFinal + applicationId);
  }
  public updateSubstituteFinalReview(finalReview: FinalReviewDTO): Observable<FinalReviewDTO> {
    return this.httpClient.post<FinalReviewDTO>(this.addFinalReviewUrl, finalReview);
  }

  public getAllFinalScores(applicationId: number): Observable<FinalReviewGridDTO[]> {
    return this.httpClient.get<FinalReviewGridDTO[]>
      (this.getAllFinalScoresUrl+ applicationId );
  }

/*
  public getAssignedApplications(): Observable<ReviewGridDTO[]> {
    return this.httpClient.get<ReviewGridDTO[]>(`${this.iprApiUrl}/Review/GetAssignedApplications`);
  }
*/


}
