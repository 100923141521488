import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { LookupService } from "src/app/core/services/lookup.service";
import { ApplicationCycleDTO } from "src/app/shared/models/ApplicationCycleDTO";
import { ApplicationDTO } from "src/app/shared/models/ApplicationDTO";
import { ApplicationStatusDTO } from "src/app/shared/models/ApplicationStatusDTO";
import { ApplicationTypeDTO } from "src/app/shared/models/ApplicationTypeDTO";
import { ApplicationService } from "../../services/application.service";
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
    selector: 'type-selection',
    templateUrl: 'type-selection.component.html'
})
export class ApplicationTypeSelectionComponent implements OnInit {
    //@Input()
    public SelectedApplicationType: string = "";
    public ugradGuidelinesAccepted: boolean = false;
    public gradGuidelinesAccepted: boolean = false;
    public postDocGuidelinesAccepted: boolean = false;
    public medicalGuidelinesAccepted: boolean = false;
    private statuses: ApplicationStatusDTO[] | null = null;
    private types: ApplicationTypeDTO[] | null = null;
    private cycle: ApplicationCycleDTO | null = null;

    public postDocType: any;
    public undergraduateType: any;
    public graduateType: any;
    public medicalType: any;
    
    constructor(private lookupSvc: LookupService,
        private applicationSvc: ApplicationService,
        private toastr: ToastrService,
        private router: Router) { }
    ngOnInit() {
        //console.log(this.lookupSvc.applicationTypes$);
        this.lookupSvc.applicationStatuses$.subscribe(data => { this.statuses = data; });
        this.lookupSvc.applicationTypes$.subscribe(data => { this.types = data; });
        this.lookupSvc.currentCycle$.subscribe(data => { this.cycle = data; });

        this.graduateType = ConfigService.settings.const.GraduateType;
        this.medicalType = ConfigService.settings.const.MedicalType;
        this.postDocType = ConfigService.settings.const.PostDocType;
        this.undergraduateType = ConfigService.settings.const.UndergraduateType;

    }

    btnTypeSelectionClick(applicationType: string) {
        this.ugradGuidelinesAccepted = false;
        this.gradGuidelinesAccepted = false;
        this.postDocGuidelinesAccepted = false;
        this.medicalGuidelinesAccepted = false;
        this.SelectedApplicationType = applicationType;
    }

    btnGuidelinesAcceptedClick(applicationType: string) {
        if (this.statuses != null && this.types != null && this.cycle != null)
        {
            //console.log(this.statuses);
            //console.log(this.types);
            
            let statusid = this.statuses.find(m => m.applicationStatusName == "InProgress")!.applicationStatusId;
            let typeid = this.types.find(m => m.applicationTypeName == applicationType)!.applicationTypeId;
    
            let newapp: ApplicationDTO = {
                applicationId: 0,
                applicationStatusId: statusid,
                applicationPeriodId: this.cycle.applicationPeriodId,
                applicationTypeId: typeid,
                applicantUserId: 0,
                guidelinesAccepted: true ,
                createdOn: new Date(),
                modifiedOn: null,
                applicationNumber: '',
                awarded: null,
            };

            this.applicationSvc.addApplication(newapp).subscribe(
                data => { 
                    this.toastr.success(`New ${applicationType} Application Created!`);
                    let currentUrl = this.router.url;
                    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
                    {
                        this.router.navigate([currentUrl]);
                    });
                },
                error => { 
                    this.toastr.error('Unable to create a new Application - please try again later.'); 
                    console.log(JSON.stringify(error));
                }
            );
        }
        else {
            this.toastr.error('Unable to create a new Application - please try again later.');
        }
    }
}