import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WelcomeComponent } from './welcome.component';
//import { CycleComponent } from './cycle.component';
import { ApplicationAdminGuard, AuthGuard,ReviewGuard,SecurityAdminGuard,ModeratorGuard, TrackingGuard } from './core/guards/auth.guard';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'welcome', component: WelcomeComponent }, 
  { path: 'admin', canActivate:[ApplicationAdminGuard, SecurityAdminGuard], loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},
  { path: 'apply', canActivate:[AuthGuard], loadChildren: () => import ('./apply/apply.module').then(m => m.ApplyModule)},
  { path: 'review', canActivate:[ReviewGuard], loadChildren: () => import('./review/review.module').then(m => m.ReviewModule)},
  { path: 'tracking', canActivate:[AuthGuard], loadChildren: () => import('./tracking/tracking.module').then(m => m.TrackingModule)},
  { path: 'moderator', canActivate:[ModeratorGuard], loadChildren: () => import('./moderator/moderator.module').then(m => m.ModeratorModule)},
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: '**', redirectTo: 'welcome', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
