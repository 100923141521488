import { Component, OnInit, ViewChild } from '@angular/core';
//import { AdministrationService } from './administration.service';
import { Router } from '@angular/router';
import { ApplicationCycleDTO } from '../../shared/models/ApplicationCycleDTO';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

import { LookupService } from 'src/app/core/services/lookup.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, process, State } from '@progress/kendo-data-query';
import { OthersReviewComponent } from 'src/app/review/components/others-review.component';
import { ReviewDetailComponent } from 'src/app/moderator/pages/review-detail.component';
import { ReviewGridDTO } from 'src/app/review/models/ReviewGridDTO';
import { ReviewService } from 'src/app/review/services/review.service';
import { ModeratorService } from 'src/app/moderator/services/moderator.service';
import { WindowCloseResult, WindowService } from '@progress/kendo-angular-dialog';
import { ApplicationTypeDTO } from '../../shared/models/ApplicationTypeDTO';
@Component({
  selector: 'app-list-apps',
  templateUrl: './list-apps.component.html',

  styles: []
})
export class ListAppsComponent implements OnInit {

  public allView!: GridDataResult;
  public allState: State = {
    sort: [{
      field: 'avgInitialScore',
      dir: 'asc'
    }]
  };

  public allApplications!: ReviewGridDTO[];
  public applicationTypes!: ApplicationTypeDTO[];
  public loading = true;
  applicationTypeId = 0;
  //public noCurrentCycle = true;

  public currentModScore!: string;
  @ViewChild('reviewDetalModal') reviewDetailModal!: ReviewDetailComponent;
  public windowOpened = false;

  private editedRowIndex!: number;
  private editedScore!: ReviewGridDTO;

  constructor(
    public authService: AuthService,
    private reviewService: ReviewService,
    private moderatorService: ModeratorService,
    private lookupService: LookupService,
    private windowService: WindowService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.lookupService.currentCycle$.subscribe(
      data => {
        if (data) {
          if ((data.reviewStartDate! <= new Date()) && (data.reviewEndDate! >= new Date())) {
            //this.noCurrentCycle = false;
          }
        }
      }
    );

    this.lookupService.applicationTypes$.subscribe(
      data => {
        if (data) { this.applicationTypes = data; }
      }
    );
    //alert("Moderator List ngOnInit()^^^^^^^^^");
    // this.getAllApps();
    this.getAllAppsForType(this.applicationTypeId);
  }

  public allDataStateChange(state: DataStateChangeEvent): void {
    this.allState = state;
    this.allView = process(this.allApplications, this.allState);
  }

  otherReviewer(applicationId: number) {
    //alert("otherReviewer^^^^^^^^^"+applicationId);
    this.reviewDetailModal.init(applicationId);
    //this.othersReviewModal.show();
  }

  onOthersChanged(event: boolean) {

    if (event) {
      this.getAllAppsForType(this.applicationTypeId);
    } else { }
  }

  private resetItem(dataItem: ReviewGridDTO) {
    if (!dataItem) { return; }
    // find orignal data item
    const originalDataItem = this.allApplications.find(item => item.applicationId === dataItem.applicationId);

    // revert changes
    if (originalDataItem) {
      Object.assign(originalDataItem, dataItem);
    }
  }

  private getAllAppsForType(applicationTypeId: any) {
    //alert("Moderator getAllApps^^^^^^^^^"+applicationTypeId);
    this.moderatorService.getAllApplicationsForType(applicationTypeId).subscribe(
      (data2) => {

        this.allApplications = data2;

        this.allView = process(this.allApplications, this.allState);

        // tslint:disable-next-line: quotemark
        const spinners = document.querySelectorAll("[id^='spin']");
        spinners.forEach(function (spinner) {
          spinner.innerHTML = '';
        });


        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error Getting Applications');
      }
    );
  }

  selectChangeApplicationTypeHandler(event: any) {
    this.applicationTypeId = event.target.value;
    //alert("selectChangeApplicationTypeHandler: this.applicationTypeId $$$"+ this.applicationTypeId);

    this.getAllAppsForType(this.applicationTypeId);
  }
}
