import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin , throwError } from 'rxjs';
import { ApplicationDTO } from 'src/app/shared/models/ApplicationDTO';
import { CommitteeAssignmentDTO } from 'src/app/shared/models/CommitteeAssignmentDTO';
import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { HttpParams } from '@angular/common/http';
import { ApplicationAssignedReviewerGridDTO } from 'src/app/admin/models/ApplicationAssignedReviewerGridDTO';

import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssignService {

    private readonly apiUrl = ConfigService.settings.urls.apiUrl;
    private baseurl = "/applicationassignedreviewer/";
    //private baseurl = "/adminapplication/";
    //private getAllUrl = this.apiUrl + this.baseurl + "all";
    //private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";
    //private updateUrl = this.apiUrl + this.baseurl + "update";
    private addUrl = this.apiUrl + this.baseurl + "add"; 
    private baseReviewerurl = "/committeeassignment/";
    private getAllByApplicationType = this.apiUrl + this.baseReviewerurl + "bytype/";  
    private deleteUrl = this.apiUrl + this.baseurl + "delete/"
    private getReviewersForApplicationUrl = this.apiUrl + this.baseurl + "byappid/";

  constructor(protected httpClient: HttpClient) { }

  public getAllReviewersByApplicationType(applicationTypeId: number): Observable<CommitteeAssignmentDTO[]> {
    return this.httpClient.get<CommitteeAssignmentDTO[]>
      (this.getAllByApplicationType+applicationTypeId);
  }

  public getReviewersForApplication(applicationId: number): Observable<ApplicationAssignedReviewerDTO[]> {
    return this.httpClient.get<ApplicationAssignedReviewerDTO[]>
      (this.getReviewersForApplicationUrl + applicationId);
  }

  public addReviewerToApplication(gridDto: ApplicationAssignedReviewerGridDTO ): Observable<any> {
    return this.httpClient.post<any>(this.addUrl,gridDto)
   ;
  }

  /*
  public deleteReviewerFromApplication(applicationAssignedReviewerId: number): Observable<number> {
    return this.httpClient.post<number>
      (this.deleteUrl+applicationAssignedReviewerId,null);
  }
  
*/
  public deleteReviewerFromApplication(applicationAssignedReviewerId: number): Observable<number> {
    return this.httpClient.post<number>(this.deleteUrl+applicationAssignedReviewerId,null)
    .pipe(
     // retry(1),
       catchError(this.handleError)
    );
  }

  public getReviewersOfApplicationAndType(applicationId: number, applicationTypeId: number): Observable<ApplicationAssignedReviewerGridDTO[]> {
    return this.httpClient.get<ApplicationAssignedReviewerGridDTO[]>
      (this.getReviewersForApplicationUrl+applicationId+"/"+applicationTypeId );
    
  
  }

  
  handleError(error:any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error.error);
  }
}
