import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { IndexComponent } from "./pages/index.component";
import { CommonModule, DatePipe } from '@angular/common';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { adminRoutes } from "./routes";

import { EditAppcycleComponent } from './applicationCycle/edit.component';
import { ListAppcycleComponent } from './applicationCycle/list.component';
import { ApplicationListComponent } from './application-list/application-list.component';
import { CycleComponent } from './pages/cycle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ComboBoxModule, DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { BrowserModule } from '@angular/platform-browser';
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ListReviewersComponent } from './committee-assignment/list-reviewers.component';
import { AddCommitteeAssignmentComponent } from './committee-assignment/add-committee-assignment.component';
import { AddReviewerComponent } from './committee-assignment/add-reviewer.component';
import { ReviewersComponent } from './pages/reviewers.component';
import { AssignReviewersComponent } from './assign-reviewers/assign-reviewers.component';
import { AllFinalScoresComponent } from 'src/app/admin/application-list/all-final-scores.component';
import { EmailsComponent } from './emails/emails.component';
import { DownloadsComponent } from './downloads/downloads.component';
@NgModule({
    declarations: [
        IndexComponent,
        ListAppcycleComponent,
        EditAppcycleComponent,
        CycleComponent,
        ApplicationListComponent,
        ListReviewersComponent,
        AddCommitteeAssignmentComponent,
        AddReviewerComponent,
        ReviewersComponent,
        AssignReviewersComponent,
        AllFinalScoresComponent,
        EmailsComponent,
        DownloadsComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        DateInputsModule,
        InputsModule,
        SwitchModule,
        ComboBoxModule,
        DropDownListModule,
        GridModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        RouterModule.forChild(adminRoutes)
    ],
    providers: [
        DatePipe
    ]
})
export class AdminModule { }