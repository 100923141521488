import { Component, OnInit } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { Router } from '@angular/router';

import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor, State, process } from '@progress/kendo-data-query';

import { ReviewService } from '../services/review.service';
import { ToastrService } from 'ngx-toastr';
// TODO: don't like using a service from a different module

import { AuthService } from 'src/app/core/services/auth.service';
import { LookupService } from 'src/app/core/services/lookup.service';


declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: 'index.component.html',
  styles: []
})
export class IndexComponent implements OnInit {

  public mineShown = true;
  public allShown = false;

  currentCycleLabel = '';

  constructor(
    private datePipe: DatePipe,
    private lookupService: LookupService,
    //private reportService: ReportService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {

    this.lookupService.currentCycle$.subscribe(
      data => {
        if (data) {
          if (data.applicationPeriodId !== 0) {
            this.currentCycleLabel = 'Reviews Due: ';
            this.currentCycleLabel += this.datePipe.transform(data.reviewEndDate, 'MMM d, y');
          } else {
            this.currentCycleLabel = 'There is no current application cycle';
          }
        }
      }
    );

  }


  public showMine() {
    
    $('#reviewTabs li:first-child a').tab('show');
    this.mineShown = true;
    this.allShown = false;
  }

  public showAll() {
    //alert("showAll()^^^^^^^^^");
    $('#reviewTabs li:nth-child(2) a').tab('show');
    //alert("22222   showAll()^^^^^^^^^");
    this.allShown = true;
    this.mineShown = false;
  }

}
