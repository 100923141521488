import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { HttpParams } from '@angular/common/http';
import { OthersReviewDTO } from 'src/app/review/models/OthersReviewDTO';
import { ReviewDTO } from 'src/app/review/models/ReviewDTO';
import { ConfigService } from 'src/app/core/services/config.service';
import { ReviewService } from '../services/review.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/core/services/auth.service';
declare var $: any;
@Component({
    selector: 'app-others-review',
    templateUrl: 'others-review.component.html',
    styles: []
})
export class OthersReviewComponent implements OnInit {
    @Input()
    public applicationId: number = 0;
    @Output() othersChanged = new EventEmitter<boolean>(true);
    public gridView!: GridDataResult;
    models: OthersReviewDTO[] = [];
   
    disabled: boolean = false;    
    public state: State = {
        skip: 0,
        sort: [{
          field: 'reviewerOrder',
         // dir: 'desc'
        }]
      };
    //public reviewGridDTO!: ReviewGridDTO;
    constructor(private svc: ReviewService,
       public authService: AuthService,
        public configService : ConfigService,
        private toastr: ToastrService) {}
    public showForm: boolean = false;

   


    ngOnInit() {}

    init(applicationId: number): void {
       // alert("otherReviewer init()^^^^^^^^^"+applicationId);
       this.applicationId = applicationId;
       this.getReviewsByApplicationId(this.applicationId); 
    }
    

    private getReviewsByApplicationId(applicationId:number){
        //alert("getReviewsByApplicationId^^^^^^^^^"+applicationId);
        this.svc.getReviewsByApplicationId(applicationId).subscribe(
            data => { 
                this.models = data; 
                this.gridView = process(this.models, this.state);
                this.show();
            }
        );
    }

    private loadItems(): void {
        //alert("loadItems  in others");
        this.gridView = process(this.models, this.state);
      }
    
     show(): void {
        //alert("show  in others");
        $('#othersReviewModal').modal('show');
      }
    
      close(): void {
       // alert("Close &&&&&&&&");
        $('#othersReviewModal').modal('hide');
        this.applicationId = undefined!;
        //this.reviewers = [];
        //this.locReviewers = [];
        this.models = [];
        this.othersChanged.emit(true);
      }
   
}