
<div class="container-fluid">
  <div class="row">
    <h2>Scholars - All applications</h2>
  </div>

  <div class="row">
      
    <app-list-apps ></app-list-apps> 

  </div>

  <div class="bg-dark text-white my-5 collapse" id="debugCard">
    <div class="card-body">
      
    </div>
  </div>
</div>