<button class="skip-link" [routerLink]="[currentUrl]" [fragment]="'main'">Skip to main content</button>

<header>
	<nav class="navbar navbar-expand-md navbar-dark bg-dark">
		<a [routerLink]="['/']" class="navbar-brand text-light" title="Pelotonia Scholars - Homepage"><img src='/assets/img/nav_icon.png' alt="OSU Block-O logo" height='30' class='mr-1' /> Pelotonia Scholars</a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarNav">
			<ul class="navbar-nav">        
				<li class="nav-item" *ngIf="authSvc.isLoggedIn ">
				<a class="btn btn-primary" [routerLink]="['/apply']">
					<i class="fa fa-edit"></i> Apply</a>
				</li>
				<li class="nav-item" *ngIf="authSvc.isApplicationsAdmin || authSvc.isSecurityAdmin">
				<a class="nav-link text-light" [routerLink]="['/admin']">
					<i class="fa fa-gears"></i> Admin</a>
				</li>
				<li class="nav-item" *ngIf="authSvc.isTrackingAdmin && showTracking">
				<a class="nav-link text-light" [routerLink]="['/tracking']">
					<i class="fa-solid fa-chart-column"></i> Tracking</a>
				</li>
				<li class="nav-item" *ngIf="authSvc.isLoggedIn && ( authSvc.isApplicationsAdmin ||authSvc.isReviewer) ">
				<a class="nav-link text-light" [routerLink]="['/review']">
					<i class="fa fa-gears"></i> Review</a>
				</li>
				<li class="nav-item" *ngIf="authSvc.isLoggedIn && ( authSvc.isApplicationsAdmin ||authSvc.isModerator) ">
				<a class="nav-link text-light" [routerLink]="['/moderator']">
					<i class="fa fa-gears"></i> Moderator</a>
				</li>
			</ul>
			<div class="ml-auto" *ngIf="authSvc.isLoggedIn">
				<a href="javascript://nop" class="text-light mr-3" (click)="logout()" title="Logout">
				Welcome, {{ authSvc.userName }}
				</a>
			</div>
			<div class="ml-auto" *ngIf="!authSvc.isLoggedIn && offline === false ">
				<button class="btn btn-primary" id="loginHeader" (click)="login()">Sign in</button>
			</div>
		</div>    

	</nav>
</header>

<main id="main">
  <router-outlet></router-outlet>
</main>

<app-footer></app-footer>

