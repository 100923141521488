import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { debounceTime } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/services/config.service';
import { LookupService } from 'src/app/core/services/lookup.service';
//import { ApplicationDTO } from 'src/app/shared/models/ApplicationDTO';
import { ApplicationFullDTO } from "src/app/shared/models/ApplicationFullDTO";
import { ReviewDTO } from '../models/ReviewDTO';
import { ReviewService } from '../services/review.service';
import { ApplicationService } from 'src/app/apply/services/application.service';

@Component({
  selector: 'app-review',
  templateUrl: 'review.component.html',
  styles: [
    `
      label {
        margin-top: 0.8em;
        margin-bottom: 0px;
      }
      dt {
        float: left;
        width: 35%;
        margin-right: 5px;
      }
      .input-group-append {
        user-select: none;
      }
    `
  ]
})
export class ReviewComponent implements OnInit {
  review!: ReviewDTO;

  application!: ApplicationFullDTO;

  reviewForm!: UntypedFormGroup;

  public appCycleOpen = false;

  public disabled = false;

  private wiegtedscore=0.00;

  public applicationType: string;
  public friendlyFieldNames: Record<string, string> = { };
  public Postdoc : string;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private reviewService: ReviewService,
    private applicationService: ApplicationService,
    private lookupService: LookupService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const reviewId = this.route.snapshot.params.id;
    this.Postdoc =  ConfigService.settings.const.Postdoc;
    this.reviewService.getReview(reviewId).subscribe((data) => {
      if (data) {
        this.review = data;  
        //alert("review %%%%%% "+this.review.reviewId+ "appID is &&&&"+data.applicationAssignedReviewer?.applicationId);
        this.applicationService.getFullApplication(data.applicationAssignedReviewer?.applicationId).subscribe((app) => {
          this.application = app;
          this.applicationType = app.applicationType;
          //alert("reviewService.getApplication------"+this.application.applicationNumber);  
          this.initializeForm(this.review);
        },
        
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        });
      }
    },
    error => {
      console.log(JSON.stringify(error));
      this.toastr.error(error.statusText, 'Error');
    });
    
    

    this.lookupService.currentCycle$.subscribe((data) => {
      // TODO: Do I need to make sure we're in the review period?
      if (data) {
        this.appCycleOpen = true;
      }
    });
  }

  public save() {
    const result: ReviewDTO = Object.assign({}, this.reviewForm.value);
    console.log('going to save: ' + JSON.stringify(result));
    this.reviewService.updateReview(result).subscribe(
      (data) => {
        this.review = data;
        this.reviewForm.markAsPristine();
        this.toastr.success('Changes saved', undefined, { closeButton: false, timeOut: 3000 });
      },
      (error) => {
        // If the save fails, stick it in LocalStorage
        localStorage.setItem('review-' + this.review.reviewId , JSON.stringify(result));
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error Saving');
      }
    );
    
  }

  
  public canSubmit(): boolean {
    if (this.appCycleOpen && this.reviewForm.valid && !this.reviewForm.dirty) {
      return true;
    } else {
      return false;
    }
  }

  public submit() {
    console.log('going to submit: ' + this.review.reviewId);
    this.reviewService.submit(this.review.reviewId).subscribe(
      data => {
        this.toastr.success('Changes saved', undefined, { closeButton: false, timeOut: 3000 });
        window.location.reload();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }

  public unsubmit() {
    this.reviewService.unsubmit(this.review.reviewId).subscribe(
      data => {
        this.toastr.success('Changes saved', undefined, {closeButton: false, timeOut: 3000});
        window.location.reload();
      }
    );
  }
  public canUnsubmit(): boolean {
    if (this.appCycleOpen && this.review.submit) {
      return true;
    } else {
      return false;
    }
  }
  private initializeForm(review: ReviewDTO) {
    //alert("Application type is ******"+this.application.applicationType);
    if (review.submit === true) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
    if(this.applicationType === ConfigService.settings.const.Postdoc){
      //alert("initializeForm in PostDoc");
      this.wiegtedscore= review.proposalScore!*0.3333+review.mentorScore!*0.3333+review.applicantScore!*0.3334;
    }
    else {
      this.wiegtedscore= review.proposalScore!*0.2+review.mentorScore!*0.4+review.applicantScore!*0.4;
    }
//alert("in initializeForm() this.wiegtedscore ===="+this.wiegtedscore);
    this.reviewForm = this.fb.group({
      reviewId: [{ value: review.reviewId, disabled: this.disabled }],
      applicationAssignedReviewerId: [{ value: review.applicationAssignedReviewer.applicationAssignedReviewerId, disabled: this.disabled }],
      initialScore: [
        { value: review.initialScore, disabled: this.disabled },
        [Validators.required, Validators.min(1), Validators.max(9)]
      ],

      applicantScore: [
        { value: review.applicantScore, disabled: this.disabled },
        [Validators.required, Validators.min(1), Validators.max(9)]
      ],
      mentorScore: [
        { value: review.mentorScore, disabled: this.disabled },
        [Validators.required, Validators.min(1), Validators.max(9)]
      ],
      proposalScore: [
        { value: review.proposalScore, disabled: this.disabled },
        [Validators.required, Validators.min(1), Validators.max(9)]
      ],
      
      weightedScore: [
        { value: this.wiegtedscore, disabled: this.disabled },
       //{ value: review.proposalScore!*0.2+review.mentorScore!*0.4+review.applicantScore!*0.4, disabled: this.disabled },
        [Validators.required]
      ],
      strength: [
        { value: review.strength, disabled: this.disabled },
        Validators.required
      ],
      weakness: [
        { value: review.weakness, disabled: this.disabled },
        Validators.required
      ],
      additionalComments: [
        { value: review.additionalComments, disabled: this.disabled },
        
      ],
    });

    this.friendlyFieldNames['initialScore'] = 'Initial Score '; 
    this.friendlyFieldNames['applicantScore'] = 'Applicant Score ';   
    this.friendlyFieldNames['mentorScore'] = 'Mentor Score ';   
    this.friendlyFieldNames['proposalScore'] = 'Proposal Score ';   
    this.friendlyFieldNames['strength'] = 'Strength '; 
    this.friendlyFieldNames['weakness'] = 'Weakness '; 

    if (!this.disabled) {
      this.watchForChanges();
    }
  }
/*
  public canUnsubmit(): boolean {
    if (this.appCycleOpen && this.review.submit) {
      return true;
    } else {
      return false;
    }
  }

  public unsubmit() {
    this.reviewService.unsubmit(this.review.reviewId).subscribe(
      data => {
        this.toastr.success('Changes saved', undefined, {closeButton: false, timeOut: 3000});
        window.location.reload();
      }
    );
  }
*/

calculate(): void {
    const {mentorScore, proposalScore,applicantScore } = this.reviewForm.value;
     //alert("in Calculate type is ^^^^"+this.applicationType);
     if(this.applicationType === ConfigService.settings.const.Postdoc){
      //alert("in PostDoc");
      this.reviewForm.get('weightedScore')!.setValue(mentorScore*0.3333 + proposalScore*0.3333+applicantScore*0.3334);
     }
     else {
     
    this.reviewForm.get('weightedScore')!.setValue(mentorScore*0.4 + proposalScore*0.2+applicantScore*0.4);
     }
  }
  private watchForChanges() {
    console.log('watching ...');
    // any changes

    this.reviewForm.valueChanges.pipe(debounceTime(2000)).subscribe(() => {
      this.save();
    });
  }
}
