import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable , BehaviorSubject} from 'rxjs';

import { AuthService } from 'src/app/core/services/auth.service';
import { ApplicationFullDTO } from 'src/app/shared/models/ApplicationFullDTO';
import { ApplicationStatusDTO } from 'src/app/shared/models/ApplicationStatusDTO';

import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationCycleDTO } from '../../shared/models/ApplicationCycleDTO';
import { DataSourceRequestState } from '@progress/kendo-data-query';
@Injectable({
  providedIn: 'root'
})
export class ApplicationListService {
  
  private readonly apiUrl = ConfigService.settings.urls.apiUrl;
  // private baseurl = "/admin/";
  //private getAllUrl = this.apiUrl + this.baseurl + "all";
  //private getSpecificUrl = this.apiUrl + this.baseurl + "byid/";
  //private updateUrl = this.apiUrl + this.baseurl + "update";
  private baseappurl = "/application/";
  private baseurl = "/adminapplication/";
  private getCurrentByCycleUrl = this.apiUrl + this.baseurl + "current/bycycle/";
  //private awardedUrl = this.apiUrl + this.baseurl + "awarded/";
  private awardedUrl  =  this.apiUrl + this.baseurl + "awarded/";  
  private deniedUrl =  this.apiUrl + this.baseurl + "denied/"; 
  private pdfUrl =  this.apiUrl + this.baseappurl + "getpdf/"; 

  state: DataSourceRequestState = {
    skip: 0,
    take: 50,
    sort: [{
      field: 'application.applicationPeriod',
      dir: 'desc'
    }]
  };


  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService
  ) { }

 /*
public getCurrentApplications(applicationCycleId: number): Observable<ApplicationFullDTO[]> {
  return this.httpClient.get<ApplicationFullDTO[]>(this.getCurrentByCycleUrl + applicationCycleId + "/all");
}
*/
public getCurrentApplications(): Observable<ApplicationFullDTO[]> {
  return this.httpClient.get<ApplicationFullDTO[]>(this.getCurrentByCycleUrl  + "all");
}

  
/*
  public awardedApproveDenied(applicationId: number, funded: string): Observable<ApplicationFullDTO> {
    //return this.httpClient.get<ApplicationFullDTO>(this.awardedUrl+applicationId+"/"+funded);
    return this.httpClient.get<ApplicationFullDTO>(this.awardedUrl+applicationId);    
  }
  */
  /*public getAwaredApplication(applicationId: number,award: boolean): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.getUrl + applicationId,null);
  }
*/


public getAllFinalScores(): Observable<ApplicationFullDTO[]> {
  return this.httpClient.get<ApplicationFullDTO[]>(this.getCurrentByCycleUrl  + "all");
}
  public awaredApplication(applicationId: number,award: boolean): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.awardedUrl+ applicationId,null);
  }

  public deniedApplication(applicationId: number,award: boolean): Observable<ApplicationFullDTO> {
    return this.httpClient.post<ApplicationFullDTO>(this.deniedUrl+ applicationId,null);
  }

 
/*
  public getPdf(applicationId: number): Observable<Blob> {
    return this.httpClient.get(`${this.iprApiUrl}/Application/GetPdf?applicationId=${applicationId}`,
              {responseType: 'blob'});
  }
*/
}