<pagetitle pageTitle="Scholars Application - Apply Here"></pagetitle>

<div class="container container-content p-3 shadow">    
    <div class="row"  *ngIf="loadingCycle === true && (authService.isApplicationsAdmin || hasOpenSubmissionCycle === true )">  
        <div class="col-12 text-center">
            <i class="fa fa-circle-notch fa-spin"></i> Loading Scholars Application...
        </div>
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="loadingCycle === false && (hasOpenSubmissionCycle === true || authService.isApplicationsAdmin)">
        <i class="fa-solid fa-circle-exclamation fa-xl"></i>Thank you for your interest in applying for a Pelotonia Research Award. Applications are due by {{applicationDueDateMessage}}. Please NOTE: <b>All materials and letters from the mentor, co-mentor and recommender are due at time of submission.</b>
    
      </div>
    <ng-container *ngIf="loadingCycle === false && currentApplication && currentApplication.application && (hasOpenSubmissionCycle === true || authService.isApplicationsAdmin)">

        <application-status 
            [application]="currentApplication.application"
            [contactInfoSectionState]="contactInfoSectionState"
            [academicBackgroundSectionState]="academicBackgroundSectionState"
            [mentorSectionState]="mentorSectionState"
            [researchProposalSectionState]="researchProposalSectionState"
            (readyForSubmissionEvent)="handleReadyForSubmissionEvent($event)"
            (applicationTypeChanged)="applicationTypeChanged($event)">
        </application-status>

    </ng-container>

    <kendo-stepper #stepper
        [steps]="steps"
        stepType="full"
		aria-label="Application form section navigation"
        [linear]="false"
        (currentStepChange)="currentStepChange($event)"
        [(currentStep)]="currentStep" *ngIf="hasOpenSubmissionCycle === true || authService.isApplicationsAdmin">
    </kendo-stepper>

    <ng-container *ngIf="loadingCycle === false && currentApplication && currentApplication.application && (hasOpenSubmissionCycle === true || authService.isApplicationsAdmin)">

        <div class="row" style="display:none;">
            <div class="col-md-12">
                <application-status 
                    [application]="currentApplication.application"
                    [contactInfoSectionState]="contactInfoSectionState"
                    [academicBackgroundSectionState]="academicBackgroundSectionState"
                    [mentorSectionState]="mentorSectionState"
                    [researchProposalSectionState]="researchProposalSectionState"
                    (readyForSubmissionEvent)="handleReadyForSubmissionEvent($event)"></application-status>
            </div>
        </div>

        <div class="row" [hidden]="currentStep !== 0" >
            <div class="col-md-12">
                <app-contact-information
                [applicationId]="currentApplication.application.applicationId" 
                [application]="currentApplication.application"
                (formGroupValidationMonitorEvent)="handleSectionIsValidMonitor($event)"></app-contact-information>
            </div>
        </div>

        <div class="row" [hidden]="currentStep !== 1">
            <div class="col-md-12">
                <academic-background
                    [applicationId]="currentApplication.application.applicationId" 
                    [application]="currentApplication.application"
                    [applicationTypeId]="currentApplication.application.applicationTypeId"
                    [biosketchDocument]="biosketchDocument"
                    (formGroupValidationMonitorEvent)="handleSectionIsValidMonitor($event)"></academic-background>
            </div>
        </div>

        <div class="row" [hidden]="currentStep !== 2">
            <div class="col-md-12">
                <app-mentor
                [applicationId]="currentApplication.application.applicationId" 
                [application]="currentApplication.application"
                [mentorRecommenderDto]="currentApplication.mentorRecommender"
                [mentorBiosketchDocument]="mentorBiosketchDocument"
                [mentorSupportDocument]="mentorSupportDocument"
                [coMentorBiosketchDocument]="coMentorBiosketchDocument"
                
                [mentorTrainingDocument]="mentorTrainingDocument"
                [coMentorTrainingDocument]="coMentorTrainingDocument" 
                [recommederSupportDocument]="recommederSupportDocument"
                (formGroupValidationMonitorEvent)="handleSectionIsValidMonitor($event)"
                ></app-mentor>
            </div>
        </div>

        <div class="row" [hidden]="currentStep !== 3">
            <div class="col-md-12">
                <research-proposal
                    [applicationId]="currentApplication.application.applicationId" 
                    [application]="currentApplication.application"
                    [researchProposalDto]="currentApplication.researchProposal" 
                    [researchProposalDocument]="researchProposalDocument" 
                    [rebuttalLetterDocument]="rebuttalLetterDocument" 
                    [resubmissionCritiqueDocument]="resubmissionCritiqueDocument"
                    (formGroupValidationMonitorEvent)="handleSectionIsValidMonitor($event)"></research-proposal>
            </div>
        </div>

        <div class="row" [hidden]="currentStep !== 4">
            <div class="col-md-12">
                <application-submission
                    [applicationId]="currentApplication.application.applicationId"
                    [application]="currentApplication.application"
                    [readyForSubmission]="readyForSubmission"
                    (handleApplicationSubmission)="handleApplicationSubmission($event)">
                </application-submission>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="k-form-buttons k-buttons-end">

                    <span style="line-height:2em">Step {{ currentStep + 1 }} of 5</span>
                    
                    <button class="btn btn-sm btn-secondary mr-2" *ngIf="currentStep !== 0" (click)="prev()">
                        Previous
                    </button>
                    <button class="btn btn-sm btn-primary" (click)="next()" *ngIf="currentStep !== 4">
                        Next
                    </button>                        
                </div>
            </div>
        </div>
    </ng-container>

    <div class="row" *ngIf="loadingCycle === false && hasOpenSubmissionCycle === false">
        <div class="col-md-12">
            <div class="alert alert-warning border border-warning shadow-sm">
                The current application submission window is closed.
            </div>
        </div>
    </div>
    
    <div class="row" *ngIf="loadingCycle === false && hasOpenSubmissionCycle === true && !currentApplication">
        <div class="col-md-12">
            <type-selection></type-selection>
        </div>
    </div>

</div>