import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-unsubmit',
  template:
  `
  <div class="row" *ngIf="submitted">
    <div class="col-md-12 my-3">
      <div class="alert alert-success" role="alert">
        <div class="row">
          <div class="col-8">
            <p class="mb-0 font-weight-bold">Your review has been submitted.</p>
            <p class="mb-0" *ngIf="canUnsubmit">If you need to make additions or corrections, you can UnSubmit.</p>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-warning btn-lg" *ngIf="canUnsubmit" (click)="unsubmit()">
              <i class="fa fa-undo" aria-hidden="true"></i> UnSubmit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: []
})
export class UnsubmitComponent implements OnInit {

  @Input() submitted!: boolean;

  @Input() canUnsubmit!: boolean;

  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  unsubmit() {
    this.clicked.next(true);
  }

}
