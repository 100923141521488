import { Component } from "@angular/core";

@Component({
    selector: 'return-to-status',
    template: `<div class="float-right">
        <button alt="Return to Application Summary" title="Return to Application Symmary" class="btn btn-primary" (click)="sendToStatusClick()"><i class="fa fa-arrow-circle-up"></i></button>
    </div>`
})
export class ReturnToStatusComponent {
    
    sendToStatusClick() {
        const ele = document.getElementById('ApplicaitonStatusSection');
        if (ele) {
            ele.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        }
    }
}