<div [formGroup]="prevSubFG">    
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="alert alert-info border border-info shadow-sm">
                All form fields are required; please enter values into all form fields to save a previous application submission record.
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <!--<div class="form-group">
                <label for="degree">Application Type</label>
                <select name="degree"                
                    class="form-control" 
                    name="applicationTypeId"
                    formControlName="applicationTypeId">
                        <option value="">Please Select</option>
                        <option [value]="1">Undergraduate</option>
                        <option [value]="2">Graduate</option>
                        <option [value]="3">Post Doc</option>
                </select>                
                <p *ngIf="f.applicationTypeId?.invalid && (f.applicationTypeId?.dirty || f.applicationTypeId?.touched || isFormValid == false)">
                    <small class="text-danger" *ngIf="f.applicationTypeId?.errors?.required">Application Type is required.</small>
                </p>
            </div>-->
            <div class="form-group">
                <label for="applicationTypeId">Application Type</label>
                  <select class="form-control mr-2"  formControlName="applicationTypeId" >
                    <option *ngFor="let applicationType of applicationTypes" [value]="applicationType.applicationTypeId" >
                      {{applicationType.displayName}}
                    </option>
                  </select>
             </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="applicationCycleName">Application Cycle</label>
                <select name="degree"                
                    class="form-control" 
                    name="applicationCycleName"
                    formControlName="applicationCycleName">
                        <option value="">Please Select</option>
                        <option value="Spring">Spring</option>
                        <option value="Summer">Summer</option>
                        <option value="Fall">Fall</option>
                </select> 
                <p *ngIf="f.applicationCycleName?.invalid && (f.applicationCycleName?.dirty || f.applicationCycleName?.touched || isFormValid == false)">
                    <small class="text-danger" *ngIf="f.applicationCycleName?.errors?.required">Application Cycle is required</small>
                </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="applicationCycleYear">Application Cycle Year</label>
                <input type="textbox" 
                    class="form-control" 
                    name="applicationCycleYear"
                    formControlName="applicationCycleYear" />                
                    <p *ngIf="f.applicationCycleYear?.invalid && (f.applicationCycleYear?.dirty || f.applicationCycleYear?.touched || isFormValid == false)">
                        <small class="text-danger" *ngIf="f.applicationCycleYear?.errors?.required">Application Cycle Year is required <br /></small>
                        <small class="text-danger" *ngIf="f.applicationCycleYear?.errors?.pattern">Application Cycle Year must be a 4 digit number<br /></small>
                        <small class="text-danger" *ngIf="f.applicationCycleYear?.errors?.max || f.applicationCycleYear?.errors?.min">Year must be in the past or after the year 2000.</small>
                    </p>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-label">Did you receive the fellowship this application cycle?</div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="recievedFellowshipYes" [value]="true" formControlName="wasFellowshipAwarded" />
                <label class="form-check-label" for="recievedFellowshipYes"> Yes</label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="recievedFellowshipNo" [value]="false" formControlName="wasFellowshipAwarded" />
                <label class="form-check-label" for="recievedFellowshipNo">No</label>
            </div>              
            <p *ngIf="f.wasFellowshipAwarded?.invalid && (f.wasFellowshipAwarded?.dirty || f.wasFellowshipAwarded?.touched || isFormValid == false)">
                <small class="text-danger" *ngIf="f.wasFellowshipAwarded?.errors?.required">"Was the Fellowship Received" is required</small>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 text-right">
            <button class="btn btn-primary" (click)="save()">Save</button>&nbsp;<button class="btn btn-secondary" (click)="cancelForm()">Cancel</button>
        </div>
        <div class="col-md-12 text-right" *ngIf="isFormValid === false && prevSubFG.valid === false">
            <small class="text-danger">You have data missing from this Previous Submission form. Please enter all required fields.</small>
        </div>
    </div>
</div>
