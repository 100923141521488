import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationTypeDTO } from '../../shared/models/ApplicationTypeDTO';
import { CommitteeAssignmentService } from './committee-assignment.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UserSearchResultDTO } from '../models/UserSearchResultDTO';
import { AddReviewerDTO } from '../models/AddReviewerDTO';
import { LookupService } from 'src/app/core/services/lookup.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { ActivatedRoute, Router,RouterModule } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-add-committee-assignment',
  templateUrl: 'add-committee-assignment.component.html',
  styles: []
})
export class AddCommitteeAssignmentComponent implements OnInit {

  @Output()
  reviewerAdded = new EventEmitter<boolean>(true);

  public applicationTypes!: ApplicationTypeDTO[];
  public userResults!: UserSearchResultDTO[];

  public selectedUser!: UserSearchResultDTO;
  public applicationTypeId: number = null!;
 

  private debouncer = new Subject();

  loading = false;

  constructor(private committeeAssignmentService: CommitteeAssignmentService, public authService: AuthService,private lookupService: LookupService, private toastr: ToastrService) { }

  ngOnInit() {
    this.lookupService.applicationTypes$.subscribe(
        data => {
          if (data) { this.applicationTypes= data; }
        }
      );
    this.debouncer.pipe(debounceTime(500))
      .subscribe(event => {
        this.searchUsers(event);
        //alert("debouncer &&&&&&&&"+event);
      }
    );
  }

  searchUsers(value:any) {
    if (value && value.length > 1) {
      console.log(`searching for ${value}`);
      this.committeeAssignmentService.findReviewer(value).subscribe(
        data => {
          this.userResults = data;
          
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        }
      );
    }
  }

  saveReviewer() {
    const newUser: AddReviewerDTO = {
        firstName: this.selectedUser.firstName,
        lastName: this.selectedUser.lastName,
        email: this.selectedUser.email,
        applicationTypeId: this.applicationTypeId
       
    };
//alert("before addRoleAssignment &&&&&&&&");
    this.committeeAssignmentService.addRoleAssignment(newUser).subscribe(
      data => {
        this.reviewerAdded.emit(true);
        this.hideForm();
        this.clearForm();

      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error);
      }
    );
  }

  selectUser(value:any) {
    this.selectedUser = value;
    //alert("selected userName &&&&&&&&"+this.selectedUser.email);
  }

  callDebouncer(event:any) {
    this.debouncer.next(event);
    //alert("callDebouncer &&&&&&&&"+event);
  }

  saveDisabled(): boolean {
    if (this.applicationTypeId == null || this.selectedUser === undefined) {
      return true;
    } else {
      return false;
    }
  }

  clearForm() {
    this.userResults = [];
    this.selectedUser = undefined!;
    this.applicationTypeId = undefined!;
  }

  hideForm() {
    $('#reviewForm').collapse('hide');
  }

/*
  loadPreviousReviewers() {

    this.committeeAssignmentService.addPreviousReviewerRoleAssignments().subscribe(
      data => {
        this.reviewerAdded.emit(true);
        this.hideForm();
        this.clearForm();

      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error);
      }
    );
  }
  */
}
