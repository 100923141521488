<h1>All Applications To Be Scored. (Click <span style="color:red; font-size:120%;">  + </span> to select a number)</h1>
<form novalidate #myForm="ngForm">
    <kendo-grid
      [data]="allView"
      [loading]="loading"
      [sortable]="true"
      [sort]="allState.sort!"
      [pageable]="false"
      [filterable]="true"
      [filter]="allState.filter!"
      [resizable]="true"
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (dataStateChange)="allDataStateChange($event)">
  
     
  
      <kendo-grid-column
        field="applicationNumber"
        title="Application Number"
        [width]="120"
        headerClass="bg-dark text-light"
        [editable]="false"
        [sortable]="false"
        [filterable]="false">
      </kendo-grid-column>
  
     
  
  <kendo-grid-column
  field="applicantName"
  [width]="130"
  headerClass="bg-dark text-light"
  [editable]="false"
        [sortable]="false"
        [filterable]="false">
  <ng-template kendoGridHeaderTemplate>
    Applicant name
  </ng-template>
</kendo-grid-column>
<kendo-grid-column
field="mentorName"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
        [sortable]="false"
        [filterable]="false">
<ng-template kendoGridHeaderTemplate>
  Mentor Name
</ng-template>
</kendo-grid-column>

<kendo-grid-column
field="titleOfProject"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
[sortable]="false"
[filterable]="false">
<ng-template kendoGridHeaderTemplate>
  Project Title
</ng-template>
</kendo-grid-column>

<kendo-grid-column *ngIf="reviewMeeting"
field="avgInitialScore"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
[sortable]="false"
[filterable]="false">
<ng-template kendoGridHeaderTemplate>
  Avg Intitial Score
</ng-template>
</kendo-grid-column>
<kendo-grid-column *ngIf="reviewMeeting"
field="avgFinalScore"
[width]="130"
headerClass="bg-dark text-light"
[editable]="false"
[sortable]="false"
[filterable]="false">
<ng-template kendoGridHeaderTemplate>
  Avg Final Score
</ng-template>
</kendo-grid-column>
<!--
<kendo-grid-column field="avgFinalScore" title="Avg Final Score" [width]="100">
  <ng-template kendoGridCellTemplate let-dataItem>
    <div *ngIf="dataItem.avgFinalScore == 0"></div>
    <div *ngIf="dataItem.avgFinalScore != 0">{{ dataItem.avgFinalScore }}</div>
  </ng-template>
</kendo-grid-column>
-->
<!--
      <kendo-grid-column
        title="Sub"
        [width]="70"
        headerClass="bg-primary text-light"
        [editable]="false"
        [filterable]="false"
        *ngIf="authService.isApplicationsAdmin">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <button type="button" class="btn btn-secondary"
            title="Enter final score for absent reviewer"
            (click)="showSubstituteScoreWindow(dataItem.applicationId, dataItem.committeeAssignmentId)"
            >
            <i class="fa fa-user-secret" aria-hidden="true"></i>
          </button>
        </ng-template>
      </kendo-grid-column>
    
    -->
   
      <kendo-grid-column 
        title="To Be Scored (Final Score)"       
        [width]="170"
        headerClass="bg-primary text-light"
        [editable]="true"
        [sortable]="false"
        [filterable]="false"
        [hidden]="!authService.isReviewer"
       >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          
          <ng-container *ngIf="dataItem.finalReviewId == 0 || dataItem.finalReviewScore == null">
            <button kendoGridEditCommand class="btn btn-sm btn-primary" title="Add Final Score">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </ng-container>
          <ng-container >
            
  
           
            
            <button kendoGridEditCommand class="btn btn-sm btn-secondary" title="Edit Final Score" 
            *ngIf="dataItem.finalReviewScore > 0">
              {{dataItem.finalReviewScore ||''}}
            </button>
  
           
          </ng-container>
          <span [id]="'spin' + rowIndex"></span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <select class="form-control mr-3" id="score" name="score" required
            [(ngModel)]="currentModScore">
            
            <optgroup label="Score">
              <option [value]="1" >1</option>
              <option [value]="2">2</option>
              <option [value]="3">3</option>
              <option [value]="4">4</option>
              <option [value]="5">5</option>
              <option [value]="6">6</option>
              <option [value]="7">7</option>
              <option [value]="8">8</option>
              <option [value]="9">9</option>
            </optgroup>
           
          </select>
  
          <button kendoGridSaveCommand class="btn btn-sm btn-primary mt-1 clearfix" [disabled]="!currentModScore || currentModScore.length == 0"
            > Save
          </button>
          <button kendoGridCancelCommand class="btn btn-sm btn-secondary mt-1 ml-1">Cancel</button>
          <span [id]="'spin' + rowIndex"></span>
        </ng-template>
      </kendo-grid-column>
  
      <kendo-grid-column 
      [width]="180"
        headerClass="bg-secondary text-light"
        title="Actionss"
        >
        <ng-template kendoGridCellTemplate let-dataItem>
          
          <app-download-pdf
          [applicationId]="dataItem.applicationId" 
          extraClass="float-left mb-1 mr-1">
        </app-download-pdf>
  
        <button class="btn btn-sm btn-secondary" 
    title="Others Review"   *ngIf="dataItem.canseeOthers === true"          
    
    (click)="otherReviewer(dataItem.applicationId) ">
    <i class="fa fa-plus" aria-hidden="true"></i> Review Others Review
  </button>
  
        </ng-template>
      </kendo-grid-column>

     
  
    </kendo-grid>
    <app-others-review #othersReviewModal (othersChanged)="onOthersChanged($event)"></app-others-review>
  </form>
  
  <div class="k-overlay" *ngIf="windowOpened"></div>
  <div kendoWindowContainer></div>