import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { ConfigService } from './core/services/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title : string  = 'PelotoniaFellowship';
  loginAvailable= ConfigService.settings.const.LOGIN_AVAILABLE;
 
  public offline : boolean = false;
  showTracking = true;

  currentUrl = '';

  constructor(public authSvc: AuthService,
    private router: Router) { }

  ngOnInit() {

    if (window.location.href.toLowerCase().indexOf('pelotoniafellowship') > 0) {
      window.location.href = 'https://pelotoniascholars.osumc.edu/';
    }

    if (window.location.href.indexOf('?postLogout=true') > 0) {
      this.authSvc.signoutRedirectCallback().then(() => {
        const url: string = this.router.url.substring(
          0,
          this.router.url.indexOf('?')
        );
        this.router.navigateByUrl(url);
      });
    }
    const d = new Date(this.loginAvailable);
    let now = new Date();
    if(d > now)
    	{ this.offline= true; }
    else{  this.offline=false;}
    	this.showTracking = ConfigService.settings.options.showTracking;

	this.router.events.subscribe((e) => {
		if (e instanceof NavigationEnd) {
			if (e.url != '') {
				this.currentUrl = e.url;
			} else {
				this.currentUrl = '';
			}
		}
	});
  }

  login(): void {
    const header = document.getElementById('loginHeader')!;
    header.innerHTML = '<i class="fa fa-circle-o-notch fa-spin fa-fw text-light"></i>';    
    this.authSvc.startAuthentication();
  }

  logout(): void {
    this.authSvc.logout();
  }
}
