import { Component, OnInit } from '@angular/core';
import { CommitteeAssignmentService } from './committee-assignment.service';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process } from '@progress/kendo-data-query';
import { CommitteeAssignmentDTO } from 'src/app/shared/models/CommitteeAssignmentDTO';
import { ToastrService } from 'ngx-toastr';
import { ApplicationTypeDTO } from '../../shared/models/ApplicationTypeDTO';
import { LookupService } from 'src/app/core/services/lookup.service';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-list-reviewers',
  templateUrl: 'list-reviewers.component.html',
  styles: []
})
export class ListReviewersComponent implements OnInit {

  public gridView!: GridDataResult;
  private committeeAssignments!: CommitteeAssignmentDTO[];

  private editedRowIndex!: number;
  private editedCommitteeAssignment!: CommitteeAssignmentDTO;

  public applicationTypes!: ApplicationTypeDTO[];

  public state: State = {
    skip: 0,
    sort: [{
      field: 'modifiedDate',
      dir: 'desc'
    }]
  };

  constructor(
    private  committeeAssignmentService:  CommitteeAssignmentService,
    public authService: AuthService,
    private lookupService: LookupService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.getAllReviewers();

    this.lookupService.applicationTypes$.subscribe(
        data => {
          if (data) { this.applicationTypes= data; }
        }
      );
  }

  onReviewerAdded(event: boolean) {
    if (event) {
      this.toastr.success('Committee Assignment added', undefined, {closeButton: false, timeOut: 3000});
      this.getAllReviewers();
    } else { }
  }


  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.committeeAssignments, this.state);
  }

  public editHandler({sender, rowIndex, dataItem}:{sender:any, rowIndex:any, dataItem:any})
  {
    this.closeEditor(sender);

    this.editedRowIndex = rowIndex;
    this.editedCommitteeAssignment = Object.assign({}, dataItem);

    sender.editRow(rowIndex);
  }

  public cancelHandler({sender, rowIndex}:{sender:any, rowIndex:any}) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({sender, rowIndex, dataItem, isNew}:{sender:any, rowIndex:any, dataItem:any, isNew:any}) {
    
    console.log("dataItem &&&&&&&&"+dataItem);
    this.committeeAssignmentService.updateRoleAssignment(dataItem).subscribe(
      data => {
        //this.toastr.success('Committee Assignment Updated!', undefined, {closeButton: false, timeOut: 3000});
        this.toastr.success('Committee Assignment Updated!', '', {closeButton: false, timeOut: 3000});
        this.getAllReviewers();
      },
      error => {
        //console.log(JSON.stringify(error));
        //this.toastr.error(error);
        console.log(JSON.stringify(error));
        this.toastr.error(error);
              //this.toastr.error(error.statusText, 'Error Committee Assignment Updated');
      }
    );

    sender.closeRow(rowIndex);

    this.editedRowIndex = undefined!;
    this.editedCommitteeAssignment = undefined!;
  }

  public removeHandler({dataItem}:{dataItem:any}) {
   // alert("committeeAssignmentId &&&&&&&&"+dataItem.committeeAssignmentId);
    this.committeeAssignmentService.deleteRoleAssignment(dataItem.committeeAssignmentId).subscribe(
      data => {
        this.toastr.success('Committee Assignment Deleted!', '', {closeButton: false, timeOut: 3000});
        this.getAllReviewers();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error);
      }
    );
  }


  private getAllReviewers() {
    
    this.committeeAssignmentService.getReviewers().subscribe(
      data => {
        this.committeeAssignments = data;
        this.loadItems();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error.statusText, 'Error');
      }
    );
  }


  private closeEditor(grid:any, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    // this.roleService.resetItem(this.editedReviewer);
    this.editedRowIndex = undefined!;
    this.editedCommitteeAssignment = undefined!;
  }

  private loadItems(): void {
    this.gridView = process(this.committeeAssignments, this.state);
  }

}
