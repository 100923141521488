 <div id="othersReviewModal" class="modal fade" tabindex="-1"  role="dialog" data-backdrop="static">
    <div  class="modal-dialog  modal-xl" style="max-width: 100%;"  role="document">
      <div class="modal-content" >
    <div class="modal-header">
      <h5 class="modal-title text-dark">Other Reviewer Comments for this application</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <form novalidate #myForm="ngForm" > 
      <kendo-grid
      [data]="gridView"
      [pageSize]="state.take!"
      [skip]="state.skip!"
     
     
      [pageable]="false"
     
      [reorderable]="true"
      [resizable]="true"
     
      style="height:auto"
      class="mb-5">  
  <!--
      <kendo-grid-column
      field="reviewerLastName"
      title="Reviewer Name"
      [hidden]="!authService.isApplicationsAdmin">
      [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.reviewerLastName}}
      </ng-template>
    </kendo-grid-column>
  -->
    <kendo-grid-column
    field="reviewerOrderName"
    title="Reviewer"
    [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.reviewerOrderName}}
    </ng-template>
  </kendo-grid-column>
    <kendo-grid-column
    field="initialScore"
    title="InitialScore"
    [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.initialScore}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    field="weightedScore"
    title="WeightedScore"
    [width]="80">
    <ng-template kendoGridCellTemplate let-dataItem>
    {{dataItem.weightedScore}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
  field="strength"
  title="Strength"
  [width]="500">
  <ng-template kendoGridCellTemplate let-dataItem>
  {{dataItem.strength}}
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
field="weakness"
title="Weakness"
[width]="220">
<ng-template kendoGridCellTemplate let-dataItem>
{{dataItem.weakness}}
</ng-template>
</kendo-grid-column>

<kendo-grid-column
field="additionalComments"
title="Additional Comments"
[width]="500">
<ng-template kendoGridCellTemplate let-dataItem>
{{dataItem.additionalComments}}
</ng-template>
</kendo-grid-column>

    </kendo-grid>
   
    </form>
   
  </div>
  </div>

  </div>