import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ApplicationDocumentDTO } from 'src/app/shared/models/ApplicationDocumentDTO';

@Injectable({ 
    providedIn: 'root'
})
export class ApplciationDocumentService {
    
    private readonly apiUrl: string = `${ConfigService.settings.urls.apiUrl}\\applicationdocument`;

    
    private readonly getAllByApplicationUrl: string = `${this.apiUrl}\\all\\byapplication\\`;


    public readonly DocumentUploadUrl: string = `${this.apiUrl}\\upload`;
    public readonly DocumentDownloadUrl: string = `${this.apiUrl}\\download\\`;
    public readonly DocumentRemoveUrl: string = `${this.apiUrl}\\remove\\`;

    public readonly APPLICATIONBIOSKETCHDOCTYPE: number = 1;
    public readonly MENTORBIOSKETCHDOCTYPE: number = 2;
    public readonly COMENTORBIOSKETCHDOCTYPE: number = 3;
    public readonly MENTORLETTEROFSUPPORTDOCTYPE: number = 4;
    public readonly COMENTORLETTEROFSUPPORTDOCTYPE: number = 5;
    public readonly RECOMMEDERLETTEROFSUPPORTDOCTYPE: number = 6;
    public readonly RESEARCHPROPOSALDOCTYPE: number = 7;
    public readonly REBUTTALLETTERDOCTYPE: number = 8;  
    public readonly MENTORTRAININGLETTERDOCTYPE: number = 9;  
    public readonly COMENTORTRAININGLETTERDOCTYPE: number = 10;  
    
    
    public readonly RESUBMISSIONCRITIQUEDOCTYPE: number = 11;    

    constructor(protected http: HttpClient,
        protected authService: AuthService) { }

    public getAllByApplicationId(id: number): Observable<ApplicationDocumentDTO[]> {
        return this.http.get<ApplicationDocumentDTO[]>(this.getAllByApplicationUrl + id);
    }

    public download(id: number) {
        return this.http.get(this.DocumentDownloadUrl + id, {responseType: 'blob'});
    }

    public remove(id: number) {
        return this.http.post(this.DocumentRemoveUrl + id, {});
    }
}

export function saveBlob(blob: Blob, fileName: string) {
    //window.navigator.msSaveBlob(blob, fileName);    
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    link.remove();
}