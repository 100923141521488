import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApplicationAssignedReviewerDTO } from 'src/app/admin/models/ApplicationAssignedReviewerDTO';
import { AssignService } from 'src/app/admin/assign-reviewers/assign-reviewers.service';
import { ApplicationDTO } from 'src/app/shared/models/ApplicationDTO';
import { CommitteeAssignmentDTO } from 'src/app/shared/models/CommitteeAssignmentDTO';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import { HttpParams } from '@angular/common/http';
import { ApplicationAssignedReviewerGridDTO } from 'src/app/admin/models/ApplicationAssignedReviewerGridDTO';

declare var $: any;

@Component({
  selector: 'app-assign-reviewers',
  templateUrl: 'assign-reviewers.component.html',
  styles: []
})
export class AssignReviewersComponent implements OnInit {
  @Output() reviewersChanged = new EventEmitter<boolean>(true);
  public gridView!: GridDataResult;
  applicationId!: number;
  applicantName: string;
  assignedReviewers!: ApplicationAssignedReviewerGridDTO[];
  reviewers: { items: CommitteeAssignmentDTO[] }[] = [];

  private editedRowIndex!: number;
  private editedApplicationAssignedReviewerDTO!: ApplicationAssignedReviewerDTO;
 // locReviewers: RoleAssignmentDTO[] = [];
 public state: State = {
  skip: 0,
  sort: [{
    field: 'reviewerDisplayName',
   // dir: 'desc'
  }]
};
  constructor(private toastr: ToastrService, private assignService: AssignService) {}

  ngOnInit() {}

  init(applicationId: number, applicationTypeId: number, applicantName: string): void {
   
    this.applicationId = applicationId;
    //alert("applicantName is  $$$$$$"+applicantName+ "applicationId is  "+applicationId);
    this.applicantName = applicantName;
    this.getReviewerAssignedGrid(this.applicationId,applicationTypeId); 
  }


 private getReviewerAssignedGrid(applicationId:number,applicationTypeId:number){
  this.assignService.getReviewersOfApplicationAndType(applicationId,applicationTypeId).subscribe(
    data => {
      this.assignedReviewers=data;
      this.gridView = process(this.assignedReviewers, this.state);
      this.show();
    },
    error => {
      console.log(JSON.stringify(error));
      this.toastr.error(error.statusText, 'Error');
    }
  );
 }
  public dataStateChange(state: DataStateChangeEvent): void {
   
    this.state = state;
    this.gridView = process(this.assignedReviewers, this.state);
  }

  public editHandler({sender, rowIndex, dataItem}:{sender:any, rowIndex:any, dataItem:any})
  {  
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    this.editedApplicationAssignedReviewerDTO= Object.assign({}, dataItem);
    sender.editRow(rowIndex);
  }

  public cancelHandler({sender, rowIndex}:{sender:any, rowIndex:any}) {  
    this.closeEditor(sender, rowIndex);
  }
  
  hasAssigedSwitchChangeHandler(event: boolean, dataItem:any) {
    if (event) {
      //alert("hasAssigedSwitchChangeHandler"+event);
      this.assignService.addReviewerToApplication(dataItem).subscribe(
        data => {        
          this.toastr.success('Reviewer Assigned', undefined, { closeButton: false, timeOut: 3000 }); 
         this.getReviewerAssignedGrid(dataItem.applicationId, dataItem.applicationTypeId);
         this.gridView = process(this.assignedReviewers, this.state);
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error.statusText, 'Error');
        }
      );
    } else {
      this.assignService.deleteReviewerFromApplication(dataItem.applicationAssignedReviewerId).subscribe(
        data => {
          this.toastr.success('Reviewer Removed', undefined, { closeButton: false, timeOut: 3000 });
          this.getReviewerAssignedGrid(dataItem.applicationId, dataItem.applicationTypeId);
          this.gridView = process(this.assignedReviewers, this.state);
        },
        error => {
          console.log(JSON.stringify(error));
          this.toastr.error(error);
          //this.dataStateChange = undefined!;
          this.close();
          //this.toastr.error(error.statusText, 'Error');
        }
      );
      
    }
    
  }
  
  public saveHandler({sender, rowIndex, dataItem, isNew}:{sender:any, rowIndex:any, dataItem:any, isNew:any}) {  
    //alert("saveHandler%%%%%%"+rowIndex);  
    this.assignService.addReviewerToApplication(dataItem).subscribe(
      data => {
        this.toastr.success('Reviewer added To Application!', undefined, {closeButton: false, timeOut: 3000});
        //this.getAllReviewers();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error);
      }
    );

    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined!;
    this.editedApplicationAssignedReviewerDTO = undefined!;
  }

  public removeHandler({dataItem}:{dataItem:any}) {   
    //alert("removeHandler%%%%%%    "+dataItem.applicationAssignedReviewerId);  
    this.assignService.deleteReviewerFromApplication(dataItem.applicationAssignedReviewerId).subscribe(
      data => {
        this.toastr.success('Reviewer has been removed From Application', undefined, {closeButton: false, timeOut: 3000});
        //this.getAllReviewers();
      },
      error => {
        console.log(JSON.stringify(error));
        this.toastr.error(error);
      }
    );
  }


 
  private closeEditor(grid:any, rowIndex = this.editedRowIndex) { 
   // alert("closeEdito%%%%%%    "+rowIndex); 
    grid.closeRow(rowIndex);
    // this.roleService.resetItem(this.editedReviewer);
    this.editedRowIndex = undefined!;
    this.editedApplicationAssignedReviewerDTO = undefined!;
  }
  
  private loadItems(): void {
   
    this.gridView = process(this.assignedReviewers, this.state);
  }

 show(): void {
    $('#assignModal').modal('show');
  }

  close(): void {
   // alert("Close &&&&&&&&");
    $('#assignModal').modal('hide');
    this.applicationId = undefined!;
    this.reviewers = [];
    //this.locReviewers = [];
    this.assignedReviewers = [];
    this.reviewersChanged.emit(true);
  }
}

