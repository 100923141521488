<h1>My assigned Applications</h1>
<kendo-grid 
  [data]="myView"
  [sortable]="true"
  [sort]="mySort"
  (sortChange)="sortMine($event)"
 
  [pageable]="false"
  [filterable]="false"
  [resizable]="true">

  <kendo-grid-column 
    field="applicationNumber"
    title="Application Number"
    [width]="130"
    headerClass="bg-dark text-light">
  </kendo-grid-column>

  
  
  <kendo-grid-column
    field="applicantName"
    [width]="130"
    headerClass="bg-dark text-light">
    <ng-template kendoGridHeaderTemplate>
      Applicant name
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
  field="mentorName"
  [width]="130"
  headerClass="bg-dark text-light">
  <ng-template kendoGridHeaderTemplate>
    Mentor Name
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
field="titleOfProject"
[width]="130"
headerClass="bg-dark text-light">
<ng-template kendoGridHeaderTemplate>
    Project Title
</ng-template>
</kendo-grid-column>


<kendo-grid-column
field="initialScore"
[width]="80"
headerClass="bg-dark text-light">
<ng-template kendoGridHeaderTemplate>
    Initial Score
</ng-template>
</kendo-grid-column>


<kendo-grid-column
field="weightedScore"
[width]="80"
headerClass="bg-dark text-light">
<ng-template kendoGridHeaderTemplate>
    Weighted Score
</ng-template>
</kendo-grid-column>
<kendo-grid-column
field="reviewerOrderName"
[width]="100"
headerClass="bg-dark text-light">
<ng-template kendoGridHeaderTemplate>
    Reviewer Order
</ng-template>
</kendo-grid-column>
 
  

  <kendo-grid-column
  [width]="240"
    title="Actions"
    headerClass="bg-dark text-light">
    <ng-template kendoGridCellTemplate let-dataItem>

      <app-download-pdf
        [applicationId]="dataItem.applicationId" 
        extraClass="float-left mb-1 mr-1">
      </app-download-pdf>

      <!--<button
        *ngIf="dataItem.reviewId == 0"
        [disabled]="noCurrentCycle"
        class="btn btn-sm btn-secondary"
        title="Begin review"
        (click)="addReview(dataItem.applicationId)">
        <i class="fa fa-plus" aria-hidden="true"></i> Add Review
      </button>

      <button
        *ngIf="dataItem.reviewId > 0"
        class="btn btn-sm btn-secondary"
        title="Edit review"
        (click)="review(dataItem.reviewId)">
        <i class="fa fa-star-half-o" aria-hidden="true"></i> Edit Review
      </button>
     
    -->
    <button
    *ngIf="dataItem.reviewId == 0 && dataItem.applicationTypeName == 'Postdoc' "
    [disabled]="noPostdocReview"
    class="btn btn-sm btn-secondary"
    title="Begin review"
    (click)="addReview(dataItem.applicationId)">
    <i class="fa fa-plus" aria-hidden="true"></i> Add Review
  </button>

  <button
    *ngIf="dataItem.reviewId > 0 && dataItem.applicationTypeName == 'Postdoc'"
    [disabled]="noPostdocReview"
    class="btn btn-sm btn-secondary"
    title="Edit review"
    (click)="review(dataItem.reviewId)">
    <i class="fa fa-star-half-o" aria-hidden="true"></i> Edit Review
  </button>
  <button
  *ngIf="dataItem.reviewId == 0 && dataItem.applicationTypeName == 'Graduate' "
  [disabled]="noGradReview"
  class="btn btn-sm btn-secondary"
  title="Begin review"
  (click)="addReview(dataItem.applicationId)">
  <i class="fa fa-plus" aria-hidden="true"></i> Add Review
</button>

<button
  *ngIf="dataItem.reviewId > 0 && dataItem.applicationTypeName == 'Graduate'"
  [disabled]="noGradReview"
  class="btn btn-sm btn-secondary"
  title="Edit review"
  (click)="review(dataItem.reviewId)">
  <i class="fa fa-star-half-o" aria-hidden="true"></i> Edit Review
</button>
<button
*ngIf="dataItem.reviewId == 0 && dataItem.applicationTypeName == 'Undergraduate' "
[disabled]="noUndergradReview"
class="btn btn-sm btn-secondary"
title="Begin review"
(click)="addReview(dataItem.applicationId)">
<i class="fa fa-plus" aria-hidden="true"></i> Add Review
</button>

<button
*ngIf="dataItem.reviewId > 0 && dataItem.applicationTypeName == 'Undergraduate'"
[disabled]="noUndergradReview"
class="btn btn-sm btn-secondary"
title="Edit review"
(click)="review(dataItem.reviewId)">
<i class="fa fa-star-half-o" aria-hidden="true"></i> Edit Review
</button>
<button
*ngIf="dataItem.reviewId == 0 && dataItem.applicationTypeName == 'Medical' "
[disabled]="noMedicalReview"
class="btn btn-sm btn-secondary"
title="Begin review"
(click)="addReview(dataItem.applicationId)">
<i class="fa fa-plus" aria-hidden="true"></i> Add Review
</button>

<button
*ngIf="dataItem.reviewId > 0 && dataItem.applicationTypeName == 'Medical'"
[disabled]="noMedicalReview"
class="btn btn-sm btn-secondary"
title="Edit review"
(click)="review(dataItem.reviewId)">
<i class="fa fa-star-half-o" aria-hidden="true"></i> Edit Review
</button>
    <button class="btn btn-sm btn-secondary" 
    title="Others Review"  *ngIf="dataItem.canseeOthers === true"            
    
    (click)="otherReviewer(dataItem.applicationId) ">
    <i class="fa fa-plus" aria-hidden="true"></i> Review Others Review
  </button>

    
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
<app-others-review #othersReviewModal (othersChanged)="onOthersChanged($event)"></app-others-review>