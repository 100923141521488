<button type="button" class="btn btn-secondary mr-3" data-toggle="modal" data-target="#mentorModal" title="Add OSU Mentor"
  *ngIf="appMentor?.mentorId == null && !isCoMentor"> 
    <i class="fa fa-plus" aria-hidden="true"></i> Add OSU Mentor
</button>

<button type="button" class="btn btn-secondary " data-toggle="modal" data-target="#mentorNonOsuModal" title="Add Non OSU Mentor"
  *ngIf="appMentor?.mentorId == null && !isCoMentor">
    <i class="fa fa-plus" aria-hidden="true"></i> Add Non OSU Mentor
</button>

<button type="button" class="btn btn-secondary mr-3" data-toggle="modal" data-target="#comentorModal" title="Add OSU Mentor"
  *ngIf="appMentor?.coMentorId == null && isCoMentor"> 
    <i class="fa fa-plus" aria-hidden="true"></i> Add OSU CoMentor
</button>

<button type="button" class="btn btn-secondary " data-toggle="modal" data-target="#comentorNonOsuModal" title="Add Non OSU Mentor"
  *ngIf="appMentor?.coMentorId == null && isCoMentor">
    <i class="fa fa-plus" aria-hidden="true"></i> Add Non OSU CoMentor
</button>

<div [id]="isCoMentor ? 'comentorModal' : 'mentorModal'" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="!isCoMentor">
            Add New Mentor
          </h5>
          <h5 class="modal-title" *ngIf="isCoMentor">
            Add New CoMentor
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div style="padding:30px">

            <div *ngIf="loading" style="margin-top:-30px;height:30px">
                <i class="fa-solid fa-sync fa-spin"></i>
            </div>
            <div *ngIf="errorMessage" class="alert alert-dismissible alert-danger" role="alert">
              <p [innerHTML]="errorMessage"></p>
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
  
            <kendo-combobox
              [data]="searchResults"
              [textField]="'fullName'"
              [valueField]="'cupId'"
              [filterable]="true"
              (filterChange)="callDebouncer($event)"
              [placeholder]="'Search by Last Name'"
              (valueChange)="chooseMentor($event)">
              <ng-template kendoComboBoxItemTemplate let-dataItem>
              {{ dataItem.fullName }} ( {{ dataItem.nameNumber }})
              </ng-template>
            </kendo-combobox>
  
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="closeOSU()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="saveOSU()">Add</button>
        </div>
      </div>
    </div>
</div>


<div [id]="isCoMentor ? 'comentorNonOsuModal' : 'mentorNonOsuModal'" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="!isCoMentor">
            Add New Non-OSU Mentor
          </h5>
          <h5 class="modal-title" *ngIf="isCoMentor">
            Add New Non-OSU CoMentor
          </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
  
          <div name="mentorFirstName" class="form p-3" >
            <div class="form-row">
              <label for="mentorFirstName" class="control-label col-md-4">Mentor First Name <span class="text-primary">*</span> </label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="mentorFirstName" name="mentorFirstName" required>
                 
              </div>
            </div>
          </div>
  
          <div name="mentorLastName" class="form p-3" >
            <div class="form-row">
              <label for="mentorLastName" class="control-label col-md-4">Mentor Last Name <span class="text-primary">*</span></label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="mentorLastName" name="mentorLastName"  required>
                 
              </div>
            </div>
          </div>
  
  
          <div name="mentorEmailAddress" class="form p-3">
            <div class="form-row" >
              <label for="mentorEmailAddress" class="control-label col-md-4">Mentor Email Address <span class="text-primary">*</span></label>
              <div class="col-md-8">
                  <input type="text" class="form-control"
                  [(ngModel)]="mentorEmailAddress" name="mentorEmailAddress"  required>
                
              </div>
            </div>
          </div>        
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="closeNonOSU()">Cancel</button>
            <button type="button" class="btn btn-primary" (click)="saveNonOSU()">Add</button>
        </div>
      </div>
    </div>
  </div>